import React, { useState, useEffect } from 'react';
import { Row, Col, FormGroup, Input } from 'reactstrap';
import Drawer from './Drawer';

import { partial } from '../../../util/translation/translation';
import QuestionType from '../../events/components/QuestionnaireBuilder/QuestionType';

const TextFieldModal = ({
  isVisible,
  close,
  selectedAnswer,
  questionNumber,
}) => {
  const [searchKey, setSearchKey] = useState('');
  const [filteredAnswers, setFilteredAnswers] = useState([]);

  useEffect(() => {
    if (searchKey === '') {
      setFilteredAnswers(selectedAnswer?.answers || []);
    } else {
      const filteredQ =
        selectedAnswer?.answers?.filter((answer) =>
          answer.text.includes(searchKey)
        ) || [];
      setFilteredAnswers(filteredQ);
    }
  }, [searchKey, selectedAnswer]);

  const qS = partial('question');
  if (!isVisible) {
    return null;
  }
  return (
    <Drawer isOpen={isVisible} onClose={close} position="right" width="480px">
      <Drawer.Body>
        <div className="summary-question-card">
          <div className="content-area">
            <Row>
              <Col className="question-header">
                <div className="question-id">
                  <p>{questionNumber}</p>
                </div>
                <div className="question-description">
                  <h5>{selectedAnswer.title}</h5>
                  <p>
                    {`${qS('answers')} ${selectedAnswer.totalAnswers} ${qS('users')} ${selectedAnswer.totalUsers}`}
                  </p>
                </div>
              </Col>
              {selectedAnswer.answers.length > 0 && (
                <Col className="col-3 d-flex justify-content-end">
                  <QuestionType type="text_field" />
                </Col>
              )}
            </Row>
            <FormGroup style={{ width: '100%', marginTop: '20px' }}>
              <Input
                type="text"
                name="textarea"
                onChange={(event) => setSearchKey(event.target.value)}
                placeholder="Search"
                bsSize="sm"
              />
            </FormGroup>
          </div>
          <hr />
          <div className="content-area">
            <div className="question-content">
              <div>
                <Row>
                  <div className="recent-answers">
                    <p>{qS('recentAnswers')}</p>
                  </div>
                </Row>
                {filteredAnswers &&
                  filteredAnswers.map(
                    (answer) =>
                      answer.text !== '' && (
                        <Row key={answer.text}>
                          <div
                            style={{
                              width: '100%',
                              display: 'flex',
                              padding: '12px 16px',
                              alignItems: 'flex-start',
                              alignSelf: 'stretch',
                              borderRadius: '4px',
                              border: '1px solid #e8eaed',
                              backgroundColor: '#fff',
                              marginTop: '12px',
                            }}
                          >
                            <p style={{ color: '#121b2b' }}>{answer.text}</p>
                          </div>
                        </Row>
                      )
                  )}
              </div>
            </div>
          </div>
        </div>
      </Drawer.Body>
    </Drawer>
  );
};

export default TextFieldModal;
