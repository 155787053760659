import React, { useState, useEffect } from 'react';
import { Container, Card, CardBody, Row, Col } from 'reactstrap';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import BalancePackageForm from './components/BalancePackageForm';
import Loading from '../../shared/components/custom/Loading';
import Api from '../../util/api';
import { convertImageToBlob } from '../../util/image-functions';
import { partial } from '../../util/translation/translation';
import { formatDateForApi } from '../../util/date-and-time';

const p = partial('balancePackageShared');
const s = partial('shared');

const BalancePackageFormPage = () => {
  const history = useHistory();
  const [balancePackage, setBalancePackage] = useState({});
  const [loadingBalancePackage, setLoadingBalancePackage] = useState(false);

  const { packageID } = useParams();

  const getBalancePackage = async () => {
    setLoadingBalancePackage(true);
    try {
      const resBalancePackage = await Api.packages.getBalancePackage(packageID);
      setBalancePackage(resBalancePackage.data);
      setLoadingBalancePackage(false);
    } catch (err) {
      toast.error(err || `${p('balancePackageRetrievingERROR')}!`);
      setLoadingBalancePackage(false);
    }
  };

  useEffect(() => {
    if (packageID) {
      getBalancePackage();
    }
  }, []);

  const handleSubmit = async (formValues) => {
    setLoadingBalancePackage(true);
    try {
      const removeImage = formValues.image && formValues.image.length === 0;
      let blobImage = '';
      if (
        formValues.image &&
        formValues.image.length !== 0 &&
        formValues.image[0].size !== undefined
      ) {
        blobImage = await convertImageToBlob(formValues.image[0]);
      }

      const payload = {
        name: formValues.name,
        sale_start: formatDateForApi(formValues.sale_start),
        sale_end: formatDateForApi(formValues.sale_end),
        price: formValues.price,
        saldo: formValues.balance,
        description: formValues.description,
        image: blobImage,
        remove_image: removeImage,
      };

      if (packageID) {
        await Api.packages.editBalancePackage(payload, packageID);
        toast.success(`${p('packageUpdated')}`);
        history.replace('/balances');
      } else {
        await Api.packages.createBalancePackage(payload);
        toast.success(`${p('packageUpdated')}`);
        history.replace('/balances');
      }
      setLoadingBalancePackage(false);
    } catch (err) {
      setLoadingBalancePackage(false);
      toast.error(err || `${p('balancePackageUpdatingERROR')}!`);
    }
  };

  return (
    <Container>
      <Loading loading={loadingBalancePackage} />
      <Row>
        <Col>
          <h3 className="page-title">
            {packageID ? `${s('edit')}` : `${s('create')}`}{' '}
            {p('balancePackage')}
          </h3>
        </Col>
      </Row>
      <Card>
        <CardBody>
          <BalancePackageForm
            balancePackage={balancePackage}
            type={packageID ? 'edit' : 'create'}
            onSubmit={handleSubmit}
          />
        </CardBody>
      </Card>
    </Container>
  );
};

export default BalancePackageFormPage;
