import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { toast } from 'react-toastify';
import { partial } from '../../../util/translation/translation';
import { formatDate, formatDateTime } from '../../../util/date-and-time';

const p = partial('box_officeShare');
const s = partial('shared');
const Navbar = (props) => {
  const [optionMatches, setOptionMatches] = useState([]);
  const [visible, setVisible] = useState(false);
  const [selectedMatch, setSelectedMatch] = useState(null);
  const [transaction, setTransaction] = useState('');

  useEffect(() => {
    setOptionMatches(
      props.matches.map((match) => ({
        value: match.id.toString(),
        label: (
          <>
            <b>
              {match.home_team.shortname} - {match.away_team.shortname}
            </b>{' '}
            {formatDate(match.match_starts_at)}
          </>
        ),
      }))
    );
  }, [props.matches]);

  useEffect(() => {
    const match = props.matches.find(
      (m) => m.id === Number(props.selectedMatchID)
    );
    if (match) {
      setSelectedMatch(match);
    } else {
      setSelectedMatch(null);
    }
  }, [props.selectedMatchID]);

  const toggleModal = (state = !visible) => {
    if (props.selectedMatchID === 'none' && state === true) {
      toast.warning(`${p('choseAMatchERROR')}`);
      return;
    }
    setVisible(state);
    props.getPurchaseHistory(props.selectedMatchID, transaction);
  };

  const searchTransactions = (event) => {
    event.preventDefault();
    props.getPurchaseHistory(props.selectedMatchID, transaction);
  };

  const renderPurchaseHistory = () => {
    return props.purchaseHistory.map((ticket) => {
      const seatsText = ticket.reduce((acc, t, index) => {
        if (ticket.length === index + 1) {
          return `${acc}${t.seat_match.seat.seat_id}`;
        }
        return `${acc}${t.seat_match.seat.seat_id}, `;
      }, '');
      return (
        <div className="navbar-modal-ticket" key={`purchase-${ticket[0].id}`}>
          <div className="space-between flex-1">
            <p>
              {s('field')} {ticket[0].seat_match.seat.row.field.name} {s('row')}{' '}
              {ticket[0].seat_match.seat.row.number} {s('seat')} {seatsText}
            </p>
            <div className="textalign-right">
              <p>
                {s('reference')}: {ticket[0].payment.transaction_id}
              </p>
              <p>
                {p('purchaseDate')}:{' '}
                {formatDateTime(ticket[0].payment.bought_at)}
              </p>
            </div>
          </div>
          <button onClick={() => props.handlePurchaseHistorySelect(ticket)}>
            {p('printAgain')}
          </button>
        </div>
      );
    });
  };

  return (
    <div className="navbar">
      <div className="navbar-left">
        <Link to="/">
          <button>
            <div className="back-symbol" />
          </button>
        </Link>
        <Select
          className="navbar-match-select"
          options={optionMatches}
          onChange={props.handleSelect}
          placeholder={p('chooseAMatch')}
          clearable={false}
          searchable={false}
          value={
            props.selectedMatchID ? props.selectedMatchID.toString() : undefined
          }
        />
      </div>
      <button onClick={() => toggleModal()} className="navbar-purchase-history">
        {p('purchaseHistory')}
      </button>

      <Modal
        isOpen={visible}
        toggle={() => {
          toggleModal();
        }}
        className="theme-light"
        centered
        size="lg"
      >
        <ModalHeader className="navbar-modal-header">
          {selectedMatch && (
            <>
              <p>
                <b>
                  {selectedMatch.home_team.name} -{' '}
                  {selectedMatch.away_team.name}
                </b>{' '}
                {formatDate(selectedMatch.match)}
              </p>
              <form className="flex form" onSubmit={searchTransactions}>
                <input
                  value={transaction}
                  onChange={(event) => setTransaction(event.target.value)}
                  placeholder={p('referenceNumber')}
                />
              </form>
            </>
          )}
        </ModalHeader>
        <ModalBody>
          <div className="navbar-modal-content">{renderPurchaseHistory()}</div>
        </ModalBody>
      </Modal>
    </div>
  );
};

Navbar.propTypes = {
  matches: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      home_team: PropTypes.shape({
        shortname: PropTypes.string,
      }),
      away_team: PropTypes.shape({
        short_name: PropTypes.string,
      }),
    })
  ).isRequired,
  handleSelect: PropTypes.func.isRequired,
  selectedMatchID: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  getPurchaseHistory: PropTypes.func.isRequired,
  purchaseHistory: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({})))
    .isRequired,
  handlePurchaseHistorySelect: PropTypes.func.isRequired,
};

export default Navbar;
