import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Alert, Row, Col, Button, ListGroup, Input, Table } from 'reactstrap';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import ArrowUpIcon from 'mdi-react/ArrowUpIcon';
import ArrowDownIcon from 'mdi-react/ArrowDownIcon';
import renderField from '../../../shared/components/custom/Field';
import { partial } from '../../../util/translation/translation';
import Api from '../../../util/api';

const s = partial('shared');
const LevelInformation = ({
  selectedLevel,
  type,
  handleSubmit,
  initialize,
  updateSelectedCompanies,
  handleModalTypeChangeToEdit,
}) => {
  const [allCompanies, setAllCompanies] = useState([]);
  const [isEditLevel, setIsEditLevel] = useState(type === 'edit');
  const [isViewLevel, setIsViewLevel] = useState(type === 'view');
  const [sort, setSort] = useState({
    field: 'name',
    direction: false,
  });
  const sortComps = (companies, direction, field) => {
    const sortDir = direction ? -1 : 1;
    const companiesToSort = [...companies];
    switch (field) {
      case 'name':
        return companiesToSort.sort(
          (a, b) => sortDir * a.name.trim().localeCompare(b.name.trim())
        );
      case 'companyLevel':
        return companiesToSort.sort(
          (a, b) => sortDir * (a.isSelected - b.isSelected)
        );
      default:
        return companiesToSort;
    }
  };
  const getCompanies = useCallback(async () => {
    try {
      const resCompanies = await Api.companies.getCompaniesForClub();
      const resSelectedCompanies = await Api.club.getCompaniesForLevel(
        selectedLevel.value
      );
      const resSelectedCompaniesIds = resSelectedCompanies.map(
        (company) => company.id
      );
      updateSelectedCompanies(resSelectedCompanies);
      const sortedCompanies = sortComps(resCompanies, false, 'name');

      setAllCompanies(
        sortedCompanies.map((company) => ({
          ...company,
          isVisible: true,
          isSelected: resSelectedCompaniesIds.includes(company.id),
        }))
      );
    } catch (err) {
      toast.error(err || `${s('400')}`);
    }
  }, [selectedLevel]);

  const getCompaniesForLevel = async () => {
    try {
      const resSelectedCompanies = await Api.club.getCompaniesForLevel(
        selectedLevel.value
      );
      setAllCompanies(
        resSelectedCompanies.map((company) => ({
          ...company,
          isVisible: true,
          isSelected: true,
        }))
      );
    } catch (err) {
      toast.error(err || `${s('400')}`);
    }
  };

  const searchCompanies = (searchText) => {
    setAllCompanies(
      sortComps(
        allCompanies.map((company) => ({
          ...company,
          isVisible: company.name
            .toLowerCase()
            .includes(searchText.toLowerCase()),
        })),
        sort.direction,
        sort.field
      )
    );
  };

  useEffect(() => {
    if (isEditLevel) {
      getCompanies();
    } else if (isViewLevel) {
      getCompaniesForLevel();
    }
  }, [isEditLevel, isViewLevel]);

  useEffect(() => {
    initialize({
      name: selectedLevel?.value,
    });
  }, [selectedLevel]);

  const toggleCompanySelection = (companyToggled, checked) => {
    const updatedCompanies = allCompanies.map((company) => ({
      ...company,
      isSelected:
        company.id === companyToggled.id ? checked : company.isSelected,
    }));
    setAllCompanies(updatedCompanies);
    updateSelectedCompanies(
      updatedCompanies.filter((company) => company.isSelected)
    );
  };

  const [companiesToShow, selectedCompanies] = useMemo(() => {
    return [
      allCompanies.filter((company) => company.isVisible),
      allCompanies.filter((company) => company.isSelected),
    ];
  }, [allCompanies]);
  const renderSort = (cellOrder) => {
    if (sort.field !== cellOrder) {
      return null;
    }
    return sort.direction && sort.field === cellOrder ? (
      <ArrowUpIcon size={16} />
    ) : (
      <ArrowDownIcon size={16} />
    );
  };

  const handleSort = (field) => {
    const newDirection = sort.field === field ? !sort.direction : true;
    const newCompanies = sortComps(allCompanies, newDirection, field);
    setAllCompanies(newCompanies);
    setSort({
      field,
      direction: newDirection,
    });
  };
  return (
    <form className="form" onSubmit={handleSubmit}>
      <div className="form__form-group">
        <span className="form-header bold">{s('levelName')}</span>
        <Field
          name="name"
          component={renderField}
          placeholder={s('levelName')}
          disabled={isViewLevel}
        />
      </div>
      {(isEditLevel || isViewLevel) && (
        <div className="form__form-group">
          <span className="form-header bold">{s('companies')}</span>
          <div className="form__form-group-field marginBottom-5">
            <Field
              component="input"
              type="text"
              placeholder="Search for companies"
              onChange={(e) => searchCompanies(e.target.value)}
              name="search"
            />
            <div className="form__form-group-icon">
              <MagnifyIcon />
            </div>
          </div>
          <div className="my-2 d-flex flex-row-reverse">
            <p>
              {companiesToShow.length} {s('companies')}
            </p>
          </div>

          {companiesToShow.length > 0 && (
            <ListGroup
              className={`companies-list-group ${isEditLevel ? 'green-border' : 'grey-border'}`}
              flush
            >
              <Table responsive="xl" bordered>
                <thead>
                  <tr>
                    <th
                      style={{ width: '25%' }}
                      className="sortable"
                      onClick={() => handleSort('name')}
                    >
                      {s('name')}
                      {renderSort('name')}
                    </th>
                    <th
                      className="sortable text-center"
                      onClick={() => handleSort('companyLevel')}
                    >
                      {s('level')}
                      {renderSort('companyLevel')}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {companiesToShow.map((company) => {
                    const exists = !(
                      company.companyLevel === null ||
                      company.companyLevel === selectedLevel?.value
                    );
                    return (
                      <tr key={company.id}>
                        <td>
                          <Col
                            lg={exists ? 8 : 10}
                            md={exists ? 8 : 10}
                            sm={exists ? 8 : 10}
                          />
                          <div>
                            {company?.images?.companyImage ? (
                              <img
                                className="company__profile__avatar mr-2"
                                src={company?.images?.companyImage}
                                alt="logo"
                              />
                            ) : (
                              <span className="company__profile__avatar_placeholder mr-2">
                                {company?.name[0]?.toUpperCase()}
                              </span>
                            )}
                            {company.name}
                          </div>
                        </td>
                        {isEditLevel && (
                          <td>
                            <Col className="d-flex flex-column align-items-center justify-content-center">
                              {company.companyLevel === null ||
                              company.companyLevel === selectedLevel?.value ? (
                                <Input
                                  type="checkbox"
                                  className="my-auto"
                                  onChange={(e) =>
                                    toggleCompanySelection(
                                      company,
                                      e.target.checked
                                    )
                                  }
                                  style={{ height: '15px', width: '15px' }}
                                  checked={company.isSelected}
                                />
                              ) : (
                                <p className="font-italic light-grey-text">
                                  Exists in {company.companyLevel}
                                </p>
                              )}
                            </Col>
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </ListGroup>
          )}
          {isViewLevel && !selectedCompanies.length && (
            <Alert color="primary">{s('noCompaniesFound')}</Alert>
          )}
        </div>
      )}
      <Row>
        <Col>
          {!isViewLevel ? (
            <Button color="primary" type="submit">
              {selectedLevel ? `${s('save')}` : `${s('create')}`}
            </Button>
          ) : (
            <Button
              color="secondary"
              onClick={(e) => {
                e.preventDefault();
                setIsEditLevel(true);
                setIsViewLevel(false);
                handleModalTypeChangeToEdit();
              }}
            >
              <p>{s('edit')}</p>
            </Button>
          )}
        </Col>
      </Row>
    </form>
  );
};

LevelInformation.propTypes = {
  selectedLevel: PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
    id: PropTypes.string,
  }),
  type: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

LevelInformation.defaultProps = {
  selectedLevel: null,
};

const reduxF = reduxForm({
  form: 'levelForm',
});
export default reduxF(LevelInformation);
