import React from 'react';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { Col, Button } from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import renderField from '../../../shared/components/custom/Field';
import validate from './validateApi';
import { partial } from '../../../util/translation/translation';

const NewsAPIForm = ({ handleSubmit }) => {
  const s = partial('shared');
  return (
    <form onSubmit={handleSubmit} className="form">
      <Col lg={6}>
        <div className="card__title">
          <h5 className="bold-text">{s('contentCaps')}</h5>
        </div>
        <div className="form__form-group">
          <span className="form">{s('companyName')}</span>
          <Field
            name="entityName"
            component={renderField}
            placeholder={s('companyName')}
          />
        </div>
      </Col>
      <Col md={12}>
        <Button type="submit" color="primary">
          {s('create')}
        </Button>
        <Link className="color-unset" to="/news?tab=2">
          <Button type="button">{s('close')}</Button>
        </Link>
      </Col>
    </form>
  );
};

const reduxF = reduxForm({
  form: 'news_api_form',
  validate,
});

export default withRouter(
  connect((state) => ({
    formValues: getFormValues('news_api_form')(state),
  }))(reduxF(NewsAPIForm))
);
