import React, { useState } from 'react';
import { Col, Row, Container, Button } from 'reactstrap';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import renderSearchSelectField from '../../../shared/components/custom/SearchSelect';
import Api from '../../../util/api';
import { partial } from '../../../util/translation/translation';
import useConfig from '../../../util/useConfig';

const AddRoleForm = (props) => {
  const config = useConfig();

  const p = partial('UserRolesTab');
  const s = partial('shared');
  const { handleSubmit } = props;
  const [userOptions, setUserOptions] = useState([]);

  const getUser = async (text) => {
    const { users } = await Api.users.getTeamUsers(
      1,
      20,
      { phoneNumber: 'asc' },
      text,
      config
    );
    const _userOptions = users.map((user) => {
      return {
        label: `${user.firstname} ${user.lastname} (${user.username})`,
        value: {
          ...user,
          teamUserId: user.teams[0].id,
        },
      };
    });
    setUserOptions(_userOptions);
  };

  const _handleSubmit = (values) => {
    handleSubmit(values).then(() => {
      props.clearFields();
    });
  };

  return (
    <form className="form full-width" onSubmit={_handleSubmit}>
      <Container>
        <Row>
          <Col md={6}>
            <div className="form__form-group">
              <span className="form-header">{p('addRoleToUser')}</span>
              <div className="marginTop-5" />
              <Field
                name="selectedUser"
                component={renderSearchSelectField}
                placeholder={s('searchNameOrNumber')}
                options={userOptions}
                search={getUser}
              />
            </div>
          </Col>
          <Col md={6}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                height: '100%',
              }}
            >
              <Button
                type="submit"
                size="sm"
                color="primary"
                style={{ margin: 0 }}
                disabled={!props.formValues?.selectedUser}
              >
                {p('assignUserToTheRole')}
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </form>
  );
};

AddRoleForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  clearFields: PropTypes.func.isRequired,
  formValues: PropTypes.shape({
    selectedUser: PropTypes.shape({
      label: PropTypes.string,
      values: PropTypes.any,
    }),
  }),
};

AddRoleForm.defaultProps = {
  formValues: {},
};

const reduxF = reduxForm({
  form: 'users_by_role_form',
});

export default connect((state) => ({
  formValues: getFormValues('users_by_role_form')(state),
}))(reduxF(AddRoleForm));
