import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { withRouter } from 'react-router';
import { getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import ResetPasswordForm from './components/ResetPasswordForm';
import Loading from '../../shared/components/custom/Loading';
import config from '../../config';
import t, { partial } from '../../util/translation/translation';
import app from '../_app/FirebaseConfig';
import Api from '../../util/api';

const RestorePasswordPage = ({
  match: {
    params: { identity, token },
  },
  formValues,
}) => {
  const [loading, setLoading] = useState(false);
  const p = partial('LoginPage');
  const s = partial('shared');
  function handleError(error) {
    switch (error) {
      case 'auth/password-doesnt-match':
        return toast.error(p('passwordDoesntMatch'));
      case 'auth/password-too-short':
        return toast.error(t('CreateUserPage.passwordTooShort'));
      case 'TOKEN_EXPIRED':
        return toast.error(p('passwordResetLinkExpired'));
      case 'TOKEN_REDEEMED':
        return toast.error(p('passwordResetLinkWasAlreadyUsed'));
      default:
        return toast.error(s('somethingWentWrong'));
    }
  }
  async function confirmPasswordReset({ repeatPassword, password }) {
    try {
      if (password !== repeatPassword) {
        throw Object.assign(new Error(), {
          code: 'auth/password-doesnt-match',
        });
      }
      if (password.length < 8) {
        throw Object.assign(new Error(), { code: 'auth/password-too-short' });
      }
      setLoading(true);
      await Api.authorization.redeemPasswordReset({
        token,
        email: identity,
        password,
      });
      const {
        data: { idToken },
      } = await Api.authorization.signInWithEmailPass(identity, password);
      const {
        data: { token: customToken },
      } = await Api.authorization.getCustomToken(idToken);
      const auth = getAuth(app);
      await signInWithCustomToken(auth, customToken);
      setLoading(false);
    } catch (err) {
      handleError(err?.code);
      setLoading(false);
    }
  }
  return (
    <div className="account">
      <Loading loading={loading} />
      <div className="account__wrapper">
        <div className="account__card">
          <div className="account__head">
            <h3 className="account__title">
              {p('welcomeTo')}
              <span className="account__logo"> {config.club_name}</span>
            </h3>
          </div>
          <ResetPasswordForm
            onSubmit={confirmPasswordReset}
            isDisabled={!formValues?.password || !formValues?.repeatPassword}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  formValues: getFormValues('reset_password_form')(state),
});
export default connect(mapStateToProps)(withRouter(RestorePasswordPage));
