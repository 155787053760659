import React, { useState, useEffect } from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from 'reactstrap';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';
import VippsForm from './components/VippsForm';
import StripeForm from './components/StripeForm';
import Api from '../../util/api';
import SwishForm from './components/SwishForm';

const CreateVendor = () => {
  const { vendorId } = useParams();
  const history = useHistory();
  const [paymentMethod, setPaymentMethod] = useState('vipps');
  const [teams, setTeams] = useState([]);
  const [vendor, setVendor] = useState(undefined);

  const setPaymentMethods = (method) => () => setPaymentMethod(method);

  const handleSubmit = async (values) => {
    const payload = {
      ...values,
      teamId: values.teamId.value,
      paymentMethod,
    };
    try {
      const { data } = await Api.teams.createVendor(payload);
      history.push(`/vendors/edit/${data.id}`);
    } catch (e) {
      toast.error(e);
    }
  };

  useEffect(() => {
    const fetchClubTeams = async () => {
      try {
        const { data } = await Api.teams.clubTeams();
        setTeams(data);
      } catch (e) {
        toast.error(e);
      }
    };
    const fetchVendor = async () => {
      try {
        const { data } = await Api.teams.getVendor(vendorId);
        setVendor(data);
        setPaymentMethod(data.paymentMethod);
      } catch (e) {
        toast.error(e);
      }
    };
    if (vendorId) {
      fetchVendor();
    }
    fetchClubTeams();
  }, [vendorId]);

  return (
    <Container>
      <Row>
        <Col>
          <h3 className="page-title">{vendorId ? 'Edit' : 'Create'}</h3>
        </Col>
      </Row>

      <Card>
        <CardBody>
          <ButtonGroup>
            <Button
              color="primary"
              active={paymentMethod === 'vipps'}
              onClick={setPaymentMethods('vipps')}
            >
              Vipps
            </Button>
            <Button
              color="primary"
              active={paymentMethod === 'stripe'}
              onClick={setPaymentMethods('stripe')}
            >
              Stripe
            </Button>
            <Button
              color="primary"
              active={paymentMethod === 'swish'}
              onClick={setPaymentMethods('swish')}
            >
              Swish
            </Button>
          </ButtonGroup>
          {paymentMethod === 'vipps' && (
            <VippsForm teams={teams} onSubmit={handleSubmit} vendor={vendor} />
          )}
          {paymentMethod === 'stripe' && (
            <StripeForm teams={teams} onSubmit={handleSubmit} vendor={vendor} />
          )}
          {paymentMethod === 'swish' && (
            <SwishForm teams={teams} onSubmit={handleSubmit} vendor={vendor} />
          )}
        </CardBody>
      </Card>
    </Container>
  );
};

export default CreateVendor;
