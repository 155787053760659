import React, { useEffect, useState } from 'react';
import {
  Container,
  Col,
  Card,
  CardBody,
  Table,
  Button,
  Badge,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Row,
  Modal,
} from 'reactstrap';
import CloseCircleOutline from 'mdi-react/CloseCircleOutlineIcon';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { toast } from 'react-toastify';
import DotsHorizontalIcon from 'mdi-react/DotsHorizontalIcon';
import Loading from '../../shared/components/custom/Loading';
import Api from '../../util/api';
import useModal from '../../shared/hooks/useModal';
import DeleteModal from '../../shared/components/custom/DeleteModal';
import Pagination from '../../shared/components/pagination/Pagination';
import { partial } from '../../util/translation/translation';
import { formatCurrencyNumber } from '../../util/functions';
import LotteryTurnover from './components/LotteryTurnover';
import { formatDate } from '../../util/date-and-time';
import useConfig from '../../util/useConfig';
// import config from '../../config';

const LotteryPage = () => {
  const config = useConfig();
  const [loading, setLoading] = useState(true);
  const [futureLotteries, setFutureLotteries] = useState([]);
  const [lotteryHandleModal, lotteryVisible, lotteryDeleteID] = useModal();
  const [pastLotteries, setPastLotteries] = useState([]);
  const [forceModal, setForceModal] = useState(false);
  const [forceDeleteItem, setForceDeleteItem] = useState(undefined);

  const s = partial('shared');
  const p = partial('LotteryPage');
  const sortLotteries = (a, b) => {
    if (moment(a.saleEnd) === moment(b.saleEnd)) {
      return 0;
    }
    return moment(a.saleEnd) > moment(b.saleEnd) ? -1 : 1;
  };

  const getLotteries = async (page = 1, pageSize = 10) => {
    setLoading(true);
    try {
      const resLotteries = await Api.lottery.all(page, pageSize);
      const sortedFutureLotteries = resLotteries.data.data.futureLotteries; //  .upcoming.sort(sortLotteries);
      setFutureLotteries(sortedFutureLotteries);
      setPastLotteries(resLotteries.data.data.previousLotteries);
      setLoading(false);
    } catch (err) {
      toast.error(err || 'error');
      setLoading(false);
    }
  };
  const deleteLottery = async () => {
    lotteryHandleModal();
    setLoading(true);
    try {
      await Api.lottery.delete(lotteryDeleteID, false);
      toast.success('Deleted');
      getLotteries();
    } catch (err) {
      if (err.toString() === 'Error: api.412') {
        setForceModal(true);
        setForceDeleteItem(
          futureLotteries
            .concat(pastLotteries)
            .find((l) => l.id === lotteryDeleteID)
        );
        setLoading(false);
      } else {
        toast.error(err || 'Deleting Failed');
        getLotteries();
      }
    }
  };
  const onChangePageSystem = (pager) => {
    getLotteries(pager.currentPage, pager.pageSize);
  };

  useEffect(() => {
    getLotteries();
  }, []);

  const listLotteries = (lotteries, isPastLotteries) => {
    return lotteries.map((lottery) => (
      <tr key={`ListLotteries-${lottery.id}`}>
        <td>
          <Link to={`lottery/edit/${lottery.id}`}>{lottery.name}</Link>
        </td>
        <td>
          {formatDate(lottery.saleStart)} - {formatDate(lottery.saleEnd)}
        </td>
        <td>{lottery.price}</td>
        <td>
          {
            lottery.tickets.filter(
              (t) =>
                t.paymentStatus === 'succeeded' || t.paymentStatus === 'SALE'
            ).length
          }
        </td>
        <td>
          <b>
            {formatCurrencyNumber(
              lottery.price *
                lottery.tickets.filter(
                  (t) =>
                    t.paymentStatus === 'succeeded' ||
                    t.paymentStatus === 'SALE'
                ).length
            )}{' '}
            {config.currencyCaps}
          </b>
        </td>
        <td>
          <Badge
            color={
              lottery.active ? 'success sqaure-badge' : 'danger sqaure-badge'
            }
          >
            {lottery.active ? s('activeCaps') : s('inActiveCaps')}
          </Badge>
        </td>
        <td>{Math.floor(lottery.maxTickets)}</td>
        <td>
          <UncontrolledDropdown className="dashboard__table-more">
            <DropdownToggle>
              <DotsHorizontalIcon />
            </DropdownToggle>
            <DropdownMenu className="dropdown__menu">
              <Link to={`/lottery/edit/${lottery.id}`}>
                <DropdownItem id={lottery.id}>{s('edit')}</DropdownItem>
              </Link>
              {!isPastLotteries && (
                <DropdownItem
                  onClick={() => lotteryHandleModal(lottery.id)}
                  className="danger"
                >
                  {s('delete')}
                </DropdownItem>
              )}
            </DropdownMenu>
          </UncontrolledDropdown>
        </td>
      </tr>
    ));
  };
  const mapAndReduceLotteryTickets = (tickets) => {
    const _t = tickets.reduce(
      (t, e) => ({
        ...t,
        [e.userIdentifier]: {
          user: t[e.userIdentifier]?.user || e.user,
          amount: (t[e.userIdentifier]?.amount || 0) + 1,
        },
      }),
      {}
    );
    return Object.keys(_t).map((key) => _t[key]);
  };
  const forceDelete = (force, refund) => async () => {
    setLoading(true);
    try {
      await Api.lottery.delete(forceDeleteItem.id, force, refund);
      setForceDeleteItem(undefined);
      setForceModal(false);
    } catch (e) {
      toast.error(e);
    } finally {
      setLoading(false);
      getLotteries();
    }
  };
  return (
    <Container>
      <Loading loading={loading} />
      <DeleteModal
        visible={lotteryVisible}
        type="lottery"
        handleModal={lotteryHandleModal}
        modalAction={deleteLottery}
      />
      <Modal isOpen={forceModal}>
        <div className="modal">
          <div className="modal-container" style={{ width: 400 }}>
            <div className="modal-icon">
              <CloseCircleOutline size="128" color="#ff4861" />
            </div>
            <div className="modal-text">
              <h3>{p('paidUsers')}</h3>
            </div>
            <div className="modal-text">
              <Table responsive className="overflow-auto">
                <thead>
                  <tr>
                    <th>{s('user')}</th>
                    <th>{p('ticketAmount')}</th>
                    <th>{p('totalAmount')}</th>
                  </tr>
                </thead>
                <tbody>
                  {mapAndReduceLotteryTickets(
                    forceDeleteItem?.tickets || []
                  ).map((e) => (
                    <tr key={`fd-${e.user.id}`}>
                      <td>{`${e.user.firstname} ${e.user.lastname}`}</td>
                      <td>{e.amount}</td>
                      <td>{e.amount * forceDeleteItem.price}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            <div className="modal-buttons">
              <Button
                className="marginBottom-0"
                color="danger"
                onClick={forceDelete(true, true)}
              >
                {s('refund')}
              </Button>
              <Button
                className="marginBottom-0"
                color="danger"
                onClick={forceDelete(true, false)}
              >
                {s('forceDelete')}
              </Button>
              <Button
                className="marginBottom-0"
                color="secondary"
                onClick={() => {
                  setForceModal(false);
                }}
              >
                {s('close')}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      <Col>
        <p className="page-title">{p('lottery')}</p>
      </Col>
      <Row>
        <Col xl={8} lg={12} md={12}>
          <Card>
            <CardBody>
              <div className="card__title">
                <h5 className="bold-text">{p('upcomingLotteriesCaps')}</h5>
                <Link to="/lottery/create">
                  <Button
                    size="sm"
                    color="primary"
                    className="flex space-between"
                    style={{ float: 'right' }}
                  >
                    {p('createLottery')}
                  </Button>
                </Link>
              </div>
              <Table responsive bordered>
                <thead>
                  <tr>
                    <th>{s('name')}</th>
                    <th>{p('salesPeriod')}</th>
                    <th>{p('pricePerTicket')}</th>
                    <th>{s('sold')}</th>
                    <th>{p('turnover')}</th>
                    <th>In-app</th>
                    <th>{p('maxTickets')}</th>
                    <th />
                  </tr>
                </thead>
                <tbody>{listLotteries(futureLotteries, false)}</tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
        <Col xl={4} lg={12} md={12}>
          <LotteryTurnover />
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <div className="card__title">
                <h5 className="bold-text">{p('previousLotteriesCaps')}</h5>
              </div>
              <Table responsive bordered>
                <thead>
                  <tr>
                    <th>{s('name')}</th>
                    <th>{p('salesPeriod')}</th>
                    <th>{p('pricePerTicket')}</th>
                    <th>{s('sold')}</th>
                    <th>{p('turnover')}</th>
                    <th>In-app</th>
                    <th>{p('maxTickets')}</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {listLotteries(pastLotteries.sort(sortLotteries), true)}
                </tbody>
              </Table>
              <Pagination
                items={pastLotteries?.total ?? 0}
                pageSize={10}
                onChangePage={onChangePageSystem}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default LotteryPage;
