export default [
  {
    label: 'Empty',
    value:
      'https://storage.googleapis.com/image-service_prod/89f3896645c886bce8c4c5b8ce0d3e5fb9a7d08ac8f490ed26ee63011f1c483e',
  },
  {
    label: 'Aalesund',
    value:
      'https://storage.googleapis.com/image-service_prod/170fd3d881fd11980a738022e62c82c435398b24fa827ffcf1c94b5094cd13fa',
  },
  {
    label: 'AIK',
    value:
      'https://storage.googleapis.com/image-service_prod/5f5e719806e5657632ed5d89af393bfb3dc25b5d84245f186ad17484a47f47f8',
  },
  {
    label: 'Almtuna',
    value:
      'https://storage.googleapis.com/image-service_prod/a2bf5bab352c6b5338be2aca46defcefc57bb6f762b04734eaacc98748771fc6',
  },
  {
    label: 'Alvesta SK',
    value:
      'https://storage.googleapis.com/image-service_prod/f1a10a4c1923abe9d8004d0da090c6b928ea6205b2e4e0543d04d95991e0a38c',
  },
  {
    label: 'Älvsjö AIK IBF',
    value:
      'https://storage.googleapis.com/image-service_prod/450bd5fa2eba7d3e53b2384874e7e5930c622a37a3822a8831700a7f57a6b03e',
  },
  {
    label: 'Åsane',
    value:
      'https://storage.googleapis.com/image-service_prod/c19e9b691ea4025d971ae9b652f9cec82912e4914a96790ef329f7159b411a05',
  },
  {
    label: 'Bäcken HC',
    value:
      'https://storage.googleapis.com/image-service_prod/b1286c8d320c115d38865d79f6105d93a003aaba955074ec27d59f63ad3615de',
  },
  {
    label: 'Bergen',
    value:
      'https://image.api.fangroup.io/f91d780347206c3f7f12bd4dc1f19b3e01ed9e558fa076a0e576b109a4e248dd',
  },
  {
    label: 'BIK Karlskoga',
    value:
      'https://storage.googleapis.com/image-service_prod/81b34a68709ec1bf8b9aa53dfbc187ebb24b119b3c122a696c1835b871f11b0b',
  },
  {
    label: 'Björklöven',
    value:
      'https://storage.googleapis.com/image-service_prod/0a6467517005db178cd88bf9ba096fe1f3b843ed5a793fb164892e3942dc9960',
  },
  {
    label: 'Bodø/Glimt',
    value:
      'https://storage.googleapis.com/image-service_prod/2bdb1455758c447240832b2513b46033946b1beaa2acfbb14e5d7884f751f932',
  },
  {
    label: 'Boro/Vetlanda HC',
    value:
      'https://storage.googleapis.com/image-service_prod/4316c0368ce0e8ac630bbecc60d6ae29fedcfa75081c5bb2363d5da26d1eec80',
  },
  {
    label: 'Brann',
    value:
      'https://storage.googleapis.com/image-service_prod/c3c7d8dee42372a2207c4a2e43d8a7eefd4aa7d169d92610ca420ec279c7d632',
  },
  {
    label: 'Brynäs IF',
    value:
      'https://storage.googleapis.com/image-service_prod/d3c9267cc3d2bfc1f6de2c24fee0f419e12cde758a64c9f16d783ed114c7ded9',
  },
  {
    label: 'Bryne',
    value:
      'https://storage.googleapis.com/image-service_prod/5b1340d1e2c964fd6b667486d0b7ba2c60898cff4cf83ebbc7422305d9767722',
  },
  {
    label: 'Comet',
    value:
      'https://image.api.fangroup.io/9fb1459eead776e0e5293549dab49c542efe8ff6c505b6f75f7e2672113ab06c',
  },
  {
    label: 'Djurgården',
    value:
      'https://storage.googleapis.com/image-service_prod/eec06c31b0316a9f6e1d5947e65583d99418a401c93776ade2af6bc649879e95',
  },
  {
    label: 'Egersund',
    value:
      'https://storage.googleapis.com/image-service_prod/20d10817063a5ab37778a4d9b1fd8e8e4801e3ea5965372a81e8642b3dce2320',
  },
  {
    label: 'Eskilstuna Linden Hockey',
    value:
      'https://storage.googleapis.com/image-service_prod/55d7ffcfbeb36857d0c72c09b706b53ea5939bdb23a68a4a34259a1a72b07d18',
  },
  {
    label: 'Falu IF',
    value:
      'https://storage.googleapis.com/image-service_prod/8412fa9cfe1e9eec5335279e28d620fea5e4b43daf7e24bfbfdd7fdae9c536e0',
  },
  {
    label: 'Färjestad BK',
    value:
      'https://storage.googleapis.com/image-service_prod/2c1d2835fb9cbfa47283454d9f061e84c4ce1d49a3805a9729e226030ae1fa83',
  },
  {
    label: 'Fehérvár AV19',
    value:
      'https://storage.googleapis.com/image-service_prod/94eb59dca5dce05626d10c1836ac4a07bb4a2638f2f063a6f5718c2288057269',
  },
  {
    label: 'Fredrikstad',
    value:
      'https://storage.googleapis.com/image-service_prod/cab4b0f89a0952b3cde24628f73d963f3363ab95c673352e1787de7300204acd',
  },
  {
    label: 'Fribourg-Gottéron',
    value:
      'https://storage.googleapis.com/image-service_prod/55f6a5c08efc3869e8c0ceb4bed4a05495347939a5e04bab6027b6f7b55bf6e3',
  },
  {
    label: 'Frisk Asker',
    value:
      'https://image.api.fangroup.io/0f0a92eb408d79e48b78aadea38f8c177a45e7cbf9d3cd607e33c7f181be2438',
  },
  {
    label: 'Frölunda HC',
    value:
      'https://storage.googleapis.com/image-service_prod/8a56d8f9a8628a35ae4a9a24a9b665e9b0b913274c702f6d17ccf0d07a6e9485',
  },
  {
    label: 'Furuset Ishockey IF',
    value:
      'https://storage.googleapis.com/image-service_prod/eab85a1f99a597ed3a0267d1bcef4efecbbaad95c9faecca2fac5698e8927c2e',
  },
  {
    label: 'Gävle GIK',
    value:
      'https://storage.googleapis.com/image-service_prod/ec9d49143ebd7918f7216f464418920fc261e0d51eff198de90f4719906d882f',
  },
  {
    label: 'Genève-Servette',
    value:
      'https://storage.googleapis.com/image-service_prod/8077f02f666074abba4f72211cbae22587bea1b2f65e7b34f29505e0e246ef28',
  },
  {
    label: 'Gjøvik',
    value:
      'https://image.api.fangroup.io/204527706e3c163a4c14d78c85d35c8d4fbff0430a222e22ea53916b5162f18c',
  },
  {
    label: 'Grästorps IK',
    value:
      'https://storage.googleapis.com/image-service_prod/28ebf9c4aba70d2d21cba84b4f693ea980026ca8fdd60f882546de6beee6fd9f',
  },
  {
    label: 'Grüner',
    value:
      'https://image.api.fangroup.io/0d2004db1ed0b66e986cb746853c5f79bf28fac132574a9095c01b748cf0adfa',
  },
  {
    label: 'Halmstad Hammers HC',
    value:
      'https://storage.googleapis.com/image-service_prod/0c65d7316ede0827e0639e8fd76d6ca48a2c47f76effb04a8c2230a1cf3f9487',
  },
  {
    label: 'HamKam',
    value:
      'https://storage.googleapis.com/image-service_prod/ed7e4b693cb4bab3672bfaef5e407c09111808cb507a6da9cccb30afec2c6430',
  },
  {
    label: 'Hammarby IF',
    value:
      'https://storage.googleapis.com/image-service_prod/9acf4b858aaac9199a361d884fa2214720783b8045363328a8196ad73fdd75c3',
  },
  {
    label: 'Hanhals IF',
    value:
      'https://storage.googleapis.com/image-service_prod/92f4ab9b40f88599d1d8720b758a4fae63835a3d30032ba6615d0b9fd8ff8ee4',
  },
  {
    label: 'Haninge Anchors HC',
    value:
      'https://storage.googleapis.com/image-service_prod/32bf8ac9bc157eb47899aac197f3369730ba3e8b4bf8a4deef82988d72354c82',
  },
  {
    label: 'Hasle-Løren',
    value:
      'https://image.api.fangroup.io/e5c8ad81dd96493c2d7e7d6baddc97c1e590fba9cc0e07c06aa100468f785a58',
  },
  {
    label: 'Haugesund',
    value:
      'https://storage.googleapis.com/image-service_prod/2a68dd1a40a14a004b645b79021dff67c85974f0df26717fa450671efefd304b',
  },
  {
    label: 'HC Lidköping Red Roosters',
    value:
      'https://storage.googleapis.com/image-service_prod/f1d7bff34d31d16ab1b3690e5a54b62bca0f4f07cc3cb3f8afb4413b48d5096a',
  },
  {
    label: 'HC Lidköping Red Roosters',
    value:
      'https://storage.googleapis.com/image-service_prod/595e9954f0ccff035af424d5ff9afa35d2682dc01af193aca3d9a26370788a7f',
  },
  {
    label: 'Hedesunda IF',
    value:
      'https://storage.googleapis.com/image-service_prod/344d6b193e28e5805a96fca9dd10909a046fff9cb3adc1bf3bee1438bfc18655',
  },
  {
    label: 'Helsingborg HC Ungdom',
    value:
      'https://storage.googleapis.com/image-service_prod/c36048d6274fa035e442a5e42394203573be28fa37abbbd9049a2244108447e5',
  },
  {
    label: 'HV71',
    value:
      'https://storage.googleapis.com/image-service_prod/d628f4be4a225d246bed451f973f645ca3e136c5f920483447bb2cce08e61f7d',
  },
  {
    label: 'IBF Falun',
    value:
      'https://storage.googleapis.com/image-service_prod/7e1ad36f7de2c050225669952b2937732e1a20ddc8c7cfd320a946d634bab64f',
  },
  {
    label: 'IBK Dalen',
    value:
      'https://storage.googleapis.com/image-service_prod/2b355f4173218ac61a77950fafea2da3da59a0b4a29cc831aff8ffad45b9c2c8',
  },
  {
    label: 'IF Malmö Redhawks',
    value:
      'https://storage.googleapis.com/image-service_prod/d98301eeb0b34a5a4c81ec2b64fb572edc9438312c40ed5c3567fbe876bc587b',
  },
  {
    label: 'IF Mölndal Hockey',
    value:
      'https://storage.googleapis.com/image-service_prod/faf6a08c723996656ba19938eb94d0c087997fd730e120b6d9dafb7328cf151d',
  },
  {
    label: 'IF Troja-Ljungby',
    value:
      'https://storage.googleapis.com/image-service_prod/a841eede760ba29b90b6a9729db15821aea57be209dc6d9ce197a43215553434',
  },
  {
    label: 'IK Oskarshamn',
    value:
      'https://storage.googleapis.com/image-service_prod/0b0470aeab2a3a958a1f05d9f6a282f57df6c89edcaa8904697664d96ea0d3be',
  },
  {
    label: 'IK Pantern',
    value:
      'https://storage.googleapis.com/image-service_prod/f9f78b64ab21f635fefec111c73acaccd06ee74c55879e2ab185b9b92a357872',
  },
  {
    label: 'IK Sirius IBK',
    value:
      'https://storage.googleapis.com/image-service_prod/a0c4d5bb4771516e8630070a37a4e3b53e3dbe1cb4cb7948fea5f2549401e934',
  },
  {
    label: 'Järfälla Bele IBF',
    value:
      'https://storage.googleapis.com/image-service_prod/0437ab65f8ae4f3ecf6106e73303def8c89e2b72725ee893885c1a0e0a030bcf',
  },
  {
    label: 'Jerv',
    value:
      'https://storage.googleapis.com/image-service_prod/dce864317747106e5b2c394699a31d4d59788eba09d7c8f34a9872852e3cca8b',
  },
  {
    label: 'KAC Klagenfurt',
    value:
      'https://storage.googleapis.com/image-service_prod/f8f2b2d9030f2e469a19ab97d36c77062be815cde6a3eff2e43572db3fe57b00',
  },
  {
    label: 'Kalmar HC',
    value:
      'https://storage.googleapis.com/image-service_prod/5436e02bcd911a963158bebb80f594140680b5d870db7b53873226f23d63e0df',
  },
  {
    label: 'Karlskrona HK',
    value:
      'https://storage.googleapis.com/image-service_prod/a28c47be5ebd4fa4b6a5d33536acf1d6b2dbb365d0336f8c51be4741a5528a93',
  },
  {
    label: 'KFUM',
    value:
      'https://storage.googleapis.com/image-service_prod/60ab3a1419760378341d292cbb1d9b237174a7c8670ae87a238cf850682a6165',
  },
  {
    label: 'Kongsvinger',
    value:
      'https://storage.googleapis.com/image-service_prod/7f562935e81a76d7d573fe875dae4d4e82e7d0f0623345c360b870d236a16c9e',
  },
  {
    label: 'Kristianstad',
    value:
      'https://storage.googleapis.com/image-service_prod/bd58e7fd270b957f1da76a8964c672213891b5d75e93018821d9a530dd865a73',
  },
  {
    label: 'Kungälvs IK',
    value:
      'https://storage.googleapis.com/image-service_prod/7b7ebe23447fe2ef6e980e7cb509b11c5b1c161bc9cdc01548c2444e91c07ab7',
  },
  {
    label: 'Lahti Pelicans',
    value:
      'https://storage.googleapis.com/image-service_prod/18ad98b46bf025a565ac223220943a7c0c6d9c88bf47cbe558681f1871566d65',
  },
  {
    label: 'Lausanne HC',
    value:
      'https://storage.googleapis.com/image-service_prod/0807f85a7ee713a029a02c99cce058a233b711b7a54bf8cabfa5c7b613a0d29f',
  },
  {
    label: 'Leksands IF',
    value:
      'https://storage.googleapis.com/image-service_prod/317b5966ce286f2b1d698d6b3796828e33a7f7704fd79a55f5690d875e2ddcfc',
  },
  {
    label: 'Levanger',
    value:
      'https://storage.googleapis.com/image-service_prod/068f55a5d4011e74c4f3b832cd313805c0649c127ccc6c78c4d45c0ea76dc45a',
  },
  {
    label: 'Lillehammer',
    value:
      'https://image.api.fangroup.io/27142788377a185a53b743e9c3ce5ede6433355449eaa512e5fd1729a49c33e5',
  },
  {
    label: 'Lillestrøm',
    value:
      'https://storage.googleapis.com/image-service_prod/91dc6d838fbcd3578cf99ec103294ab2309d58b64e0a447ad98b3de0940f299e',
  },
  {
    label: 'Lindesberg',
    value:
      'https://storage.googleapis.com/image-service_prod/f512c0f9a86c8094774ec9c1cb66286e0d4edbdaf7882a6524864507194ef3ef',
  },
  {
    label: 'Linköping HC',
    value:
      'https://storage.googleapis.com/image-service_prod/d8211b83943648dda1fef29859a3fc9efd24bf233072ed6181dc9fd12b974c44',
  },
  {
    label: 'Lørenskog IK',
    value:
      'https://image.api.fangroup.io/116ff5854eceff7737b3b076f490c919c9415120475ca4e8c8b1a0befd51e098',
  },
  {
    label: 'Luleå HF',
    value:
      'https://storage.googleapis.com/image-service_prod/129704c00c60e3d3330032d9ff484df83ad7d60656294a091080d40e626eac48',
  },
  {
    label: 'Lyn',
    value:
      'https://storage.googleapis.com/image-service_prod/37df41d2cdf17b9989d63563ba857a0f054673829c62e3067002b1a0dbc0c3a4',
  },
  {
    label: 'Manglerud Star',
    value:
      'https://image.api.fangroup.io/7f407e954d1f5311b052dafa690dac47bdbc4944b281c8cc6fc04c46dd2ff7a9',
  },
  {
    label: 'Mjøndalen',
    value:
      'https://storage.googleapis.com/image-service_prod/ec1a4c589fc9c905e0242a05a163352532204a0d76f1aa8d69af7b47b7b83e5a',
  },
  {
    label: 'MoDo Hockey',
    value:
      'https://storage.googleapis.com/image-service_prod/1e948e9cdbe6d8f8aea1c78ed1f2f22ac22395ea4a60749a74a53d082594596d',
  },
  {
    label: 'Molde',
    value:
      'https://storage.googleapis.com/image-service_prod/be2806e2689f45ec723fef1627ccd35f541b5e575461744973de7f040ca96b1a',
  },
  {
    label: 'Mora',
    value:
      'https://storage.googleapis.com/image-service_prod/c87fbfdabafef67c2c6ed14a4c607307aed2fc6539c51aae0b6c98c953eb6bf6',
  },
  {
    label: 'Mora IK/Hällefors IK',
    value:
      'https://storage.googleapis.com/image-service_prod/310b333e297dd47c73a5f4f3f824ff6389eea77b1605b70a081f0128eeb8d8c7',
  },
  {
    label: 'Mörrums GoIS IK',
    value:
      'https://storage.googleapis.com/image-service_prod/8a58f2f9385c1f79299afb97de2ab8ea081aa177de8d80fead48738f9903c30b',
  },
  {
    label: 'Moss',
    value:
      'https://storage.googleapis.com/image-service_prod/23eecb0d8de3794cee6d6437c24dbfc7778ffb3ea673c92eb1259de3c93af9bc',
  },
  {
    label: 'Narvik',
    value:
      'https://image.api.fangroup.io/7a6b09854127e28639a187dd6b19986e82fd953ed45ae6f0171d1f3ceca1b207',
  },
  {
    label: 'Nidaros',
    value:
      'https://storage.googleapis.com/image-service_prod/0159ff39a70d7a2d9308cc68efa416a386c28c6a78a156e0bc6976b27ae527bf',
  },
  {
    label: 'Nybro Vikings',
    value:
      'https://storage.googleapis.com/image-service_prod/679dc165d12ed9dbddbb07e8425f4606c33e76dd3a3cbad2c6f483af1b574ebf',
  },
  {
    label: 'Oceláři Třinec',
    value:
      'https://storage.googleapis.com/image-service_prod/50928feadb59d4ad25ef63d0e90316c991a2e61f70c258e47c3697de3d32e684',
  },
  {
    label: 'Odd',
    value:
      'https://storage.googleapis.com/image-service_prod/11d1be1a3168f81a509e9e4f385b46379cbeae849af98d373d98dfd4f40c161b',
  },
  {
    label: 'Olofströms IK',
    value:
      'https://storage.googleapis.com/image-service_prod/d35ea91c288db2f8a7e5c481813981f638a27bb9347ecbe3bebd772868425075',
  },
  {
    label: 'Örebro HK',
    value:
      'https://storage.googleapis.com/image-service_prod/a9e664bb295c6068676a3923185661aa111880ed75735731d4720455e8e829a7',
  },
  {
    label: 'Östersund',
    value:
      'https://storage.googleapis.com/image-service_prod/d554d6a6593daaff614b55fd213f1d272af07dde3fcb352766f546afbfa01608',
  },
  {
    label: 'Ranheim',
    value:
      'https://storage.googleapis.com/image-service_prod/71192359cfdc7f276f18809179b7ad8cc352af8718ee744da0f39c0e128d12d3',
  },
  {
    label: 'Raufoss',
    value:
      'https://storage.googleapis.com/image-service_prod/bbd3fe2ce45b71245117b56202ae156035156bcee68bbdecf3438ae87e9213d7',
  },
  {
    label: 'RIG Umeå IBF',
    value:
      'https://storage.googleapis.com/image-service_prod/a67a01d7a48a93217e01b0c7fff27c1639a35488932473bd718f41b51a3f6a26',
  },
  {
    label: 'Ringerike',
    value:
      'https://image.api.fangroup.io/2d4db983c93f5c38ff9cebd0c7fb367718f96993385a552e5808dc9987602578',
  },
  {
    label: 'Rögle BK',
    value:
      'https://storage.googleapis.com/image-service_prod/8377274af031ba7f408cd0c3fea51eab18586ffc4f8c027d5cb734f1a4811f62',
  },
  {
    label: 'Rosenborg',
    value:
      'https://storage.googleapis.com/image-service_prod/a8853b317c36232ee488693501f682fd3c1e77b32636b2966551a933862d7166',
  },
  {
    label: 'Rungsted',
    value:
      'https://image.api.fangroup.io/3dfa82df56c0cdba413a5b16851f36aec725edf7f80d07ccdf6fcd2b9bed7476',
  },
  {
    label: 'Sandefjord',
    value:
      'https://storage.googleapis.com/image-service_prod/3ba74fb2abb520e8d179ec3679d44e64bcb7b5a977b9ed00b22bd43bd60b7f6d',
  },
  {
    label: 'Sandnes Ulf',
    value:
      'https://storage.googleapis.com/image-service_prod/2d5dbad08448decc5219c885c0d54abb29d64ccbcc05ad0b0cf77eee18a68d32',
  },
  {
    label: 'Sarpsborg 08',
    value:
      'https://storage.googleapis.com/image-service_prod/9b6ad99dbe8fe61a12ebb49be64fe1c8bcfe635f7b047c7046942c07ad8f2fac',
  },
  {
    label: 'SBBK',
    value:
      'https://storage.googleapis.com/image-service_prod/3f380af2d34895608695504474109611e420c6184d0d5eac1f8d94f255953889',
  },
  {
    label: 'SDE HF',
    value:
      'https://storage.googleapis.com/image-service_prod/4f0bb7350d3b4b8dba3aa633abfc49ef94d6ff67993fb5a4a5d502edd9cbd3d8',
  },
  {
    label: 'SHK Hockey Club',
    value:
      'https://storage.googleapis.com/image-service_prod/ee5a101aeed379717bf8fcaf69fa861b1c54250f76c8568e0fb9eb9b023b49c5',
  },
  {
    label: 'Skälby IBK',
    value:
      'https://storage.googleapis.com/image-service_prod/0df70f28c531c7c86041147cd8fe10d39c8d639ad1626cf9e2e30116e800fc85',
  },
  {
    label: 'Skellefteå AIK',
    value:
      'https://storage.googleapis.com/image-service_prod/0a12d56359c3e42945aa4ade5b41664416932b81865bf6902346c5c7cc63a1f0',
  },
  {
    label: 'Ski Ishockey Elite',
    value:
      'https://storage.googleapis.com/image-service_prod/6df245128d9fd46200885c2886aeef6a641b68814c18c87ad07af3be04c67c74',
  },
  {
    label: 'Södertälje SK',
    value:
      'https://storage.googleapis.com/image-service_prod/d3d4e53643037e6e9cd88b670c4172a377fe395166cd65d28b04fa23582745d2',
  },
  {
    label: 'Sogndal',
    value:
      'https://storage.googleapis.com/image-service_prod/74a6e01cd3576d28e2a87c36711ecb050f7bc9dbc25ff0d82b2a28354f94e58a',
  },
  {
    label: 'Sölvesborgs IK',
    value:
      'https://storage.googleapis.com/image-service_prod/2a5f2ee589b71079507b90308801d2c9e0ec180f1d85dbbeeb43c0243c4fcdea',
  },
  {
    label: 'Sparta',
    value:
      'https://image.api.fangroup.io/fa9096e1ef0dba910946a47f59b7a1f62c51eef25e9458865efeb6e97c2399f7',
  },
  {
    label: 'Stabæk',
    value:
      'https://storage.googleapis.com/image-service_prod/c2b3ae06863ea3188af6833542e6e50bcbc7802894561836d72146c6e94d686e',
  },
  {
    label: 'Start',
    value:
      'https://storage.googleapis.com/image-service_prod/7ad6e01103445fd18d1ac8189b030060ca9714652d0af261be665c709ee54d2c',
  },
  {
    label: 'Stavanger Oilers',
    value:
      'https://storage.googleapis.com/image-service_prod/967846d27f021f9775e6b7889f183761694ef3713acea692520dd0e99aba82f2',
  },
  {
    label: 'Stenungsund HF',
    value:
      'https://storage.googleapis.com/image-service_prod/82ef670a4089f216a40d60e9a230e559c687c14be0a97e89412b64d5183806d7',
  },
  {
    label: 'Stjernen',
    value:
      'https://image.api.fangroup.io/1349103eac3a57163ad87f35fb4453956d607ee37a937e8fd9cfa63ae4ea9d58',
  },
  {
    label: 'Storhamar',
    value:
      'https://storage.googleapis.com/image-service_prod/83a45e727ddac42c654f81abe6219fe0b244d8986325e81a8ba82852d2c5778f',
  },
  {
    label: 'Strømsgodset',
    value:
      'https://storage.googleapis.com/image-service_prod/51d211ead28234cfa3d18d5651125ffe6a614492d1f00b565680ea0db0decfae',
  },
  {
    label: 'Sundsvall FBC',
    value:
      'https://storage.googleapis.com/image-service_prod/d0b1f3dd85a5bacc96aef37f4f786cec0cd6266a511fc3d2086218ec2bc83955',
  },
  {
    label: 'Tappara Tampere',
    value:
      'https://storage.googleapis.com/image-service_prod/8c1dc2bb5fef8e946a759feb95a9b266d1254facef3c3bf4dea8d400f519eb0f',
  },
  {
    label: 'Team Uppsala HC',
    value:
      'https://storage.googleapis.com/image-service_prod/b9737d4e27a4d9d2bbcd9e50e7272101e7b3b128cf56b2422f0f3806a3080aa0',
  },
  {
    label: 'Timrå IK',
    value:
      'https://storage.googleapis.com/image-service_prod/770e6dc96a4d0c41dd8e02065bfcb5f76ad6a2d95beee8c556f4cc22ced7d3a3',
  },
  {
    label: 'Tingsryd',
    value:
      'https://storage.googleapis.com/image-service_prod/393b389605c4c97046ee4b9112b636c23899ca998c02772d80f57a7928cd2dd1',
  },
  {
    label: 'Tromsø',
    value:
      'https://storage.googleapis.com/image-service_prod/22349b237b897caf2fc71ed56ac3910abde42039ff7d8a0d9cd771ebd8197b09',
  },
  {
    label: 'TYRESÖ DAM ELIT',
    value:
      'https://storage.googleapis.com/image-service_prod/f512c0f9a86c8094774ec9c1cb66286e0d4edbdaf7882a6524864507194ef3ef',
  },
  {
    label: 'Tyresö Trollbäcken IBK',
    value:
      'https://storage.googleapis.com/image-service_prod/667d33f01cf8b3fd5ff5096e7e7173e92ceee9299e24f8f2f4560b98d4fadd50',
  },
  {
    label: 'Tyringe SoSS',
    value:
      'https://storage.googleapis.com/image-service_prod/b55d911c3ca7d8cefc4742b648b5d233d87a41c99a148e0de4760ecdb1f0bf10',
  },
  {
    label: 'Ulricehamns IF',
    value:
      'https://storage.googleapis.com/image-service_prod/3bc6d204374de46f8a3dc2c3c01f11f0daabcd2b81c35169ced67083035f129e',
  },
  {
    label: 'Vålerenga',
    value:
      'https://storage.googleapis.com/image-service_prod/e5a81e4994ed2ea73cf7d4a2dc24613b0db7fa97d9f6f3d65d9ff6b0b030d362',
  },
  {
    label: 'Vänersborgs HC',
    value:
      'https://storage.googleapis.com/image-service_prod/28e418f7e18fac420cf6d6a11c11785bb499063e1f0deb4f677174b412dffb8a',
  },
  {
    label: 'Värnamo GIK',
    value:
      'https://storage.googleapis.com/image-service_prod/56e605304182ad1d24e735f9e3301e15d5e25033a6c9ca5fb255d897055192e0',
  },
  {
    label: 'Västerås IK',
    value:
      'https://storage.googleapis.com/image-service_prod/cba7ffb6d0fbec35075efdd20f7c554fc599500138c6750e38f6f8989c4b10f9',
  },
  {
    label: 'Västervik',
    value:
      'https://storage.googleapis.com/image-service_prod/1c639b2bb359e0994d1bd3da9a6eccf011085c03f1d5b90e6497b08f4abf9c1d',
  },
  {
    label: 'Västerviks IK',
    value:
      'https://storage.googleapis.com/image-service_prod/ee513be6bf4791d7cc3e0aeefd68608a4ef77acd675c2222a61e9ee287f5cd8d',
  },
  {
    label: 'Växjö Lakers',
    value:
      'https://storage.googleapis.com/image-service_prod/c96e13202af3a0b4e38394c220cac106e074427f561d387f791a6b2c1044b1f7',
  },
  {
    label: 'Viking',
    value:
      'https://storage.googleapis.com/image-service_prod/5c2ddf605a0ca532e2ab9d3247e791c42024fbf48458f6cc391696610b0436d5',
  },
  {
    label: 'Vimmerby Hockey',
    value:
      'https://storage.googleapis.com/image-service_prod/27e8c57f1d338240234bf0dda7fea3e235ce1e637ef88d4276e7a67161f6d5dc',
  },
  {
    label: 'Visby IBK',
    value:
      'https://storage.googleapis.com/image-service_prod/d8bd1376b770671ef957c9b20d68bc4592ee97b2b51f23fd42d7d98da76257d6',
  },
  {
    label: 'Vita Hästen',
    value:
      'https://storage.googleapis.com/image-service_prod/8ff980d99e532e05d927f2a961f8d258bd7a9f098a7c27b032e22d35057a843f',
  },
  {
    label: 'ZSC Lions Zurich',
    value:
      'https://storage.googleapis.com/image-service_prod/7f24c0ad14d1c03847d95c24b56ec3402b3020a70ef71b0c1b0158b86c8f61f9',
  },
  {
    label: 'UppsalaBOIS',
    value:
      'https://storage.googleapis.com/image-service_prod/clubLogos/SvenskBandy/SSL_Dam/UppsalaBOIS.png',
  },
  {
    label: 'Mölndal',
    value:
      'https://storage.googleapis.com/image-service_prod/clubLogos/SvenskBandy/SSL_Dam/Mo%CC%88lndal.png',
  },
  {
    label: 'VillaLidköpingBK',
    value:
      'https://storage.googleapis.com/image-service_prod/clubLogos/SvenskBandy/SSL_Dam/VillaLidko%CC%88pingBK.png',
  },
  {
    label: 'Västerås',
    value:
      'https://storage.googleapis.com/image-service_prod/clubLogos/SvenskBandy/SSL_Dam/Va%CC%88stera%CC%8As.png',
  },
  {
    label: 'Sandvikens',
    value:
      'https://storage.googleapis.com/image-service_prod/clubLogos/SvenskBandy/SSL_Dam/Sandvikens.png',
  },
  {
    label: 'Hammarby',
    value:
      'https://storage.googleapis.com/image-service_prod/clubLogos/SvenskBandy/SSL_Dam/Hammarby.png',
  },
  {
    label: 'Skirö',
    value:
      'https://storage.googleapis.com/image-service_prod/clubLogos/SvenskBandy/SSL_Dam/Skiro%CC%88.png',
  },
  {
    label: 'Vänersborg',
    value:
      'https://storage.googleapis.com/image-service_prod/clubLogos/SvenskBandy/SSL_Dam/Va%CC%88nersborg.png',
  },
  {
    label: 'Brobergs IF',
    value:
      'https://storage.googleapis.com/image-service_prod/clubLogos/SvenskBandy/SSL_Herr/Brobergs%20IF.png',
  },
  {
    label: 'Bollnäs GOIF',
    value:
      'https://storage.googleapis.com/image-service_prod/clubLogos/SvenskBandy/SSL_Herr/Bollna%CC%88sGOIF.png',
  },
  {
    label: 'EdsbynsIF',
    value:
      'https://storage.googleapis.com/image-service_prod/clubLogos/SvenskBandy/SSL_Herr/EdsbynsIF.png',
  },
  {
    label: 'IK Sirius',
    value:
      'https://storage.googleapis.com/image-service_prod/clubLogos/SvenskBandy/SSL_Herr/IK%20Sirius.png',
  },
  {
    label: 'Gripen BK',
    value:
      'https://storage.googleapis.com/image-service_prod/clubLogos/SvenskBandy/SSL_Herr/Gripen%20BK.png',
  },
  {
    label: 'Vetlanda BK',
    value:
      'https://storage.googleapis.com/image-service_prod/clubLogos/SvenskBandy/SSL_Herr/Vetlanda%20BK.png',
  },
  {
    label: 'Vänersborg IFK',
    value:
      'https://storage.googleapis.com/image-service_prod/clubLogos/SvenskBandy/SSL_Herr/Va%CC%88nersborg%20IFK.png',
  },
  {
    label: 'Västerås SK',
    value:
      'https://storage.googleapis.com/image-service_prod/clubLogos/SvenskBandy/SSL_Herr/Va%CC%88stera%CC%8AsSK.png',
  },
  {
    label: 'ÅbyTjureda',
    value:
      'https://storage.googleapis.com/image-service_prod/clubLogos/SvenskBandy/SSL_Herr/A%CC%8AbyTjureda.png',
  },
  {
    label: 'Frillesås BK',
    value:
      'https://storage.googleapis.com/image-service_prod/clubLogos/SvenskBandy/SSL_Herr/Frillesa%CC%8As%20BK.png',
  },
  {
    label: 'Ljusdals BK',
    value:
      'https://storage.googleapis.com/image-service_prod/clubLogos/SvenskBandy/SSL_Herr/Ljusdals%20BK.png',
  },
];
