import React from 'react';
import { Link } from 'react-router-dom';

const EmptyStateInfo = ({ icon, title, text, url, linkName, style }) => {
  return (
    <div style={{ ...style }}>
      <img
        style={{
          opacity: 0.4,
          display: 'block',
          margin: '20px auto 0px',
          width: 150,
        }}
        src={`/assets/files/admin/${icon}.svg`}
        alt="icon"
      />
      <h4 className="bold text-center mt-3">{title}</h4>
      <p className="text-center mb-4">{text}</p>
      {!!url && (
        <Link
          className="btn btn-primary btn-sm"
          style={{ display: 'table', margin: '0 auto' }}
          to={url}
        >
          {linkName}
        </Link>
      )}
    </div>
  );
};

export default EmptyStateInfo;
