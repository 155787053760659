import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Api from '../../../../util/api';
import useConfig from '../../../../util/useConfig';
import { partial } from '../../../../util/translation/translation';

const Offer = ({ offer }) => {
  const dT = partial('DashboardPage');
  const oT = partial('OfferCard');
  const sT = partial('shared');
  const config = useConfig();
  const [creatorName, setCreatorName] = useState(sT('club'));
  const [creatorImage, setCreatorImage] = useState(config.club_logo);

  const fetchCompany = useCallback(() => {
    Api.companies.getCompany(offer.companyId).then((res) => {
      setCreatorImage(res.images.companyImage);
      setCreatorName(res.name);
    });
  }, []);

  useEffect(() => {
    if (offer.companyId) {
      fetchCompany();
    }
  }, []);

  const renderLogo = () => {
    if (creatorImage) {
      return (
        <img
          src={creatorImage}
          alt={offer.title}
          style={{ width: '44px', height: '44px', borderRadius: '100%' }}
        />
      );
    } else {
      return (
        <div
          style={{
            background: '#70bbfd',
            color: '#fff',
            fontSize: '18px',
            width: '44px',
            height: '44px',
            borderRadius: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {creatorName.charAt(0).toUpperCase()}
        </div>
      );
    }
  };

  const setCategoryForTranslation = (category) => {
    let categoryFortranslation = '';
    const categoryArray = category.split('&');
    if (categoryArray.length > 0) {
      categoryFortranslation = categoryArray
        .map((letter) => letter.toLowerCase().trim())
        .join('_');
    } else {
      categoryFortranslation = category.toLowerCase();
    }
    return categoryFortranslation;
  };

  return (
    <Link to={`/offers/edit/${offer.id}`}>
      <div className="dashboard-offer">
        <div className="dashboard-offer-wrapper">
          {renderLogo()}
          <div className="dashboard-offer-content">
            <h3 className="dashboard-offer-content-title">{offer.title}</h3>
            <div className="dashboard-offer-creator-wrapper">
              <div className="dashboard-offer-creator">
                {dT('createdBy')} {creatorName}
              </div>
              <div className="dashboard-offer-divider" />
              <div className="dashboard-offer-validity">
                {dT('validFrom')} {moment(offer.startDate).format('DD')}.
                {moment(offer.startDate).format('MMM')}-
                {moment(offer.endDate).format('DD')}.
                {moment(offer.endDate).format('MMM')}
              </div>
            </div>
          </div>
        </div>
        <div className="dashboard-offer-category">
          {oT(setCategoryForTranslation(offer.category))}
        </div>
      </div>
    </Link>
  );
};

export default Offer;
