import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import MatchTicketsList from './components/MatchTicketsList';
import Api from '../../../../util/api';
import Loading from '../../../../shared/components/custom/Loading';
import DeleteModal from '../../../../shared/components/custom/DeleteModal';
import ShareModal from '../../../../shared/components/custom/ShareModal';
import AssignTicketForm from './screens/assign/components/AssignTicketForm';
import { partial } from '../../../../util/translation/translation';
import { formatDate } from '../../../../util/date-and-time';
import UpdateSeasonTickets from '../../components/UpdateSeasonTickets';
import useConfig from '../../../../util/useConfig';

const p = partial('screensIndexShare');
const t = partial('TicketStates');
const f = partial('TicketFilters');
const options = [
  { type: 'shareable', label: f('canBeShared'), value: 1, filterValue: 1 },
  { type: 'shareable', label: f('cannotBeShared'), value: 2, filterValue: 0 },
  { type: 'VIP', label: f('VIP'), value: 3, filterValue: 1 },
  { type: 'VIP', label: f('notVIP'), value: 4, filterValue: 0 },
];

const MatchTickets = () => {
  const [selectedMatch, setSelectedMatch] = useState(null);
  const [selectedField, setSelectedField] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [futureMatches, setFutureMatches] = useState([]);
  const [pastMatches, setPastMatches] = useState([]);
  const [fields, setFields] = useState([]);
  const [rows, setRows] = useState([]);
  const [tickets, setTickets] = useState({
    current_page: '0',
    per_page: 100,
    data: [],
  });
  const [searchText, setSearchText] = useState('');
  const [checkBoxes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleteTickets, setDeleteTickets] = useState([]);
  const [releaseTicketsModalVisible, setReleaseTicketsModalVisible] =
    useState(false);
  const [shareTicketsModalVisibale, setShareTicketsModalVisibale] =
    useState(false);
  const [ticketStates, setTicketStates] = useState([]);
  const [matchPrices, setMatchPrices] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [shareTickets, setShareTickets] = useState([]);
  const [editUsersTickets, setEditUsersTickets] = useState(false);
  const [updateSeasonTickets, setUpdateSeasonTickets] = useState([]);
  const [updateSeasonTicketsModal, setUpdateSeasonTicketsModal] =
    useState(false);
  const [hasInitializedData, setHasInitializedData] = useState(false);
  const [filter, setFilter] = useState({
    ticketState: null,
    priceGroup: null,
    options: [],
    sort: {
      type: 'seats',
      order: 'asc',
    },
  });
  const config = useConfig();

  const assignTicketStates = ticketStates.filter((ticketState) =>
    ['ACTIVE', 'INACTIVE', 'RESERVED/RELEASABLE'].includes(ticketState.key)
  );

  const history = useHistory();

  useEffect(() => {
    const url = new URL(window.location.href);
    setSearchText(url.searchParams.get('search') || '');
  }, []);

  const handleTicketStates = (allTicketStates) => {
    return allTicketStates.map((ticketState) => {
      return {
        label: t(ticketState.name.toLowerCase()),
        value: ticketState.id.toString(),
        key: ticketState.name,
      };
    });
  };

  const handleMatchPrices = (allMatchPrices) => {
    return allMatchPrices.map((price) => {
      return {
        label: price.title,
        value: price.id.toString(),
      };
    });
  };

  const getTickets = async (
    page = 1,
    pageSize = tickets.per_page,
    sort = filter.sort
  ) => {
    setLoading(true);
    const getByType = (type) => (option) => option.type === type;
    const shareable = filter.options.filter(getByType('shareable'));
    const vip = filter.options.filter(getByType('VIP'));
    const shareableFilter =
      shareable.length === 1 ? shareable[0].filterValue : '';
    const vipFilter = vip.length === 1 ? vip[0].filterValue : '';
    try {
      const resTickets = await Api.tickets.getTickets(
        selectedMatch?.id,
        selectedField?.id ?? '',
        selectedRow?.id ?? '',
        filter?.ticketState?.value ?? '',
        filter?.priceGroup?.value ?? '',
        shareableFilter,
        vipFilter,
        searchText,
        page,
        pageSize,
        sort.type,
        sort.order
      );
      setTickets(resTickets.data);
      setLoading(false);
    } catch (err) {
      toast.error(err || p('fetchingSeatsFailed'));
      setLoading(false);
    }
  };

  const updateSort = (type) => {
    let change = 'desc';
    if (filter.sort.type === type) {
      change = filter.sort.order === 'asc' ? 'desc' : 'asc';
    }
    setFilter((prev) => ({ ...prev, sort: { type, order: change } }));
  };

  useEffect(() => {
    if (selectedMatch) {
      getTickets();
    }
  }, [selectedMatch, selectedField, selectedRow, filter]);

  const getInitialData = async () => {
    try {
      const [resMatches, resFields, resTicketStates, resPriceGroups] =
        await Promise.all([
          Api.tickets.getCurrentSeasonMatches(),
          Api.tickets.getFields(),
          Api.tickets.getTicketStates(),
          Api.tickets.getPriceGroups(),
        ]);
      const url = new URL(window.location.href);

      const currentDate = moment();
      if (url === currentDate) {
        setFilter();
      }
      const tmpMatches = resMatches.data.map((match) => ({
        ...match,
        date: formatDate(match.match_starts_at),
      }));
      const allPastMatches = tmpMatches
        .filter((match) => moment(match.match_starts_at) < currentDate)
        .sort((a, b) =>
          moment(a.match_starts_at).isBefore(moment(b.match_starts_at)) ? 1 : -1
        );
      const allFutureMatches = tmpMatches
        .filter((match) => moment(match.match_starts_at) >= currentDate)
        .sort((a, b) =>
          moment(a.match_starts_at).isBefore(moment(b.match_starts_at)) ? -1 : 1
        );
      const matches = [...allFutureMatches, ...allPastMatches];

      const defaultField = resFields.data.find(
        (field) => field.name.toString() === url.searchParams.get('field')
      );
      const defaultRow = defaultField?.rows?.find?.(
        (row) => row.number.toString() === url.searchParams.get('row')
      );

      setFutureMatches(allFutureMatches);
      setPastMatches(allPastMatches);
      setSelectedMatch(matches?.[0]);
      setFields(resFields.data);
      setSelectedField(defaultField ?? null);
      setRows(defaultField?.rows ?? []);
      setSelectedRow(defaultRow ?? null);
      setTicketStates(handleTicketStates(resTicketStates.data));
      setMatchPrices(handleMatchPrices(resPriceGroups.data));

      setHasInitializedData(true);
    } catch (err) {
      toast.error(err);
    }
  };

  useEffect(() => {
    getInitialData();
  }, []);

  const onChangePage = (pager) => {
    if (tickets.current_page === '0') {
      return;
    }
    const isNotSamePage = tickets.current_page !== pager.currentPage;
    const isNotSameSize = Number(tickets.per_page) !== Number(pager.pageSize);
    if ((isNotSamePage || isNotSameSize) && hasInitializedData) {
      getTickets(pager.currentPage, pager.pageSize);
    }
  };
  const getUser = async (text) => {
    const resUsers = await Api.users.getTeamUsers(
      1,
      10,
      { phoneNumber: 'asc' },
      text,
      config
    );
    setUserOptions(
      resUsers.users.map((user) => ({
        label: `${user.firstname} ${user.lastname} (${user.phoneNumber})`,
        value: user.teams?.[0]?.monolithUserId,
      }))
    );
  };

  const handleMatch = (match) => {
    setSelectedMatch(JSON.parse(JSON.stringify(match)));
    setTickets({ current_page: '0', per_page: tickets.per_page, data: [] });
  };

  const handleField = (field) => {
    if (field === null) {
      const url = new URL(window.location.href);
      url.searchParams.delete('field');
      url.searchParams.delete('row');
      history.replace(`${history.location.pathname}${url.search}`);
    }
    setSelectedField(JSON.parse(JSON.stringify(field)));
    setRows(field?.rows ?? []);
    setSelectedRow(null);
  };

  const handleRow = (row) => {
    setSelectedRow(JSON.parse(JSON.stringify(row)));
  };

  const handleInput = (e) => setSearchText(e.target.value);

  const handleSearch = (e) => {
    if (e.key === 'Enter') {
      const url = new URL(window.location.href);
      url.searchParams.set('search', searchText);
      history.replace(`${history.location.pathname}${url.search}`);
      getTickets();
    }
  };

  useEffect(() => {
    const url = new URL(window.location.href);
    if (selectedMatch) {
      url.searchParams.set('match', selectedMatch.id);
    }

    if (selectedField) {
      url.searchParams.set('field', selectedField.name);
    }

    if (selectedRow) {
      url.searchParams.set('row', selectedRow.number);
    }

    history.push(`${history.location.pathname}${url.search}`);
  }, [selectedMatch, selectedField, selectedRow]);

  const handleShareTicketsModal = (state, selectedShareTickets = []) => {
    setShareTicketsModalVisibale(state);
    setShareTickets(selectedShareTickets);
  };

  const submitShareTickets = async (formValues) => {
    const payload = {
      seat_ids: shareTickets,
      match_id: selectedMatch.id,
      ticket_state_id: formValues.ticketState?.value
        ? Number(formValues.ticketState.value)
        : null,
      price_group_id: formValues.priceGroup?.value
        ? Number(formValues.priceGroup.value)
        : null,
      user_id: formValues.user?.value ? Number(formValues.user.value) : null,
      vip: formValues.vip ? Number(formValues.vip) : null,
      shareable: formValues.shareable ? !!Number(formValues.shareable) : null,
      notify_user: !!formValues.push,
    };

    if (formValues.ticketState === undefined && editUsersTickets) {
      delete payload.ticket_state_id;
    }
    if (formValues.priceGroup === undefined && editUsersTickets) {
      delete payload.price_group_id;
    }
    if (formValues.owner) {
      payload[formValues.owner.isCompany ? 'company_id' : 'owner_id'] =
        formValues.owner.value;
    }
    setShareTicketsModalVisibale(false);
    setLoading(true);
    try {
      if (editUsersTickets) {
        await Api.tickets.updateUsersTickets(payload);
      } else {
        await Api.tickets.assignTickets(payload);
      }
      toast.success(p('ticketsAssigned'));
      getTickets(tickets.current_page);
    } catch (err) {
      toast.error(err || p('errorAssigningTickets'));
      setLoading(false);
    }
  };

  const handleReleaseTicketsModal = (state, toDeleteTickets = []) => {
    setReleaseTicketsModalVisible(state);
    setDeleteTickets(toDeleteTickets);
  };

  const handleToggle = (index, toggled = tickets.data[index].toggled) => {
    let allTickets = [...tickets.data];
    if (index === -1) {
      allTickets = allTickets.map((ticket) => ({
        ...ticket,
        toggled: !toggled,
      }));
    } else {
      allTickets[index].toggled = !toggled;
    }
    setTickets({ ...tickets, data: allTickets });
  };

  const giveSeasonTickets = async (_seasonTickets) => {
    setLoading(true);
    setUpdateSeasonTicketsModal(false);
    const payload = {
      seasonTickets: _seasonTickets,
      match_id: selectedMatch.id,
    };
    try {
      await Api.tickets.assignTicketsFromSeasonTickets(payload);
      toast.success(p('ticketsDiverted'));
      getTickets(tickets.current_page);
    } catch (err) {
      getTickets(tickets.current_page);
      toast.error(err || p('thereWasAProblem'));
    }
  };

  const submitDeleteTickets = async () => {
    setReleaseTicketsModalVisible(false);
    setLoading(true);
    const payload = {
      ticket_ids: deleteTickets
        .filter((seat) => seat.ticket_id)
        .map((seat) => seat.ticket_id),
      match_id: deleteTickets[0].match_id,
      reservation_ids: deleteTickets
        .filter((seat) => seat.reservation_id)
        .map((seat) => seat.reservation_id),
    };
    try {
      const {
        data: { seasonTickets },
      } = await Api.tickets.deleteTickets(payload);
      if (seasonTickets?.length > 0) {
        setUpdateSeasonTickets(seasonTickets);
        setUpdateSeasonTicketsModal(true);
      }
      toast.success(p('ticketsDiverted'));
      getTickets(tickets.current_page);
    } catch (err) {
      getTickets(tickets.current_page);
      toast.error(err || p('thereWasAProblem'));
    }
  };
  return (
    <>
      <DeleteModal
        type={deleteTickets.length > 1 ? p('matchTickets') : p('matchTicket')}
        visible={releaseTicketsModalVisible}
        handleModal={handleReleaseTicketsModal}
        modalAction={submitDeleteTickets}
        modalType="release"
      />
      <ShareModal
        visible={shareTicketsModalVisibale}
        handleModal={handleShareTicketsModal}
      >
        <AssignTicketForm
          userOptions={userOptions}
          getUser={getUser}
          ticketStates={assignTicketStates}
          matchPrices={matchPrices}
          onSubmit={submitShareTickets}
          onClose={() => handleShareTicketsModal(false)}
          editUsersTickets={editUsersTickets}
        />
      </ShareModal>
      <ShareModal
        visible={updateSeasonTicketsModal}
        handleModal={() => setUpdateSeasonTicketsModal(false)}
      >
        <UpdateSeasonTickets
          seasonTickets={updateSeasonTickets}
          handleModal={() => setUpdateSeasonTicketsModal(false)}
          handle={giveSeasonTickets}
        />
      </ShareModal>
      <Loading loading={loading} />
      <MatchTicketsList
        tickets={tickets}
        fields={fields}
        rows={rows}
        pastMatches={pastMatches}
        futureMatches={futureMatches}
        selectedField={selectedField}
        selectedRow={selectedRow}
        selectedMatch={selectedMatch}
        handleField={handleField}
        handleRow={handleRow}
        handleMatch={handleMatch}
        searchText={searchText}
        handleInput={handleInput}
        handleSearch={handleSearch}
        checkBoxes={checkBoxes}
        handleReleaseTicketsModal={handleReleaseTicketsModal}
        handleShareTicketsModal={handleShareTicketsModal}
        handleToggle={handleToggle}
        onChangePage={onChangePage}
        setEditUsersTickets={setEditUsersTickets}
        editUsersTickets={editUsersTickets}
        ticketStates={ticketStates}
        matchPrices={matchPrices}
        filter={filter}
        setFilter={setFilter}
        options={options}
        updateSort={updateSort}
      />
    </>
  );
};

export default MatchTickets;
