import React, { useEffect, useState } from 'react';
import {
  Container,
  Col,
  Card,
  CardBody,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router';
import classnames from 'classnames';
import MatchTypeForm from './components/PriceGroupForm';
import Api from '../../util/api';
import Loading from '../../shared/components/custom/Loading';
import PriceGroupFieldsExclusions from './components/PriceGroupFieldsExclusions';
import { partial } from '../../util/translation/translation';

const PriceGroupsFormPage = () => {
  const [loading, setLoading] = useState(false);
  const [priceGroup, setPriceGroup] = useState(null);
  const [fields, setFields] = useState([]);
  const [activeTab, setActiveTab] = useState('1');
  const p = partial('settingsObjectsShared');
  const s = partial('shared');

  const history = useHistory();
  const { priceGroupID } = useParams();

  const getInitialData = async () => {
    try {
      if (priceGroupID) {
        const [resPriceGroup, resFields] = await Promise.all([
          Api.settings.getPriceGroup(priceGroupID),
          Api.settings.getFields(),
        ]);
        const mappedFields = resFields.data.map((field) => ({
          ...field,
          available: resPriceGroup.data.availableFields.some(
            (availableField) => availableField === field.id
          ),
        }));
        setPriceGroup(resPriceGroup.data);
        setFields(mappedFields);
      } else {
        const resFields = await Api.settings.getFields();
        const mappedFields = resFields.data.map((field) => ({
          ...field,
          available: true,
        }));
        setFields(mappedFields);
      }
    } catch (err) {
      toast.error(err || p('errorWhenFetchingNecessaryData'));
    }
  };

  useEffect(() => {
    getInitialData();
  }, []);

  const handleSubmit = async (values) => {
    const payload = {
      title: values.title,
      description: values.description,
      percentage: values.percentage,
      sort_order: values.sortOrder,
      control: values.control,
      buyable: values.buyable,
      excluded_fields: fields
        .filter((field) => field.available === false)
        .map((field) => field.id),
    };
    setLoading(true);
    try {
      if (priceGroupID) {
        await Api.settings.updatePriceGroup(payload, priceGroup.id);
        toast.success(p('priceGroupWasCreated'));
        setLoading(false);
      } else {
        await Api.settings.createPriceGroup(payload);
        toast.success(p('priceGroupCreated'));
        history.replace('/settings?tab=2');
      }
    } catch (err) {
      toast.error(p('errorWhenUpdatingPriceGroup'));
      setLoading(false);
    }
  };

  const handleAvailable = (fieldIndex) => {
    let updatedFields = [...fields];
    if (fieldIndex === -1) {
      updatedFields = updatedFields.map((field) => ({
        ...field,
        available: !fields[0].available,
      }));
    } else {
      updatedFields[fieldIndex].available =
        !updatedFields[fieldIndex].available;
    }
    setFields(updatedFields);
  };

  return (
    <Container>
      <Loading loading={loading} />
      <Col>
        <h3 className="page-title">
          {priceGroupID ? s('edit') : s('create')} {s('priceGroup')}
        </h3>
      </Col>
      <Col>
        <Card>
          <CardBody>
            <div className="tabs tabs--justify tabs--bordered-top overflow-unset">
              <div className="tabs__wrap">
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '1' })}
                      onClick={() => {
                        setActiveTab('1');
                      }}
                    >
                      {s('details')}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '2' })}
                      onClick={() => {
                        setActiveTab('2');
                      }}
                    >
                      {p('divideFields')}
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <MatchTypeForm
                      priceGroup={priceGroup}
                      onSubmit={handleSubmit}
                    />
                  </TabPane>
                  <TabPane tabId="2">
                    <PriceGroupFieldsExclusions
                      fields={fields}
                      handleAvailable={handleAvailable}
                      onSubmit={handleSubmit}
                    />
                  </TabPane>
                </TabContent>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Container>
  );
};

PriceGroupsFormPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      priceGroupID: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func,
  }).isRequired,
};

export default PriceGroupsFormPage;
