import React, { useEffect, useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';
import { Col, Button, Row } from 'reactstrap';
import countries from 'i18n-iso-countries';
import { parsePhoneNumber } from 'react-phone-number-input';
import { useHistory } from 'react-router-dom';
import companyValidate from './companyValidate';
import renderField from '../../../shared/components/custom/Field';
import renderSelectField from '../../../shared/components/form/Select';
import renderMultiSelectField from '../../../shared/components/form/MultiSelect';
import renderCountryPhone from '../../log_in/components/CountryPhone';
import userLanguageState from '../../../recoil/userLanguage/selector';
import { partial } from '../../../util/translation/translation';
import TooltipWithIcon from '../../../shared/components/custom/TooltipWithIcon';
import renderCheckBoxField from '../../../shared/components/form/CheckBox';
import renderSearchSelectField from '../../../shared/components/custom/SearchSelect';

const s = partial('shared');
const p = partial('CompanyForm');
const ct = partial('Category');

countries.registerLocale(require('i18n-iso-countries/langs/nb.json'));
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

const BusinessInfoForm = ({
  company,
  categories,
  levels,
  handleSubmit,
  initialize,
  type,
  existingCompanyFound,
  onCancel,
  companyOptions,
  getCompanies,
  onCompanySelect,
}) => {
  const history = useHistory();

  const [categoryOptions, setCategoryOptions] = useState([]);
  const userLanguage = useRecoilValue(userLanguageState);
  const countryOptions = Object.entries(countries.getNames(userLanguage)).map(
    ([iso, country]) => ({
      name: country,
      iso,
      value: iso,
      label: country,
    })
  );

  let countryCode;
  let phoneNumber;
  try {
    const parsedPhone = parsePhoneNumber(company.phone);
    countryCode = parsedPhone.countryCallingCode;
    phoneNumber = parsedPhone.nationalNumber;
  } catch (err) {
    countryCode = undefined;
    phoneNumber = undefined;
  }
  const c = company ? company.country : s('defaultCountry');
  const selectedCountry = countryOptions.find((country) => country.name === c);
  const selectedCategory = company.companyCategories?.map((item) => {
    let translation = '';
    try {
      translation = ct(item);
    } catch (e) {
      translation = item;
    }
    return {
      name: translation,
      label: translation,
      value: item,
    };
  });

  useEffect(() => {
    const translatedCategories = categories.map((category) => {
      let translation = '';
      try {
        translation = ct(category);
      } catch (e) {
        translation = category;
      }
      return {
        name: translation,
        value: category,
        label: translation,
      };
    });
    setCategoryOptions(translatedCategories);
  }, [userLanguage, categories]);

  useEffect(() => {
    if (company && company.id) {
      const selectedLevel = levels.find(
        (item) => company.companyLevel === item.value
      );
      initialize({
        website: company.websiteUrl,
        isSponsor: company.isSponsor,
        orgName: company.name,
        orgNumber: company.organization_number,
        email: company.email,
        category: selectedCategory,
        id: company.id,
        countryCode,
        phoneNumber,
        description: company.companyStrenghts,
        descriptionSupportingClub: company.companySupportDescription,
        address: company.address,
        addressLink: company.addressLink,
        city: company.city,
        zip: company.zip,
        country: selectedCountry,
        companyLevel: selectedLevel,
        isAppVisible: company.isAppVisible,
      });
    }
  }, [company, levels]);

  const handleCompanySelect = (item) => {
    if (item && item.value !== item.label) {
      const { value } = item;
      onCompanySelect(value);
    }
  };
  return (
    <>
      <form className="form wizard__form" onSubmit={handleSubmit}>
        <Col md={6}>
          <Row>
            <Col lg={6} md={12}>
              <div className="form__form-group">
                <span className="form-header">{s('organizationNumber')}*</span>
                <TooltipWithIcon
                  text={p('searchCompanyInstruction')}
                  id="companyNameTooltip"
                />
                {company?.organization_number ? (
                  <Field
                    name="orgNumber"
                    component={renderField}
                    placeholder={s('companyName')}
                  />
                ) : (
                  <Field
                    name="orgNumber"
                    component={renderSearchSelectField}
                    placeholder={s('organizationNumber')}
                    search={(text) => getCompanies(text)}
                    onChange={(item) => handleCompanySelect(item)}
                    options={companyOptions}
                    creatable
                    promptText={s('create')}
                  />
                )}
              </div>
            </Col>
            <Col lg={6} md={12}>
              <div className="form__form-group">
                <span className="form-header">{s('companyName')}*</span>
                <Field
                  name="orgName"
                  component={renderField}
                  placeholder={s('companyName')}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="form__form-group">
                <span className="form-header">{p('companyEmail')}*</span>
                <Field
                  name="email"
                  component={renderField}
                  placeholder={p('companyEmail')}
                />
              </div>
            </Col>
          </Row>
          {type === 'edit' ? (
            <Row>
              <Col lg={4} md={12}>
                <div className="form__form-group">
                  <p className="form-header">{s('countryCode')}</p>
                  <Field
                    name="countryCode"
                    component={renderField}
                    type="number"
                    placeholder={s('countryCodePlaceHolder')}
                  />
                </div>
              </Col>
              <Col lg={8} md={12}>
                <div className="form__form-group">
                  <p className="form-header">{p('phoneNumber')}</p>
                  <Field
                    name="phoneNumber"
                    component={renderField}
                    type="text"
                    placeholder="12345678"
                  />
                </div>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col md={12}>
                <div className="form__form-group">
                  <span className="form-header">{p('companyPhone')}*</span>
                  <Field
                    name="phone"
                    component={renderCountryPhone}
                    placeholder={p('companyPhone')}
                  />
                </div>
              </Col>
            </Row>
          )}
          <Row>
            <Col lg={6} md={12}>
              <div className="form__form-group">
                <span className="form-header">
                  {s('website')}
                  <TooltipWithIcon
                    text={p('websiteUrlTooltip')}
                    id="websiteUrlTooltip"
                  />
                </span>
                <Field
                  name="website"
                  component={renderField}
                  placeholder={s('website')}
                />
              </div>
            </Col>
            <Col lg={6} md={12}>
              <div className="form__form-group">
                <span className="form-header">{p('companyLevel')}*</span>
                <Field
                  name="companyLevel"
                  options={levels}
                  component={renderSelectField}
                  placeholder={p('companyLevel')}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="form__form-group">
                <span className="form-header">{s('address')}</span>
                <Field
                  name="address"
                  component={renderField}
                  placeholder={s('address')}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="form__form-group">
                <span className="form-header">{s('addressLink')}</span>
                <Field
                  name="addressLink"
                  component={renderField}
                  placeholder={p('addressLinkPlaceholder')}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={4} md={12}>
              <div className="form__form-group">
                <span className="form-header">{p('zip')}</span>
                <Field name="zip" component={renderField} placeholder="1234" />
              </div>
            </Col>
            <Col lg={4} md={12}>
              <div className="form__form-group">
                <span className="form-header">{p('city')}</span>
                <Field name="city" component={renderField} placeholder="City" />
              </div>
            </Col>
            <Col lg={4} md={12}>
              <div className="form__form-group">
                <span className="form-header">{p('country')}</span>
                <Field
                  name="country"
                  component={renderSelectField}
                  options={countryOptions}
                  placeholder={s('chooseCountry')}
                />
              </div>
            </Col>
          </Row>
        </Col>
        <Col md={6}>
          <Row>
            <Col lg={12}>
              <div className="form__form-group">
                <span className="form-header">{p('companyCategory')}*</span>
                <Field
                  name="category"
                  options={categoryOptions}
                  component={renderMultiSelectField}
                  placeholder={p('companyCategory')}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="form__form-group">
                <span className="form-header">{p('companyDescription')}</span>
                <Field
                  name="description"
                  component="textarea"
                  type="textarea"
                  placeholder={p('companyDescription')}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="form__form-group">
                <span className="form-header">
                  {p('descriptionSupportingClub')}
                </span>
                <Field
                  name="descriptionSupportingClub"
                  component="textarea"
                  type="textarea"
                  placeholder={p('descriptionSupportingClub')}
                />
              </div>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col xl={6} md={12}>
              <div className="form__form-group">
                <span className="form-header marginRight-8">
                  {p('isSponsor')}
                </span>
                <TooltipWithIcon
                  id="sponsor-tooltip"
                  text={p('sponsorExplained')}
                />
                <Field
                  name="isSponsor"
                  component={renderCheckBoxField}
                  className="colored-click"
                />
              </div>
            </Col>
            <Col xl={6} md={12}>
              <div className="form__form-group">
                <span className="form-header marginRight-8">
                  {p('showInApp')}
                </span>
                <TooltipWithIcon
                  id="appVisibility-tooltip"
                  text={p('visibleInAppExplained')}
                />
                <Field
                  name="isAppVisible"
                  component={renderCheckBoxField}
                  className="colored-click"
                />
              </div>
            </Col>
          </Row>
        </Col>
        <Col>
          {existingCompanyFound ? (
            <Button size="sm" className="green-button no-style" type="submit">
              {s('connect')}
            </Button>
          ) : (
            <Button color="primary" type="submit" className="next" size="sm">
              {type === 'create'
                ? `${s('create')} ${s('companySmall')}`
                : s('save')}
            </Button>
          )}

          {type === 'edit' && (
            <Button
              type="button"
              color="secondary"
              size="sm"
              onClick={history.goBack}
            >
              {s('close')}
            </Button>
          )}
          {type === 'create' && (
            <Button
              onClick={onCancel}
              type="button"
              color="secondary"
              size="sm"
            >
              {s('cancelText')}
            </Button>
          )}
        </Col>
      </form>
    </>
  );
};

BusinessInfoForm.propTypes = {
  initialize: PropTypes.func.isRequired,
  company: PropTypes.shape({
    name: PropTypes.string,
    organization_number: PropTypes.string,
    country: PropTypes.string,
    id: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    address: PropTypes.string,
    companyCategory: PropTypes.string,
    isSponsor: PropTypes.bool,
  }),
  categories: PropTypes.arrayOf(PropTypes.string),
  levels: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),

  handleSubmit: PropTypes.func.isRequired,
  type: PropTypes.string,
};

BusinessInfoForm.defaultProps = {
  company: undefined,
  categories: [],
  levels: [],
  type: 'create',
};

const reduxF = reduxForm({
  form: 'businessInfoForm',
  validate: companyValidate,
});

export default reduxF(BusinessInfoForm);
