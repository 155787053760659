import React, { useState, useEffect } from 'react';
import { Col, Row, Button } from 'reactstrap';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from './validators/validateField';
import renderField from '../../../shared/components/custom/Field';
import renderSelectField from '../../../shared/components/form/Select';
import renderRadioButtonField from '../../../shared/components/form/RadioButton';
import renderDropZoneField from '../../../shared/components/form/DropZone';
import { partial } from '../../../util/translation/translation';

const FieldForm = (props) => {
  const p = partial('FieldForm');
  const s = partial('shared');
  const [fieldTypeColor, setFieldTypeColor] = useState('');

  useEffect(() => {
    if (props.field) {
      if (props.field.field_type.colour) {
        setFieldTypeColor(props.field.field_type.colour);
      }
      props.initialize({
        name: props.field.name,
        fieldType: {
          label: props.field.field_type.display_name,
          value: props.field.field_type.id.toString(),
        },
        buyable: props.field.buyable ? '1' : '0',
        sellable: props.field.sellable ? '1' : '0',
        buyableWebsale: props.field.buyable_websale ? '1' : '0',
        overview: props.field.overview_image
          ? [
              {
                ...props.field.overview_image,
                preview: props.field.overview_image.url,
              },
            ]
          : undefined,
        panorama: props.field.panorama_image
          ? [
              {
                ...props.field.panorama_image,
                preview: props.field.panorama_image.url,
              },
            ]
          : undefined,
      });
    }
  }, [props.field]);

  const onChange = (event) => {
    const fieldType = props.fieldTypes.find((fT) => fT.value === event.value);
    if (fieldType && fieldType.color) {
      setFieldTypeColor(fieldType.color);
    } else {
      setFieldTypeColor('');
    }
  };

  return (
    <form className="form" onSubmit={props.handleSubmit}>
      <div className="flex-1">
        <Row>
          <Col>
            <div className="form__form-group">
              <span className="form-header">{s('fieldName')}</span>
              <div className="marginTop-5" />
              <Field
                name="name"
                component={renderField}
                placeholder={s('fieldName')}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="form__form-group">
              <span className="form-header">{s('fieldType')}</span>
              <div className="marginTop-5" />
              <div
                style={
                  fieldTypeColor !== ''
                    ? { borderLeft: `32px solid ${fieldTypeColor}` }
                    : {}
                }
              >
                <Field
                  name="fieldType"
                  options={props.fieldTypes}
                  component={renderSelectField}
                  placeholder={p('chooseFieldType')}
                  onChange={onChange}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={12} sm={12}>
            <div className="form__form-group">
              <span className="form-header">{p('availableForSaleInApp')}</span>
              <div className="marginTop-5" />
              <div className="form__form-group margin-0">
                <div className="form__form-group-field">
                  <Field
                    name="buyable"
                    radioValue="1"
                    label={s('yes')}
                    component={renderRadioButtonField}
                  />
                </div>
              </div>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="buyable"
                    radioValue="0"
                    label={s('no')}
                    component={renderRadioButtonField}
                    defaultChecked
                  />
                </div>
              </div>
            </div>
          </Col>
          <Col lg={6} md={12}>
            <div className="form__form-group">
              <span className="form-header">
                {p('availableForSaleInBoxOffice')}
              </span>
              <div className="marginTop-5 form__form-group margin-0">
                <div className="form__form-group-field">
                  <Field
                    name="sellable"
                    radioValue="1"
                    label={s('yes')}
                    component={renderRadioButtonField}
                  />
                </div>
              </div>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="sellable"
                    radioValue="0"
                    label={s('no')}
                    component={renderRadioButtonField}
                    defaultChecked
                  />
                </div>
              </div>
            </div>
          </Col>
          <Col lg={6} md={12}>
            <div className="form__form-group">
              <span className="form-header">
                {p('availableForSaleInWebsale')}
              </span>
              <div className="marginTop-5 form__form-group margin-0">
                <div className="form__form-group-field">
                  <Field
                    name="buyableWebsale"
                    radioValue="1"
                    label={s('yes')}
                    component={renderRadioButtonField}
                  />
                </div>
              </div>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="buyableWebsale"
                    radioValue="0"
                    label={s('no')}
                    component={renderRadioButtonField}
                    defaultChecked
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className="flex-1">
        <Col>
          <div className="form__form-group">
            <span className="form-header">
              {p('overviewPicture(birdView)')}
            </span>
            <Field name="overview" component={renderDropZoneField} />
          </div>
        </Col>
        <Col>
          <div className="form__form-group">
            <span className="form-header">{p('180picture(firstView)')}</span>
            <Field name="panorama" component={renderDropZoneField} />
          </div>
        </Col>
      </div>
      <Col className="marginTop-15" md={12}>
        <Button color="primary" type="submit">
          {props.field ? s('save') : s('create')}
        </Button>
        <Link to="/stadiums">
          <Button type="button">{s('close')}</Button>
        </Link>
      </Col>
    </form>
  );
};

FieldForm.propTypes = {
  fieldTypes: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  handleSubmit: PropTypes.func.isRequired,
  field: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    field_type: PropTypes.shape({
      id: PropTypes.number,
      display_name: PropTypes.string,
      colour: PropTypes.string,
    }),
    overview_image: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }),
    panorama_image: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }),
    buyable: PropTypes.number,
    sellable: PropTypes.number,
  }),
  initialize: PropTypes.func.isRequired,
};

FieldForm.defaultProps = {
  fieldTypes: [],
  field: null,
};

const reduxF = reduxForm({
  form: 'stadium-field-form',
  validate,
});

export default connect((state) => ({
  formValues: getFormValues('stadium-field-form')(state),
}))(reduxF(FieldForm));
