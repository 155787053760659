import React, { Component } from 'react';
import { Container, Col, Card, CardBody } from 'reactstrap';
import { getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes, { any } from 'prop-types';
import { toast } from 'react-toastify';
import Form from './components/form';
import Api from '../../../../../../util/api';
import Loading from '../../../../../../shared/components/custom/Loading';
import { partial } from '../../../../../../util/translation/translation';

const p = partial('screensIndexShare');
const s = partial('TicketStates');

class AssignSeasonTicketPage extends Component {
  static propTypes = {
    formValues: PropTypes.objectOf(any),
    match: PropTypes.shape({
      params: PropTypes.shape({
        seatID: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    formValues: undefined,
  };

  state = {
    ownerOptions: [],
    userOptions: [],
    ticketStates: [],
    seasonPrices: [],
    season: {},
    loading: true,
  };

  componentDidMount() {
    this.initializeComponent();
  }

  onSubmit = () => {
    const payload = {
      new_company_id:
        this.props.formValues.owner.isCompany === true
          ? this.props.formValues.owner.value
          : undefined,
      notify_user: this.props.formValues.push,
      owner_id: !this.props.formValues.owner.isCompany
        ? this.props.formValues.owner.value
        : undefined,
      season_id: this.state.season.id,
      tags: this.props.formValues.tags,
      season_price_id: this.props.formValues.priceGroup.value,
      seat_id: this.props.match.params.seatID,
      shareable: this.props.formValues.shareable,
      ticket_state_id: this.props.formValues.ticketState.value,
      total: this.props.formValues.total,
      user_id: this.props.formValues.user.value,
      vip: this.props.formValues.vip,
      comment: this.props.formValues.comment,
      releasable: this.props.formValues.releasable,
    };
    this.setState({ loading: true }, () => {
      Api.seasonTickets
        .assignSeasonTicket(payload)
        .then(() => {
          this.props.history.push('/tickets?tab=1');
          toast.success(p('seasonTicketAssigned'));
        })
        .catch((err) => {
          this.setState({ loading: false });
          toast.error(err || p('errorAssigningSeasonTicket'));
        });
    });
  };
  // eslint-disable-next-line react/sort-comp
  debounce = (cb) => {
    let id = '';
    return (value) => {
      clearTimeout(id);
      id = setTimeout(() => {
        cb(value);
      }, 400);
    };
  };
  getOwners = this.debounce(async (text) => {
    try {
      const ownerOptions = [];
      const [resCompanies, { users: resUsers }] = await Promise.all([
        Api.companies.searchCompanies(text.toLowerCase()),
        Api.users.getTeamUsers(1, 100, { phoneNumber: 'ASC' }, text),
      ]);
      resCompanies.data.forEach((company) =>
        ownerOptions.push({
          label: company.name,
          value: company.id,
          isCompany: true,
        })
      );
      resUsers.forEach((user) =>
        ownerOptions.push({
          label: `${user.firstname} ${user.lastname} ${user.username}`,
          value: user.userCloudId,
          isCompany: false,
        })
      );
      this.setState({ ownerOptions });
    } catch (err) {
      toast.error(err || p('fetchingSponsorsFailed'));
    }
  });

  getUsers = this.debounce(async (text) => {
    try {
      const { users } = await Api.users.getTeamUsers(
        1,
        100,
        { phoneNumber: 'ASC' },
        text
      );
      const userOptions = users.map((user) => ({
        label: `${user.firstname} ${user.lastname} ${user.username}`,
        value: user.userCloudId,
      }));
      this.setState({ userOptions });
    } catch (err) {
      toast.error(err || p('fetchingUsersFailed'));
    }
  });

  handleTicketStates = (ticketStates) => {
    const acceptedStates = ['ACTIVE', 'RESERVED'];
    const filterTicketStates =
      (_acceptedStates) =>
      ({ name }) =>
        _acceptedStates.includes(name);
    return ticketStates
      .filter(filterTicketStates(acceptedStates))
      .map((ticketState) => {
        return {
          label: s(ticketState.name.toLowerCase()),
          value: ticketState.id.toString(),
        };
      });
  };

  handleSeasonPrices = (seasonPrices, season) => {
    const filteredSeasonPrices = seasonPrices.filter(
      (seasonPrice) => seasonPrice.season_id === season.id
    );
    return filteredSeasonPrices.map((price) => {
      return {
        label: price.title,
        value: price.id.toString(),
      };
    });
  };

  initializeComponent = () => {
    const url = new URL(window.location.href);
    const seasonId = url.searchParams.get('season');
    const available = url.searchParams.get('available') === 'true';
    const getSeason = () =>
      seasonId
        ? Api.seasons.getSeasonByID(seasonId)
        : Api.seasonTickets.getCurrentSeason();
    Promise.all([
      Api.seasonTickets.getTicketStates(),
      Api.seasonTickets.getSeasonPrices(),
      getSeason(),
    ])
      .then(([resTicketStates, resSeasonPrices, resSeason]) => {
        const ticketStates = this.handleTicketStates(resTicketStates.data);
        const seasonPrices = this.handleSeasonPrices(
          resSeasonPrices.data,
          resSeason.data
        );
        this.setState({
          ticketStates,
          seasonPrices,
          available,
          season: resSeason.data,
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(err || p('errorFetchingInfo'));
      });
  };

  render() {
    return (
      <Container>
        <Loading loading={this.state.loading} />
        <Col md={12}>
          <h3 className="page-title">{p('assignSeasonTicket')}</h3>
        </Col>
        <Col md={12}>
          <Card>
            <CardBody>
              <Form
                ownerOptions={this.state.ownerOptions}
                userOptions={this.state.userOptions}
                getOwner={this.getOwners}
                getUser={this.getUsers}
                ticketStates={this.state.ticketStates}
                seasonPrices={this.state.seasonPrices}
                onSubmit={this.onSubmit}
                available={this.state.available}
              />
            </CardBody>
          </Card>
        </Col>
      </Container>
    );
  }
}
export default connect((state) => ({
  formValues: getFormValues('assign_season_ticket')(state),
}))(AssignSeasonTicketPage);
