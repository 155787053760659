import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { partial } from '../../../../util/translation/translation';

const Event = ({ event }) => {
  const dT = partial('DashboardPage');
  const [filledSpots, setFilledSpots] = useState(0);
  const [filledWidth, setFilledWidth] = useState('');

  useEffect(() => {
    const { maxAttendees, participants } = event;
    const filled = participants.reduce(
      (total, participant) => total + participant.friendAmount + 1,
      0
    );
    const filledPercentage = (filled * 100) / maxAttendees;
    setFilledSpots(filled);
    setFilledWidth(filledPercentage);
  }, []);

  return (
    <Link to={`/events/edit/${event.id}`}>
      <div className="dashboard-event">
        <div className="dashboard-event-wrapper">
          <div className="dashboard-event-calendar">
            <div className="dashboard-event-calendar-month">
              {moment(event.startDate).format('MMM')}
            </div>
            <div className="dashboard-event-calendar-day">
              {moment(event.startDate).format('DD')}
            </div>
          </div>
          <div className="dashboard-event-content">
            <h3 className="dashboard-event-content-title">{event.title}</h3>
            <div className="dashboard-event-content-date">
              {dT('salesStart')}: {moment(event.registrationStart).format('DD')}
              .{moment(event.registrationStart).format('MMM')},{' '}
              {moment(event.registrationStart).format('HH')}:
              {moment(event.registrationStart).format('mm')}
            </div>
          </div>
        </div>
        <div>
          <div className="dashboard-event-participants">
            <span className="dashboard-event-participants-count">
              {filledSpots}
            </span>{' '}
            / {event.maxAttendees}
          </div>
          <div className="dashboard-event-progress">
            <div
              className="dashboard-event-progress-bar"
              style={{ width: `${filledWidth}px` }}
            />
            <div className="dashboard-event-progress-indicator" />
          </div>
        </div>
      </div>
    </Link>
  );
};
export default Event;
