import React from 'react';

export default function CountIcon(props) {
  return (
    <img
      src="/assets/files/questions/up-down.svg"
      alt="Count question"
      {...props}
    />
  );
}
