import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Close from 'mdi-react/CloseCircleOutlineIcon';
import SectionLabelComponentForm from './SectionLabelComponentForm';
import t, { partial } from '../../util/translation/translation';

const SectionLabelComponent = ({
  showModal,
  onClose,
  selectedComponent,
  onSave,
}) => {
  const compName =
    selectedComponent?.id === 'section-label' ? 'sectionLabel' : null;
  if (!compName) {
    return null;
  }
  const s = partial('shared');

  const promptTitle = `${selectedComponent?.value ? s('edit') : s('create')} ${t(`appLayout.${compName}`)}`;
  const handleSubmit = (values) => {
    const payload = {
      ...selectedComponent,
      value: values.title,
      label: values.title,
    };
    onSave(payload);
  };
  return (
    <Modal
      modalClassName="question-modal"
      isOpen={!!showModal}
      toggle={onClose}
    >
      <ModalHeader className="navbar-modal-header">
        <div className="modal-header-title">{promptTitle}</div>
        <div className="float-right">
          <button className="marginBottom-0 builder-button" onClick={onClose}>
            <Close />
          </button>
        </div>
      </ModalHeader>
      <ModalBody>
        <SectionLabelComponentForm
          component={selectedComponent}
          onSubmit={handleSubmit}
          onClose={onClose}
        />
      </ModalBody>
    </Modal>
  );
};
export default SectionLabelComponent;
