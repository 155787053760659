import React, { useState } from 'react';
import {
  Table,
  Col,
  Row,
  Button,
  Input,
  Modal,
  FormGroup,
  ButtonToolbar,
  ButtonGroup,
  Container,
  Badge,
} from 'reactstrap';
import { reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import CloseCircleOutline from 'mdi-react/CloseCircleOutlineIcon';
import InfoCircleOutline from 'mdi-react/InfoCircleOutlineIcon';
import CheckboxMarkedCircleIcon from 'mdi-react/CheckboxMarkedCircleIcon';
import CloseCircleIcon from 'mdi-react/CloseCircleIcon';
import Api from '../../../util/api';
import t, { partial } from '../../../util/translation/translation';
import ContactInfoForm from './ContactInfoForm';
import FindUserForm from './FindUserForm';
import useModal from '../../../shared/hooks/useModal';
import Loading from '../../../shared/components/custom/Loading';
import IconButton from '../../../shared/components/custom/IconButton';

const s = partial('shared');
const p = partial('AddEmployee');

const AddEmployee = (props) => {
  const [loading, setLoading] = useState(false);
  const [user, setUserData] = useState(undefined);
  const [showNotFoundModal, setNotFoundModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [handleModal, visible, deleteID, comment, setComment] = useModal();

  const handelDeleteEmployee = () => {
    props.handleDeleteButton(deleteID, comment);
    handleModal();
  };
  const handleDeleteClick = (index) => {
    handleModal(index);
  };

  const handleSearch = async (query) => {
    setIsEdit(false);
    const { email, phone } = query;
    let condition = {};
    if (phone) {
      condition = {
        phoneNumber: phone.phone,
      };
    }
    if (email) {
      condition = {
        email,
      };
    }
    if (!phone && !email) {
      toast.info(p('fieldEmpty'));
      return;
    }
    const userData = await Api.users.userSearch(condition);
    if (!userData) {
      toast.info(p('noResultFound'));
      setNotFoundModal(true);
      setUserData({});
      return;
    }
    setUserData(userData);
  };

  const getEmployeeInformationForEdit = async (employeeID) => {
    try {
      if (employeeID) {
        const resEmployee = await Api.employees.getEmployeeById(employeeID);
        setUserData({
          userCloudId: resEmployee.id,
          firstname: resEmployee.firstName,
          lastname: resEmployee.lastName,
          email: resEmployee.email,
          phoneNumber: resEmployee.phone,
          companyPosition: resEmployee.positionTitle,
          visibleCompany: resEmployee.visibleCompany,
          visiblePrivate: resEmployee.visiblePrivate,
          isPrimaryContact:
            resEmployee.status === 'PRIMARY_CONTACT' ||
            resEmployee.status === 'PRIMARY_AND_VIP',
          isVIP:
            resEmployee.status === 'VIP' ||
            resEmployee.status === 'PRIMARY_AND_VIP',
        });
        setIsEdit(true);
      }
    } catch (err) {
      toast.error(err || `${p('employeeFetchError')}'!'`);
      setUserData({});
      setIsEdit(false);
    }
  };

  const handleEmployeeEditSubmit = async (values) => {
    const phone = `+${values.countryCode}${values.phoneNumber}`;

    let empStatus;

    if (values.isPrimaryContact && values.isVIP) {
      empStatus = 'PRIMARY_AND_VIP';
    } else if (values.isPrimaryContact) {
      empStatus = 'PRIMARY_CONTACT';
    } else if (values.isVIP) {
      empStatus = 'VIP';
    } else {
      empStatus = 'NONE';
    }
    const payload = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phone,
      positionTitle: values.companyPosition,
      visibleCompany: values.visibleCompany,
      visiblePrivate: values.visiblePrivate,
      status: empStatus,
    };
    try {
      setLoading(true);
      const employeeID = values.userId;
      if (employeeID) {
        await Api.employees.editEmployee(employeeID, payload);
        toast.success(`${p('employeeUpdated')}!`);
        setUserData({});
        setIsEdit(false);
        props.refecthEmployees();
      }
    } catch (err) {
      toast.error(err || `${p('employeeSavingERROR')}`);
    }
    setLoading(false);
  };

  const renderEmployees = () => {
    return (
      props.employees &&
      props.employees.length > 0 &&
      props.employees.map((employee, index) => (
        <tr key={`employees-${employee.id}`}>
          <td>{employee?.firstName}</td>
          <td>{employee?.lastName}</td>
          {employee?.userId ? (
            <td>
              <Link to={`/users/edit/${employee?.userId}`}>
                {employee?.email}
              </Link>
            </td>
          ) : (
            <td>{employee?.email}</td>
          )}
          <td>{employee?.phone}</td>
          <td>{employee?.positionTitle}</td>
          <td>
            <Badge color={employee?.visibleCompany ? 'success' : 'danger'}>
              {employee?.visibleCompany ? s('visible') : s('inVisible')}
            </Badge>
          </td>
          <td>
            <Badge color={employee?.visiblePrivate ? 'success' : 'danger'}>
              {employee?.visiblePrivate ? s('visible') : s('inVisible')}
            </Badge>
          </td>
          <td>
            {employee.isPrimaryContact ? (
              <CheckboxMarkedCircleIcon color="#4DE1B6" />
            ) : (
              <CloseCircleIcon color="#FF4761" />
            )}
          </td>
          <td>
            {employee.isVIP ? (
              <CheckboxMarkedCircleIcon color="#4DE1B6" />
            ) : (
              <CloseCircleIcon color="#FF4761" />
            )}
          </td>
          <td>
            <ButtonToolbar className="float-right">
              <ButtonGroup className="btn-group--icons edit-delete-btn-group">
                <IconButton
                  onClick={() => getEmployeeInformationForEdit(employee?.id)}
                  type={IconButton.types.EDIT}
                  id={`edit-${employee.id}`}
                />
                <IconButton
                  onClick={() => handleDeleteClick(Number(index))}
                  type={IconButton.types.DELETE}
                  id={`delete-${employee.id}`}
                />
              </ButtonGroup>
            </ButtonToolbar>
          </td>
        </tr>
      ))
    );
  };

  return (
    <>
      <Container style={{ width: '100%' }}>
        <Loading loading={loading} />
        <div className="mb-3">
          <FindUserForm onSubmit={handleSearch} />
        </div>
        <div>
          <ContactInfoForm
            isEdit={isEdit}
            onSubmit={
              isEdit ? handleEmployeeEditSubmit : props.handleNewEmpolyeeSubmit
            }
            user={user}
          />
        </div>
        {props.employees.length > 0 && (
          <>
            <Row className="my-4">
              <Col md={12}>
                <div
                  style={{
                    display: 'flex',
                    paddingBottom: 5,
                    background: '#fff',
                    borderRadius: 6,
                    border: '1px solid rgba(0,0,0,0.08)',
                  }}
                >
                  <Table responsive striped>
                    <thead>
                      <tr>
                        <th>{s('firstname')}</th>
                        <th>{s('lastname')}</th>
                        <th>{s('email')}</th>
                        <th>{s('phone')}</th>
                        <th>{p('companyPosition')}</th>
                        <th>{t('Reports.visibleForPartners')}</th>
                        <th>{t('Reports.visibleForPublic')}</th>
                        <th>{t('AddEmployee.primaryContact')}</th>
                        <th>VIP</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>{renderEmployees()}</tbody>
                  </Table>
                </div>
              </Col>
            </Row>
          </>
        )}
        <Modal
          isOpen={visible}
          toggle={() => {
            handleModal();
          }}
          size="md"
        >
          <div className="modal">
            <div className="modal-container" style={{ width: '500px' }}>
              <div className="modal-icon">
                <CloseCircleOutline size="36" color="#ff4861" />
              </div>
              <div className="modal-text">
                <h4>{p('deleteEmployeeText')}</h4>
              </div>
              <FormGroup style={{ width: '80%', marginTop: '5px' }}>
                <Input
                  type="textarea"
                  name="textarea"
                  onChange={(event) => setComment(event.target.value)}
                  placeholder="Why you want to remove this employee?"
                  bsSize="sm"
                />
              </FormGroup>

              <div className="modal-buttons">
                <Button
                  className="marginBottom-0"
                  color="danger"
                  onClick={handelDeleteEmployee}
                >
                  {s('delete')}
                </Button>
                <Button
                  className="marginBottom-0"
                  color="secondary"
                  onClick={() => {
                    handleModal();
                  }}
                >
                  {s('close')}
                </Button>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={showNotFoundModal}
          toggle={() => {
            setNotFoundModal(false);
          }}
          size="md"
        >
          <div className="modal">
            <div className="modal-container" style={{ width: '500px' }}>
              <div className="modal-icon">
                <InfoCircleOutline size="36" color="#ff4861" />
              </div>
              <div className="modal-text marginBottom-5">
                <h4>{p('createNewEmployeeText')}</h4>
              </div>

              <div className="modal-buttons">
                <Button
                  className="marginBottom-0"
                  color="primary"
                  onClick={() => {
                    setUserData({});
                    setNotFoundModal(false);
                  }}
                >
                  {s('create')}
                </Button>
                <Button
                  className="marginBottom-0"
                  color="secondary"
                  onClick={() => {
                    setNotFoundModal(false);
                  }}
                >
                  {s('close')}
                </Button>
              </div>
            </div>
          </div>
        </Modal>
        <div style={{ margin: '100px' }} />
      </Container>
    </>
  );
};

AddEmployee.propTypes = {
  employees: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      positionTitle: PropTypes.string,
      phone: PropTypes.string,
      email: PropTypes.string,
      userId: PropTypes.string,
    })
  ),
  refecthEmployees: PropTypes.func.isRequired,
  handleDeleteButton: PropTypes.func.isRequired,
  handleNewEmpolyeeSubmit: PropTypes.func.isRequired,
};

AddEmployee.defaultProps = {
  employees: [],
};

const reduxF = reduxForm({
  form: 'addEmployee',
});

export default reduxF(AddEmployee);
