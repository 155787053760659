// Main Component
import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Button,
  ButtonGroup,
} from 'reactstrap';
import Api from '../../util/api.js';
import useConfig from '../../util/useConfig';

import TimeframeSelector from './components/TimeframeSelector';
import DeviceTable from './components/DeviceTable';
import StatsCards from './components/StatsCards';
import ScreenTable from './components/ScreenTable';
import UserChart from './components/UserChart';
import DateRangePicker from './components/DateRangePicker';
import { partial } from '../../util/translation/translation';

import { useUserLoginData } from './hooks/useUserLoginData';
import { useUserAvgLoginStats } from './hooks/useUserAvgLoginStats';

export function UserLoginStats() {
  const config = useConfig();
  const s = partial('shared');

  const [timeframe, setTimeframe] = useState('7days');
  const [userDataType, setUserDataType] = useState('all');
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [dateRange, setDateRange] = useState({
    start: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
    end: new Date(),
  });

  const {
    loginData,
    rawData,
    totalSessions,
    sessionsPerUser,
    activeUsers,
    deviceData,
    screenData,
    isLoading: sessionDataLoading,
    fetchUserLoginStats,
    fetchCustomDateStats,
    processData,
  } = useUserLoginData();

  console.log('screenData---', screenData);

  const {
    averageEngagementTimePerUser,
    isLoading: userAvgDataLoading,
    fetchUserAvgLoginStats,
    fetchCustomAvgDateStats,
  } = useUserAvgLoginStats();

  const formatDate = (date) => {
    return date
      .toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
      })
      .replace(/\//g, '/');
  };

  const handleTimeframeClick = async (tf) => {
    setTimeframe(tf);
    setUserDataType('all');
    if (tf === 'Custom') {
      setShowDatePicker(true);
    } else {
      setShowDatePicker(false);

      const today = new Date();
      const end = new Date(today);
      end.setDate(today.getDate() - 1);
      let start = new Date();

      if (tf === '7days') {
        start = new Date(end);
        start.setDate(end.getDate() - 7);
      } else if (tf === '1month') {
        start = new Date(end - 30 * 24 * 60 * 60 * 1000);
      } else if (tf === '3months') {
        start = new Date(end - 90 * 24 * 60 * 60 * 1000);
      }

      setDateRange({ start, end });
      await fetchUserLoginStats(tf);
      await fetchUserAvgLoginStats(tf);
    }
  };

  const handleDatePickerApply = async (startDate, endDate) => {
    setShowDatePicker(false);
    setDateRange({ start: startDate, end: endDate });
    await fetchCustomDateStats(startDate, endDate);
    await fetchCustomAvgDateStats(startDate, endDate);
  };

  const handleUserDataTypeChange = (type) => {
    setUserDataType(type);
    processData(rawData, type);
  };

  useEffect(() => {
    fetchUserLoginStats('7days');
    fetchUserAvgLoginStats('7days');
  }, []);

  return (
    <div className="pt-3 pb-5" style={{ minHeight: '100vh' }}>
      <Container>
        <div className="d-flex justify-content-between align-items-center mb-4">
          <TimeframeSelector
            timeframe={timeframe}
            onTimeframeChange={handleTimeframeClick}
          />
          <div className="">
            {formatDate(dateRange.start)} - {formatDate(dateRange.end)}
          </div>
        </div>

        <Row className="mb-4">
          <Col xs={12} lg={5}>
            <div>
              <DeviceTable
                devices={deviceData}
                clubName={config.club_name}
                isLoading={sessionDataLoading}
                loginData={loginData}
              />

              <StatsCards
                totalSessions={totalSessions}
                sessionsPerUser={sessionsPerUser}
                averageEngagementTime={
                  averageEngagementTimePerUser.formatted_period_avg_engagement_per_user
                }
                sessionDataLoading={sessionDataLoading}
                userAvgDataLoading={userAvgDataLoading}
                loginData={loginData}
              />
            </div>
          </Col>

          <Col xs={12} lg={7} className="mt-3 mt-lg-0">
            <ScreenTable
              screenData={screenData}
              isLoading={sessionDataLoading}
              loginData={loginData}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <Card>
              <CardHeader className="bg-white">
                <div className="d-flex justify-content-between align-items-center">
                  <CardTitle
                    tag="h6"
                    className="mb-0"
                    style={{ fontSize: '18px', fontWeight: 'bold' }}
                  >
                    User Login Statistics
                  </CardTitle>
                  <UserDataTypeSelector
                    userDataType={userDataType}
                    onUserDataTypeChange={handleUserDataTypeChange}
                  />
                </div>
              </CardHeader>
              <CardBody>
                <DateRangePicker
                  isOpen={showDatePicker}
                  onCancel={() => setShowDatePicker(false)}
                  onApply={handleDatePickerApply}
                />

                <UserChart
                  loginData={loginData}
                  userDataType={userDataType}
                  isLoading={sessionDataLoading}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

function UserDataTypeSelector({ userDataType, onUserDataTypeChange }) {
  const ab = partial('Analytics');
  const getUserTypeButtonStyle = (type) => {
    return {
      borderRadius: '10px',
      fontWeight: userDataType === type ? '500' : 'normal',
      fontSize: '0.875rem',
      padding: '0.395rem 0.60rem',
      marginLeft: '5px',
    };
  };

  return (
    <ButtonGroup>
      <Button
        onClick={() => onUserDataTypeChange('all')}
        style={getUserTypeButtonStyle('all')}
        color="primary"
        outline
        active={userDataType === 'all'}
      >
        {ab('allUsers')}
      </Button>
      <Button
        onClick={() => onUserDataTypeChange('unique')}
        style={getUserTypeButtonStyle('unique')}
        color="primary"
        outline
        active={userDataType === 'unique'}
      >
        Unique users
      </Button>
      <Button
        onClick={() => onUserDataTypeChange('both')}
        style={getUserTypeButtonStyle('both')}
        color="primary"
        outline
        active={userDataType === 'both'}
      >
        {ab('both')}
      </Button>
    </ButtonGroup>
  );
}
