import React, { useEffect } from 'react';
import { Col, Button, Row } from 'reactstrap';
import { Field, reduxForm, propTypes as reduxFormProps } from 'redux-form';
import { Link } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';
import renderField from '../../../shared/components/custom/Field';
import validateLotteryForm from './validateLotteryForm';
import renderCheckBoxField from '../../../shared/components/form/CheckBox';
import renderTextAreaField from '../../../shared/components/custom/TextArea';
import { partial } from '../../../util/translation/translation';
import TooltipWithIcon from '../../../shared/components/custom/TooltipWithIcon';
import renderSelectField from '../../../shared/components/form/Select';
import useConfig from '../../../util/useConfig';
import renderDropZoneFieldNew from '../../../shared/components/form/DropZoneNew';

const LotteryForm = ({ handleSubmit, lottery, initialize, vendors }) => {
  const config = useConfig();
  const s = partial('shared');
  const p = partial('LotteryForm');

  useEffect(() => {
    if (lottery) {
      const teamId = config.teams.find((team) => team.id === lottery.teamId);
      const vendor = vendors.reduce((t, e) => {
        const id = lottery.vendorId[e.paymentMethod];
        if (id) {
          return [
            ...t,
            { label: e.name, value: { paymentMethod: e.paymentMethod, id } },
          ];
        }
        return t;
      }, []);
      initialize({
        teamId: teamId ? { label: teamId.name, value: teamId.id } : undefined,
        name: lottery.name,
        startDate: moment(lottery.saleStart)
          .utc(false)
          .format('YYYY-MM-DDTHH:mm'),
        endDate: moment(lottery.saleEnd).utc(false).format('YYYY-MM-DDTHH:mm'),
        price: lottery.price,
        prizes: lottery.prizes,
        image: lottery.imageUrl
          ? [{ src: lottery.imageUrl, value: lottery.imageUrl }]
          : undefined,
        description: lottery.description,
        winningDescription: lottery.winningDescription,
        vendor,
      });
    }
  }, [lottery, config, vendors]);

  return (
    <>
      <form className="form" onSubmit={handleSubmit}>
        <Col xl={8} lg={7} md={6}>
          <Row>
            <Col lg={5} md={12}>
              <div className="card__title">
                <h4 className="bold-text">{p('detailsCaps')}</h4>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={3} md={12}>
              <div className="form__form-group">
                <span className="form-header">{s('name')}</span>
                <Field
                  name="name"
                  component={renderField}
                  placeholder={s('name')}
                />
              </div>
            </Col>
            <Col lg={3} md={12}>
              <div className="form__form-group">
                <span className="form-header">{s('team')}</span>
                <Field
                  name="teamId"
                  component={renderSelectField}
                  options={config.teams?.map((team) => ({
                    label: team.name,
                    value: `${team.id}`,
                  }))}
                  placeholder={s('team')}
                />
              </div>
            </Col>
            <Col lg={3} md={12}>
              <div className="form__form-group">
                <span className="form-header">{s('price')}</span>
                <Field
                  name="price"
                  component={renderField}
                  placeholder={s('price')}
                />
              </div>
            </Col>
            <Col lg={3} md={12}>
              <div className="form__form-group">
                <span className="form-header">{s('vendor')}</span>
                <Field
                  name="vendor"
                  component={renderSelectField}
                  options={vendors.map((vendor) => ({
                    label: vendor.name,
                    value: {
                      paymentMethod: vendor.paymentMethod,
                      id: vendor.id,
                    },
                  }))}
                  placeholder={s('vendor')}
                  multiple
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="push"
                    component={renderCheckBoxField}
                    label={p('sendPushAlert')}
                    className="colored-click"
                    disabled
                  />
                  <span style={{ marginLeft: '10px' }}>
                    <TooltipWithIcon
                      id="push-lottery"
                      text={p('pushAlertExplanation')}
                    />
                  </span>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="form__form-group">
                <span className="form-header">{s('description')}</span>
                <Field name="description" component={renderTextAreaField} />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl={3} lg={6}>
              <div className="form__form-group">
                <span className="form-header">{s('startDate')}</span>
                <div className="marginTop-5" />
                <Field
                  name="startDate"
                  component={renderField}
                  type="datetime-local"
                />
              </div>
            </Col>
            <Col xl={4} lg={6}>
              <div className="form__form-group">
                <span className="form-header">{s('endDate')}</span>
                <div className="marginTop-5" />
                <Field
                  name="endDate"
                  component={renderField}
                  type="datetime-local"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="form__form-group">
                <span className="bold-text">{p('winningDescription')}</span>
                <Field
                  name="winningDescription"
                  component={renderTextAreaField}
                />
              </div>
            </Col>
          </Row>
        </Col>
        <Col xl={4} lg={5} md={6}>
          <div className="form__form-group">
            <span className="form-header">{s('image')}(1125 * 630)</span>
            <Field name="image" component={renderDropZoneFieldNew} />
          </div>
        </Col>
        <Col md={12} style={{ marginTop: '15px' }}>
          <Button color="primary">{lottery ? s('save') : s('create')}</Button>
          <Link to="/lottery">
            <Button>{s('close')}</Button>
          </Link>
        </Col>
      </form>
    </>
  );
};
LotteryForm.propTypes = {
  ...reduxFormProps,
  handleSubmit: PropTypes.func.isRequired,
  lottery: PropTypes.shape({
    name: PropTypes.string.isRequired,
    saleStart: PropTypes.string.isRequired,
    saleEnd: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    prizes: PropTypes.array,
    image: PropTypes.string,
    description: PropTypes.string,
    winningDescription: PropTypes.string,
  }),
};

LotteryForm.defaultProps = {
  lottery: null,
};

const reduxF = reduxForm({
  form: 'lottery_form',
  validate: validateLotteryForm,
});

export default reduxF(LotteryForm);
