import React from 'react';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { RecoilRoot } from 'recoil';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import App from './containers/_app/App';
import store from './containers/_app/store';
// import config from './config';
import HomePage from './containers/home/HomePage';
import Config from './util/Config';
import 'survey-core/defaultV2.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

render(
  <QueryClientProvider client={queryClient}>
    <RecoilRoot>
      <Provider store={store}>
        <Config>{(config) => (config ? <App /> : <HomePage />)}</Config>
      </Provider>
    </RecoilRoot>
  </QueryClientProvider>,
  document.getElementById('root')
);
