import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Picker from '@emoji-mart/react';
import apple from '@emoji-mart/data/sets/14/apple.json';
import SmileyIcon from 'mdi-react/SmileyOutlineIcon';

const renderField = ({
  input: inputProps,
  placeholder,
  type,
  disabled,
  meta: { touched, error },
  autoComplete,
  showError,
  onKeyPress,
  emoji,
}) => {
  const isEmoji = emoji && type === 'text';

  const [showEmoji, setShowEmoji] = useState(false);

  const handleEmojiRender = (event) => {
    event.preventDefault();
    setShowEmoji(!showEmoji);
  };

  const handleEmojiSelect = (emoji1) => {
    inputProps.onChange(inputProps.value + emoji1.native);
  };

  return (
    <div className="form__form-group-input-wrap">
      {isEmoji ? (
        <div className="d-flex">
          <input
            {...inputProps}
            placeholder={placeholder}
            type={type}
            disabled={disabled}
            autoComplete={autoComplete}
            onKeyPress={onKeyPress !== undefined ? onKeyPress : undefined}
            onChange={inputProps.onChange}
            style={{ position: 'relative', margin: '10px' }}
          />
          <>
            <SmileyIcon
              onClick={handleEmojiRender}
              color="#939393"
              style={{ cursor: 'pointer', marginTop: '10px' }}
              size={33}
            />
            {showEmoji && (
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  right: -50,
                  zIndex: 100,
                  marginTop: '10px',
                }}
              >
                <Picker
                  set="apple"
                  data={apple}
                  onClickOutside={handleEmojiRender}
                  onEmojiSelect={handleEmojiSelect}
                />
              </div>
            )}
          </>
        </div>
      ) : (
        <input
          {...inputProps}
          placeholder={placeholder}
          type={type}
          id={inputProps.name}
          disabled={disabled}
          autoComplete={autoComplete}
          onKeyPress={onKeyPress !== undefined ? onKeyPress : undefined}
        />
      )}
      {touched && error && showError && (
        <span className="form__form-group-error">{error}</span>
      )}
    </div>
  );
};

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  autoComplete: PropTypes.string,
  showError: PropTypes.bool,
  onKeyPress: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  emoji: PropTypes.bool,
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
  disabled: false,
  autoComplete: '',
  showError: true,
  onKeyPress: undefined,
  emoji: false,
};

export default renderField;
