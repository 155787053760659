import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import DotsHorizontalIcon from 'mdi-react/DotsHorizontalIcon';
import Loading from '../../shared/components/custom/Loading';
import DeleteModal from '../../shared/components/custom/DeleteModal';
import Api from '../../util/api';
import NavTab from '../../shared/components/custom/NavTab';
import useModal from '../../shared/hooks/useModal';
import CheckIcon from '../../shared/components/custom/CheckIcon';
import { partial } from '../../util/translation/translation';
import { formatDateTime } from '../../util/date-and-time';
import useConfig from '../../util/useConfig';
// import config from '../../config';

const s = partial('shared');
const p = partial('DiscountsPage');

const DiscountsPage = () => {
  const config = useConfig();
  const [loading, setLoading] = useState(false);
  const [volumeDiscounts, setVolumeDiscounts] = useState([]);
  const [discountCodes, setDiscountCodes] = useState([]);
  const [
    handleVolumeDiscountModal,
    volumeDiscountVisible,
    deleteVolumeDiscountId,
  ] = useModal();
  const [handleDiscountCodeModal, discountCodeVisible, deleteDiscountCodeId] =
    useModal();
  const [tab, setTab] = useState('1');

  const getDiscounts = async () => {
    setLoading(true);
    try {
      const [resVolumeDiscounts, resDiscountCodes] = await Promise.all([
        Api.packages.getDiscounts(),
        Api.discountCode.all(),
      ]);
      setVolumeDiscounts(resVolumeDiscounts.data);
      setDiscountCodes(resDiscountCodes.data);
      setLoading(false);
    } catch (err) {
      toast.error(err || `${p('discountFetchingERROR')}'!'`);
      setLoading(false);
    }
  };

  useEffect(() => {
    getDiscounts();
  }, []);

  const deleteVolumeDiscount = async () => {
    try {
      await Api.packages.deleteDiscount(deleteVolumeDiscountId);
      handleVolumeDiscountModal();
      toast.success(`${p('discountDeleted')}`);
      getDiscounts();
    } catch (err) {
      handleVolumeDiscountModal();
      toast.error(err || `${p('problemOccurred')}'!'`);
    }
  };

  const deleteDiscountCode = async () => {
    try {
      await Api.discountCode.delete(deleteDiscountCodeId);
      handleDiscountCodeModal();
      toast.success(`${p('discountDeleted')}`);
      getDiscounts();
    } catch (err) {
      handleDiscountCodeModal();
      toast.error(err || `${p('problemOccurred')}'!'`);
    }
  };

  const renderDotsHorizontal = (id, route, handleModal) => (
    <UncontrolledDropdown className="dashboard__table-more">
      <DropdownToggle>
        <DotsHorizontalIcon />
      </DropdownToggle>
      <DropdownMenu className="dropdown__menu">
        <Link to={route}>
          <DropdownItem>{s('edit')}</DropdownItem>
        </Link>
        <DropdownItem onClick={() => handleModal(id)} className="danger">
          {s('delete')}
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );

  const renderVolumeDiscounts = () => {
    if (volumeDiscounts.rules && volumeDiscounts.rules.length > 0) {
      return volumeDiscounts.rules.map((volumeDiscount) => (
        <tr key={`volumeDiscount-${volumeDiscount.id}`} className="center-list">
          <td>
            <Link to={`/discounts/volume/edit/${volumeDiscount.id}`}>
              {volumeDiscount.package.name}
            </Link>
          </td>
          <td>
            <CheckIcon state={volumeDiscount.package.active} />
          </td>
          <td>
            {volumeDiscount.season
              ? `${p('seasonCardSale')}`
              : `${p('singleTicketSale')}`}
          </td>
          <td>{volumeDiscount.product_transactions_count}</td>
          <td>{volumeDiscount.package.description}</td>
          <td>{volumeDiscount.package.price}</td>
          <td>{formatDateTime(volumeDiscount.package.sale_start)}</td>
          <td>
            {volumeDiscount.package.sale_end
              ? formatDateTime(volumeDiscount.package.sale_end)
              : '-'}
          </td>
          <td>
            {renderDotsHorizontal(
              volumeDiscount.id,
              `/discounts/volume/edit/${volumeDiscount.id}`,
              handleVolumeDiscountModal
            )}
          </td>
        </tr>
      ));
    }
    return null;
  };

  const renderDiscountCodes = () => {
    return discountCodes.map((discountCode) => (
      <tr key={`discountCode-${discountCode.id}`}>
        <td>
          <Link to={`/discounts/code/edit/${discountCode.id}`}>
            {discountCode.package.name}
          </Link>
        </td>
        <td>
          <CheckIcon state={discountCode.active} />
        </td>
        <td>{discountCode.package.price}</td>
        <td>
          {discountCode.payments_count} /{' '}
          {discountCode.amount === 0 ? '∞' : discountCode.amount}
        </td>
        <td>{formatDateTime(discountCode.package.sale_start)}</td>
        <td>
          {discountCode.package.sale_end
            ? formatDateTime(discountCode.package.sale_end)
            : '-'}
        </td>
        <td>{discountCode.code}</td>
        <td>
          {renderDotsHorizontal(
            discountCode.id,
            `/discounts/code/edit/${discountCode.id}`,
            handleDiscountCodeModal
          )}
        </td>
      </tr>
    ));
  };

  return (
    <>
      <DeleteModal
        type={p('theQuantityDiscountSmall')}
        visible={volumeDiscountVisible}
        handleModal={handleVolumeDiscountModal}
        modalAction={deleteVolumeDiscount}
        modalType="delete"
      />
      <DeleteModal
        type={p('discountCodeSmall')}
        visible={discountCodeVisible}
        handleModal={handleDiscountCodeModal}
        modalAction={deleteDiscountCode}
        modalType="delete"
      />
      <Container>
        <Row>
          <Col>
            <NavTab
              tabs={[`${p('quantityDiscounts')}`, `${p('discountCodes')}`]}
              setTab={setTab}
              activeTab={tab}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Loading loading={loading} />
            {tab === '1' && (
              <Card>
                <CardBody>
                  <div
                    className="flex space-between"
                    style={{ float: 'right' }}
                  >
                    <Link to="/discounts/volume/create">
                      <Button className="margin-0" color="primary" size="sm">
                        {p('createQuantityDiscounts')}
                      </Button>
                    </Link>
                  </div>
                  <Table responsive bordered>
                    <thead>
                      <tr>
                        <th>{p('discountName')}</th>
                        <th>{s('active')}</th>
                        <th>{p('appliesTo')}</th>
                        <th>{p('timesUsed')}</th>
                        <th>{s('description')}</th>
                        <th>
                          {p('discountIn')}
                          {config.currencyShortSmall}
                        </th>
                        <th>{p('availableFrom')}</th>
                        <th>{p('availableTo')}</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>{renderVolumeDiscounts()}</tbody>
                  </Table>
                </CardBody>
              </Card>
            )}
            {tab === '2' && (
              <Card>
                <CardBody>
                  <div
                    className="flex space-between"
                    style={{ float: 'right' }}
                  >
                    <Link to="/discounts/code/create">
                      <Button color="primary" size="sm">
                        {p('createDiscountCode')}
                      </Button>
                    </Link>
                  </div>
                  <Table responsive bordered>
                    <thead>
                      <tr>
                        <th>{p('discountName')}</th>
                        <th>{s('active')}</th>
                        <th>{p('discountedAmount')}</th>
                        <th>{s('quantity')}</th>
                        <th>{p('availableFrom')}</th>
                        <th>{p('availableTo')}</th>
                        <th>{p('code')}</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>{renderDiscountCodes()}</tbody>
                  </Table>
                </CardBody>
              </Card>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default DiscountsPage;
