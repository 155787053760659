import React, { useEffect } from 'react';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { Col, Button } from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import renderField from '../../../shared/components/custom/Field';
import validate from './validateApi';
import { partial } from '../../../util/translation/translation';

const NotificationLabelForm = ({ handleSubmit, label, initialize }) => {
  const s = partial('shared');
  useEffect(() => {
    if (label) {
      initialize({
        title: label.title,
      });
    }
  }, [label]);
  return (
    <form onSubmit={handleSubmit} className="form">
      <Col lg={6}>
        <div className="form__form-group">
          <span className="form">{s('title')}</span>
          <Field
            name="title"
            component={renderField}
            placeholder={s('title')}
          />
        </div>
      </Col>
      <Col md={12}>
        <Button type="submit" color="primary">
          {label ? s('save') : s('create')}
        </Button>
        <Link className="color-unset" to="/messages?tab=3">
          <Button type="button">{s('close')}</Button>
        </Link>
      </Col>
    </form>
  );
};

const reduxF = reduxForm({
  form: 'news_api_form',
  validate,
});

export default withRouter(
  connect((state) => ({
    formValues: getFormValues('messages_api_form')(state),
  }))(reduxF(NotificationLabelForm))
);
