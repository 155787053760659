/* eslint-disable no-unused-vars */
import React, { useState, useMemo, useEffect } from 'react';
// import { Row, Col, FormGroup, Input, Offcanvas } from 'reactstrap';

import { partial } from '../../../util/translation/translation';
import QuestionType from './QuestionnaireBuilder/QuestionType';

const QuestionStatsModel = () => {
  return <></>;
};

export default QuestionStatsModel;

/* const QuestionsStatsModel = (props) => {
  const { showModal, handleCloseModal, questionKey, questionValues } = props;

  const [searchKey, setSearchKey] = useState('');
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    if (searchKey === '') {
      setQuestions(questionValues);
    } else {
      const filteredQ = questionValues.filter((item) =>
        item.answer.includes(searchKey),
      );
      setQuestions(filteredQ);
    }
  }, [searchKey, questionValues]);

  const qS = partial('question');

  const renderModalContent = useMemo(() => {
    return (
      <div>
        <Row>
          <div className="recent-answers">
            <p>{qS('recentAnswers')}</p>
          </div>
        </Row>
        {questions &&
          questions.map(
            (item, index) =>
              item.answer !== '' && (
                // eslint-disable-next-line react/no-array-index-key
                <Row key={index}>
                  <div className="answer-box">
                    <p>{item.answer}</p>
                  </div>
                </Row>
              ),
          )}
      </div>
    );
  }, [questions]);

  return (
    <Offcanvas toggle={handleCloseModal} isOpen={showModal} direction="end">
      <div className="summary-question-card">
        <div className="content-area">
          <Row>
            <Col className="question-header">
              <div className="question-id">
                <p>{props.questionNumber}</p>
              </div>
              <div className="question-description">
                <h5>{questionKey}</h5>
                <p>
                  {questionValues.length} {qS('users')}
                </p>
              </div>
            </Col>
            {questionValues && questionValues.length > 0 && (
              <Col className="col-3 d-flex justify-content-end">
                <QuestionType type={questionValues[0].question_type} />
              </Col>
            )}
          </Row>
          <FormGroup style={{ width: '100%', marginTop: '20px' }}>
            <Input
              type="text"
              name="textarea"
              onChange={(event) => setSearchKey(event.target.value)}
              placeholder="Search"
              bsSize="sm"
            />
          </FormGroup>
        </div>
        <hr />
        <div className="content-area">
          <div className="question-content">{renderModalContent}</div>
        </div>
      </div>
    </Offcanvas>
  );
};

export default QuestionsStatsModel;
*/
