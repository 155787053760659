import React, { PureComponent } from 'react';
import t from '../../../util/translation/translation';
import DeleteIcon from '../../../containers/events/components/QuestionnaireBuilder/icons/DeleteIcon';

class ItemsArrayField extends PureComponent {
  render() {
    const { onChange, value } = this.props;
    return (
      <div>
        {(value || []).map((url, index) => (
          <div
            className=""
            // eslint-disable-next-line react/no-array-index-key
            key={`video-${index}`}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: 10,
            }}
          >
            <input
              type="text"
              value={url}
              onChange={(e) => {
                const newUrls = [...(value || [])];
                newUrls[index] = e.target.value;
                onChange(newUrls);
              }}
              style={{ marginRight: 10 }}
              placeholder={t('NewsForm.youtubeVideoID')}
            />
            <button
              className="action-button red-border"
              onClick={() => {
                const newUrls = [...(value || [])];
                newUrls.splice(index, 1);
                onChange(newUrls);
              }}
            >
              <DeleteIcon />
            </button>
          </div>
        ))}
        <div
          style={{ color: 'blue' }}
          onClick={() => {
            onChange([...(value || []), '']);
          }}
          role="button"
          tabIndex={0}
        >
          {`+ ${t('shared.add')}`}
        </div>
      </div>
    );
  }
}

const renderItemsArrayField = (props) => (
  <div className="form__form-group-input-wrap">
    <ItemsArrayField {...props.input} />
    {props.meta.touched && props.meta.error && (
      <span className="form__form-group-error">{props.meta.error}</span>
    )}
  </div>
);

export default renderItemsArrayField;
