import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import { useParams, useHistory } from 'react-router';
import Api from '../../util/api';
import Loading from '../../shared/components/custom/Loading';
import t, { partial } from '../../util/translation/translation';
import NewsProviderForm from './components/NewsProviderForm';

const s = partial('shared');
const NewsProviderFormPage = () => {
  const [loading, setLoading] = useState(false);
  const { providerID } = useParams();
  const [newsCategories, setNewsCategories] = useState([]);
  const [providerData, setProviderData] = useState(null);
  const history = useHistory();
  const onSubmit = async (values) => {
    setLoading(true);
    const payload = {
      categoryId: values.category.value,
    };
    try {
      await Api.news.updateProviderCategory(providerID, payload);
      setLoading(false);
      history.replace('/news?tab=2');
    } catch (err) {
      toast.error(err || s('somethingWentWrong'));
      setLoading(false);
    }
  };
  const getNewsCategories = async () => {
    setLoading(true);
    try {
      const categoryList = await Api.news.getNewsCategories();
      setNewsCategories(categoryList);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(t('NewsFormPage.fetchingNewsFailed'));
    }
  };
  const getProviderData = async () => {
    setLoading(true);
    try {
      const allProviders = await Api.news.getNewsProviders();
      const foundProvider = allProviders.find((item) => item.id === providerID);
      setProviderData(foundProvider);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(t('NewsFormPage.fetchingNewsFailed'));
    }
  };
  useEffect(() => {
    getNewsCategories();
  }, []);
  useEffect(() => {
    getProviderData();
  }, [providerID]);
  return (
    <Container>
      <Loading loading={loading} />
      <Row>
        <Col md={12}>
          <h3 className="page-title">{s('edit')}</h3>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <NewsProviderForm
                onSubmit={onSubmit}
                categories={newsCategories}
                providerData={providerData}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default NewsProviderFormPage;
