import React, { useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Col, Row, Container, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import renderField from '../../../shared/components/custom/Field';
import validate from './validateSegment';
import { partial } from '../../../util/translation/translation';

const SpotlightSegmentForm = (props) => {
  const { handleSubmit, entity, initialize } = props;
  const s = partial('shared');
  useEffect(() => {
    if (entity) {
      initialize({
        name: entity.name,
        description: entity.description,
      });
    }
  }, [entity]);
  return (
    <form onSubmit={handleSubmit} className="form">
      <Container>
        <Row>
          <Col lg="12" className="mb-3">
            <Row>
              <Col>
                <div className="form__form-group">
                  <span className="form">{s('heading')}</span>
                  <div className="form__form-group-field">
                    <Field
                      name="name"
                      component={renderField}
                      placeholder={s('heading')}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="form__form-group">
                  <span className="form">{s('description')}</span>
                  <div className="form__form-group-field">
                    <Field
                      name="description"
                      component={renderField}
                      placeholder={s('description')}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col>
            <Button type="submit" color="primary">
              {entity ? s('save') : s('create')}
            </Button>
            <Link to="/users?tab=3">
              <Button>{s('cancel')}</Button>
            </Link>
          </Col>
        </Row>
      </Container>
    </form>
  );
};

const reduxF = reduxForm({
  form: 'spotlight-segment-form',
  validate,
});

export default reduxF(SpotlightSegmentForm);
