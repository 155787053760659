import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import Api from '../../util/api';
import Loading from '../../shared/components/custom/Loading';
import { partial } from '../../util/translation/translation';
import NotificationLabelForm from './components/NotificationLabelForm';

const s = partial('shared');

const NotificationLabelFormPage = () => {
  const [loading, setLoading] = useState(false);
  const [label, setLabel] = useState(null);
  const { labelId } = useParams();
  const history = useHistory();
  const getLabel = async () => {
    setLoading(true);
    try {
      const resLabel = await Api.userDevices.getNotificationLabel(labelId);
      setLabel(resLabel);
    } catch (err) {
      toast.error(err || s('somethingWentWrong'));
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (labelId) {
      getLabel();
    }
  }, [labelId]);
  const onSubmit = async (values) => {
    setLoading(true);
    const payload = {
      title: values.title,
    };
    try {
      if (labelId) {
        await Api.userDevices.updateNotificationLabel(labelId, payload);
      } else {
        await Api.userDevices.createNotificationLabel(payload);
      }
      setLoading(false);
      history.replace('/messages?tab=3');
    } catch (err) {
      toast.error(err || s('somethingWentWrong'));
      setLoading(false);
    }
  };
  return (
    <Container>
      <Loading loading={loading} />
      <Row>
        <Col md={12}>
          <h3 className="page-title">{s('create')}</h3>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <NotificationLabelForm onSubmit={onSubmit} label={label} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default NotificationLabelFormPage;
