import React, { useState, useEffect } from 'react';
import { Table, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { groupBy, uniq } from 'ramda';
import Loading from '../../../shared/components/custom/Loading';
import Api from '../../../util/api';
import { partial } from '../../../util/translation/translation';
import TooltipWithIcon from '../../../shared/components/custom/TooltipWithIcon';
import renderSwitchField from '../../../shared/components/custom/Switch';

const LotteryListDraw = ({ lotteryID, lottery }) => {
  const [loading, setLoading] = useState(true);
  const [winners, setWinners] = useState([]);
  const s = partial('shared');
  const p = partial('LotteryListDraw');

  const collected = (id) => async () => {
    await Api.lottery.collect(id);
    const resSystem = await Api.lottery.draw(lotteryID);
    setWinners(resSystem.data);
    setLoading(false);
  };
  const SwitchCollected = renderSwitchField;
  const inputOpt = (winner) => ({
    onChange: collected(winner.id),
    name: `${winner.lotteryTicket.id}`,
    value: !!winner.collectedBy,
  });

  const collectedBy = (winner) => {
    if (!winner.collectedBy) {
      return '';
    }
    return `${winner.collectedBy.firstname} ${winner.collectedBy.lastname} ${winner.collectedBy.lastname === null ? `(${winner.collectedBy.phoneNumber})` : ''}`;
  };
  const collectedByDate = (winner) => {
    if (!winner.collectedBy) {
      return '';
    }
    return `${moment(moment(winner.updatedAt).format('LLL'))}`;
  };
  const draw = async () => {
    setLoading(true);
    Api.lottery
      .draw(lotteryID, {
        localDate: moment().utc(true),
      })
      .then((resSystem) => {
        setWinners(resSystem.data);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(p(err.data.message));
        setLoading(false);
      });
  };

  const renderWinners = () => {
    return winners.map((winner) => {
      const {
        lotteryTicket: { user, raffleNumber },
        lotteryPrize: prize,
      } = winner;
      return (
        <tr key={`winner-${raffleNumber}`}>
          <td>{user.phoneNumber}</td>
          <td>
            {user.firstname} {user.lastname}
          </td>
          <td>{raffleNumber}</td>
          <td>{prize?.name}</td>
          <td>
            <SwitchCollected input={inputOpt(winner)} label={['', '']} />
          </td>
          <td>{`${collectedBy(winner)}`}</td>
          <td>{collectedByDate(winner)}</td>
        </tr>
      );
    });
  };

  const push = async () => {
    try {
      await Api.lottery.push(lotteryID);
      toast.success('Push success');
    } catch (err) {
      toast.error(err);
    }
  };

  const getUserIdsFromWinners = async (_winners) => {
    const userIds = uniq(
      _winners
        .map((winner) =>
          [winner.collectedBy?.id, winner.lotteryTicket.userUid].filter(
            (e) => e
          )
        )
        .flat()
    );
    const { data: usersByIds } = await Api.users.getUsersByCloudIds(userIds);
    const usrs = groupBy((usr) => usr.userCloudId, usersByIds);
    setWinners(
      _winners.map((winner) => {
        return {
          ...winner,
          collectedBy: {
            ...winner.collectedBy,
            ...(usrs[winner.collectedBy?.id]
              ? usrs[winner.collectedBy.id][0]
              : {}),
          },
          lotteryTicket: {
            ...winner.lotteryTicket,
            user: {
              ...winner.lotteryTicket.user,
              ...usrs[winner.lotteryTicket.userUid]?.[0],
            },
          },
        };
      })
    );
  };

  useEffect(() => {
    if (lottery.winners?.length > 0) {
      getUserIdsFromWinners(lottery.winners);
    }
    setLoading(false);
  }, [lotteryID, lottery]);
  return (
    <>
      <Loading loading={loading} />
      <div className="flex">
        <div className="margin-10" />
        <Col lg={12} md={12}>
          <div className="card__title">
            <h4 className="bold-text">{p('winnersCaps')}</h4>
          </div>
          <Table responsive bordered>
            <thead>
              <tr>
                <th>{p('phone')}</th>
                <th>{s('name')}</th>
                <th>{p('ticketNumber')}</th>
                <th>{p('prize')}</th>
                <th>{p('collected')}</th>
                <th>{p('deliveredBy')}</th>
                <th className="min-width-150">{s('date')}</th>
              </tr>
            </thead>
            <tbody>{renderWinners(winners)}</tbody>
          </Table>
        </Col>
      </div>
      <Col md={12} style={{ marginTop: '15px' }}>
        <div>
          <Link to="/lottery">
            <Button>{s('close')}</Button>
          </Link>
          <Button
            style={{ float: 'right' }}
            color="primary"
            onClick={draw}
            disabled={moment().isBefore(lottery.sale_end) || winners.length > 0}
          >
            {' '}
            {p('draw')}{' '}
          </Button>
          <Button
            style={{ float: 'right' }}
            color="primary"
            onClick={push}
            disabled={winners.length === 0}
          >
            {p('sendPush')}
          </Button>
          <span style={{ float: 'right', marginRight: '10px' }}>
            <TooltipWithIcon id="push-draw" text={p('pushExplanation')} />
          </span>
        </div>
      </Col>
    </>
  );
};

LotteryListDraw.propTypes = {
  lotteryID: PropTypes.string.isRequired,
  lottery: PropTypes.shape({
    name: PropTypes.string.isRequired,
    saleStart: PropTypes.string.isRequired,
    saleEnd: PropTypes.string.isRequired,
    price: PropTypes.number,
    prizes: PropTypes.array,
    image: PropTypes.string,
  }).isRequired,
};

export default LotteryListDraw;
