import React from 'react';
import { ButtonGroup, Button } from 'reactstrap';

const TimeframeSelector = ({ timeframe, onTimeframeChange }) => {
  const getTimeframeButtonStyle = (tf) => {
    return {
      borderRadius: '10px',
      fontWeight: timeframe === tf ? '500' : 'normal',
      fontSize: '0.875rem',
      padding: '0.395rem 0.60rem',
      marginLeft: '5px',
    };
  };

  return (
    <ButtonGroup>
      {['7days', '1month', '3months', 'Custom'].map((tf) => (
        <Button
          key={tf}
          onClick={() => onTimeframeChange(tf)}
          style={getTimeframeButtonStyle(tf)}
          active={tf === timeframe}
          color="primary"
          outline
        >
          {tf === '7days'
            ? '7 Days'
            : tf === '1month'
              ? '1 Month'
              : tf === '3months'
                ? '3 Months'
                : tf}
        </Button>
      ))}
    </ButtonGroup>
  );
};

export default TimeframeSelector;
