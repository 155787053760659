import React, { useEffect, useState, useMemo } from 'react';
import { Field, reduxForm, propTypes as reduxFormProps } from 'redux-form';
import { Button, Row, Col, Alert, ListGroup, Input, Table } from 'reactstrap';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import ArrowUpIcon from 'mdi-react/ArrowUpIcon';
import ArrowDownIcon from 'mdi-react/ArrowDownIcon';
import renderField from '../../../shared/components/custom/Field';
import t, { partial } from '../../../util/translation/translation';
import Api from '../../../util/api';

const s = partial('shared');

const WebsiteSponsorForm = ({ handleSubmit, setCompanies, setSettings }) => {
  const [allCompanies, setAllCompanies] = useState([]);
  const [sort, setSort] = useState({
    field: 'name',
    direction: false,
  });
  const sortComps = (companies, direction, field) => {
    const sortDir = direction ? -1 : 1;
    const companiesToSort = [...companies];
    switch (field) {
      case 'name':
        return companiesToSort.sort(
          (a, b) => sortDir * a.name.trim().localeCompare(b.name.trim())
        );
      case 'isSelected':
        return companiesToSort.sort(
          (a, b) => sortDir * (a.isSelected - b.isSelected)
        );
      default:
        return companiesToSort;
    }
  };
  const getCompanies = async () => {
    try {
      const resCompanies = await Api.companies.getCompaniesForClub();
      const { data: resSettings } = await Api.club.getSettingsByKey(
        'WebsiteSponsorIdsList'
      );
      const resSelectedCompaniesIds = resSettings.map(
        (setting) => setting.value
      );
      const sortedCompanies = sortComps(resCompanies, false, 'name');
      setSettings(resSettings);
      setAllCompanies(
        sortedCompanies.map((company) => ({
          ...company,
          isVisible: true,
          isSelected: resSelectedCompaniesIds.includes(company.id),
        }))
      );
      setCompanies(
        sortedCompanies.map((company) => ({
          ...company,
          isVisible: true,
          isSelected: resSelectedCompaniesIds.includes(company.id),
        }))
      );
    } catch (err) {
      toast.error(err || `${s('400')}`);
    }
  };

  const searchCompanies = (searchText) => {
    setAllCompanies(
      sortComps(
        allCompanies.map((company) => ({
          ...company,
          isVisible: company.name
            .toLowerCase()
            .includes(searchText.toLowerCase()),
        })),
        sort.direction,
        sort.field
      )
    );
  };

  const toggleCompanySelection = (companyToggled, checked) => {
    const updatedCompanies = allCompanies.map((company) => ({
      ...company,
      isSelected:
        company.id === companyToggled.id ? checked : company.isSelected,
    }));
    setAllCompanies(updatedCompanies);
    setCompanies(updatedCompanies);
  };

  useEffect(() => {
    getCompanies();
  }, []);

  const [companiesToShow, companiesLength] = useMemo(() => {
    const filtered = allCompanies.filter((company) => company.isVisible);
    const grouped = filtered.reduce((acc, company) => {
      if (!acc[company.companyLevel || t('OfferCard.other')]) {
        acc[company.companyLevel || t('OfferCard.other')] = [];
      }
      acc[company.companyLevel || t('OfferCard.other')].push(company);
      return acc;
    }, {});
    return [grouped, filtered.length];
  }, [allCompanies]);
  const renderSort = (cellOrder) => {
    if (sort.field !== cellOrder) {
      return null;
    }
    return sort.direction && sort.field === cellOrder ? (
      <ArrowUpIcon size={16} />
    ) : (
      <ArrowDownIcon size={16} />
    );
  };
  const handleSort = (field) => {
    const newDirection = sort.field === field ? !sort.direction : true;
    const newCompanies = sortComps(allCompanies, newDirection, field);
    setAllCompanies(newCompanies);
    setSort({
      field,
      direction: newDirection,
    });
  };
  return (
    <form className="form" onSubmit={handleSubmit}>
      <div className="form__form-group">
        <span className="form-header bold">{s('companies')}</span>
        <div className="form__form-group-field">
          <Field
            component="input"
            type="text"
            placeholder="Search for companies"
            onChange={(e) => searchCompanies(e.target.value)}
            name="search"
          />
          <div className="form__form-group-icon">
            <MagnifyIcon />
          </div>
        </div>

        <div className="my-2 d-flex flex-row-reverse">
          <p>
            {companiesLength} {s('companies')}
          </p>
        </div>

        {companiesLength > 0 && (
          <ListGroup className={`companies-list-group green-border`} flush>
            <Table responsive="xl" bordered>
              <thead>
                <tr>
                  <th
                    style={{ width: '25%' }}
                    className="sortable"
                    onClick={() => handleSort('name')}
                  >
                    {s('name')}
                    {renderSort('name')}
                  </th>
                  <th
                    className="sortable text-center"
                    onClick={() => handleSort('isSelected')}
                  >
                    {s('selected')}
                    {renderSort('isSelected')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(companiesToShow).map((levelName) => {
                  const companies = companiesToShow[levelName];
                  return (
                    <React.Fragment key={levelName}>
                      <tr className="table-secondary">
                        <td colSpan="2">
                          <strong>{levelName}</strong>
                        </td>
                      </tr>
                      {companies.map((company) => (
                        <tr key={company.id}>
                          <td>
                            <div>
                              {company?.images?.companyImage ? (
                                <img
                                  className="company__profile__avatar mr-2"
                                  src={company?.images?.companyImage}
                                  alt="logo"
                                />
                              ) : (
                                <span className="company__profile__avatar_placeholder mr-2">
                                  {company?.name[0]?.toUpperCase()}
                                </span>
                              )}
                              {company.name}
                            </div>
                          </td>
                          <td>
                            <Col className="d-flex flex-column align-items-center justify-content-center">
                              <Input
                                type="checkbox"
                                className="my-auto"
                                onChange={(e) =>
                                  toggleCompanySelection(
                                    company,
                                    e.target.checked
                                  )
                                }
                                style={{ height: '15px', width: '15px' }}
                                checked={company.isSelected}
                              />
                            </Col>
                          </td>
                        </tr>
                      ))}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </Table>
          </ListGroup>
        )}
      </div>

      <Row>
        <Col>
          <Button color="primary" type="submit">
            {s('save')}
          </Button>
        </Col>
      </Row>
    </form>
  );
};

const reduxF = reduxForm({
  form: 'website_sponsor_form',
});

export default reduxF(WebsiteSponsorForm);
