import React, { PureComponent } from 'react';
import Select from 'react-select';

class SelectField extends PureComponent {
  static defaultProps = {
    placeholder: '',
    options: [],
    disabled: false,
    isClearable: false,
  };

  handleChange = (selectedOption) => {
    this.props.onChange(selectedOption);
  };

  render() {
    const {
      value,
      name,
      placeholder,
      options,
      disabled,
      multiple,
      className,
      isClearable,
    } = this.props;
    return (
      <Select
        name={name}
        value={value}
        onChange={this.handleChange}
        options={options}
        clearable={isClearable}
        className={`form__form-group-select ${className}`}
        placeholder={placeholder}
        disabled={disabled}
        multi={multiple}
      />
    );
  }
}

const renderSelectField = (props) => {
  return (
    <div className="form__form-group-input-wrap">
      <SelectField
        {...props.input}
        options={props.options}
        placeholder={props.placeholder}
        disabled={props.disabled}
        multiple={props.multiple}
        className={props.className}
        isClearable={props.isClearable}
      />
      {props.meta.touched && props.meta.error && (
        <span className="form__form-group-error">{props.meta.error}</span>
      )}
    </div>
  );
};
renderSelectField.defaultProps = {
  meta: null,
  options: [],
  placeholder: '',
  disabled: false,
  multiple: false,
  className: '',
  isClearable: false,
};

export default renderSelectField;
