import React from 'react';

export default function UpArrowIcon(props) {
  return (
    <img
      src="/assets/files/questions/up-arrow-enabled.svg"
      alt="Move question up"
      {...props}
    />
  );
}
