import React, { useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { partial } from '../../../util/translation/translation';
import Api from '../../../util/api';
import { formatShortDateTime } from '../../../util/date-and-time';
import EmptyStateInfo from '../../../shared/components/custom/EmptyStateInfo';

const LikesList = ({ newsID }) => {
  const [likes, setLikes] = useState([]);
  const p = partial('NewsForm');
  const s = partial('shared');
  const loadLikes = async () => {
    try {
      const resLikes = await Api.news.getNewsLikes(newsID);
      const { data: userData } = await Api.users.getUsersByCloudIds(
        resLikes.map((item) => item.userId)
      );
      const userMap = {};
      for (let i = 0; i < userData.length; i++) {
        const user = userData[i];
        userMap[user.userCloudId] = user;
      }
      const mappedLikes = [];
      for (let i = 0; i < resLikes.length; i++) {
        const like = resLikes[i];
        if (userMap[like.userId]) {
          mappedLikes.push({
            ...like,
            user: userMap[like.userId],
          });
        }
      }
      setLikes(mappedLikes);
    } catch (err) {
      toast.error(err || p('fetchingNewsFailed'));
    }
  };
  useEffect(() => {
    loadLikes();
  }, [newsID]);
  const renderLikes = () => {
    // first name+lastname link, date,
    return likes.map((like) => {
      const {
        userId,
        user: { firstname, lastname, email },
        createdAt,
      } = like;
      return (
        <tr key={`like-${like.id}`} className="center-list">
          <td>
            <Link to={`/users/edit/${userId}`}>
              {firstname} {lastname}
            </Link>
          </td>
          <td>{email}</td>
          <td>{formatShortDateTime(createdAt)}</td>
        </tr>
      );
    });
  };

  return (
    <div className="px-3 bp-3">
      {likes.length === 0 && (
        <EmptyStateInfo
          icon="special"
          title={s('noLikesTitle')}
          text={s('noLikesDescription')}
        />
      )}
      {likes.length > 0 && (
        <Table responsive bordered>
          <thead>
            <tr>
              <th>
                <div className="flex alignItems-center">{`${s('user')} - ${s('totalAmountOfLikes')} (${likes.length})`}</div>
              </th>
              <th>
                <div className="flex alignItems-center">{s('email')}</div>
              </th>
              <th>
                <div className="flex alignItems-center">{s('date')}</div>
              </th>
            </tr>
          </thead>
          <tbody>{renderLikes()}</tbody>
        </Table>
      )}
    </div>
  );
};
export default LikesList;
