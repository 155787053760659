import React, { useState } from 'react';
import { connect } from 'react-redux';
import { reduxForm, change } from 'redux-form';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
import NavTab from '../../shared/components/custom/NavTab';
// import TicketCount from './externalProviders/TicketCount';
import TicksterEvents from './externalProviders/TicksterEvents';

const ExternalTicketsPage = () => {
  const [tab, setTab] = useState('');
  return (
    <Container>
      <Row>
        <Col>
          <Card>
            <CardBody style={{ padding: 0 }}>
              <NavTab
                tabs={[
                  /* { tab: 'Tickets' }, */
                  { tab: 'Tickster' },
                ]}
                activeTab={tab}
                setTab={(activeTab) => setTab(activeTab)}
                size="sm"
                style={{ marginBottom: 0 }}
              />
              <TabContent activeTab={tab}>
                {/* <TabPane style={{ padding: 10 }} tabId="1">
                    <TicketCount />
                  </TabPane> */}
                <TabPane style={{ padding: 10 }} tabId="1">
                  <TicksterEvents />
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default reduxForm({
  form: 'ticket_event_form',
})(connect(null, { change })(ExternalTicketsPage));
