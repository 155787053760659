import React, { useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import api from '../../util/api';
import Loading from '../../shared/components/custom/Loading';
import RenderFileInputField from '../../shared/components/form/FileInput';

const ImportCompaniesPage = () => {
  const [loading, setLoading] = useState(false);
  const [csvCompanies, setCsvCompanies] = useState({});
  const handleUpload = async () => {
    try {
      setLoading(true);
      const {
        data: { linked, created, employees, errors },
      } = await api.companies.addCompaniesEmployeesFromCSV(csvCompanies.file);
      toast.success(
        `Linked ${linked} companies, created ${created} companies, made ${employees} employees`
      );
      errors.forEach((error) => {
        toast.error(error);
      });
    } catch (e) {
      toast.error(e?.data?.message || 'Unknown error');
    } finally {
      setLoading(false);
    }
  };
  const handleCsv = (type) => (values) => {
    if (type === 'companies') {
      return setCsvCompanies(values);
    }
    return null;
  };

  return (
    <>
      <Loading loading={loading} />
      <div className="form">
        <Row className="width-100p">
          <Col>
            <RenderFileInputField
              meta={{}}
              input={{
                name: 'csvCompanies',
                value: csvCompanies,
                onChange: handleCsv('companies'),
              }}
            />
          </Col>
          <Col>
            <Button
              color="primary"
              disabled={!Object.keys(csvCompanies).length}
              onClick={handleUpload}
            >
              Upload
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ImportCompaniesPage;
