import React, { useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  Table,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import CheckboxMarkedCircleIcon from 'mdi-react/CheckboxMarkedCircleIcon';
import CloseCircleIcon from 'mdi-react/CloseCircleIcon';
import DotsHorizontalIcon from 'mdi-react/DotsHorizontalIcon';
import { toast } from 'react-toastify';
import Loading from '../../../shared/components/custom/Loading';
import Api from '../../../util/api';
import DeleteModal from '../../../shared/components/custom/DeleteModal';
import { partial } from '../../../util/translation/translation';

const FieldTypesList = () => {
  const p = partial('FieldTypesList');
  const s = partial('shared');
  const [fieldTypes, setFieldTypes] = useState([]);
  const [modal, setModal] = useState({
    id: null,
    visible: false,
    removeWarning: p('fieldRemoveWarning'),
  });
  const [loading, setLoading] = useState(false);

  const getFieldTypes = async () => {
    setLoading(true);
    try {
      const resFieldTypes = await Api.settings.getFieldTypes();
      setFieldTypes(resFieldTypes.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getFieldTypes();
  }, []);

  const handleModal = (id = false) => {
    setModal({ ...modal, visible: id !== false, id: id !== false && id });
  };

  const modalAction = async () => {
    if (modal.visible === false) {
      return;
    }
    setLoading(true);
    try {
      await Api.settings.deleteFieldType(modal.id);
      toast.success(p('fieldWasDeleted'));
    } catch (err) {
      toast.error(err || p('errorWhenDeletingStadium'));
    }
    getFieldTypes();
    setModal({
      ...modal,
      visible: false,
    });
  };

  const renderFieldTypes = () =>
    fieldTypes.map((fieldType) => (
      <tr className="center-list" key={`fieldType-${fieldType.id}`}>
        <td className="flex-Vcenter">
          <div
            className="field-type-color"
            style={{
              background: fieldType.colour ? fieldType.colour : '#ffffff',
            }}
          />
          {fieldType.name}
        </td>
        <td>
          <Link to={`/stadiums/field_types/edit/${fieldType.id}`}>
            {fieldType.display_name}
          </Link>
        </td>
        <td>{fieldType.price}</td>
        <td>
          {fieldType.is_unmarked ? (
            <CheckboxMarkedCircleIcon color="#399351" />
          ) : (
            <CloseCircleIcon color="#FF4861" />
          )}
        </td>
        <td>
          <UncontrolledDropdown className="dashboard__table-more">
            <DropdownToggle>
              <p>
                <DotsHorizontalIcon />
              </p>
            </DropdownToggle>
            <DropdownMenu className="dropdown__menu">
              <Link to={`/stadiums/field_types/edit/${fieldType.id}`}>
                <DropdownItem>{s('edit')}</DropdownItem>
              </Link>
              <DropdownItem
                onClick={() => handleModal(fieldType.id)}
                className="danger"
              >
                {s('delete')}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </td>
      </tr>
    ));

  return (
    <>
      <DeleteModal
        type="felttypen"
        visible={modal.visible}
        removeWarning={modal.removeWarning}
        handleModal={handleModal}
        modalAction={modalAction}
      />
      <Loading loading={loading} />
      <Card>
        <CardBody>
          <div className="card__title marginTop-0">
            <p className="page-title">{p('fieldTypes')}</p>
            <Link
              className="btn btn-primary btn-sm"
              to="/stadiums/field_types/create"
            >
              {p('createFieldTypes')}
            </Link>
          </div>
          <Table responsive bordered>
            <thead>
              <tr>
                <th>{s('name')}</th>
                <th>{p('viewingName')}</th>
                <th>{s('price')}</th>
                <th>{p('unMarkedField')}</th>
                <th />
              </tr>
            </thead>
            <tbody>{renderFieldTypes()}</tbody>
          </Table>
        </CardBody>
      </Card>
    </>
  );
};

export default FieldTypesList;
