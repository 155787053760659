/* eslint-disable no-new */
import { getAuth } from 'firebase/auth';
import { toast } from 'react-toastify';
import axios from 'axios';
import Api, { getClubName, getServiceUrl } from './api';
import { partial } from './translation/translation';

export const hexToRgb = (hex) => {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const newHex = hex.replace(
    shorthandRegex,
    (_m, r, g, b) => r + r + g + g + b + b
  );
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(newHex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};
export const convertPositionToPositionSignature = (position) => {
  const p = partial('PlayerForm');
  switch (position) {
    case 'Keeper':
      return p('goalKeeperShort');
    case 'Back':
      return p('backShort');
    case 'Attacker':
      return p('attackerShort');
    case 'Right wing':
      return p('rightWingShort');
    case 'Left wing':
      return p('leftWingShort');
    default:
      return '';
  }
};
export const convertScreenToTranslation = (screenName) => {
  const p = partial('Reports');
  const removedSeasonAndTicket = screenName
    .replace('Season', '')
    .replace('Ticket', '')
    .replace('ticket', '')
    .trim();
  switch (removedSeasonAndTicket) {
    case 'entrance':
      return p('ticketEntrance');
    case 'pick zone':
      return p('ticketPickZone');
    case 'pick row':
      return p('ticketPickRow');
    case 'pick seat':
      return p('ticketPickSeat');
    case 'confirmation':
      return p('ticketConfirmation');
    case 'Receipt':
      return p('receipt');
    default:
      return '';
  }
};

export const formatCurrencyNumber = (number = 0) =>
  Number(number).toLocaleString().split(',').join(' ');

export const convertLayoutName = (layoutName) => {
  const p = partial('AppLayout');
  try {
    return p(`${layoutName}Tile`);
  } catch (e) {
    return p('missingName');
  }
};

export const range = function (_start, _end, _step) {
  const step = _step === undefined ? 1 : _step;
  let end = _end;
  let start = _start;
  const arr = [];
  let len = 0;
  let i = 0;
  if (arguments.length === 1) {
    len = start;
    start = 0;
    end = start;
  } else {
    start = start === undefined ? 1 : start;
    end = end === undefined ? 1 : end;
    len = end - start;
  }
  while (i < len) {
    arr.push(start + i * step);
    i += 1;
  }
  return arr;
};
export const capitalizeFirstLetter = (str) =>
  str.charAt(0).toUpperCase() + str.slice(1);
export const isInfiniteDate = (inputDate) =>
  inputDate === new Date(Date.UTC(10000, 0, 1)).toISOString();

export const isSportalityAvailable = (config) => config.clubSportalityAvailable;
export const isSportalityLeagueAvailable = (config) =>
  config.leagueSportalityAvailable;
export const mappedPermissions = [
  {
    key: '1',
    name: 'admin',
    permissions: [
      'access-admin-panel',
      'access-users',
      'access-messages',
      'access-news',
      'access-ads',
      'access-season-tickets',
      'access-tickets',
      'access-stadium',
      'access-teams',
      'access-matches',
      'access-roles',
      'access-sales',
      'require-profile-image',
      'access-reports',
      'access-settings',
      'access-companies',
      'access-kiosks',
      'access-kiosk-sales',
      'access-payments',
      'ticket-validator',
      'access-extended-user',
    ],
  },
  {
    key: '2',
    name: 'user_admin',
    permissions: ['access-admin-panel', 'access-users'],
  },
  {
    key: '3',
    name: 'message_admin',
    permissions: ['access-admin-panel', 'access-users'],
  },
  {
    key: '4',
    name: 'news_admin',
    permissions: ['access-admin-panel', 'access-news'],
  },
  {
    key: '5',
    name: 'ad_admin',
    permissions: ['access-admin-panel', 'access-ads'],
  },
  {
    key: '6',
    name: 'season_ticket_admin',
    permissions: ['access-admin-panel', 'access-season-tickets'],
  },
  {
    key: '7',
    name: 'ticket_admin',
    permissions: ['access-admin-panel', 'access-tickets'],
  },
  {
    key: '8',
    name: 'stadium_admin',
    permissions: ['access-admin-panel', 'access-stadium'],
  },
  {
    key: '9',
    name: 'team_admin',
    permissions: ['access-admin-panel', 'access-teams'],
  },
  {
    key: '10',
    name: 'match_admin',
    permissions: ['access-admin-panel', 'access-matches'],
  },
  {
    key: '11',
    name: 'role_admin',
    permissions: ['access-admin-panel', 'access-roles'],
  },
  {
    key: '12',
    name: 'salesperson',
    permissions: ['access-admin-panel', 'access-sales'],
  },
  {
    key: '13',
    name: 'functionary',
    permissions: ['require-profile-image'],
  },
  {
    key: '14',
    name: 'report_admin',
    permissions: ['access-admin-panel', 'access-reports'],
  },
  {
    key: '15',
    name: 'settings_admin',
    permissions: ['access-admin-panel', 'access-settings'],
  },
  {
    key: '16',
    name: 'companies_admin',
    permissions: ['access-admin-panel', 'access-companies'],
  },
  {
    key: '17',
    name: 'kiosk_admin',
    permissions: ['access-admin-panel', 'access-kiosks', 'access-kiosks-sales'],
  },
  {
    key: '18',
    name: 'kiosk_seller',
    permissions: ['access-admin-panel', 'access-kiosks-sales'],
  },
  {
    key: '19',
    name: 'payment_admin',
    permissions: ['access-admin-panel', 'access-payments'],
  },
  {
    key: '20',
    name: 'ticket_validator',
    permissions: ['ticket-validator'],
  },
  {
    key: '21',
    name: 'extended_user_admin',
    permissions: ['access-admin-panel', 'access-users', 'access-extended-user'],
  },
];

export const getPermissions = async () => {
  const club = getClubName();
  const firebaseAuth = getAuth();
  const usr = await firebaseAuth.currentUser.getIdTokenResult();
  const team = usr.claims.teams[club];
  const { isAdmin: isSuperAdmin } = usr.claims;
  return team.roles.reduce(
    (o, e) => [
      ...o,
      ...mappedPermissions.find((p) => +p.key === +e).permissions,
    ],
    isSuperAdmin ? ['super-admin'] : []
  );
};

export const isValidUrl = (url) => {
  try {
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
};
export const cleanUrlParams = (imageUrl) => {
  if (imageUrl?.includes('params')) {
    return imageUrl.split('-params')[0];
  }
  return imageUrl?.split('?')[0];
};
export const getImageHash = (imageUrl) => {
  if (!imageUrl) {
    return null;
  }
  if (imageUrl.includes('params')) {
    return imageUrl.split('/').at(-1);
  }
  const baseImageUrl = `${getServiceUrl('image')}/`;
  const replaced = imageUrl.replace(baseImageUrl, '');
  const finalHash = replaced.includes('?') ? replaced.split('?')[0] : replaced;
  return finalHash;
};

export const convertUrlToFile = async (imageUrl) => {
  const response = await fetch(imageUrl);
  const data = await response.blob();
  return new File([data], 'test', {
    type: data.type,
  });
};
export const uploadImage = (image, blob, preserveOriginal = false) => {
  const s = partial('shared');
  const hasImage = !!image;
  if (!hasImage) {
    throw new Error('No image to upload');
  }
  return async (type, uploadEntityField, uploadEntityId) => {
    const data = new FormData();
    const imageMime = blob.split(';base64')[0].split(':')[1];
    data.append('image', image, {
      filename: image.filename,
      contentType: imageMime,
    });
    data.append('type', type);
    data.append('uploadEntityField', uploadEntityField);
    data.append('uploadEntityId', `${uploadEntityId}`);
    const modificationObject = {
      ...image.crop,
      ...(image.scale ? { scale: image.scale } : {}),
    };
    if (Object.keys(modificationObject).length && !preserveOriginal) {
      data.append('modificationParams', JSON.stringify(modificationObject));
    }
    Api.images.uploadImage(data).catch(() => {
      toast.error(s('imageUploadingERROR'));
    });
  };
};
export const uploadVideo = (video) => {
  const s = partial('shared');
  const hasVideo = !!video;
  if (!hasVideo) {
    throw new Error('No video to upload');
  }
  return async () => {
    try {
      const { uploadUrl, publicUrl } = await Api.images.getPresignedUploadUrl(
        video.name
      );
      await axios.put(uploadUrl, video, {
        headers: {
          'Content-Type': video.type,
        },
      });
      return publicUrl;
    } catch (e) {
      toast.error(s('imageUploadingERROR'));
      throw new Error(s('imageUploadingERROR'));
    }
  };
};
export const truncate = (limit, sentence) =>
  sentence?.length > limit ? `${sentence.substr(0, limit)}...` : sentence;
