import React from 'react';

import { partial } from '../../../util/translation/translation';
import DragHandle from '../../events/components/QuestionnaireBuilder/DragHandle';

export default function LibraryQuestionItem({ question }) {
  const p = partial('questionaire');

  return (
    <div className="library-question-card">
      <div className="d-flex align-items-center">
        <div className="d-flex align-items-center justify-content-center draggable-handle">
          <DragHandle />
        </div>
        <div>
          <div className="question-content">{question.title}</div>
          <div className="question-type">
            {p(`questionType-${question?.type}`)}
          </div>
        </div>
      </div>
    </div>
  );
}
