import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import { toast } from 'react-toastify';
import { useRecoilState } from 'recoil';
import MatchVippsReport from './MatchVippsReport';
import MatchKioskReport from './MatchKioskReport';
import MatchFreeReport from './MatchFreeReport';
import Loading from '../../../shared/components/custom/Loading';
import LazyTabPane from '../../../shared/components/custom/LazyTabPane';
import Api from '../../../util/api';
import { partial } from '../../../util/translation/translation';
import { formatDate } from '../../../util/date-and-time';
import seasonsState from '../../../recoil/seasons/selector';

const p = partial('ReportsPage');
const s = partial('shared');

const MatchSoldTicketsTabs = ({ activeTab }) => {
  const [seasons] = useRecoilState(seasonsState);
  const [soldTickets, setSoldTickets] = useState({
    data: [],
    kiosk: {
      data: [],
      keys: [],
    },
    freetickets: {
      data: [],
      keys: [],
    },
  });
  const [loading, setLoading] = useState(true);
  const [matches, setMatches] = useState([]);
  const [selectedSeason, setSelectedSeason] = useState(null);
  const [vippsArray, setVippsArray] = useState([]);
  const [kioskArray, setKioskArray] = useState([]);
  const [freeArray, setFreeArray] = useState([]);

  const getInitialData = async () => {
    setLoading(true);
    try {
      const [resSoldTickets, resMatches] = await Promise.all([
        Api.reports.getSoldTickets(),
        Api.reports.getMatches(),
      ]);
      setSoldTickets(resSoldTickets.data);
      setMatches(resMatches.data);
      setLoading(false);
    } catch (err) {
      toast.error(err || p('fetcingMatchTicketsFailed'));
      setLoading(false);
    }
    return '';
  };
  const renderMatchName = (id) => {
    const selectedMatch = matches.find((match) => match.id === id);

    if (selectedMatch) {
      return `${selectedMatch.away_team.name} (${formatDate(selectedMatch.match_starts_at)})`;
    }
    return p('didNotFindTheMatch');
  };

  const formatSoldTicketsData = (column, data) => {
    return data[column] ? data[column] : 0;
  };

  useEffect(() => {
    getInitialData();
  }, []);

  const sortTicketsBySeason = (array) => {
    if (typeof array !== 'undefined') {
      let res = [];
      const sortedData = seasons.map((season) => {
        res = array.filter(
          (match) =>
            Date.parse(match.match_start) > Date.parse(season.start_date) &&
            Date.parse(match.match_start) < Date.parse(season.end_date)
        );
        return {
          season: season.name,
          matches: res,
        };
      });
      return sortedData;
    }
    return null;
  };

  const handleSeasonChange = (season) => {
    const resVipps = sortTicketsBySeason(soldTickets.data).find(
      (mappedSeason) => mappedSeason.season === season.name
    );
    const resKiosk = sortTicketsBySeason(soldTickets.kiosk.data).find(
      (mappedSeason) => mappedSeason.season === season.name
    );
    const resFree = sortTicketsBySeason(soldTickets.freetickets.data).find(
      (mappedSeason) => mappedSeason.season === season.name
    );

    setVippsArray(resVipps.matches);
    setKioskArray(resKiosk.matches);
    setFreeArray(resFree.matches);
  };

  useEffect(() => {
    if (selectedSeason) {
      handleSeasonChange(selectedSeason);
    }
  }, [selectedSeason]);

  const listSeasons = () => {
    return seasons.map((season) => (
      <DropdownItem
        onClick={() => setSelectedSeason(season)}
        key={`listSeasons-${season.id}`}
      >
        {s('theSeason')} {season.name}
      </DropdownItem>
    ));
  };

  const renderCardName = (tabNr) => {
    switch (tabNr !== null) {
      case tabNr === '2':
        return s('vipps');
      case tabNr === '3':
        return s('kiosk');
      case tabNr === '4':
        return p('freeTickets');
      default:
        return s('unknown');
    }
  };

  return (
    <>
      <Loading loading={loading} />
      {(activeTab === '2' || activeTab === '3' || activeTab === '4') && (
        <div>
          <div className="card__title marginBottom-30">
            <h5 className="bold-text">
              {p('ticketSaleCaps')} - {renderCardName(activeTab)}
            </h5>
          </div>
          <h5 className="bold-text" style={{ marginBottom: '5px' }}>
            {s('periodCaps')}
          </h5>
          <UncontrolledDropdown className="marginRight-15">
            <DropdownToggle className="icon icon--right" outline>
              {selectedSeason?.name
                ? `${s('theSeason')} ${selectedSeason.name}`
                : p('chooseSeason')}
              <ChevronDownIcon />
            </DropdownToggle>
            <DropdownMenu className="dropdown__menu">
              {listSeasons()}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      )}
      <LazyTabPane activeTab={activeTab} tabId="2">
        <MatchVippsReport
          matchTickets={vippsArray}
          renderMatchName={renderMatchName}
          formatSoldTicketsData={formatSoldTicketsData}
        />
      </LazyTabPane>
      <LazyTabPane activeTab={activeTab} tabId="3">
        <MatchKioskReport
          matchTickets={soldTickets.kiosk}
          renderMatchName={renderMatchName}
          formatSoldTicketsData={formatSoldTicketsData}
          kioskArray={kioskArray}
        />
      </LazyTabPane>
      <LazyTabPane activeTab={activeTab} tabId="4">
        <MatchFreeReport
          matchTickets={soldTickets.freetickets}
          renderMatchName={renderMatchName}
          formatSoldTicketsData={formatSoldTicketsData}
          freeArray={freeArray}
        />
      </LazyTabPane>
    </>
  );
};

MatchSoldTicketsTabs.propTypes = {
  activeTab: PropTypes.string.isRequired,
};

export default MatchSoldTicketsTabs;
