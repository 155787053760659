import React, { Component } from 'react';
import {
  Row,
  Col,
  Table,
  ButtonToolbar,
  Container,
  Button,
  TabPane,
  TabContent,
  UncontrolledTooltip,
  ButtonGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import { toast } from 'react-toastify';
import { Link, withRouter } from 'react-router-dom';
import fileDownload from 'js-file-download';
import { Parser } from 'json2csv';
import CheckboxMarkedCircleIcon from 'mdi-react/CheckboxMarkedCircleIcon';
import CloseCircleIcon from 'mdi-react/CloseCircleIcon';
import DragIcon from 'mdi-react/DragIcon';
import DownloadIcon from 'mdi-react/DownloadIcon';
import exportToExcelFile from '../../util/xlsx';
import Api from '../../util/api';
import Loading from '../../shared/components/custom/Loading';
import Pagination from '../../shared/components/pagination/Pagination';
import DeleteModal from '../../shared/components/custom/DeleteModal';
import SortDirection from '../../shared/components/custom/SortDirection';
import NavTab from '../../shared/components/custom/NavTab';
import t, { partial } from '../../util/translation/translation';
import AppSponsor from './components/AppSponsor';
import { convertImageToBlob } from '../../util/image-functions';
import LinkModal from '../../shared/components/custom/LinkModal';
import ShareModal from '../../shared/components/custom/ShareModal';
import GroupForm from './components/GroupForm';
import LevelInformation from './components/LevelInformation';
import { capitalizeFirstLetter } from '../../util/functions';
import AlertModal from '../../shared/components/custom/AlertModal';
import IconButton from '../../shared/components/custom/IconButton';
import useConfig from '../../util/useConfig';
import GoalServiceSponsor from './components/GoalServiceSponsor';
import config from '../../config';
import WebsiteSponsor from './components/WebsiteSponsor';

const s = partial('shared');
const p = partial('CompanyListPage');
const ct = partial('Category');
const a = partial('AlertModal');

const SettingType = {
  GROUP: 'group',
  LEVEL: 'level',
};

class CompanyListPage extends Component {
  constructor() {
    super();
    this.state = {
      companies: {
        total: 0,
        current_page: '1',
        per_page: '25',
        page: 1,
        data: [],
      },
      order: 'name',
      reverse: true,
      searchText: '',
      searchIn: '',
      loadingCompanies: false,
      loadingSponsor: false,
      linkModalVisible: false,
      modalVisible: false,
      selectedCompanyID: null,
      loadingSettings: false,
      clubProfile: null,
      tab: '',
      deleteModal: {
        visible: false,
        settingID: null,
        type: 'level',
      },
      groupModal: {
        visible: false,
        groupState: null,
        type: '',
      },
      levelModal: {
        visible: false,
        levelState: null,
        type: '',
      },
      settingState: false,
      groups: [],
      levels: [],
      selectedCompanies: [],
    };
  }

  componentDidMount() {
    this.getGroupsAndLevels();
    this.getAppSponsor();
    this.getAllCompanies();
  }

  onChangePage = (pager) => {
    if (
      pager.pageSize !== this.state.companies.per_page ||
      pager.currentPage !== this.state.companies.current_page
    ) {
      this.getCompanies(pager.currentPage, pager.pageSize);
    }
  };

  addDeleteCompaniesFromGroupOrLevel = async (fieldValue, type) => {
    const { selectedCompanies } = this.state;
    try {
      const selectedCompaniesIds = selectedCompanies.map((x) => x.id);
      let resSelectedCompanies = [];
      if (type === SettingType.GROUP) {
        resSelectedCompanies =
          await Api.companies.getCompaniesForGroup(fieldValue);
      } else {
        resSelectedCompanies = await Api.club.getCompaniesForLevel(fieldValue);
      }
      const initiallySelected = resSelectedCompanies.map((x) => x.id);
      const toDelete = initiallySelected.filter(
        (x) => !selectedCompaniesIds.includes(x)
      );
      const toAdd = selectedCompaniesIds.filter(
        (x) => !initiallySelected.includes(x)
      );
      const promises = [];
      if (toDelete.length) {
        promises.push(
          type === SettingType.GROUP
            ? Api.club.deleteCompaniesFromGroup({
                companyId: toDelete,
                fieldValue,
              })
            : Api.club.deleteCompaniesFromLevel({
                companyId: toDelete,
                fieldValue,
              })
        );
      }
      if (toAdd.length) {
        promises.push(
          type === SettingType.GROUP
            ? Api.club.addCompaniesToGroup({ companyId: toAdd, fieldValue })
            : Api.club.addCompaniesToLevel({ companyId: toAdd, fieldValue })
        );
      }
      await Promise.all(promises);
    } catch (err) {
      toast.error(err || `${s('400')}`);
    }
  };

  handleModalTypeChangeToEdit = () => {
    const { levelModal, groupModal } = this.state;
    const key = levelModal.visible ? SettingType.LEVEL : SettingType.GROUP;
    if (key === SettingType.LEVEL) {
      this.setState({ levelModal: { ...levelModal, type: 'edit' } });
    } else {
      this.setState({ groupModal: { ...groupModal, type: 'edit' } });
    }
  };

  onSubmitSetting = async (values) => {
    const { levelModal, groupModal } = this.state;
    const key = levelModal.visible ? SettingType.LEVEL : SettingType.GROUP;
    const type = key === SettingType.LEVEL ? levelModal.type : groupModal.type;
    this.setState(
      {
        loadingSettings: true,
      },
      async () => {
        try {
          if (type === 'edit') {
            await this.addDeleteCompaniesFromGroupOrLevel(
              key === SettingType.GROUP
                ? groupModal?.groupState.value
                : levelModal?.levelState.value,
              key
            );
          }

          if (type === 'create') {
            const payload = {
              key: capitalizeFirstLetter(key),
              value: values.name,
            };

            await Api.club.createSetting(payload);
            toast.success(`${p(`${key}Created`)}`);
            this.getGroupsAndLevels();
          } else if (type === 'edit') {
            const payload = {
              id:
                key === SettingType.LEVEL
                  ? levelModal?.levelState?.id
                  : groupModal?.groupState.id,
              value: values.name,
              key: capitalizeFirstLetter(key),
            };
            await Api.club.editSetting(payload);
            toast.success(`${p(`${key}Updated`)}`);
            this.getGroupsAndLevels();
          }
        } catch (err) {
          toast.error(err || `${s('somethingWentWrong')}`);
          this.getGroupsAndLevels();
        } finally {
          this.setState({
            loadingSettings: false,
            levelModal: { visible: false, levelState: null, type: '' },
            groupModal: { visible: false, groupState: null, type: '' },
          });
        }
      }
    );
  };

  getAppSponsor = async () => {
    this.setState({ loadingSponsor: true });
    try {
      const { data: clubProfile } = await Api.clubadmin.profile.fetchProfile(
        this.props.clubConfig.ntb_team_id
      );
      this.setState({ loadingSponsor: false, clubProfile });
    } catch (err) {
      this.setState({ loadingSponsor: false, clubProfile: null }, () => {
        toast.error(err || `${s('somethingWentWrong')}`);
      });
    }
  };
  getAllCompanies = async () => {
    this.setState({ loadingSponsor: true });
    try {
      const { data: clubProfile } = await Api.clubadmin.profile.fetchProfile(
        this.props.clubConfig.ntb_team_id
      );
      this.setState({ loadingSponsor: false, clubProfile });
    } catch (err) {
      this.setState({ loadingSponsor: false, clubProfile: null }, () => {
        toast.error(err || `${s('somethingWentWrong')}`);
      });
    }
  };

  getCompanies = (
    page = this.state.companies.current_page,
    pageSize = this.state.companies.per_page
  ) => {
    this.setState({ loadingCompanies: true }, async () => {
      try {
        const resCompanies = await Api.companies.getCompanies(
          pageSize,
          page,
          this.state.searchText,
          this.state.order,
          this.state.reverse,
          this.state.searchIn
        );
        this.setState({
          companies: {
            ...this.state.companies,
            data: resCompanies.data.data,
            total: resCompanies.data.total,
          },
          loadingCompanies: false,
        });
      } catch (err) {
        this.setState({ loadingCompanies: false });
        toast.error(err || `${p('companiesFetchingERROR')}`);
      }
    });
  };

  hasImageThenConvert = async (image) => {
    const hasImage = Array.isArray(image) && image.length === 1;
    if (!hasImage) {
      return '';
    }
    if (image[0].size === undefined) {
      return image[0];
    }
    try {
      return {
        ...image[0],
        preview: await convertImageToBlob(image[0]),
      };
    } catch (err) {
      toast.error(err || `${s('imageUploadingERROR')}`);
      throw err;
    }
  };
  handleSubmitAppSponsor = async ({ selectedCompany }) => {
    this.setState({ loadingSponsor: true });
    try {
      const payload = {
        ...this.state.clubProfile,
        appSponsorId: selectedCompany.value.id,
        clubId: this.props.clubConfig.ntb_team_id,
      };
      await Api.clubadmin.profile.post(payload);
      toast.success(`${p('categoryCreated')}`);
      this.getAppSponsor();
    } catch (err) {
      this.setState({ loadingSponsor: false, clubProfile: null }, () => {
        toast.error(err || `${s('somethingWentWrong')}`);
      });
    }
  };

  handleModal = (state, companyID) => {
    this.setState({ modalVisible: state, selectedCompanyID: companyID });
  };

  handleLinkModal = (state, companyID) => {
    this.setState({ linkModalVisible: state, selectedCompanyID: companyID });
  };

  displayCategories = (company) => {
    const categories =
      company?.companyCategories
        ?.map((x) => {
          try {
            return ct(x);
          } catch (e) {
            return x;
          }
        })
        ?.join(', ') || '-';
    return (
      <>
        <div id={`tooltip-${company.id}`}>
          {' '}
          {`${categories.length > 50 ? `${categories.substring(0, 20)}...` : categories}`}
        </div>
        {categories.length > 50 && (
          <UncontrolledTooltip
            placement="left"
            target={`tooltip-${company.id}`}
          >
            {categories}
          </UncontrolledTooltip>
        )}
      </>
    );
  };
  handleSettingDeleteModal = (settingID = null, type = 'level') => {
    this.setState({ deleteModal: { visible: !!settingID, settingID, type } });
  };

  listCompanies = () => {
    const { history } = this.props;
    return this.state.companies.data.map((company) => (
      <tr key={`Company-${company.id}`}>
        <td>
          {company?.images?.companyImage ? (
            <img
              className="company__profile__avatar mr-2"
              src={company?.images?.companyImage}
              alt="logo"
            />
          ) : (
            <span className="company__profile__avatar_placeholder mr-2">
              {company?.name[0]?.toUpperCase()}
            </span>
          )}
        </td>
        <td>
          <Link to={`/companies/edit/${company.id}?tab=1`}>{company.name}</Link>
        </td>
        <td>{company.employeeCount}</td>
        <td>{this.displayCategories(company)}</td>
        <td>{company.companyLevel}</td>
        <td>
          {company.isSponsor ? (
            <CheckboxMarkedCircleIcon color="#4DE1B6" />
          ) : (
            <CloseCircleIcon color="#FF4761" />
          )}
        </td>
        <td>
          {company.isAppVisible ? (
            <CheckboxMarkedCircleIcon color="#4DE1B6" />
          ) : (
            <CloseCircleIcon color="#FF4761" />
          )}
        </td>
        <td>
          <ButtonToolbar className="float-right">
            <ButtonGroup className="btn-group--icons edit-delete-btn-group">
              <IconButton
                onClick={() =>
                  history.push(`/companies/edit/${company.id}?tab=1`)
                }
                type={IconButton.types.EDIT}
                id={`edit-${company.id}`}
              />
              <IconButton
                title={p('addContactPerson')}
                onClick={() =>
                  history.push(`/companies/edit/${company.id}?tab=2`)
                }
                type={IconButton.types.ADD_USER}
                id={`add-${company.id}`}
              />
              <IconButton
                title={p('sponsorMaterial')}
                onClick={() =>
                  history.push(`/companies/edit/${company.id}?tab=3`)
                }
                type={IconButton.types.IMAGE}
                id={`sponsor-${company.id}`}
              />
              <IconButton
                onClick={() => this.handleModal(true, company.id)}
                type={IconButton.types.DELETE}
                id={`delete-${company.id}`}
              />
            </ButtonGroup>
          </ButtonToolbar>
        </td>
      </tr>
    ));
  };

  getGroupsAndLevels = async () => {
    try {
      const [levels, groups] = await Promise.all([
        Api.club.getLevels(),
        Api.club.getGroups(),
      ]);

      let groupCompaniesCount = {};
      let levelCompaniesCount = {};
      if (groups?.length) {
        groupCompaniesCount =
          await Api.companies.getCompaniesCountForGroups(groups);
      }
      if (levels?.length) {
        levelCompaniesCount =
          await Api.companies.getCompaniesCountForLevels(levels);
      }
      const levelsWithCount = levels.map((level) => {
        return {
          ...level,
          count:
            levelCompaniesCount?.find((l) => l.value === level.value)?.count ??
            0,
        };
      });
      const groupsWithCount = groups.map((group) => {
        return {
          ...group,
          count:
            groupCompaniesCount?.find((g) => g.value === group.value)?.count ??
            0,
        };
      });

      this.setState({ groups: groupsWithCount, levels: levelsWithCount });
    } catch (err) {
      toast.error(err || `${s('400')}`);
    }
  };

  handleSort = (order) => {
    const reverse =
      this.state.order === order ? !this.state.reverse : this.state.reverse;
    this.setState({ order, reverse }, () => {
      this.getCompanies();
    });
  };

  handleInput = (e) => {
    this.setState({ searchText: e.target.value });
  };

  handleSearch = (e) => {
    if (e.key === 'Enter') {
      this.getCompanies();
    }
  };

  updateSelectedCompanies = (companiesSelected) => {
    this.setState({ selectedCompanies: companiesSelected });
  };

  exportCompanies = () => {
    this.setState({ loadingCompanies: true }, async () => {
      try {
        const resCompanies = await Api.companies.getClubCompanies();
        const fields = [
          { label: 'Name', value: 'name' },
          { label: 'VAT', value: 'organization_number' },
          { label: 'Phone', value: 'phone' },
          { label: 'email', value: 'email' },
          { label: 'Level', value: 'companyLevel' },
        ];
        const json2csvParser = new Parser({ fields });
        const csv = json2csvParser.parse(resCompanies);
        fileDownload(`\uFEFF${csv}`, `${p('exportCompany')}.csv`);
        this.setState({ loadingCompanies: false });
      } catch (err) {
        this.setState({ loadingCompanies: false });
        toast.error(err || `${p('listExportingERROR')}'!'`);
      }
    });
  };

  exportCompaniesXLSX = () => {
    this.setState({ loadingCompanies: true }, async () => {
      try {
        const resCompanies = await Api.companies.getClubCompanies();

        const data = resCompanies.map((i) => {
          const result = {};
          result[`${s('companyName')}`] = i?.name;
          result[`${s('organizationNumber')}`] = i?.organization_number;
          result[`${s('phone')}`] = i?.phone;
          result[`${s('email')}`] = i?.email;
          result[`${s('level')}`] = i?.companyLevel;
          return result;
        });
        console.log(data);
        exportToExcelFile(data, `${p('exportCompany')}`);
        this.setState({ loadingCompanies: false });
      } catch (err) {
        this.setState({ loadingCompanies: false });
        toast.error(err || `${p('listExportingERROR')}'!'`);
      }
    });
  };

  exportEmployees = () => {
    this.setState({ loadingCompanies: true }, async () => {
      try {
        const { data: resEmployees } =
          await Api.companies.getAllCompaniesEmployees();
        const mappedEmployees = resEmployees
          .map((emp) => {
            let primaryContact = '';
            let vip = '';

            if (emp.status === 'PRIMARY_AND_VIP') {
              primaryContact = 'true';
              vip = 'true';
            } else if (emp.status === 'PRIMARY_CONTACT') {
              primaryContact = 'true';
            } else if (emp.status === 'VIP') {
              vip = 'true';
            } else {
              primaryContact = '';
              vip = '';
            }
            return {
              compName: emp.company.generalName,
              vat: emp.company.organization_number,
              firstName: emp.firstName,
              lastName: emp.lastName,
              email: emp.email,
              phone: emp.phone,
              connected: !!emp.userId,
              primaryContact,
              vip,
            };
          })
          .sort((first, second) =>
            first.compName.localeCompare(second.compName)
          );
        const fields = [
          { label: s('companyName'), value: 'compName' },
          { label: 'VAT', value: 'vat' },
          { label: t('AddEmployee.firstName'), value: 'firstName' },
          { label: t('AddEmployee.lastName'), value: 'lastName' },
          { label: s('email'), value: 'email' },
          { label: s('phone'), value: 'phone' },
          { label: p('connected'), value: 'connected' },
          { label: t('AddEmployee.primaryContact'), value: 'primaryContact' },
          { label: 'vip', value: 'vip' },
        ];
        const json2csvParser = new Parser({ fields });
        const csv = json2csvParser.parse(mappedEmployees);
        fileDownload(`\uFEFF${csv}`, `${p('exportEmployees')}.csv`);
        this.setState({ loadingCompanies: false });
      } catch (err) {
        this.setState({ loadingCompanies: false });
        toast.error(err || `${p('listExportingERROR')}'!'`);
      }
    });
  };

  exportEmployeesXLSX = () => {
    this.setState({ loadingCompanies: true }, async () => {
      try {
        const { data: resEmployees } =
          await Api.companies.getAllCompaniesEmployees();
        const data = resEmployees.map((emp) => {
          let primaryContact = '';
          let vip = '';

          if (emp.status === 'PRIMARY_AND_VIP') {
            primaryContact = 'true';
            vip = 'true';
          } else if (emp.status === 'PRIMARY_CONTACT') {
            primaryContact = 'true';
          } else if (emp.status === 'VIP') {
            vip = 'true';
          } else {
            primaryContact = '';
            vip = '';
          }
          const result = {};
          result[`${s('companyName')}`] = emp?.company.generalName;
          result['VAT'] = emp?.company.organization_number;
          result[`${t('AddEmployee.firstName')}`] = emp?.firstName;
          result[`${t('AddEmployee.lastName')}`] = emp?.lastName;
          result[`${s('email')}`] = emp?.email;
          result[`${s('phone')}`] = emp?.phone;
          result[`${p('connected')}`] = emp?.userId;
          result[`${t('AddEmployee.primaryContact')}`] = primaryContact;
          result['VIP'] = vip;
          return result;
        });

        exportToExcelFile(data, `${p('exportEmployees')}`);
        this.setState({ loadingCompanies: false });
      } catch (err) {
        this.setState({ loadingCompanies: false });
        toast.error(err || `${p('listExportingERROR')}'!'`);
      }
    });
  };

  removeCompany = (companyID) => {
    this.setState({ loadingCompanies: true }, async () => {
      try {
        await Api.companies.unlinkCompany({
          companyId: companyID,
          deleteComment: '',
        });
        this.setState({ modalVisible: false });
        this.getCompanies();
      } catch (err) {
        this.setState({ modalVisible: false, loadingCompanies: false });
        toast.error(err || `${p('companyDeletingERROR')}'!'`);
        this.getCompanies();
      }
    });
  };

  categoryOnDragEnd = ({ destination, source }) => {
    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    const draggedLevel = this.state.levels[source.index];
    const newLevels = [...this.state.levels];
    newLevels.splice(source.index, 1);
    newLevels.splice(destination.index, 0, draggedLevel);
    this.setState({ levels: newLevels, loadingSettings: true }, async () => {
      const order = newLevels.map((level, index) => ({
        id: level.id,
        order: index,
      }));
      try {
        await Api.club.updateSettingsOrder({ settingOrders: order });
        toast.success(`${p('categoryOrderUpdated')}`);
        this.setState({ loadingSettings: false });
      } catch (err) {
        this.setState({ loadingSettings: false });
        toast.error(`${p('orderUpdatingERROR')}`);
      }
    });
  };

  handleSettingViewClick = (setting, type) => {
    if (type === SettingType.GROUP) {
      this.setState({
        groupModal: { visible: true, groupState: setting, type: 'view' },
      });
    }
  };

  handleSettingEditClick = (setting, type) => {
    if (type === SettingType.LEVEL) {
      this.setState({
        levelModal: { visible: true, levelState: setting, type: 'edit' },
      });
    } else {
      this.setState({
        groupModal: { visible: true, groupState: setting, type: 'edit' },
      });
    }
  };

  renderGroups = () => {
    const { groups } = this.state;
    return groups.map((group) => (
      <tr key={`groups-${group.id}`}>
        <td>
          <button
            className="link-button"
            onClick={() =>
              this.setState({
                groupModal: { visible: true, groupState: group, type: 'edit' },
              })
            }
          >
            {group.value}
          </button>
        </td>
        <td>{group.count}</td>
        <td>
          <ButtonToolbar className="float-right">
            <ButtonGroup className="btn-group--icons edit-delete-btn-group">
              <IconButton
                onClick={() =>
                  this.handleSettingEditClick(group, SettingType.GROUP)
                }
                type={IconButton.types.EDIT}
                id={`edit-${group.id}`}
              />
              <IconButton
                onClick={() =>
                  this.handleSettingDeleteModal(group.id, SettingType.GROUP)
                }
                type={IconButton.types.DELETE}
                id={`delete-${group.id}`}
              />
            </ButtonGroup>
          </ButtonToolbar>
        </td>
      </tr>
    ));
  };

  renderLevels = () => {
    const { levels } = this.state;
    return levels.map((level, index) => (
      <Draggable
        key={`level-${level.id}`}
        draggableId={`level-${level.id}`}
        index={index}
        isDragDisabled={!!this.state.settingState}
      >
        {(provided, snapshot) => (
          <tr
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={snapshot.isDragging ? 'rbdnd-dragging' : ''}
            key={`level-${level.id}`}
          >
            <td>
              <DragIcon color="#999999" />
            </td>
            <td>
              <button
                className="link-button"
                onClick={() =>
                  this.setState({
                    levelModal: {
                      visible: true,
                      levelState: level,
                      type: 'edit',
                    },
                  })
                }
              >
                {level.value}
              </button>
            </td>
            <td>{level.count}</td>
            <td>
              <ButtonToolbar className="float-right">
                <ButtonGroup className="btn-group--icons edit-delete-btn-group">
                  <IconButton
                    onClick={() =>
                      this.setState({
                        levelModal: {
                          visible: true,
                          levelState: level,
                          type: 'edit',
                        },
                      })
                    }
                    type={IconButton.types.EDIT}
                    id={`edit-${level.id}`}
                  />
                  <IconButton
                    onClick={() =>
                      this.handleSettingDeleteModal(level.id, 'level')
                    }
                    type={IconButton.types.DELETE}
                    id={`delete-${level.id}`}
                  />
                </ButtonGroup>
              </ButtonToolbar>
            </td>
          </tr>
        )}
      </Draggable>
    ));
  };

  closeSettingModal = (type) => {
    if (type === SettingType.GROUP) {
      this.setState({
        groupModal: { visible: false, groupState: null, type: '' },
      });
    } else if (type === SettingType.LEVEL) {
      this.setState({
        levelModal: { visible: false, levelState: null, type: '' },
      });
    }
  };
  deleteSetting = async () => {
    try {
      this.setState({ loadingSettings: true });
      await Api.club.deleteSetting(this.state.deleteModal.settingID);
      await this.getGroupsAndLevels();
      toast.success(p(`${this.state.deleteModal.type}Deleted`));
    } catch (e) {
      toast.error(s('somethingWentWrong'));
    } finally {
      this.handleSettingDeleteModal(false);
      this.setState({ loadingSettings: false });
    }
  };
  render() {
    const {
      order,
      reverse,
      tab,
      clubProfile,
      levelModal,
      loadingCompanies,
      loadingSettings,
      loadingSponsor,
      groupModal,
    } = this.state;
    const isLoading = loadingCompanies || loadingSettings || loadingSponsor;
    const availableTabs = [
      `${s('companies')}`,
      `${s('settings')}`,
      'App sponsor',
      `${ct('goalService')}`,
      config.clubWebsiteEnabled ? `${ct('websiteSponsor')}` : null,
    ].filter(Boolean);
    return (
      <Container>
        <Loading loading={isLoading} />
        <NavTab
          activeTab={tab}
          tabs={availableTabs}
          setTab={(activeTab) => this.setState({ tab: activeTab })}
        />
        <DeleteModal
          type={s(`the${this.state.deleteModal.type}Small`)}
          visible={this.state.deleteModal.visible}
          handleModal={this.handleSettingDeleteModal}
          modalAction={this.deleteSetting}
        />
        <LinkModal
          visible={this.state.linkModalVisible}
          handleModal={this.handleLinkModal}
          modalAction={this.linkCompany}
        />
        <AlertModal
          visible={this.state.modalVisible}
          handleModal={this.handleModal}
          modalType="DELETION"
          message={a('companyDeleteMsg')}
          subText={s('companyRemoveMsg')}
          modalAction={() => this.removeCompany(this.state.selectedCompanyID)}
        />
        <TabContent activeTab={this.state.tab}>
          <TabPane tabId="1">
            <Row className="my-1">
              <Col md="3">
                <h3 className="page-title"> </h3>
              </Col>
              <Col md="9">
                <ButtonToolbar className="margin-top-0 float-right">
                  <Link to="/companies/create?tab=1">
                    <Button color="primary" size="sm">
                      {p('createCompany')}
                    </Button>
                  </Link>
                </ButtonToolbar>
              </Col>
            </Row>
            <Row className="my-1 mb-3">
              <Col>
                <ButtonToolbar className="margin-top-0 float-right">
                  <div className="form">
                    <div className="form__form-group products-list__search">
                      <input
                        placeholder={p('searchForCompany')}
                        value={this.state.searchText}
                        onChange={this.handleInput}
                        onKeyPress={this.handleSearch}
                        name="search"
                      />
                      <MagnifyIcon />
                    </div>
                  </div>
                  <UncontrolledDropdown>
                    <DropdownToggle
                      className="icon icon--right mb-2"
                      outline
                      size="sm"
                    >
                      <DownloadIcon /> {s('export')} <ChevronDownIcon />
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        key="exp2"
                        onClick={this.exportCompaniesXLSX}
                      >
                        {p('exportCompanies')} (XLSX)
                      </DropdownItem>
                      <DropdownItem
                        key="exp4"
                        onClick={this.exportEmployeesXLSX}
                      >
                        {p('exportEmployees')} (XLSX)
                      </DropdownItem>
                      <DropdownItem key="exp1" onClick={this.exportCompanies}>
                        {p('exportCompanies')} (CSV)
                      </DropdownItem>
                      <DropdownItem key="exp3" onClick={this.exportEmployees}>
                        {p('exportEmployees')} (CSV)
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </ButtonToolbar>
              </Col>
            </Row>
            <div
              style={{
                display: 'flex',
                paddingBottom: 5,
                background: '#fff',
                borderRadius: 6,
                boxShadow: '0px 2px 8px 0px rgba(0,0,0,0.08)',
              }}
            >
              <Table responsive bordered>
                <thead>
                  <tr>
                    <th> </th>
                    <th
                      className="sortable"
                      onClick={() => this.handleSort('name')}
                    >
                      {s('name')}
                      <SortDirection
                        active={order === 'name'}
                        direction={reverse}
                      />
                    </th>
                    <th
                      className="sortable"
                      onClick={() => this.handleSort('employeeCount')}
                    >
                      {s('employees')}
                      <SortDirection
                        active={order === 'employeeCount'}
                        direction={reverse}
                      />
                    </th>
                    <th
                      className="sortable"
                      onClick={() => this.handleSort('companyCategories')}
                    >
                      {s('category')}
                      <SortDirection
                        active={order === 'companyCategories'}
                        direction={reverse}
                      />
                    </th>
                    <th
                      className="sortable"
                      onClick={() => this.handleSort('companyLevel')}
                    >
                      {s('level')}
                      <SortDirection
                        active={order === 'companyLevel'}
                        direction={reverse}
                      />
                    </th>
                    <th>{s('sponsor')}</th>
                    <th>{s('visibleInApp')}</th>
                    <th />
                  </tr>
                </thead>
                <tbody>{this.listCompanies()}</tbody>
              </Table>
            </div>
            <Pagination
              items={this.state.companies.total}
              rowsPerPage={[25, 50, 100]}
              onChangePage={this.onChangePage}
            />
          </TabPane>
          <TabPane tabId="2">
            <Row>
              <Col lg={6}>
                <ShareModal
                  visible={levelModal.visible}
                  handleModal={() => {
                    this.closeSettingModal(SettingType.LEVEL);
                    this.setState({
                      levelModal: {
                        visible: false,
                        levelState: null,
                        type: '',
                      },
                    });
                  }}
                >
                  <LevelInformation
                    selectedLevel={levelModal.levelState}
                    type={levelModal.type}
                    onSubmit={this.onSubmitSetting}
                    updateSelectedCompanies={this.updateSelectedCompanies}
                    handleModalTypeChangeToEdit={
                      this.handleModalTypeChangeToEdit
                    }
                  />
                </ShareModal>
                <div className="card__title my-3">
                  <h3 className="page-title">{s('levels')}</h3>
                  <Button
                    onClick={() =>
                      this.setState({
                        levelModal: {
                          visible: true,
                          levelState: null,
                          type: 'create',
                        },
                      })
                    }
                    size="sm"
                    color="primary"
                  >
                    {p('createLevel')}
                  </Button>
                </div>
                <DragDropContext onDragEnd={this.categoryOnDragEnd}>
                  <div
                    style={{
                      display: 'flex',
                      marginTop: 10,
                      paddingBottom: 5,
                      background: '#fff',
                      borderRadius: 6,
                      boxShadow: '0px 2px 8px 0px rgba(0,0,0,0.08)',
                    }}
                  >
                    <Table responsive striped>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>{s('name')}</th>
                          <th>{s('companies')}</th>
                          <th />
                        </tr>
                      </thead>
                      <Droppable droppableId="category-dnd">
                        {(provided) => (
                          <tbody
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {this.renderLevels()}
                            {provided.placeholder}
                          </tbody>
                        )}
                      </Droppable>
                    </Table>
                  </div>
                </DragDropContext>
              </Col>
              <Col lg={6}>
                <ShareModal
                  visible={groupModal.visible}
                  handleModal={() => {
                    this.closeSettingModal(SettingType.GROUP);
                    this.setState({
                      groupModal: {
                        visible: false,
                        groupState: null,
                        type: '',
                      },
                    });
                  }}
                >
                  <GroupForm
                    initialValues={{ name: groupModal?.groupState?.value }}
                    type={SettingType.GROUP}
                    settingState={groupModal.groupState}
                    modalType={groupModal.type}
                    onSubmit={this.onSubmitSetting}
                    updateSelectedCompanies={this.updateSelectedCompanies}
                    handleModalTypeChangeToEdit={
                      this.handleModalTypeChangeToEdit
                    }
                  />
                </ShareModal>

                <div className="card__title my-3">
                  <h3 className="page-title">{s('groups')}</h3>
                  <Button
                    onClick={() =>
                      this.setState({
                        groupModal: {
                          visible: true,
                          groupState: null,
                          type: 'create',
                        },
                      })
                    }
                    size="sm"
                    color="primary"
                  >
                    {p('createGroup')}
                  </Button>
                </div>
                <DragDropContext onDragEnd={this.categoryOnDragEnd}>
                  <div
                    style={{
                      display: 'flex',
                      marginTop: 10,
                      paddingBottom: 5,
                      background: '#fff',
                      borderRadius: 6,
                      boxShadow: '0px 2px 8px 0px rgba(0,0,0,0.08)',
                    }}
                  >
                    <Table responsive striped>
                      <thead>
                        <tr>
                          <th>{s('name')}</th>
                          <th>{s('companies')}</th>
                          <th />
                        </tr>
                      </thead>
                      <Droppable droppableId="category-dnd">
                        {(provided) => (
                          <tbody
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {this.renderGroups()}
                            {provided.placeholder}
                          </tbody>
                        )}
                      </Droppable>
                    </Table>
                  </div>
                </DragDropContext>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="3">
            <AppSponsor
              onSubmit={this.handleSubmitAppSponsor}
              isLoading={loadingSponsor}
              appSponsorId={clubProfile?.appSponsorId}
            />
          </TabPane>
          <TabPane tabId="4">
            <GoalServiceSponsor />
          </TabPane>
          {config.clubWebsiteEnabled && (
            <TabPane tabId="5">
              <WebsiteSponsor />
            </TabPane>
          )}
        </TabContent>
      </Container>
    );
  }
}
const PageWithHock = (Page) => {
  return (props) => {
    const config = useConfig();

    return <Page clubConfig={config} {...props} />;
  };
};
export default withRouter(PageWithHock(CompanyListPage));
