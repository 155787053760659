import React, { useState, useEffect } from 'react';
import {
  Col,
  Row,
  Container,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import { Field, FieldArray, reduxForm, getFormValues } from 'redux-form';
import moment from 'moment';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import renderField from '../../../shared/components/custom/Field';
import renderTextAreaField from '../../../shared/components/custom/TextArea';
import renderChromeColorPickerField from '../../../shared/components/form/ChromeColorPicker';
import PriceGroupsField from './PriceGroupsField';
import validatePackage from './validatePackage';
import renderCheckBoxField from '../../../shared/components/form/CheckBox';
import { hexToRgb } from '../../../util/functions';
import { partial } from '../../../util/translation/translation';
import { formatShortDate } from '../../../util/date-and-time';

const PackagesForm = ({
  priceGroups,
  products,
  setProducts,
  pack,
  initialize,
  handleSubmit,
  values,
  type,
}) => {
  const [activeTab, setActiveTab] = useState('1');
  const [initialized, setInitialized] = useState(false);
  const p = partial('PackagesForm');
  const s = partial('shared');

  const selectProduct = (index) => {
    const updatedProducts = JSON.parse(JSON.stringify(products));
    updatedProducts[index].active = !updatedProducts[index].active;
    setProducts(updatedProducts);
  };

  useEffect(() => {
    if (initialized || priceGroups.length === 0) {
      return;
    }
    const formattedPriceGroups = priceGroups.map((priceGroup) => ({
      id: priceGroup.id,
      buyable: !!priceGroup.buyable,
      price: priceGroup.percentage,
    }));
    if (type === 'create') {
      initialize({
        priceGroups: formattedPriceGroups,
        badgeColor: { hex: '#ff0000' },
      });
    } else {
      initialize({
        name: pack.package.name,
        saleStart: moment(pack.package.sale_start).format('YYYY-MM-DDTHH:mm'),
        saleEnd: moment(pack.package.sale_end).format('YYYY-MM-DDTHH:mm'),
        badgeText: pack.badge_text,
        badgeColor: {
          hex: pack.badge_color,
          rgb: hexToRgb(pack.badge_color),
        },
        useBlackBadgeText: !!pack.black_badge_text,
        useFees: !!pack.add_fees,
        description: pack.package.description,
        priceGroups: formattedPriceGroups,
      });
    }
    setInitialized(true);
  }, [priceGroups, pack]);

  const renderProducts = () => {
    return products.map((product, index) => (
      <button
        className={
          product.active ? 'package-product active' : 'package-product'
        }
        key={`product-${product.item_type}-${product.id}`}
        type="button"
        onClick={() => selectProduct(index)}
      >
        <p>{product.name}</p>
        <p>
          {product.description?.date
            ? formatShortDate(product.description.date)
            : product.description}
        </p>
      </button>
    ));
  };

  return (
    <form className="form" onSubmit={handleSubmit}>
      <Container>
        <div className="tabs tabs--justify tabs--bordered-top overflowY-hidden">
          <div className="tabs__wrap">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '1' })}
                  onClick={() => setActiveTab('1')}
                >
                  {s('details')}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '2' })}
                  onClick={() => setActiveTab('2')}
                >
                  {s('priceGroups')}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '3' })}
                  onClick={() => setActiveTab('3')}
                >
                  {s('matches')}
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <Row>
                  <Col md={12}>
                    <div className="form__form-group">
                      <span className="form">{s('name')}</span>
                      <Field
                        name="name"
                        component={renderField}
                        placeholder={p('packageName')}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={12}>
                    <div className="form__form-group">
                      <span>{s('saleStart')}</span>
                      <Field
                        name="saleStart"
                        component={renderField}
                        type="datetime-local"
                      />
                    </div>
                  </Col>
                  <Col lg={6} md={12}>
                    <div className="form__form-group">
                      <span className="form">{s('saleEnd')}</span>
                      <Field
                        name="saleEnd"
                        component={renderField}
                        type="datetime-local"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={12}>
                    <div className="form__form-group">
                      <span>{s('badgeText')}</span>
                      <Field
                        name="badgeText"
                        component={renderField}
                        placeholder={s('badgeText')}
                      />
                    </div>
                  </Col>
                  <Col lg={4} md={12}>
                    <div className="form__form-group">
                      <span>{s('badgeColor')}</span>
                      <Field
                        name="badgeColor"
                        component={renderChromeColorPickerField}
                        defaultColor="#ff0000"
                      />
                    </div>
                  </Col>
                  <Col lg={2} md={12}>
                    <div className="form__form-group margin-0">
                      <span className="form-header" />
                      <div className="marginTop-5" />
                      <Field
                        name="useBlackBadgeText"
                        component={renderCheckBoxField}
                        label={p('blackBadgeText')}
                        className="colored-click"
                      />
                    </div>
                  </Col>
                  <Col md={2}>
                    <div
                      className="packages-badge"
                      style={{
                        backgroundColor: values.badgeColor
                          ? values.badgeColor.hex
                          : 'white',
                        color: values.useBlackBadgeText ? 'black' : 'white',
                        display: values.badgeText ? 'flex' : 'none',
                      }}
                    >
                      {values.badgeText ? values.badgeText.toUpperCase() : ''}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <div className="form__form-group">
                      <span>{s('description')}</span>
                      <Field
                        name="description"
                        component={renderTextAreaField}
                        placeholder={s('packageDescription')}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={1} md={12}>
                    <div className="form__form-group margin-0">
                      <span className="form-header" />
                      <div className="marginTop-5" />
                      <Field
                        name="useFees"
                        component={renderCheckBoxField}
                        label={s('useFees')}
                        className="colored-click"
                      />
                    </div>
                  </Col>
                  {type === 'create' && (
                    <Col lg={2} md={12}>
                      <div className="form__form-group margin-0">
                        <span className="form-header" />
                        <div className="marginTop-5" />
                        <Field
                          name="push"
                          component={renderCheckBoxField}
                          label={s('sendPushNotification')}
                          className="colored-click"
                        />
                      </div>
                    </Col>
                  )}
                </Row>
              </TabPane>
              <TabPane tabId="2">
                <Row>
                  <FieldArray
                    name="priceGroups"
                    component={PriceGroupsField}
                    priceGroups={priceGroups}
                  />
                </Row>
              </TabPane>
              <TabPane tabId="3">
                <Row className="packages-product-list">
                  <Col md={12}>
                    <div className="card__title margin-0">
                      <h5 className="bold-text">{p('chooseMatchesCaps')}</h5>
                    </div>
                    {renderProducts()}
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </div>
        </div>
      </Container>
      <Row>
        <Col md={12}>
          <Button type="submit" color="primary">
            {s('save')}
          </Button>
          <Link className="color-unset" to="/packages">
            <Button type="button">{s('close')}</Button>
          </Link>
        </Col>
      </Row>
    </form>
  );
};

const reduxF = reduxForm({
  form: 'packagesForm',
  validate: validatePackage,
});

PackagesForm.propTypes = {
  pack: PropTypes.shape({
    id: PropTypes.number,
    base_package_id: PropTypes.number,
    badge_color: PropTypes.string,
    badge_text: PropTypes.string,
    add_fees: PropTypes.number,
    updated_at: PropTypes.string,
    black_badge_text: PropTypes.number,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        description: PropTypes.string,
        id: PropTypes.number,
        item_type: PropTypes.string,
      })
    ),
    package_price_groups: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        package_id: PropTypes.number,
        price_group_id: PropTypes.number,
        price: PropTypes.number,
      })
    ),
    package: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      price: PropTypes.number,
      sale_start: PropTypes.string,
      sale_end: PropTypes.string,
      description: PropTypes.string,
      updated_at: PropTypes.string,
    }),
  }),
  priceGroups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
    })
  ).isRequired,
  initialize: PropTypes.func.isRequired,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
      item_type: PropTypes.string.isRequired,
      active: PropTypes.bool,
    })
  ).isRequired,
  setProducts: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  values: PropTypes.shape({
    badgeColor: PropTypes.shape({
      hex: PropTypes.string,
    }),
    badgeText: PropTypes.string,
    useBlackBadgeText: PropTypes.bool,
  }),
  type: PropTypes.string.isRequired,
};

PackagesForm.defaultProps = {
  values: {},
  pack: undefined,
};

const mapStateToProps = (state) => ({
  values: getFormValues('packagesForm')(state),
});

export default reduxF(connect(mapStateToProps)(PackagesForm));
