import { SET_CREATE_OR_EDIT_MATCH_TYPE } from '../actions/matchTypeActions';

const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CREATE_OR_EDIT_MATCH_TYPE:
      return action.matchType;
    default:
      return state;
  }
}
