import React, { useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Row, Col, Button } from 'reactstrap';
import renderField from '../../../shared/components/custom/Field';
import t, { partial } from '../../../util/translation/translation';
import validate from './validateCategoryForm';

const s = partial('shared');
const EditCustomEventCategory = ({
  selectedCategory,
  handleSubmit,
  initialize,
}) => {
  useEffect(() => {
    initialize({
      maxParticipants: selectedCategory?.maxAttendes,
    });
  }, [selectedCategory]);
  return (
    <form className="form" onSubmit={handleSubmit}>
      <div className="form__form-group">
        <span className="form-header bold">
          {t('EventsForm.maxNumberOfParticipant')} ({s('maximum')} :{' '}
          {selectedCategory?.category?.maxAttendees})
        </span>
        <Field
          name="maxParticipants"
          component={renderField}
          type="number"
          placeholder="0"
        />
      </div>
      <Row>
        <Col>
          <Button color="primary" type="submit">
            {selectedCategory ? `${s('save')}` : `${s('create')}`}
          </Button>
        </Col>
      </Row>
    </form>
  );
};

const reduxF = reduxForm({
  form: 'EditCustomEventCategory',
  validate,
});
export default reduxF(EditCustomEventCategory);
