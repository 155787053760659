import React from 'react';
import { CardBody } from 'reactstrap';
import skeletonTableLoader from '../../../shared/components/custom/SkeletonLoaders';

const StatsCards = ({
  totalSessions,
  sessionsPerUser,
  averageEngagementTime,
  sessionDataLoading,
  userAvgDataLoading,
  loginData,
}) => {
  const StatItem = ({ title, value, isLoading }) => (
    <div>
      <h6
        className="mb-1"
        style={{ fontSize: '14px', fontWeight: 'medium', color: '#98A0AE' }}
      >
        {title}
      </h6>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center h-100">
          {skeletonTableLoader(1, 1, 70)}
        </div>
      ) : loginData.length === 0 ? (
        <div
          className="d-flex justify-content-center align-items-center h-100"
          style={{ fontSize: '22px', fontWeight: 'bold' }}
        >
          <p className="text-center">0</p>
        </div>
      ) : (
        <h1
          className="display-4 mb-0 text-center"
          style={{ fontSize: '22px', fontWeight: 'bold' }}
        >
          {value}
        </h1>
      )}
    </div>
  );

  return (
    <div className="mt-3">
      <div className="d-flex flex-direction-row justify-content-between align-items-center">
        <CardBody>
          <div className="d-flex justify-content-between align-items-center">
            <StatItem
              title="Sessions"
              value={totalSessions.toLocaleString()}
              isLoading={sessionDataLoading}
            />
            <StatItem
              title="Sessions per user"
              value={sessionsPerUser}
              isLoading={sessionDataLoading}
            />
            <StatItem
              title="Average engagement time per active user"
              value={averageEngagementTime}
              isLoading={userAvgDataLoading || sessionDataLoading}
            />
          </div>
        </CardBody>
      </div>
    </div>
  );
};

export default StatsCards;
