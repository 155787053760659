import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Row, Col, TabContent, TabPane } from 'reactstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { toast } from 'react-toastify';
import QuestionModal from './QuestionModal';

import { EventContext } from '../context';
import Api from '../../../util/api';
import useConfig from '../../../util/useConfig';
import LibraryQuestionItem from './QuestionnaireBuilder/LibraryQuestionItem';
import PickedQuestionItem from './QuestionnaireBuilder/PickedQuestionItem';
import ListHeading from './QuestionnaireBuilder/ListHeading';
import SearchField from './QuestionnaireBuilder/SearchField';
import DeleteModal from '../../../shared/components/custom/DeleteModal';
import useModal from '../../../shared/hooks/useModal';
import { partial } from '../../../util/translation/translation';
import AlertModal, {
  AlertModalType,
} from '../../../shared/components/custom/AlertModal';
import QuestionsStatsCard from './QuestionsStatsCard';

const QuestionCard = ({ eventId }) => {
  const [tab, setTab] = useState('1');
  const [loading, setLoading] = useState(true);
  const [isNewForm, setIsNewForm] = useState(true);
  const [questionList, setQuestionList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const {
    questions,
    setPickedQuestions,
    pickedQuestions,
    setEventId,
    fetchQuestions,
    setPickedQuestionId,
  } = useContext(EventContext);
  const [search, setSearch] = useState('');
  const { id } = useConfig();
  const [editingQuestion, setEditingQuestion] = useState(null);
  const [editability, setEditability] = useState([]);
  const [handlePickedQuestionModal, pickedVisible, deletedPickedQuestion] =
    useModal();
  const [handleLibraryQuestionModal, libraryVisible, deletedLibraryQuestion] =
    useModal();
  const [handleClearAllQuestionModal, clearQuestionVisible] = useModal();
  const qT = partial('question');

  const getDomainQuestions = useCallback(async () => {
    const { data } = await Api.questions.fetchDomainSpecificQuestions(
      eventId,
      id
    );
    if (data?.question?.length > 0) {
      setPickedQuestionId(data.id);
      setPickedQuestions(data.question);
      if (Array.isArray(data.question) && data.question?.length) {
        setIsNewForm(false);
      }
    } else {
      setPickedQuestions([]);
    }
    setLoading(false);
  }, []);

  const searchSharedQuestions = useCallback(async () => {
    const { data } = await Api.questions.searchSharedQuestions(
      { domain: 'event', query: search },
      id
    );
    setQuestionList(data);
  }, [search]);

  useEffect(() => {
    setEventId(eventId);
    getDomainQuestions();
  }, []);

  useEffect(() => {
    setQuestionList(questions);
  }, [questions]);

  useEffect(() => {
    const timer = setTimeout(() => {
      searchSharedQuestions();
    }, 200);
    return () => clearTimeout(timer);
  }, [search]);

  const checkIfExists = (source) =>
    pickedQuestions.findIndex(
      (p) => p.id === questionList[source.index]?.id
    ) !== -1 && source.droppableId === 'list';

  const removeIndicator = () => {
    const cardElements = document.querySelectorAll('.picked-question-card');
    cardElements.forEach((element) => {
      element?.parentNode.classList.remove('prepend-indicator');
      element?.parentNode.classList.remove('append-indicator');
    });
  };

  const handleOnUpdate = ({ source, destination }) => {
    if (destination && source.droppableId === 'list') {
      const questionCount = pickedQuestions.length;
      const { index } = destination;
      removeIndicator();
      const cardElement = document.querySelectorAll('.picked-question-card')[
        index === questionCount ? index - 1 : index
      ];
      if (index <= questionCount - 1) {
        cardElement?.parentNode.classList.add('prepend-indicator');
      } else {
        cardElement?.parentNode.classList.add('append-indicator');
      }
    }
  };

  const handleOnDrag = ({ source, destination }) => {
    removeIndicator();
    const existingPicked = [...pickedQuestions];
    const existingList = [...questionList];

    let sourceQuestion = {
      id: existingList[source.index]?.id,
      ...existingList[source.index]?.question,
    };

    if (!destination) return;
    if (source.droppableId === 'pick' && destination.droppableId === 'list') {
      return;
    }
    if (
      source.droppableId === destination.droppableId &&
      source.droppableId === 'list'
    ) {
      return;
    }
    if (checkIfExists(source)) return;

    if (source.droppableId === 'pick') {
      sourceQuestion = existingPicked[source.index];
    }

    if (source.droppableId === destination.droppableId) {
      const toBeSwaped = existingPicked[destination.index];
      existingPicked[destination.index] = existingPicked[source.index];
      existingPicked[source.index] = toBeSwaped;
    } else if (
      destination.index !== 0 &&
      destination.index !== existingPicked.length
    ) {
      existingPicked.splice(destination.index, 0, sourceQuestion);
    } else if (destination.index === 0) {
      existingPicked.unshift(sourceQuestion);
    } else if (destination.index === existingPicked.length) {
      existingPicked.push(sourceQuestion);
    }
    setEditability((prevEditability) => [
      ...prevEditability,
      sourceQuestion.id,
    ]);
    setPickedQuestions(existingPicked);
    setQuestionList(existingList);
  };

  const handleAddQuestions = async () => {
    try {
      await Api.questions.createDomainQuestions(
        { domainId: eventId, question: pickedQuestions },
        id
      );
      const toastMessage = isNewForm
        ? qT('questionsAdded')
        : qT('questionsUpdated');
      toast.success(toastMessage);
      setEditability([]);
      setIsNewForm(false);
    } catch (error) {
      toast.error(error);
    }
  };
  const handleClearQuestions = async () => {
    try {
      await Api.questions.deleteAllQuestionsInDomain(eventId);
      toast.success('All Messages Cleared!');
      setEditability([]);
      setIsNewForm(false);
      getDomainQuestions(eventId, id);
      handleClearAllQuestionModal();
    } catch (error) {
      toast.error(error);
    }
  };

  const handleSearch = async (e) => {
    setSearch(e.target.value);
  };

  const handlePickedQuestionDelete = async () => {
    const q = deletedPickedQuestion;
    const selectedQuestions = [...pickedQuestions];
    const index = selectedQuestions.findIndex(
      (question) => question.id === q.id
    );
    if (index !== -1) {
      selectedQuestions.splice(index, 1);
      try {
        await Api.questions.deleteDomainQuestion(eventId, {
          question: selectedQuestions,
        });
        getDomainQuestions(eventId, id);
      } catch (error) {
        toast.error(error);
      }
    }
    handlePickedQuestionModal();
  };

  const handlePickedQuestionEdit = (question) => {
    setEditingQuestion({
      id: question.id,
      question,
      type: 'domain',
    });
    setShowModal(true);
    setEditability([]);
  };

  const handlePromptClose = () => {
    setShowModal(false);
    setEditingQuestion(null);
  };

  const handleLibraryEdit = async (q) => {
    setEditingQuestion(q);
    setShowModal(true);
  };

  const addNewQuestion = () => {
    setShowModal(true);
  };

  const handleLibraryDelete = async () => {
    const question = deletedLibraryQuestion;
    try {
      await Api.questions.deleteSharedQuestion(question.id);
      toast.success(qT('questionDeleted'));
      fetchQuestions();
    } catch (error) {
      toast.error(error);
    }
    handleLibraryQuestionModal();
  };

  const movePickedQuestions = (question, direction) => {
    setPickedQuestions((existing) => {
      const currentIndex = existing.findIndex((q) => q.id === question.id);
      const desiredIndex = currentIndex + direction;
      existing.splice(currentIndex, 1);
      existing.splice(desiredIndex, 0, question);
      return [...existing];
    });
  };

  const handlePickedQuestionUp = (question) => {
    movePickedQuestions(question, -1);
  };

  const handlePickedQuestionDown = (question) => {
    movePickedQuestions(question, 1);
  };

  const handleDomainQuestionUpdate = (question) => {
    movePickedQuestions(question, 0);
  };

  const formButtonLabel = isNewForm ? qT('create') : qT('update');

  if (loading) return <>Loading...</>;
  return (
    <>
      <DeleteModal
        type="Question"
        visible={pickedVisible}
        handleModal={handlePickedQuestionModal}
        modalAction={handlePickedQuestionDelete}
      />
      <DeleteModal
        type="Question"
        visible={libraryVisible}
        handleModal={handleLibraryQuestionModal}
        modalAction={handleLibraryDelete}
      />
      <AlertModal
        message="Are you sure you want to clear all questions"
        subText="This action will remove all answers given by thie participants of this event?"
        visible={clearQuestionVisible}
        handleModal={handleClearAllQuestionModal}
        modalAction={handleClearQuestions}
        modalType={AlertModalType.DELETION}
      />

      <Row>
        <Col md={6}>
          <h3 className="page-title">
            {tab === '1'
              ? qT('questionnaireForm')
              : qT('summaryResponses')}{' '}
          </h3>
        </Col>
        <Col md={6}>
          <Row className="justify-content-end">
            <button
              className={tab === '1' ? 'button-active' : 'button'}
              onClick={() => setTab('1')}
            >
              {qT('questionnaireForm')}
            </button>
            <button
              className={tab === '2' ? 'button-active' : 'button'}
              onClick={() => setTab('2')}
            >
              {qT('summaryResponses')}
            </button>
          </Row>
        </Col>
      </Row>

      <div className="question-summary">
        <hr />
      </div>

      <TabContent activeTab={tab}>
        <TabPane tabId="1">
          <Row>
            <Col className="col-6">
              <ListHeading>{qT('form')}</ListHeading>
            </Col>
            <Col className="col-6 d-flex justify-content-between">
              <ListHeading>{qT('library')}</ListHeading>
              <button className="add-new-question" onClick={addNewQuestion}>
                + {qT('addBtn')}
              </button>
            </Col>
          </Row>
          <Row style={{ height: '100vh' }}>
            <QuestionModal
              showModal={showModal}
              editableQuestion={editingQuestion}
              onClose={handlePromptClose}
            />
            <DragDropContext
              onDragEnd={handleOnDrag}
              onDragUpdate={handleOnUpdate}
            >
              <Col
                className="col-6"
                style={{ height: '100%', overflowY: 'scroll' }}
              >
                <Droppable droppableId="pick">
                  {(droppableProvided) => (
                    <div
                      className={`flex-column question-answers-list ${
                        !pickedQuestions.length ? 'flex-Vcenter' : ''
                      }`}
                      ref={droppableProvided.innerRef}
                      {...droppableProvided.droppableProps}
                    >
                      {Array.isArray(pickedQuestions) &&
                        !pickedQuestions.length && (
                          <div className="flex-Vcenter justify-content-center">
                            {qT('addQuestionsFromLibrary')}
                          </div>
                        )}
                      {pickedQuestions.map((q, idx) => {
                        const isFirst = idx === 0;
                        const isLast = idx === pickedQuestions.length - 1;

                        return (
                          <Draggable
                            draggableId={String(`pick-${q.id}`)}
                            index={idx}
                            key={q.id}
                          >
                            {(draggableProvided) => (
                              <div
                                ref={draggableProvided.innerRef}
                                {...draggableProvided.dragHandleProps}
                                {...draggableProvided.draggableProps}
                              >
                                <PickedQuestionItem
                                  index={idx}
                                  question={q}
                                  first={isFirst}
                                  last={isLast}
                                  onDelete={(x) => handlePickedQuestionModal(x)}
                                  editability={editability}
                                  onEdit={handlePickedQuestionEdit}
                                  onMoveUp={handlePickedQuestionUp}
                                  onMoveDown={handlePickedQuestionDown}
                                  onUpdate={handleDomainQuestionUpdate}
                                />
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                      {droppableProvided.placeholder}
                    </div>
                  )}
                </Droppable>
                {pickedQuestions.length > 0 && (
                  <Row>
                    <Col className="mt-3">
                      <button
                        disabled={!pickedQuestions.length}
                        className="btn btn-primary"
                        onClick={handleAddQuestions}
                      >
                        {formButtonLabel}
                      </button>
                      <button
                        disabled={!pickedQuestions.length}
                        className="btn btn-secondary"
                        onClick={handleClearAllQuestionModal}
                      >
                        Clear All
                      </button>
                    </Col>
                  </Row>
                )}
              </Col>
              <Col
                className="col-6"
                style={{ height: '100%', overflowY: 'scroll' }}
              >
                <div className="mb-3">
                  <SearchField value={search} onChange={handleSearch} />
                </div>
                <Droppable droppableId="list">
                  {(droppableProvided) => (
                    <div
                      className="question-library"
                      ref={droppableProvided.innerRef}
                      {...droppableProvided.droppableProps}
                    >
                      {questionList.map((q, i) => (
                        <Draggable
                          draggableId={String(`list-${q.id}`)}
                          index={i}
                          key={q.id}
                        >
                          {(draggableProvided) => (
                            <div
                              className="question"
                              ref={draggableProvided.innerRef}
                              {...draggableProvided.dragHandleProps}
                              {...draggableProvided.draggableProps}
                            >
                              <LibraryQuestionItem
                                index={i}
                                question={q}
                                onEdit={handleLibraryEdit}
                                onDelete={(x) => handleLibraryQuestionModal(x)}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {droppableProvided.placeholder}
                    </div>
                  )}
                </Droppable>
              </Col>
            </DragDropContext>
          </Row>
        </TabPane>

        <TabPane tabId="2">
          <QuestionsStatsCard eventId={eventId} />
        </TabPane>
      </TabContent>
    </>
  );
};
export default QuestionCard;
