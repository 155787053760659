import React, { useEffect } from 'react';
import { Field, reduxForm, propTypes as reduxFormProps } from 'redux-form';
import { Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from '../validators/matchTypeValidate';
import RenderField from '../../../shared/components/custom/Field';
import RenderDropZoneField from '../../../shared/components/form/DropZone';
import RenderCheckBoxField from '../../../shared/components/form/CheckBox';
import { partial } from '../../../util/translation/translation';

const MatchTypeForm = ({ handleSubmit, initialize, matchType }) => {
  const p = partial('settingsObjectsShared');
  const s = partial('shared');
  useEffect(() => {
    if (matchType) {
      initialize({
        name: matchType.name,
        seasonInclusion: !!matchType.season_inclusion,
        icon: [
          {
            preview: matchType.icon.url,
            ...matchType.icon,
          },
        ],
      });
    }
  }, [matchType]);

  return (
    <form className="form" onSubmit={handleSubmit}>
      <Col md={6}>
        <div className="form__form-group">
          <span className="form-header">{s('name')}</span>
          <Field
            name="name"
            component={RenderField}
            placeholder={p('matchTypeName')}
          />
        </div>
        <div className="form__form-group">
          <span className="form-header">{p('partOfSeasonticket')}</span>
          <Field
            name="seasonInclusion"
            component={RenderCheckBoxField}
            className="colored-click"
          />
        </div>
      </Col>
      <Col md={6}>
        <div className="form__form-group">
          <span className="form-header">{p('ikon(200px/200px)')}</span>
          <Field name="icon" component={RenderDropZoneField} />
        </div>
      </Col>
      <Col md={12}>
        <Button type="submit" color="primary">
          {matchType === null ? s('create') : s('save')}
        </Button>
        <Link className="color-unset" to="/settings?tab=3">
          <Button type="button">{s('close')}</Button>
        </Link>
      </Col>
    </form>
  );
};

MatchTypeForm.propTypes = {
  ...reduxFormProps,
  handleSubmit: PropTypes.func.isRequired,
  matchType: PropTypes.shape({
    name: PropTypes.string.isRequired,
    season_inclusion: PropTypes.number,
    icon: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
};

MatchTypeForm.defaultProps = {
  matchType: null,
};

const reduxF = reduxForm({
  form: 'create_or_update_match_type_form',
  validate,
});

export default reduxF(MatchTypeForm);
