import Moment from 'moment';
import { partial } from './translation/translation';

export function formatDateTime(dateTime) {
  const t = partial('DateFormat');
  return Moment(dateTime).format(t('formatDateTime'));
}

export function formatShortDate(dateTime) {
  const t = partial('DateFormat');
  return Moment(dateTime).format(t('formatShortDate'));
}

export function formatWeekDateTime(dateTime) {
  const t = partial('DateFormat');
  return Moment(dateTime).format(t('formatWeekDateTime'));
}

export function dateIsPast(dateTime) {
  return Moment(dateTime).isBefore(Moment());
}

export function dateIsFuture(dateTime) {
  return Moment(dateTime).isAfter(Moment());
}

export function formatShortDateTime(dateTime) {
  const t = partial('DateFormat');
  return Moment(dateTime).format(t('formatShortDateTime'));
}

export function formatDate(date) {
  const t = partial('DateFormat');
  return Moment(date).format(t('formatDate'));
}

export function formatTime(time) {
  return Moment(time).format('HH:mm');
}
export function formatDateForApi(date, local = true) {
  return Moment(date).local(local).format('YYYY-MM-DD HH:mm:ss');
}
export function getBaseDate(date) {
  const t = partial('DateFormat');
  return Moment(date).format(t('getBaseDate'));
}
export function getBaseDateTime(date) {
  const t = partial('DateFormat');
  return Moment(date).format(t('getBaseDateTime'));
}
export default {
  formatDateTime,
  formatWeekDateTime,
  formatDate,
  formatTime,
  formatShortDate,
  formatShortDateTime,
  getBaseDate,
};
