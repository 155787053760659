import React, { useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useRecoilState } from 'recoil';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment/locale/nb';
import 'moment/locale/en-gb';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { setIsAuthenticated } from '../../redux/actions/adminActions';
import MainWrapper from './MainWrapper';
import LoginPage from '../log_in/LoginPage';
import AuthService from './AuthService';
import t from '../../util/translation/translation';
import userLanguageState from '../../recoil/userLanguage/selector';
import EmailLoginPage from '../email_login/EmailLoginPage';
import RestorePasswordPage from '../restore_password/RestorePasswordPage';
import PrivateRoute from './PrivateRoute';
import WrappedRoutes from './WrappedRoutes';
import Pages from './Pages';
import Api from '../../util/api';
import Loading from '../../shared/components/custom/Loading';

const Router = ({ isAuthenticated, dispatch }) => {
  const [lang] = useRecoilState(userLanguageState);
  const history = useHistory();
  const fetchCustomClaims = async () => {
    const auth = getAuth();
    const currFirToken = await auth.currentUser.getIdToken();
    const {
      data: { token: customToken },
    } = await Api.authorization.getCustomToken(currFirToken);
    await signInWithCustomToken(auth, customToken);
  };
  const onAuthStateChanged = async (user) => {
    if (user) {
      try {
        await fetchCustomClaims();
        await AuthService.afterFirebaseLogin(dispatch);
        if (
          ['/log_in', '/email_login', '/restore_password'].some((path) =>
            history.location.pathname.includes(path)
          )
        ) {
          toast.success(t('LoginPage.successfullLogin'));
          history.replace('/');
        }
      } catch (e) {
        toast.error('Failed to log in');
        history.push('/log_in');
      }
    } else {
      dispatch(setIsAuthenticated(false));
      if (!window.location.pathname.includes('restore_password')) {
        history.replace('/log_in');
      }
    }
  };

  useEffect(() => {
    if (
      isAuthenticated === false &&
      !window.location.pathname.includes('restore_password')
    ) {
      history.replace('/log_in');
    }
  }, [isAuthenticated]);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = auth.onAuthStateChanged(onAuthStateChanged);
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    moment.locale(lang);
  }, [lang]);

  if (isAuthenticated === 'loading') {
    return <Loading loading />;
  }

  return (
    <MainWrapper>
      <main>
        <ToastContainer limit="3" />
        {isAuthenticated === true ? (
          <Switch>
            <PrivateRoute path="/kiosk" component={Pages} />
            <PrivateRoute path="/box" component={Pages} />
            <PrivateRoute path="/" component={WrappedRoutes} />
          </Switch>
        ) : (
          <Switch>
            <Route exact path="/log_in" component={LoginPage} />
            <Route exact path="/email_login" component={EmailLoginPage} />
            <Route
              exact
              path="/restore_password/:identity/:token"
              component={RestorePasswordPage}
            />
          </Switch>
        )}
      </main>
    </MainWrapper>
  );
};

Router.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
};

export default connect((state) => ({
  isAuthenticated: state.admin.isAuthenticated,
}))(Router);
