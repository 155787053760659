const EventTypes = {
  TargetAudience: {
    partner: 'partner',
    private: 'private',
    both: 'both',
    all: 'all',
  },
};

export default EventTypes;
