import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';
import PackagesForm from './components/PackagesForm';
import Api from '../../util/api';
import Loading from '../../shared/components/custom/Loading';
import PackageConflictForm from './components/PackageConflictForm';
import { partial } from '../../util/translation/translation';
import { formatDateForApi } from '../../util/date-and-time';

const PackagesFormPage = () => {
  const [priceGroups, setPriceGroups] = useState([]);
  const [products, setProducts] = useState([]);
  const [pack, setPackage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState(undefined);
  const history = useHistory();

  const { packageID } = useParams();
  const p = partial('PackagesFormPage');
  const s = partial('shared');

  const getInitialData = async () => {
    setLoading(true);
    try {
      const [resPriceGroups, resProducts, resPackage = undefined] =
        await Promise.all([
          Api.packages.getPriceGroups(),
          Api.packages.getProductGroupsType('Billetter'),
          packageID && Api.packages.getPackage(packageID),
        ]);
      if (packageID) {
        setPackage(resPackage.data);
        const mappedProducts = resProducts.data.map((product) => ({
          active: resPackage.data.items.some(
            (selectedProduct) => product.id === selectedProduct.id
          ),
          ...product,
        }));
        const itemsMissingInProducts =
          resPackage.data?.items
            .filter(
              (item) =>
                mappedProducts.some((product) => product.id === item.id) ===
                false
            )
            .map((item) => ({ ...item, active: true })) ?? [];
        const mappedPriceGroups = resPriceGroups.data.map((priceGroup) => {
          const selectedPriceGroup = resPackage.data.package_price_groups.find(
            (selPriceGroup) => priceGroup.id === selPriceGroup.price_group_id
          );
          return {
            ...priceGroup,
            buyable: !!selectedPriceGroup,
            percentage: selectedPriceGroup
              ? selectedPriceGroup.price.toFixed(2)
              : priceGroup.percentage,
          };
        });
        setProducts([...itemsMissingInProducts, ...mappedProducts]);
        setPriceGroups(mappedPriceGroups);
      } else {
        setPriceGroups(resPriceGroups.data);
        setProducts(resProducts.data);
      }
      setLoading(false);
    } catch (err) {
      toast.error(p('fetchingInformationFailed'));
      setLoading(false);
    }
  };

  useEffect(() => {
    getInitialData();
  }, []);

  const handleSubmit = async (values) => {
    const items = products.filter((product) => product.active);
    if (items.length === 0) {
      toast.error(p('thereMustBeAMatchInPackage'));
      return;
    }
    const payload = {
      name: values.name,
      sale_start: formatDateForApi(values.saleStart),
      sale_end: formatDateForApi(values.saleEnd),
      badge_text: values.badgeText,
      badge_color: values.badgeColor ? values.badgeColor.hex : undefined,
      use_black_badge_text: values.useBlackBadgeText,
      description: values.description,
      use_fees: values.useFees,
      priceGroups: values.priceGroups.filter(
        (priceGroup) => priceGroup.buyable
      ),
      items: products.filter((product) => product.active),
      usePriceGroups: true,
      notify_user: values.push ? values.push : false,
    };
    setLoading(true);
    try {
      if (packageID) {
        await Api.packages.updatePackage(packageID, payload);
        const errData = await Api.packages.updatePackage(packageID, payload);
        if (errData.data.errors) {
          toast.warning(p('PackageUpdatedTicketConflict'));
          const [error = []] = errData.data.errors;
          if (error.reduce((t, e) => t || e, false)) {
            setErrors(error);
          }
        } else {
          toast.success(p('packageUpdated'));
          history.push('/packages');
          setErrors(undefined);
        }
      } else {
        await Api.packages.savePackage(payload);
        toast.success(p('packageCreated'));
        history.push('/packages');
        setErrors(undefined);
      }
      setLoading(false);
    } catch (err) {
      toast.error(err || p('creatingPackageFailed'));
      setLoading(false);
    }
  };

  return (
    <Container>
      <Loading loading={loading} />
      <Row>
        <Col md={12}>
          <h3 className="page-title">
            {packageID ? s('edit') : s('create')} {s('package')}
          </h3>
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <PackagesForm
                pack={pack}
                priceGroups={priceGroups}
                products={products}
                setProducts={setProducts}
                onSubmit={handleSubmit}
                type={packageID ? 'edit' : 'create'}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      {errors && (
        <>
          <Row>
            <Col md={12}>
              <h3 className="page-title">{s('conflicts')}</h3>
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={12}>
              <Card>
                <CardBody>
                  <PackageConflictForm
                    errors={errors}
                    setErrors={setErrors}
                    handleSubmit={() => {}}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};

export default PackagesFormPage;
