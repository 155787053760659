import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Api from '../../util/api';
import Loading from '../../shared/components/custom/Loading';
import SeasonForm from './components/SeasonForm';

const SeasonAdminCreate = () => {
  const { seasonId } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [season, setSeason] = useState(undefined);

  const fetchSeason = async (id) => {
    setLoading(true);
    try {
      const { data } = await Api.clubadmin.seasons.fetch(id);
      setSeason(data);
    } catch (e) {
      toast.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (seasonId) {
      fetchSeason(seasonId);
    }
  }, [seasonId]);

  const handleSubmit = async (values) => {
    const payload = {
      type: values.type.value,
      year: values.year,
    };
    setLoading(true);
    try {
      if (!seasonId) {
        toast.warning('Creating leagues... please wait');
      }
      const { data } = seasonId
        ? await Api.seasons.update(seasonId, payload)
        : await Api.clubadmin.seasons.create(payload);
      if (seasonId) {
        fetchSeason(seasonId);
      } else {
        history.push(`/clubadmin/season/edit/${data.id}`);
      }
      toast.success(
        seasonId ? 'Season successfully updated' : 'Season successfully created'
      );
    } catch (e) {
      toast.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Loading loading={loading} />
      <Row>
        <Col>
          <h3 className="page-title">{season ? 'Edit' : 'Create'} Season</h3>
        </Col>
      </Row>
      <Row>
        <Card>
          <CardBody>
            <SeasonForm onSubmit={handleSubmit} season={season} />
          </CardBody>
        </Card>
      </Row>
    </Container>
  );
};

export default SeasonAdminCreate;
