import React, {
  createContext,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react';
import Api from '../../util/api';
import useConfig from '../../util/useConfig';

const EventContext = createContext(null);

const EventState = ({ children }) => {
  const [questions, setQuestions] = useState([]);
  const [pickedQuestionId, setPickedQuestionId] = useState('');
  const [pickedQuestions, setPickedQuestions] = useState([]);
  const [eventId, setEventId] = useState(null);
  const { id } = useConfig();

  const fetchQuestions = useCallback(() => {
    if (questions.length === 0) {
      Api.questions.fetchSharedQuestions(id).then(({ data }) => {
        setQuestions(data);
      });
    }
  }, []);

  useEffect(() => {
    fetchQuestions();
  }, [fetchQuestions]);

  const memoizedState = useMemo(
    () => ({
      questions,
      pickedQuestions,
      setPickedQuestions,
      fetchQuestions,
      eventId,
      setEventId,
      pickedQuestionId,
      setPickedQuestionId,
    }),
    [
      questions,
      pickedQuestions,
      fetchQuestions,
      setPickedQuestions,
      eventId,
      setEventId,
      pickedQuestionId,
      setPickedQuestionId,
    ]
  );

  return (
    <EventContext.Provider value={memoizedState}>
      {children}
    </EventContext.Provider>
  );
};
export { EventState, EventContext };
