import React, { PureComponent } from 'react';
import DatePicker from 'react-datepicker';
import PropTypes, { any } from 'prop-types';

class DateTimePickerField extends PureComponent {
  static propTypes = {
    timeFormat: PropTypes.string.isRequired,
    dateFormat: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(any)])
      .isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      startDate: null,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.state.startDate && nextProps.value !== '') {
      this.setState({ startDate: nextProps.value });
    }
  }

  handleChange = (date) => {
    this.setState({
      startDate: date,
    });
    this.props.onChange(date);
  };

  render() {
    return (
      <div className="date-picker">
        <DatePicker
          timeFormat={this.props.timeFormat || 'HH:mm'}
          className="form__form-group-datepicker"
          selected={this.state.startDate}
          onChange={this.handleChange}
          showTimeSelect
          dateFormat={this.props.dateFormat || 'MMMM D, YYYY HH:mm'}
          timeIntervals={this.props.timeIntervals || undefined}
          minDate={this.props.minDate || undefined}
        />
      </div>
    );
  }
}

const renderDateTimePickerField = (props) => (
  <div className="form__form-group-input-wrap">
    <DateTimePickerField
      timeFormat={props.timeFormat}
      dateFormat={props.dateFormat}
      timeIntervals={props.timeIntervals}
      minDate={props.minDate}
      {...props.input}
    />
    {props.meta.touched && props.meta.error && (
      <span className="form__form-group-error">{props.meta.error}</span>
    )}
  </div>
);

renderDateTimePickerField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
  timeFormat: PropTypes.string,
  dateFormat: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
};

renderDateTimePickerField.defaultProps = {
  meta: null,
  options: [],
  timeFormat: 'HH:mm',
  dateFormat: 'MMMM D, YYYY HH:mm',
};

export default renderDateTimePickerField;
