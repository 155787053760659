import { partial } from '../../../../../../util/translation/translation';

const validate = (values) => {
  const p = partial('validateErrors');
  const errors = {};
  if (!values.ticketState && !values.editUsersTickets) {
    errors.ticketState = p('ticketSaleCantBeEmpty');
  }

  if (!values.priceGroup && !values.editUsersTickets) {
    errors.priceGroup = p('priceGroupFieldCantBeEmpty');
  }

  if (!values.owner) {
    errors.owner = p('ownerFieldCantBeEmpty');
  }

  if (!values.user) {
    errors.user = p('userFieldCantBeEmpty');
  }

  return errors;
};

export default validate;
