import { partial } from '../../util/translation/translation';

const validations = (values) => {
  const errors = {};
  const o = partial('OfferForm');

  if (values.isCustomMatch) {
    if (!values.awayTeam) {
      errors.awayTeam = o('nameRequired');
    }
    if (!values.homeTeam) {
      errors.homeTeam = o('nameRequired');
    }
    if (!values.matchType) {
      errors.matchType = o('nameRequired');
    }
    if (!values.matchStartsAt) {
      errors.matchStartsAt = o('dateRequired');
    }
    if (!values.administrativeTeam) {
      errors.administrativeTeam = o('nameRequired');
    }
    if (!values.matchTypeLogo) {
      errors.matchTypeLogo = o('imageRequired');
    }
  }

  return errors;
};

export default validations;
