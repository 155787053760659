import { partial } from '../../../util/translation/translation';

const validateApi = (values) => {
  const p = partial('validateErrors');
  const errors = {};
  if (!values.entityName) {
    errors.entityName = p('nameFieldEmpty');
  }
  return errors;
};

export default validateApi;
