import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import DotsHorizontalIcon from 'mdi-react/DotsHorizontalIcon';
import { setBalanceName } from '../../redux/actions/balanceActions';
import DeleteModal from '../../shared/components/custom/DeleteModal';
import Loading from '../../shared/components/custom/Loading';
import Api from '../../util/api';
import { partial } from '../../util/translation/translation';
import { formatDateTime } from '../../util/date-and-time';

const p = partial('balancePackageShared');
const s = partial('shared');

const BalancePackagesPage = ({ balance, dispatch }) => {
  const [balancePackages, setBalancePackages] = useState([]);
  const [loadingBalancePackages, setLoadingBalancePackages] = useState(false);
  const [modalState, setModalState] = useState({
    visible: false,
    balancePackageID: null,
  });
  const [editBalanceName, setEditBalanceName] = useState(false);
  const [name, setName] = useState('-');

  const getPackages = async () => {
    setLoadingBalancePackages(true);
    try {
      const resPackages = await Api.packages.getBalancePackages();
      setBalancePackages(resPackages.data.data);
      setName(resPackages.data.balance_name);
      setLoadingBalancePackages(false);
    } catch (err) {
      toast.error(err || `${p('balancePackageRetrievingERROR')}!`);
      setLoadingBalancePackages(false);
    }
  };

  useEffect(() => {
    getPackages();
  }, []);

  const listBalancePackages = () => {
    return balancePackages.map((balancePackage) => (
      <tr key={`ListBalancePackages-${balancePackage.package.id}`}>
        <td>
          <Link to={`/balances/edit/${balancePackage.id}`}>
            {balancePackage.package.name}
          </Link>
        </td>
        <td>{balancePackage.package.price}</td>
        <td>{formatDateTime(balancePackage.package.sale_start)}</td>
        <td>
          {balancePackage.package.sale_end
            ? formatDateTime(balancePackage.package.sale_end)
            : '-'}
        </td>
        <td>{balancePackage.saldo}</td>
        <td>
          <UncontrolledDropdown className="dashboard__table-more">
            <DropdownToggle>
              <p>
                <DotsHorizontalIcon />
              </p>
            </DropdownToggle>
            <DropdownMenu className="dropdown__menu">
              <Link to={`/balances/edit/${balancePackage.id}`}>
                <DropdownItem id={balancePackage.id}>{s('edit')}</DropdownItem>
              </Link>
              <DropdownItem
                onClick={() =>
                  setModalState({
                    visible: true,
                    balancePackageID: balancePackage.id,
                  })
                }
                className="danger"
              >
                {s('deleteCaps')}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </td>
      </tr>
    ));
  };

  const handleDelete = async () => {
    if (modalState.packageID === null) {
      setModalState({ visible: false, packageID: null });
      return;
    }

    setLoadingBalancePackages(true);
    try {
      await Api.packages.deleteBalancePackage(modalState.balancePackageID);
      getPackages();
    } catch (err) {
      toast.error(err || `${p('tokenPackageDeletingERROR')}!`);
      getPackages();
    }
    setModalState({ visible: false, packageID: null });
  };

  const handleNameChange = async (event) => {
    event.preventDefault();
    if (editBalanceName === false) {
      setEditBalanceName(true);
      return;
    }
    setLoadingBalancePackages(true);
    try {
      const resBalance = await Api.packages.changeBalancePackageName({
        title: name,
      });
      dispatch(setBalanceName(resBalance.data.value));
      setEditBalanceName(false);
      getPackages();
    } catch (err) {
      toast.error(err || `${p('titleChangingERROR')}!`);
      setLoadingBalancePackages(false);
    }
  };

  return (
    <Container>
      <DeleteModal
        visible={modalState.visible}
        type="saldopakken"
        handleModal={() => setModalState(false, null)}
        modalAction={handleDelete}
      />
      <Loading loading={loadingBalancePackages} />
      <Row>
        <Col>
          <h3 className="page-title">{`${s('fanAccount')} (${balance.name})`}</h3>
        </Col>
      </Row>
      <Card>
        <CardBody>
          <div className="flex space-between" style={{ float: 'right' }}>
            <Link
              style={{ cssFloat: 'right' }}
              className="btn btn-primary btn-sm"
              to="/balances/create"
            >
              {p('createPackage')}
            </Link>
          </div>
          <Table responsive bordered>
            <thead>
              <tr>
                <th>{p('packageName')}</th>
                <th>{p('packagePrice')}</th>
                <th>{p('salesStart')}</th>
                <th>{p('salesEnd')}</th>
                <th>{s('balance')}</th>
                <th />
              </tr>
            </thead>
            <tbody>{listBalancePackages()}</tbody>
          </Table>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <div className="flex space-between">
            <h5 className="bold-text">{s('settings')}</h5>
          </div>
          <form className="form flex width-100p" onSubmit={handleNameChange}>
            <Row className="space-between alignItems-center width-100p">
              <Col md="10">
                {editBalanceName ? (
                  <input
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                    placeholder={s('balance')}
                  />
                ) : (
                  <p>
                    {p('balanceName')}: {name}
                  </p>
                )}
              </Col>
              <Col md={2}>
                <Button
                  onClick={handleNameChange}
                  color="primary"
                  size="sm"
                  className="margin-0 pull-right"
                  type="submit"
                >
                  {editBalanceName ? `${s('save')}` : `${s('edit')}`}
                </Button>
              </Col>
            </Row>
          </form>
        </CardBody>
      </Card>
    </Container>
  );
};

BalancePackagesPage.propTypes = {
  balance: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  balance: state.balance,
});

export default connect(mapStateToProps)(BalancePackagesPage);
