import React, { useState, useEffect } from 'react';
import { Button, Table } from 'reactstrap';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { getBaseDate } from '../../util/date-and-time';
import Api from '../../util/api';

const LeagueAdminListPage = () => {
  const [leagues, setLeagues] = useState([]);

  const fetchLeagues = async () => {
    try {
      const { data } = await Api.clubadmin.leagues.all();
      setLeagues(data);
    } catch (e) {
      toast.error(e);
    }
  };
  const renderLeagues = (_leagues) =>
    _leagues.map((league) => (
      <tr key={`leauge:${league.id}`}>
        <td>{league.id}</td>
        <td>
          <Link to={`/clubadmin/league/edit/${league.id}`}>{league.name}</Link>
        </td>
        <td>{league.provider}</td>
        <td>
          {getBaseDate(league.season?.seasonStart)} -<br />
          {getBaseDate(league.season?.seasonEnd)}
          <br />
          {league.season?.type}
        </td>
        <td>
          {league.sport}, {league.extraData.providerName}
        </td>
        <td>{league.gender}</td>
      </tr>
    ));
  useEffect(() => {
    fetchLeagues();
  }, []);
  return (
    <>
      <div className="flex space-between" style={{ float: 'right' }}>
        <Link to="/clubadmin/league/create">
          <Button color="primary" size="sm">
            Create League
          </Button>
        </Link>
      </div>
      <Table responsive bordered striped>
        <thead>
          <tr>
            <th> </th>
            <th>Name</th>
            <th>Provider</th>
            <th>Season</th>
            <th>Sport</th>
            <th>Gender</th>
          </tr>
        </thead>
        <tbody>{renderLeagues(leagues)}</tbody>
      </Table>
    </>
  );
};

export default LeagueAdminListPage;
