import React, { useState } from 'react';
import { Table } from 'reactstrap';
import skeletonTableLoader from '../../../shared/components/custom/SkeletonLoaders';
import SortDirection from '../../../shared/components/custom/SortDirection';
import Pagination from '../../../shared/components/pagination/Pagination';
import { partial } from '../../../util/translation/translation';

const ScreenTable = ({ screenData, isLoading, loginData }) => {
  const a = partial('Analytics');
  const s = partial('shared');

  const [sort, setSort] = useState({ column: 'views', order: 0 });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(4);

  const handleHeaderClick = (header) => {
    const direction = header === sort.column ? (sort.order === 1 ? 0 : 1) : 0;

    setSort({ column: header, order: direction });
  };

  const handlePagination = (paginationObject) => {
    setCurrentPage(paginationObject.currentPage);
    setPageSize(paginationObject.pageSize);
  };
  const indexOfLastScreen = currentPage * pageSize;
  const indexOfFirstScreen = indexOfLastScreen - pageSize;

  const sortedData = [...screenData].sort((a, b) => {
    if (sort.order === 1) {
      return a.viewCount - b.viewCount;
    } else {
      return b.viewCount - a.viewCount;
    }
  });

  const currentScreens = sortedData.slice(
    indexOfFirstScreen,
    indexOfLastScreen
  );

  return (
    <div>
      <div
        style={{
          display: 'flex',
          marginBottom: 10,
          paddingBottom: 5,
          background: '#fff',
          borderRadius: 6,
          boxShadow: '0px 2px 8px 0px rgba(0,0,0,0.08)',
        }}
      >
        <Table responsive striped>
          <thead>
            <tr>
              <th>Page title and screen name</th>
              <th
                className="sortable text-align-center"
                onClick={() => handleHeaderClick('views')}
              >
                {s('views')}
                <SortDirection
                  active={sort.column === 'views'}
                  direction={sort.order}
                />
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan="3">
                  <div className="d-flex justify-content-center align-items-center h-100">
                    {skeletonTableLoader(1, 1, 750)}
                  </div>
                </td>
              </tr>
            ) : loginData.length === 0 ? (
              <tr>
                <td colSpan="3">
                  <div className="d-flex justify-content-center align-items-center h-100">
                    <p className="">{a('noData')}</p>
                  </div>
                </td>
              </tr>
            ) : (
              currentScreens.map((screen, index) => (
                <tr key={index}>
                  <td>{screen.screenName || 'Unknown'}</td>
                  <td className="text-align-center">{screen.viewCount}</td>
                  <td />
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </div>
      {screenData.length > 0 && (
        <Pagination
          items={screenData.length}
          showRowsPicker={false}
          rowsPerPage={[4, 8, 12]}
          onChangePage={handlePagination}
        />
      )}
    </div>
  );
};

export default ScreenTable;
