import * as XLSX from 'xlsx';
import fileDownload from 'js-file-download';

const exportToExcelFile = (data, title) => {
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
  fileDownload(blob, `${title}.xlsx`);
};

export default exportToExcelFile;
