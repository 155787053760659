import React, { useState, useRef } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Label,
  Row,
  Col,
} from 'reactstrap';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import t, { partial } from '../../../util/translation/translation';
import Slider from './Slider';

const CROP_TYPES = {
  BANNER_CROP: {
    height: 1125,
    width: 630,
    x: 0,
    y: 0,
    aspect: 16 / 9,
    unit: 'px',
  },
  PROFILE_CROP: { width: 750, height: 750, x: 0, y: 0, aspect: 1, unit: 'px' },
  MATCHSPONSOR_CROP: {
    height: 900,
    width: 90,
    x: 0,
    y: 0,
    aspect: 10 / 1,
    unit: 'px',
  },
  PLAYERCARD_CROP: {
    height: 900,
    width: 90,
    x: 0,
    y: 0,
    aspect: 10 / 1,
    unit: 'px',
  },
  PRINTEDPAPERTICKET_CROP: {
    height: 800,
    width: 160,
    x: 0,
    y: 0,
    aspect: 10 / 1,
    unit: 'px',
  },
  FREE_FORM_CROP: { width: 75, height: 75, x: 0, y: 0, aspect: 1, unit: 'px' },
  APP_SPONSOR_CROP: {
    width: 1035,
    height: 255,
    x: 0,
    y: 0,
    aspect: 4 / 1,
    unit: 'px',
  },
  TIER_CROP: { width: 200, height: 200, x: 0, y: 0, aspect: 1, unit: 'px' },
  PLAYER_IMAGE_CROP: {
    width: 750,
    height: 750,
    x: 0,
    y: 0,
    aspect: 750 / 750,
    unit: 'px',
  },
  SPOTLIGHT_SQUARE_CROP: {
    width: 750,
    height: 750,
    x: 0,
    y: 0,
    aspect: 750 / 750,
    unit: 'px',
  },
  SPOTLIGHT_LANDSCAPE_CROP: {
    width: 1125,
    height: 630,
    x: 0,
    y: 0,
    aspect: 16 / 9,
    unit: 'px',
  },
  SPOTLIGHT_PORTRAIT_CROP: {
    width: 630,
    height: 1125,
    x: 0,
    y: 0,
    aspect: 9 / 16,
    unit: 'px',
  },
  SPOTLIGHT_BANNER_CROP: {
    width: 750,
    height: 211,
    x: 0,
    y: 0,
    aspect: 16 / 4.5,
    unit: 'px',
  },
  SPOTLIGHT_BANNER_SMALL_CROP: {
    width: 375,
    height: 40,
    x: 0,
    y: 0,
    aspect: 75 / 8,
    unit: 'px',
  },
  SPOTLIGHT_BANNER_MEDIUM_CROP: {
    width: 375,
    height: 80,
    x: 0,
    y: 0,
    aspect: 75 / 16,
    unit: 'px',
  },
  SPOTLIGHT_BANNER_LARGE_CROP: {
    width: 375,
    height: 150,
    x: 0,
    y: 0,
    aspect: 5 / 2,
    unit: 'px',
  },
  SPOTLIGHT_CIRCLE_CROP: {
    width: 750,
    height: 750,
    x: 0,
    y: 0,
    aspect: 750 / 750,
    unit: 'px',
  },
  SPOTLIGHT_HALF_PORTRAIT_CROP: {
    width: 800,
    height: 1000,
    x: 0,
    y: 0,
    aspect: 4 / 5,
    unit: 'px',
  },
  NEWS_THUMB_CROP: {
    height: 1035,
    width: 585,
    x: 0,
    y: 0,
    aspect: 16 / 9,
    unit: 'px',
  },
  MATCHBANNER_IMAGE_CROP: {
    width: 500,
    height: 500,
    x: 0,
    y: 0,
    aspect: 500 / 500,
    unit: 'px',
  },
  NEWS_OPTIONS_CROP: {
    height: 1035,
    width: 585,
    x: 0,
    y: 0,
    aspect: 16 / 9,
    unit: 'px',
  },
  SQUARE: { height: 750, width: 750, x: 0, y: 0, aspect: 1 / 1, unit: 'px' },
  LANDSCAPE: {
    height: 1280,
    width: 720,
    x: 0,
    y: 0,
    aspect: 16 / 9,
    unit: 'px',
  },
};

const VIEW_TYPE = {
  NEW: 'new',
  EDIT: 'edit',
};
const s = partial('shared');
const p = partial('ImageCrop');
const ImageCropModal = (props) => {
  const [cropType, setType] = useState(
    CROP_TYPES[props.crop] || CROP_TYPES.PROFILE_CROP
  );
  const [crop, setCrop] = useState(cropType);
  const [corpOption, setCropOption] = useState('LANDSCAPE');
  const [percentageCrop, setPercentageCrop] = useState({
    width: 100,
    height: 100,
    x: 0,
    y: 0,
    unit: '%',
  });
  const [croppedImage, setCroppedImage] = useState(null);
  const imageRef = useRef(null);
  const [scale, setScale] = useState(1);
  const centerAspectCrop = (mediaWidth, mediaHeight, aspect) => {
    return centerCrop(
      makeAspectCrop(
        {
          unit: 'px',
          width: crop.width / 3,
          height: crop.height / 3,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  };

  const getCroppedImg = (image, croped, fileName) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const pixelRatio = window.devicePixelRatio;

    canvas.width = Math.floor(croped.width * scaleX * pixelRatio);
    canvas.height = Math.floor(croped.height * scaleY * pixelRatio);

    ctx.scale(pixelRatio, pixelRatio);
    ctx.imageSmoothingQuality = 'high';

    const cropX = croped.x * scaleX;
    const cropY = croped.y * scaleY;

    const centerX = image.naturalWidth / 2;
    const centerY = image.naturalHeight / 2;

    ctx.save();

    ctx.translate(-cropX, -cropY);
    ctx.translate(centerX, centerY);
    ctx.scale(scale, scale);
    ctx.translate(-centerX, -centerY);
    ctx.drawImage(
      image,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight
    );

    ctx.restore();
    return new Promise((resolve) => {
      /* eslint-disable  no-param-reassign */
      canvas.toBlob(
        (blob) => {
          blob.name = fileName;
          window.URL.revokeObjectURL(blob.fileUrl);
          blob.fileUrl = window.URL.createObjectURL(blob);
          resolve(blob);
        },
        'image/png',
        0.25
      );
    });
  };

  const makeClientCrop = async (croped) => {
    if (imageRef && croped.width && croped.height) {
      const croppedImageResult = await getCroppedImg(
        imageRef.current,
        croped,
        'newFile.png'
      );
      setCroppedImage(croppedImageResult);
    }
  };

  const onCropComplete = (croped) => {
    makeClientCrop(croped);
  };

  const onImageLoaded = (e) => {
    const { width, height } = e.currentTarget || crop;
    const centerCroOpt = centerAspectCrop(width, height, cropType.aspect);
    setCrop(centerCroOpt);
    makeClientCrop(centerCroOpt);
  };

  const onScaleChange = (newScale) => {
    setScale(newScale);
    makeClientCrop(crop);
  };

  const getHeaderMessage = () => {
    switch (props.crop) {
      case 'BANNER_CROP':
        return p('headerMsgCover');
      case 'PROFILE_CROP':
        return p('headerMsgProfile');
      case 'MATCHSPONSOR_CROP':
        return p('headerMsgMatchSponsor');
      case 'PLAYERCARD_CROP':
        return p('headerMsgPlayerCard');
      case 'PLAYER_IMAGE_CROP':
        return p('cropImageToASquare');
      case 'PRINTEDPAPERTICKET_CROP':
        return p('headerMsgTicket');
      case 'NEWS_THUMB_CROP':
        return p('newsThumbnailCrop');
      case 'NEWS_OPTIONS_CROP':
        return p('newsOptionsCrop');
      default:
        return '';
    }
  };
  const getSubMessage = () => {
    switch (props.crop) {
      case 'PLAYER_IMAGE_CROP':
        return p('playerCropDescription');
      default:
        return '';
    }
  };
  async function getFileFromUrl(url, name, defaultType = 'image/jpeg') {
    const response = await fetch(url);
    const data = await response.blob();
    return new File([data], name, {
      type: data.type || defaultType,
    });
  }
  const handleSubmit = async () => {
    if (croppedImage) {
      const originalFile = await getFileFromUrl(props.src, 'test');
      originalFile.preview = props.src;
      originalFile.crop = percentageCrop;
      originalFile.scale = scale;
      const croppedFile = new File([croppedImage], 'newFile.png');
      croppedFile.preview = croppedImage.fileUrl;
      croppedFile.crop = percentageCrop;
      croppedFile.scale = scale;
      props.action([croppedFile, originalFile]);
      props.handleModal(false);
    } else {
      props.action([]);
      props.handleModal(false);
    }
  };

  const SelectCropOptions = (type) => {
    setType(CROP_TYPES[type]);
    setCrop(type);
    setCroppedImage(null);
    setCropOption(type);
  };

  return (
    <Modal
      isOpen={props.visible}
      toggle={() => {
        props.handleModal(false);
      }}
      size="lg"
    >
      <>
        <ModalHeader
          toggle={() => {
            props.handleModal(false);
          }}
        >
          <span>{p('title')}</span>
        </ModalHeader>
        <ModalBody>
          <div className="ml-auto mr-auto mt-1 mb-1">
            <p>{p('headerTitle')}</p>
            <p>{getHeaderMessage()}</p>
            <p>{getSubMessage()}</p>
            {props.crop === 'NEWS_OPTIONS_CROP' && (
              <>
                <Button
                  color={corpOption === 'LANDSCAPE' ? 'primary' : 'secondary'}
                  onClick={() => SelectCropOptions('LANDSCAPE')}
                >
                  {t('HighlightBannerPage.LANDSCAPE')}
                </Button>
                <Button
                  color={corpOption === 'SQUARE' ? 'primary' : 'secondary'}
                  onClick={() => SelectCropOptions('SQUARE')}
                >
                  {t('HighlightBannerPage.SQUARE')}
                </Button>
              </>
            )}
            <Row style={{ justifyContent: 'center' }}>
              {console.log(crop)}
              {props.type === VIEW_TYPE.EDIT ? (
                <ReactCrop
                  className={
                    props.crop === 'PLAYERCARD_CROP'
                      ? 'cropper-black'
                      : 'cropper'
                  }
                  crop={crop}
                  onChange={(pixel, percentage) => {
                    setCrop(pixel);
                    setPercentageCrop(percentage);
                  }}
                  onComplete={onCropComplete}
                  aspect={cropType.aspect}
                  locked={props.locked}
                  unit={cropType.unit}
                >
                  <img
                    style={{ transform: `scale(${scale})` }}
                    crossOrigin="anonymous"
                    ref={imageRef}
                    src={props.src}
                    alt="Crop me"
                    onLoad={onImageLoaded}
                  />
                </ReactCrop>
              ) : (
                <ReactCrop
                  className={
                    props.crop === 'PLAYERCARD_CROP'
                      ? 'cropper-black'
                      : 'cropper'
                  }
                  crop={crop}
                  onComplete={onCropComplete}
                  onChange={(pixel, percentage) => {
                    setCrop(pixel);
                    setPercentageCrop(percentage);
                  }}
                  aspect={cropType.aspect}
                  locked={props.locked}
                  unit={cropType.unit}
                >
                  <img
                    style={{ transform: `scale(${scale})` }}
                    ref={imageRef}
                    alt="Crop me"
                    src={props.src}
                    onLoad={onImageLoaded}
                  />
                </ReactCrop>
              )}
            </Row>
            <div>
              <Label htmlFor="scale-input">Scale: </Label>
              <Slider
                name="scale-input"
                step={0.1}
                setValue={onScaleChange}
                value={scale}
                min={0.1}
                max={1}
              />
            </div>
          </div>
          <hr />
          <br />
          <div className="section-group d-block">
            <div>
              <Label>{s('previewImage')}:</Label>
            </div>
            {croppedImage && props.crop === 'PLAYERCARD_CROP' ? (
              <div className="sponsor-player bg-dark">
                <img src={croppedImage.fileUrl} alt="Crop" />
              </div>
            ) : (
              <>
                {croppedImage && props.crop === 'PROFILE_CROP' ? (
                  <img
                    className="company-logo"
                    src={croppedImage.fileUrl}
                    alt="Crop"
                  />
                ) : (
                  <>
                    {croppedImage ? (
                      <div
                        className="ml-auto mr-auto mt-1 mb-1"
                        style={{
                          width: '30%',
                          height: '30%',
                          marginRight: 'auto',
                          marginLeft: 'auto',
                        }}
                      >
                        <img
                          alt="Crop"
                          style={{
                            maxWidth: '100%',
                            border: 'black solid 1px',
                          }}
                          src={croppedImage.fileUrl}
                        />
                      </div>
                    ) : null}
                  </>
                )}
              </>
            )}
          </div>
          <Row>
            <Col style={{ marginTop: '10px' }}>
              <Button color="primary" onClick={handleSubmit}>
                {s('continue')}
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </>
    </Modal>
  );
};

export default ImageCropModal;
