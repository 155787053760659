import { partial } from '../../../../util/translation/translation';

const p = partial('validateErrors');
const s = partial('shared');
const validateBanner = (values) => {
  const errors = {};
  if (!values.title) {
    errors.title = p('titleFieldEmpty');
  }
  if (!values.maxPerAccount) {
    errors.maxPerAccount = p('maxPerAccountEmpty');
  }
  if (!values.maxParticipants) {
    errors.maxParticipants = p('maxParticipantsEmpty');
  }
  if (Number(values.maxPerAccount) > Number(values.maxParticipants)) {
    errors.maxPerAccount = p(
      'participantsFieldCantBeBiggerThanMaxParticipants'
    );
  }
  if (Number(values.maxParticipants) < 0) {
    errors.maxParticipants = p('participantsCantBeLessThanZero');
  }
  if (Number(values.maxPerAccount) < 0) {
    errors.maxPerAccount = p('participantsCantBeLessThanZero');
  }
  if (!values.option) {
    errors.option = `${s('chooseType')}!`;
  }
  return errors;
};

export default validateBanner;
