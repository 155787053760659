import React, { useState } from 'react';
import { Table } from 'reactstrap';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import filedownload from 'js-file-download';
import CheckCircleIcon from 'mdi-react/CheckCircleIcon';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import AttendanceForm from './AttendanceForm';
import Api from '../../../util/api';
import Loading from '../../../shared/components/custom/Loading';
import Pagination from '../../../shared/components/pagination/Pagination';
import { partial } from '../../../util/translation/translation';

const Attendance = ({ match, matchID, dispatch }) => {
  const [loading, setLoading] = useState(true);
  const [manualAttendance, setManualAttendance] = useState([]);
  const [systemAttendance, setSystemAttendance] = useState(null);

  const p = partial('Attendance');
  const s = partial('shared');

  const getManualVisitors = async (page = 1, pageSize = 10) => {
    setLoading(true);
    try {
      const resManual = await Api.matches.getManualVisitors(
        matchID,
        page,
        pageSize
      );
      setManualAttendance(resManual.data);
      setLoading(false);
    } catch (err) {
      toast.error(err);
      setLoading(false);
    }
  };

  const getSystemVisitors = async (page = 1, pageSize = 10) => {
    setLoading(true);
    try {
      const resSystem = await Api.matches.getSystemVisitors(
        matchID,
        page,
        pageSize
      );
      setSystemAttendance(resSystem.data);
      setLoading(false);
    } catch (err) {
      toast.error(err);
      setLoading(false);
    }
  };

  const removeAttendance = async (attendanceID) => {
    setLoading(true);
    try {
      await Api.matches.removeAttendance(matchID, attendanceID);
      getManualVisitors(manualAttendance?.current_page ?? 1);
    } catch (err) {
      getManualVisitors();
    }
  };

  const exportAttendance = async () => {
    setLoading(true);
    try {
      const resExportData = await Api.matches.exportAttendance(matchID);
      const bom = new Uint8Array([0xef, 0xbb, 0xbf]); // Changes default encoding in excel to utf8 instead of ANSI
      filedownload(
        resExportData.data,
        `${match.home_team.shortname} - ${match.away_team.shortname} ${p('audienceList')}.csv`,
        undefined,
        bom
      );
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(err);
    }
  };

  const renderAttendance = (attendanceList, removeable) => {
    return attendanceList.map((user) => (
      <tr key={`attendance-${user.id}`}>
        <td>{user.phone_number}</td>
        <td>
          {user.firstname} {user.lastname}
        </td>
        <td>{user.amount}</td>
        {typeof user.validated !== 'undefined' ? (
          <td>
            <CheckCircleIcon
              size="22px"
              color={user.validated ? '#4DE1B6' : '#FF4761'}
            />
          </td>
        ) : (
          <></>
        )}
        {removeable ? (
          <td>
            <div className="flex-centerVertHort">
              <button
                onClick={() => removeAttendance(user.id)}
                className="outline-badge danger"
              >
                {s('remove')}
              </button>
            </div>
          </td>
        ) : (
          <></>
        )}
      </tr>
    ));
  };

  const onSubmit = async (values) => {
    const payload = {
      phone_number: values.phoneNumber,
      firstname: values.firstname,
      lastname: values.lastname,
    };
    setLoading(true);
    try {
      await Api.matches.createManualVisitor(matchID, payload);
      dispatch(reset('AttendanceForm'));
      getManualVisitors();
    } catch (err) {
      getManualVisitors();
      toast.error(err);
    }
  };

  const onChangePageManual = (pager) => {
    getManualVisitors(pager.currentPage, pager.pageSize);
  };

  const onChangePageSystem = (pager) => {
    getSystemVisitors(pager.currentPage, pager.pageSize);
  };

  return (
    <>
      <Loading loading={loading} />
      <div>
        <p className="bold">{p('registerAttendanceManually')}</p>
        <AttendanceForm
          onSubmit={onSubmit}
          exportAttendance={exportAttendance}
        />
      </div>
      <div className="flex">
        <div className="flex-1">
          <p className="bold">{p('manuallyRegistered')}</p>
          <Table responsive bordered>
            <thead>
              <tr>
                <th>{s('phonenumber')}</th>
                <th>{s('name')}</th>
                <th>{p('amountOfTickets')}</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {renderAttendance(manualAttendance?.data ?? [], true)}
            </tbody>
          </Table>
          <Pagination
            items={manualAttendance?.total ?? 0}
            pageSize={10}
            initialPage={manualAttendance?.current_page ?? 1}
            onChangePage={onChangePageManual}
          />
        </div>
        <div className="margin-10" />
        <div className="flex-1">
          <p className="bold marginTop-30">{p('usersFromSystemTickets')}</p>
          <Table responsive bordered>
            <thead>
              <tr>
                <th>{s('phonenumber')}</th>
                <th>{s('name')}</th>
                <th>{p('amountOfTickets')}</th>
                <th>{s('validated')}</th>
              </tr>
            </thead>
            <tbody>{renderAttendance(systemAttendance?.data ?? [])}</tbody>
          </Table>
          <Pagination
            items={systemAttendance?.total ?? 0}
            pageSize={10}
            onChangePage={onChangePageSystem}
          />
        </div>
      </div>
    </>
  );
};

Attendance.propTypes = {
  match: PropTypes.shape({
    id: PropTypes.number,
    home_team: PropTypes.shape({
      name: PropTypes.string,
    }),
    away_team: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
  matchID: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
};

Attendance.defaultProps = {
  match: null,
};

export default connect()(Attendance);
