import React from 'react';

export default function EditPencilIcon(props) {
  return (
    <img
      src="/assets/files/questions/edit-pencil.svg"
      alt="Edit question"
      {...props}
    />
  );
}
