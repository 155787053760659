import React, { useEffect, useState } from 'react';
import { Button, Table } from 'reactstrap';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Api from '../../util/api';

const ClubAdminListPage = () => {
  const [clubs, setClubs] = useState([]);
  useEffect(() => {
    const fetchClubs = async () => {
      try {
        const { data } = await Api.clubadmin.all();
        setClubs(data);
      } catch (e) {
        toast.error(e);
      }
    };
    fetchClubs();
  }, []);

  const renderClubs = (_clubs) =>
    _clubs.map((club) => (
      <tr key={`clist${club.id}`}>
        <td>
          <Link to={`/clubadmin/edit/${club.id}`}>{club.id}</Link>
        </td>
        <td>{club.config.club_name}</td>
        <td>
          {club.administrates?.map(
            (team) => `${team.name} ${team.gender === 'male' ? '👱🏻‍' : '👱🏻‍'}`
          )}
        </td>
      </tr>
    ));

  return (
    <>
      <div className="flex space-between" style={{ float: 'right' }}>
        <Link to="/clubadmin/create">
          <Button color="primary" size="sm">
            Create Club
          </Button>
        </Link>
      </div>
      <Table responsive bordered>
        <thead>
          <tr>
            <th>Club Id</th>
            <th>Club name</th>
            <th>Teams</th>
          </tr>
        </thead>
        <tbody>{renderClubs(clubs)}</tbody>
      </Table>
    </>
  );
};

export default ClubAdminListPage;
