import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { Col, Container, Row, CardBody, Card } from 'reactstrap';
import Api from '../../util/api';
import ExternalStoreForm from './components/ExternalStoreForm';
import Loading from '../../shared/components/custom/Loading';
import { convertImageToBlob } from '../../util/image-functions';
import t, { partial } from '../../util/translation/translation';
import { formatDateForApi } from '../../util/date-and-time';

const ExternalStoreFormPage = () => {
  const [externalStore, setExternalStore] = useState(null);
  const [loading, setLoading] = useState(false);

  const { externalStoreID } = useParams();
  const history = useHistory();
  const p = partial('ExternalStoreFormPage');

  const getExternalStore = async () => {
    setLoading(true);
    try {
      const resExternalStore = await Api.externalStores.show(externalStoreID);
      setExternalStore(resExternalStore.data);
      setLoading(false);
    } catch (err) {
      toast.error(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (externalStoreID) {
      getExternalStore();
    }
  }, [externalStoreID]);

  const onSubmit = async (values) => {
    if (!values.image || values.image.length !== 1) {
      toast.error(p('mustUploadImage'));
      return;
    }

    let image = values.image[0];
    if (!image.url) {
      image = await convertImageToBlob(image);
    }
    const payload = {
      name: values.name,
      description: values.description,
      external_url: values.externalUrl,
      base_url: values.baseUrl,
      fast_link: values.fastLink,
      sale_start: formatDateForApi(values.saleStart),
      sale_end: values.saleEnd ? formatDateForApi(values.saleEnd) : null,
      image,
      button_text: values.buttonText,
      button_color: values.buttonColor.hex,
      button_text_color: values.buttonTextColor.hex,
    };
    setLoading(true);
    if (externalStoreID) {
      try {
        await Api.externalStores.update(payload, externalStoreID);
        toast.success(p('externalStoreHasBeenUpdated'));
        setLoading(false);
      } catch (err) {
        setLoading(false);
        toast.error(err || p('somethingWentWrongUpdatingExternalStore'));
      }
    } else {
      try {
        await Api.externalStores.create(payload);
        toast.success(p('externalStoreHasBeenCreated'));
        history.replace('/external_stores?tab=2');
      } catch (err) {
        setLoading(false);
        toast.error(err || p('somethingWentWrongCreatingExternalStore'));
      }
    }
  };

  return (
    <Container>
      <Loading loading={loading} />
      <Row>
        <Col>
          <h3 className="page-title">
            {externalStoreID ? t('shared.edit') : t('shared.create')}{' '}
            {p('externalStore')}
          </h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <ExternalStoreForm
                externalStore={externalStore}
                onSubmit={onSubmit}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ExternalStoreFormPage;
