import moment from 'moment';
import { partial } from '../../../util/translation/translation';

const validateDetails = (values) => {
  const p = partial('validateErrors');
  const errors = {};
  if (!values.title) {
    errors.title = p('headerFieldCantBeEmpty');
  }

  if (!values.publicationDate) {
    errors.publicationDate = p('publicationDateFieldCantBeEmpty');
  }
  if (values.publicationDate && values.expirationDate) {
    if (
      moment(values.expirationDate).isBefore(moment(values.publicationDate))
    ) {
      errors.expirationDate = p('endBeforeStart');
    }
  }
  if (!values.imageHeaderType?.value) {
    errors.imageHeaderType = p('fieldEmpty');
  }
  if (values.imageHeaderType?.value === 'IMAGE' && !values.headingImage) {
    errors.headingImage = p('picFieldCantBeEmpty');
  }
  if (values.imageHeaderType?.value === 'VIDEO' && !values.headingVideo) {
    errors.headingVideo = p('videoFieldCantBeEmpty');
  }
  return errors;
};

export default validateDetails;
