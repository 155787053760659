import React from 'react';
import { Button, Modal } from 'reactstrap';
import CloseCircleOutline from 'mdi-react/CloseCircleOutlineIcon';
import CashMultipleIcon from 'mdi-react/CashMultipleIcon';
import PropTypes from 'prop-types';
import { partial } from '../../../util/translation/translation';

const p = partial('DeleteModal');
const s = partial('shared');
const DeleteModal = (props) => {
  return (
    <Modal isOpen={props.visible} toggle={() => props.handleModal(false)}>
      <div className="modal">
        <div className="modal-container">
          <div className="modal-icon">
            {props.modalType === 'delete' && (
              <CloseCircleOutline size="128" color="#ff4861" />
            )}
            {props.modalType === 'release' && (
              <CloseCircleOutline size="128" color="#ff4861" />
            )}
            {props.modalType === 'refund' && (
              <CashMultipleIcon size="128" color="#ff4861" />
            )}
          </div>
          <div className="modal-text">
            <h3>
              {props.modalType === 'delete' &&
                `${p('sureDeleting')} ${props.type}? ${props.removeWarning ? props.removeWarning : ''}`}
              {props.modalType === 'release' &&
                `${p('sureRelease')} ${props.type}?`}
              {props.modalType === 'refund' &&
                `${p('sureRefound')} ${props.type}?`}
            </h3>
          </div>
          {props.canComment && (
            <div className="form marginTop-10 marginBottom-20 width-80p">
              <textarea
                name="comment"
                placeholder={p('comment')}
                onChange={(event) => props.onCommentChange(event.target.value)}
                value={props.comment}
                type="text"
              />
            </div>
          )}
          <div className="modal-buttons">
            <Button
              className="marginBottom-0"
              color="danger"
              onClick={() => {
                props.modalAction();
              }}
            >
              {props.modalType === 'delete' && s('delete')}
              {props.modalType === 'release' && p('release')}
              {props.modalType === 'refund' && p('refound')}
            </Button>{' '}
            <Button
              className="marginBottom-0"
              color="secondary"
              onClick={() => {
                props.handleModal(false);
              }}
            >
              {s('close')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

DeleteModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  handleModal: PropTypes.func.isRequired,
  modalAction: PropTypes.func,
  modalType: PropTypes.string,
  removeWarning: PropTypes.string,
};

DeleteModal.defaultProps = {
  modalAction: null,
  modalType: 'delete',
  removeWarning: undefined,
};

export default DeleteModal;
