import React, { useEffect, useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Col, Row, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import validate from '../validators/teamValidate';
import renderField from '../../../shared/components/custom/Field';
import renderDropZoneField from '../../../shared/components/form/DropZone';
import { partial } from '../../../util/translation/translation';
import renderSelectField from '../../../shared/components/form/Select';
import Api from '../../../util/api';

const p = partial('settingsObjectsShared');
const s = partial('shared');

const TeamForm = ({ handleSubmit, team, initialize }) => {
  const [teams, setTeams] = useState([]);
  useEffect(() => {
    if (team) {
      initialize({
        ...team,
        logo: [
          {
            ...team.logo,
            preview: team.logo?.url,
          },
        ],
      });
    }
  }, [team]);

  const fetchTeams = async () => {
    try {
      const { data } = await Api.clubadmin.teams.fetchTeams();
      setTeams(data);
    } catch (e) {
      toast.error(e);
    }
  };

  useEffect(() => {
    fetchTeams();
  }, []);
  return (
    <form className="form" onSubmit={handleSubmit}>
      <Col md={6}>
        <Row>
          <Col md={12}>
            <div className="form__form-group">
              <span className="form-header">{p('selectTeam')}</span>
              <Field
                name="ntb_id"
                component={renderSelectField}
                placeholder={p('selectTeam')}
                options={teams.map((_team) => ({
                  label: _team.name,
                  value: _team.id,
                }))}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <div className="form__form-group">
              <span className="form-header">{s('abbreviation')}</span>
              <Field
                name="shortname"
                component={renderField}
                placeholder={p('shortNameOnTeam')}
              />
            </div>
          </Col>
          <Col md={12}>
            <div className="form__form-group">
              <span className="form-header">{s('name')}</span>
              <Field
                name="name"
                component={renderField}
                placeholder={p('fullNameOnTeam')}
              />
            </div>
          </Col>
        </Row>
      </Col>
      <Col md={6}>
        <div className="form__form-group">
          <span className="form-header">{p('clubPicture200px/200px')}</span>
          <Field name="logo" component={renderDropZoneField} />
        </div>
      </Col>
      <Col md={12}>
        <Button type="submit" color="primary">
          {team ? s('save') : s('create')}
        </Button>
        <Link className="color-unset" to="/settings?tab=1">
          <Button type="button">{s('close')}</Button>
        </Link>
      </Col>
    </form>
  );
};

const reduxF = reduxForm({
  form: 'create_or_update_team_form',
  validate,
});

export default reduxF(TeamForm);
