/* eslint-disable max-len */
import React from 'react';

export default function DragHandle(props) {
  return (
    <img
      src="/assets/files/questions/draggable-handle.svg"
      alt="drag-handle"
      {...props}
    />
  );
}

export function DragHandleSVGIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.33317 3.33333C6.33317 4.0697 5.7362 4.66667 4.99984 4.66667C4.26347 4.66667 3.6665 4.0697 3.6665 3.33333C3.6665 2.59695 4.26347 2 4.99984 2C5.7362 2 6.33317 2.59695 6.33317 3.33333ZM4.99984 9.33333C5.7362 9.33333 6.33317 8.73637 6.33317 8C6.33317 7.26363 5.7362 6.66667 4.99984 6.66667C4.26347 6.66667 3.6665 7.26363 3.6665 8C3.6665 8.73637 4.26347 9.33333 4.99984 9.33333ZM4.99984 14C5.7362 14 6.33317 13.403 6.33317 12.6667C6.33317 11.9303 5.7362 11.3333 4.99984 11.3333C4.26347 11.3333 3.6665 11.9303 3.6665 12.6667C3.6665 13.403 4.26347 14 4.99984 14Z"
        fill="#B8B8B8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3332 3.33333C12.3332 4.0697 11.7362 4.66667 10.9998 4.66667C10.2635 4.66667 9.6665 4.0697 9.6665 3.33333C9.6665 2.59695 10.2635 2 10.9998 2C11.7362 2 12.3332 2.59695 12.3332 3.33333ZM10.9998 9.33333C11.7362 9.33333 12.3332 8.73637 12.3332 8C12.3332 7.26363 11.7362 6.66667 10.9998 6.66667C10.2635 6.66667 9.6665 7.26363 9.6665 8C9.6665 8.73637 10.2635 9.33333 10.9998 9.33333ZM10.9998 14C11.7362 14 12.3332 13.403 12.3332 12.6667C12.3332 11.9303 11.7362 11.3333 10.9998 11.3333C10.2635 11.3333 9.6665 11.9303 9.6665 12.6667C9.6665 13.403 10.2635 14 10.9998 14Z"
        fill="#B8B8B8"
      />
    </svg>
  );
}
