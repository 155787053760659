export const optionsAvailable = (type) => {
  if (
    [
      'RADIO_BUTTONS',
      'CHECKBOX',
      'TEXT_INPUT',
      'SCORE_PREDICTOR',
      'DROPDOWN',
    ].includes(type)
  ) {
    return true;
  }
  return false;
};
export const questionValueToPayload = (questionValue) => {
  if (!Array.isArray(questionValue) || questionValue.length === 0) {
    throw new Error('Wrong values');
  }
  const { type } = questionValue[0];
  if (type === 'TEXT_INPUT') {
    return {
      title: '',
    };
  }
  if (type === 'PLAYER_PICKER') {
    return {
      teamId: questionValue[0].value,
    };
  }
  return {
    availableOptions: questionValue.map((item) => ({
      id: item.id,
      title: item.value,
    })),
  };
};

export const payloadToQuestionValue = (payload, type) => {
  if (type === 'TEXT_INPUT') {
    return [
      {
        id: 0,
        value: '',
      },
    ];
  }
  if (type === 'PLAYER_PICKER') {
    return [
      {
        id: 0,
        value: payload.teamId,
      },
    ];
  }
  return payload.availableOptions.map((item) => ({
    id: item.id,
    value: item.title,
  }));
};
