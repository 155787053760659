export default [
  {
    label: 'Empty',
    value:
      'https://storage.googleapis.com/image-service_prod/89f3896645c886bce8c4c5b8ce0d3e5fb9a7d08ac8f490ed26ee63011f1c483e',
  },
  {
    label: 'SDHL',
    value:
      'https://storage.googleapis.com/image-service_prod/02b77870154ef6326dc66e32530bf20d49db076ed3babda72c1cb59ec9bacf76',
  },
  {
    label: 'CHL',
    value:
      'https://storage.googleapis.com/image-service_prod/ee37bbb4a320911c69d66b0d845a15300b341b5b4798b9082f80abcc17317fa8',
  },
  {
    label: 'NDHL',
    value:
      'https://storage.googleapis.com/image-service_prod/2885db219d46bd258f1135fe6430e849ab24555832f8238290ca300c9a82bfaa',
  },
  {
    label: 'Bambusaligaen',
    value:
      'https://storage.googleapis.com/image-service_prod/357bf1aa1b890454031a6c017775d21d6cae29afac20a2ff5bbe5b6bd494ee18',
  },
  {
    label: 'SBL',
    value:
      'https://storage.googleapis.com/image-service_prod/6af57adf81a369cc1ed33a603ee658e7abc79e27bfcd9340e6170c38673207be',
  },
  {
    label: 'Allsvenskan fotboll',
    value:
      'https://storage.googleapis.com/image-service_prod/Allsvenskan_Logo.webp',
  },
  {
    label: 'SSL',
    value:
      'https://storage.googleapis.com/image-service_prod/SwedishSuperLeague.png',
  },
  {
    label: 'Svenska Cupen Fotboll',
    value:
      'https://storage.googleapis.com/image-service_prod/svenska_cupen.png',
  },
  {
    label: 'Svenska Cupen Basket',
    value:
      'https://storage.googleapis.com/image-service_prod/svenska_cupen_basket.jpg',
  },
  {
    label: 'Ettan fotboll',
    value:
      'https://storage.googleapis.com/image-service_prod/FotbollsEttan.png',
  },
  {
    label: 'Allsvenskan innebandy',
    value:
      'https://storage.googleapis.com/image-service_prod/InnebandyAllsvenskan.png',
  },
  {
    label: 'Svensk innebandy',
    value:
      'https://storage.googleapis.com/image-service_prod/SvenskInnebandy.png',
  },
  {
    label: 'Eliteserien Bandy',
    value:
      'https://storage.googleapis.com/image-service_prod/leagueLogos/EliteserienBandy.png',
  },
  {
    label: 'Europa league',
    value:
      'https://storage.googleapis.com/image-service_prod/leagueLogos/EuropaLeague.png',
  },
  {
    label: 'Norgesmesterskapet',
    value:
      'https://storage.googleapis.com/image-service_prod/leagueLogos/NMFotball.png',
  },
].sort((a, b) => {
  if (a.label < b.label) {
    return -1;
  }
  if (a.label > b.label) {
    return 1;
  }
  return 0;
});
