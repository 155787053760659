import React from 'react';
import { useRecoilState } from 'recoil';
import axios from 'axios';
import { ConfigState } from '../recoil/config/selector';
// import Api from './api';
import _confiig from '../config';

const useConfig = () => {
  const [config, setConfig] = useRecoilState(ConfigState);

  React.useEffect(() => {
    if (!window.location.pathname.startsWith(config?.basename)) {
      // eslint-disable-next-line no-unused-vars
      const club = window.location.pathname.split('/')[1];
      // eslint-disable-next-line no-unused-vars
      const fetchConfig = async (_club) => {
        const adjustedClub = _club === 'stj' ? 'stjernen' : _club;
        const base =
          _club === 'tfg' ? 'api-dev.fangroup.io' : 'api.fangroup.io';
        const url = `https://club.${base}/club/teamConfig`;
        const { data } = await axios.get(url, {
          headers: {
            'fep-context-name': adjustedClub,
          },
        });
        if (data) {
          setConfig({
            ...data,
            ntb_team_id: data.id,
            staging_url: `https://${adjustedClub}.api${adjustedClub === 'tfg' ? '-dev' : ''}.fangroup.io`,
            prod_url: `https://${adjustedClub}.api${adjustedClub === 'tfg' ? '-dev' : ''}.fangroup.io`,
            api: '/api',
            basename: `/${_club}`,
            currencyCaps: data.currency.toUpperCase(),
            currencyShort: data.currency,
            currencyShortSmall: data.currency,
          });
          localStorage.setItem(`ntb_team_id-${_club}`, data.id);
          localStorage.setItem(`app_name-${_club}`, adjustedClub);
        }
      };
      fetchConfig(club);
    }
  }, [window.location.pathname, config]);
  return config || _confiig;
};

export default useConfig;
