import { SET_CREATE_OR_EDIT_FIELD_TYPE } from '../actions/fieldTypeActions';

const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CREATE_OR_EDIT_FIELD_TYPE:
      return action.fieldType;
    default:
      return state;
  }
}
