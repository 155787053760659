import React from 'react';

const FormBox = ({ children, title, style }) => {
  return (
    <div className="form-box" style={{ ...style }}>
      {title && (
        <div className="form-box-title">
          <strong>{title}</strong>
        </div>
      )}
      {children}
    </div>
  );
};

export default FormBox;
