import React, { useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  TabPane,
  TabContent,
} from 'reactstrap';
import { useParams, useHistory } from 'react-router';
import moment from 'moment';
import { toast } from 'react-toastify';
import { EditorState } from 'draft-js';
import LotteryForm from './components/LotteryForm';
import Loading from '../../shared/components/custom/Loading';
import Api from '../../util/api';
import { convertImageToBlob } from '../../util/image-functions';
import NavTab from '../../shared/components/custom/NavTab';
import { partial } from '../../util/translation/translation';
import LotteryListDraw from './components/LotteryListDraw';
import LotteryStats from './components/LotteryStats';
import LotteryPrizesForm from './components/LotteryPrizesForm';
import { formatDateForApi } from '../../util/date-and-time';

const LotteryFormPage = () => {
  const { lotteryID } = useParams();
  const history = useHistory();
  const [vendors, setVendors] = useState([]);
  const [loadingLottery, setLoadingLottery] = useState(!!lotteryID);
  const [tab, setTab] = useState('0');
  const [lottery, setLottery] = useState(null);
  const [prizes, setPrizes] = useState([]);
  const [sponsors, setSponsors] = useState([]);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const s = partial('shared');
  const p = partial('LotteryFormPage');
  const optionsMap = (data) => ({
    ...data,
    value: data.id.toString(),
    label: data.name,
  });

  const getInitialData = async () => {
    setLoadingLottery(true);
    try {
      const [resLottery, resVendors, resCompanies] = await Promise.all([
        lotteryID && Api.lottery.show(lotteryID),
        Api.teams.allVendors(),
        Api.companies.getCompaniesForClub(),
      ]);
      setVendors(resVendors.data);
      const mappedSponsors = resCompanies.map(optionsMap);
      setSponsors(mappedSponsors);
      if (lotteryID) {
        setLottery(resLottery.data);
        setPrizes(
          resLottery.data.prizes
            ?.map((prize) => ({
              ...prize,
              name: prize.name,
              value: prize.value,
              order: prize.order,
              url: prize.imageUrl,
              sponsorID: mappedSponsors.find(
                (sponsor) => sponsor.id === prize.company?.id
              ),
            }))
            .sort((a, b) => a.order - b.order) || []
        );
      }
    } catch (err) {
      toast.error(err);
    }
    setLoadingLottery(false);
  };

  useEffect(() => {
    getInitialData();
  }, [lotteryID]);

  const deletePrize = (pIndex) => async () => {
    try {
      await Api.lottery.deletePrize(lotteryID, pIndex.id);
      setPrizes(prizes.filter((prize) => prize !== pIndex));
    } catch (err) {
      toast.error(err);
    }
  };
  const createPrizes = async (values) => {
    const img = values.prizeImage
      ? await convertImageToBlob(values.prizeImage.file)
      : undefined;
    const payload = {
      name: values.prizeName,
      value: +values.prizeValue,
      image: img,
      order: prizes.length,
      companyName: values.sponsorID?.name,
      companyImage: values.sponsorID?.imagesUrls.sqNormal,
      companyId: values.sponsorID?.id,
    };
    setLoadingLottery(true);
    try {
      const res = await Api.lottery.update(
        {
          ...lottery,
          image: lottery.imageUrl,
          prizes: [
            ...prizes
              .filter((_p) => values.id !== _p.id)
              .map((pr) => ({
                ...pr,
                image: pr.imageUrl,
                ...(values.sponsorID
                  ? {
                      companyName: values.sponsorID.name,
                      companyImage: values.sponsorID.imagesUrls.sqNormal,
                      companyId: values.sponsorID.id,
                    }
                  : {}),
              })),
            payload,
          ],
        },
        lotteryID
      );
      setPrizes(res.data.prizes.map((_p) => ({ ..._p, url: _p.imageUrl })));
      setLottery(res.data);
    } catch (e) {
      toast.error(e);
    } finally {
      setLoadingLottery(false);
    }
  };
  const onSubmitLottery = async (values) => {
    const payload = {
      name: values.name,
      teamId: values.teamId.value,
      vendorId:
        values.vendor.length > 0
          ? values.vendor.reduce(
              (t, e) => ({
                ...t,
                [e.value.paymentMethod]: e.value.id,
              }),
              {}
            )
          : undefined,
      saleStart: formatDateForApi(values.startDate, true),
      saleEnd: formatDateForApi(values.endDate, true),
      price: values.price,
      prizes:
        values?.prizes?.map((pr) => ({
          ...pr,
          image: pr.imageUrl,
          ...(pr.sponsorID
            ? {
                companyName: pr.sponsorID.name,
                companyImage: pr.sponsorID.imagesUrls.sqNormal,
                companyId: pr.sponsorID.id,
              }
            : {}),
        })) || [],
      image: values.image ? values.image[0].value : undefined,
      description: values.description,
      push: values.push,
      winningDescription: values.winningDescription,
    };
    setLoadingLottery(true);
    try {
      if (lotteryID) {
        await Api.lottery.update(payload, lotteryID);
        toast.success('Lottery updated');
      } else {
        const newLottery = await Api.lottery.create(payload);
        toast.success('Lottery created');
        history.replace(`/lottery/edit/${newLottery.data.id}`);
      }
    } catch (err) {
      toast.error(err || s('somethingWentWrong'));
    } finally {
      setLoadingLottery(false);
    }
  };

  return (
    <Container>
      <Loading loading={loadingLottery} />
      <Row>
        <Col>
          <h3 className="page-title">
            {lotteryID ? lottery?.name : `${s('create')} ${p('lottery')}`}
          </h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <NavTab
                tabs={[
                  {
                    tab: p('general'),
                    disabled: lottery && moment().isAfter(lottery.saleEnd),
                  },
                  { tab: p('stats'), disabled: !lotteryID },
                  { tab: p('draw'), disabled: !lotteryID },
                ]}
                setTab={setTab}
                activeTab={tab}
              />
              <TabContent activeTab={tab}>
                <TabPane tabId="1">
                  <LotteryForm
                    vendors={vendors}
                    onSubmit={onSubmitLottery}
                    lottery={lottery}
                    editorState={editorState}
                    setEditorState={setEditorState}
                  />
                  <LotteryPrizesForm
                    setPrizes={setPrizes}
                    sponsors={sponsors}
                    lotteryID={lotteryID}
                    prizes={prizes}
                    deletePrize={deletePrize}
                    onSubmit={createPrizes}
                  />
                </TabPane>
                <TabPane tabId="2">
                  {lottery && (
                    <LotteryStats lotteryID={lotteryID} lottery={lottery} />
                  )}
                </TabPane>
                <TabPane tabId="3">
                  {lottery && (
                    <LotteryListDraw lotteryID={lotteryID} lottery={lottery} />
                  )}
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default LotteryFormPage;
