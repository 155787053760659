import React from 'react';
import { Button, UncontrolledTooltip } from 'reactstrap';
import PropTypes from 'prop-types';
import EditOutlineIcon from 'mdi-react/EditOutlineIcon';
import ContentCopyIcon from 'mdi-react/ContentCopyIcon';
import DeleteOutlineIcon from 'mdi-react/DeleteOutlineIcon';
import UserAddOutlineIcon from 'mdi-react/UserAddOutlineIcon';
import ImageAlbumIcon from 'mdi-react/ImageOutlineIcon';
import PinIcon from 'mdi-react/PinOutlineIcon';
import PinOffIcon from 'mdi-react/PinOffIcon';
import { partial } from '../../../util/translation/translation';

const s = partial('shared');
const o = partial('OffersFormPage');

export const IconButtonType = {
  EDIT: 'EDIT',
  CLONE: 'CLONE',
  DELETE: 'DELETE',
  ADD_USER: 'ADD_USER',
  IMAGE: 'IMAGE',
  PIN_ON: 'PIN_ON',
  PIN_OFF: 'PIN_OFF',
};
const CustomIcon = ({ type }) => {
  switch (type) {
    case IconButtonType.EDIT:
      return <EditOutlineIcon />;
    case IconButtonType.CLONE:
      return <ContentCopyIcon />;
    case IconButtonType.DELETE:
      return <DeleteOutlineIcon />;
    case IconButtonType.ADD_USER:
      return <UserAddOutlineIcon />;
    case IconButtonType.IMAGE:
      return <ImageAlbumIcon />;
    case IconButtonType.PIN_ON:
      return <PinIcon />;
    case IconButtonType.PIN_OFF:
      return <PinOffIcon />;
    default:
      return null;
  }
};
const getTitle = (type) => {
  switch (type) {
    case IconButtonType.EDIT:
      return s('edit');
    case IconButtonType.CLONE:
      return s('clone');
    case IconButtonType.DELETE:
      return s('delete');
    case IconButtonType.PIN_ON:
      return o('pinOffer');
    case IconButtonType.PIN_OFF:
      return o('removePin');
    default:
      return '';
  }
};
const IconButton = ({ onClick, type, title, id, disabled }) => {
  const buttonTitle = title || getTitle(type);
  return (
    <Button outline id={id} onClick={onClick} disabled={disabled}>
      <span>
        <CustomIcon type={type} />
        <UncontrolledTooltip placement="bottom" target={id}>
          {buttonTitle}
        </UncontrolledTooltip>
      </span>
    </Button>
  );
};

IconButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  type: PropTypes.oneOf(Object.keys(IconButtonType)).isRequired,
  title: PropTypes.string,
};

IconButton.defaultProps = {
  title: null,
};
IconButton.types = IconButtonType;
export default IconButton;
