import React, { Component } from 'react';
import {
  Card,
  CardBody,
  Col,
  Table,
  ButtonToolbar,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from 'reactstrap';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import DotsHorizontalIcon from 'mdi-react/DotsHorizontalIcon';
import Pagination from '../../../shared/components/pagination/Pagination';
import DeleteModal from '../../../shared/components/custom/DeleteModal';
import Api from '../../../util/api';
import Loading from '../../../shared/components/custom/Loading';
import SortDirection from '../../../shared/components/custom/SortDirection';
import { partial } from '../../../util/translation/translation';

const p = partial('KioskList');
const s = partial('shared');
class KioskList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      order: 'name',
      reverse: 0,
      modalVisible: false,
      deleteKiosksID: null,
      loading: false,
      kiosks: { data: [], per_page: 10 },
    };
  }

  onChangePage = (pager) => {
    if (
      pager.pageSize !== this.state.kiosks.per_page ||
      pager.currentPage !== this.state.kiosks.current_page
    ) {
      this.getKiosks(pager.currentPage, pager.pageSize);
    }
  };

  getKiosks = (
    page = this.state.kiosks.current_page,
    pageSize = this.state.kiosks.per_page
  ) => {
    this.setState({ loading: true }, () => {
      Api.cnc
        .getKiosks(
          this.state.order,
          this.state.reverse,
          this.state.searchText,
          page,
          pageSize
        )
        .then((res) => {
          this.setState({ kiosks: res.data, loading: false });
        })
        .catch((err) => {
          this.setState({ loading: false });
          toast.error(err || `${p('kioskFetchingERROR')}`);
        });
    });
  };

  handleModal = (state, id) => {
    this.setState({ modalVisible: state, deleteKiosksID: id });
  };

  handleSort = (order) => {
    const reverse =
      this.state.order === order
        ? Number(!this.state.reverse)
        : Number(this.state.reverse);
    this.setState({ order, reverse }, () => {
      this.getKiosks();
    });
  };

  updateStatus = (kioskId) => {
    this.setState({ loading: true }, async () => {
      try {
        await Api.cnc.updateKioskStatus(kioskId);
        this.getKiosks(this.state.kiosks.current_page);
      } catch (err) {
        toast.error(err);
        this.getKiosks(this.state.kiosks.current_page);
      }
    });
  };

  listKiosks = () => {
    return this.state.kiosks.data.map((kiosk) => {
      return (
        <tr className="center-list" key={`kiosks-${kiosk.id}`}>
          <td>
            {kiosk.image ? (
              <img className="kiosk-logo" src={kiosk.image.url} alt="" />
            ) : (
              '-'
            )}
          </td>
          <td>
            <Link to={`/cnc/edit/${kiosk.id}`}>{kiosk.name}</Link>
          </td>
          <td>{kiosk.tax}%</td>
          <td>
            <button
              className={
                kiosk.open ? 'outline-badge success' : 'outline-badge danger'
              }
              onClick={() => this.updateStatus(kiosk.id)}
            >
              {kiosk.open ? `${s('isOpen')}` : `${s('closed')}`}
            </button>
          </td>
          <td>{kiosk.products_count}</td>
          <td>
            <UncontrolledDropdown className="dashboard__table-more">
              <DropdownToggle>
                <DotsHorizontalIcon />
              </DropdownToggle>
              <DropdownMenu className="dropdown__menu">
                <Link to={`/cnc/edit/${kiosk.id}`}>
                  <DropdownItem>{s('edit')}</DropdownItem>
                </Link>
                <DropdownItem
                  onClick={() => {
                    this.handleModal(true, kiosk.id);
                  }}
                  className="danger"
                >
                  {s('delete')}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </td>
        </tr>
      );
    });
  };

  handleInput = (e) => {
    this.setState({ searchText: e.target.value });
  };

  handleSearch = (e) => {
    if (e.key === 'Enter') {
      this.getKiosks();
    }
  };

  deleteKiosks = () => {
    this.setState({ loading: true }, () => {
      Api.cnc
        .deleteKiosks(this.state.deleteKiosksID)
        .then(() => {
          this.setState({ modalVisible: false, deleteKiosksID: null });
          this.getKiosks();
        })
        .catch((err) => {
          this.setState({ loading: false });
          toast.error(err || `${p('kioskDeletingERROR')}`);
        });
    });
  };

  render() {
    const { order, reverse } = this.state;
    return (
      <Col md={12} lg={12}>
        <Loading loading={this.state.loading} />
        <DeleteModal
          type={p('theKioskSmall')}
          visible={this.state.modalVisible}
          handleModal={this.handleModal}
          modalAction={this.deleteKiosks}
        />
        <Card>
          <CardBody>
            <div className="card__title marginTop-0">
              <h5 className="bold-text">{s('kiosks')}</h5>
              <ButtonToolbar className="margin-top-0 float-right">
                <div className="form">
                  <div className="form__form-group products-list__search">
                    <input
                      placeholder="Search..."
                      value={this.state.searchText}
                      onKeyPress={this.handleSearch}
                      onChange={this.handleInput}
                      name="search"
                    />
                    <MagnifyIcon />
                  </div>
                </div>
                <Link className="btn btn-primary btn-sm" to="/cnc/create">
                  {p('createKiosk')}
                </Link>
              </ButtonToolbar>
            </div>

            <Table responsive bordered>
              <thead>
                <tr>
                  <th
                    className="sortable"
                    onClick={() => this.handleSort('image_id')}
                  >
                    {s('image')}
                    <SortDirection
                      active={order === 'image_id'}
                      direction={reverse}
                    />
                  </th>
                  <th
                    className="sortable"
                    onClick={() => this.handleSort('name')}
                  >
                    {s('name')}
                    <SortDirection
                      active={order === 'name'}
                      direction={reverse}
                    />
                  </th>
                  <th
                    className="sortable"
                    onClick={() => this.handleSort('tax')}
                  >
                    {s('taxCaps')}
                    <SortDirection
                      active={order === 'tax'}
                      direction={reverse}
                    />
                  </th>
                  <th
                    className="sortable"
                    onClick={() => this.handleSort('open')}
                  >
                    {p('openForSale')}
                    <SortDirection
                      active={order === 'open'}
                      direction={reverse}
                    />
                  </th>
                  <th
                    className="sortable"
                    onClick={() => this.handleSort('products_count')}
                  >
                    {s('products')}
                    <SortDirection
                      active={order === 'products_count'}
                      direction={reverse}
                    />
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>{this.listKiosks()}</tbody>
            </Table>
            <Pagination
              items={this.state.kiosks.total || 0}
              pageSize={10}
              onChangePage={this.onChangePage}
            />
          </CardBody>
        </Card>
      </Col>
    );
  }
}

export default KioskList;
