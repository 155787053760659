import React, { useCallback, useContext } from 'react';
import ReactDOMServer from 'react-dom/server';
import { Model, SvgRegistry } from 'survey-core';
import { Survey } from 'survey-react-ui';
import { toast } from 'react-toastify';
import useConfig from '../../../util/useConfig';
import Api from '../../../util/api';
import { EventContext } from '../context';

import { DragHandleSVGIcon } from './QuestionnaireBuilder/DragHandle';
import s, { partial } from '../../../util/translation/translation';

const DRAG_ICON = ReactDOMServer.renderToString(<DragHandleSVGIcon />);

const surveyJson = {
  logoPosition: 'right',
  pages: [
    {
      name: 'page1',
      elements: [
        {
          type: 'paneldynamic',
          name: 'question',
          titleLocation: 'hidden',
          templateElements: [
            {
              type: 'dropdown',
              name: 'question_type',
              title: s('question.type'),
              titleLocation: 'top',
              isRequired: true,
              choices: [
                {
                  value: 'text_field',
                  text: s('question.textInput'),
                },
                {
                  value: 'radio_button',
                  text: s('question.radioGroup'),
                },
                {
                  value: 'checkbox',
                  text: s('question.checkbox'),
                },
                {
                  value: 'dropdown',
                  text: s('question.dropdown'),
                },
              ],
              placeholder: s('question.selectType'),
            },
            {
              type: 'text',
              name: 'question',
              title: s('question.question'),
              titleLocation: 'top',
              isRequired: true,
              placeholder: s('question.enterQuestion'),
            },
            {
              type: 'matrixdynamic',
              name: 'choices',
              visibleIf:
                "{panel.question_type} = 'radio_button' or {panel.question_type} = 'checkbox' or {question[0].question_type} = 'dropdown'",
              title: 'Options',
              titleLocation: 'top',
              isRequired: true,
              showHeader: false,
              columns: [
                {
                  name: 'choice',
                  title: 'choice',
                },
              ],
              choices: [1, 2, 3, 4, 5],
              placeholder: 'Type option here...',
              cellType: 'text',
              rowCount: 1,
              addRowText: 'Add option',
              allowRowsDragAndDrop: true,
            },
          ],
          allowAddPanel: false,
          allowRemovePanel: false,
          panelCount: 1,
          panelAddText: 'Add new question',
        },
      ],
    },
  ],
  showTitle: false,
  completeText: 'Add Question',
  widthMode: 'responsive',
};

SvgRegistry.registerIconFromSvg('v2dragelement_16x16', DRAG_ICON);

const FormBuilder = ({ setShowModal, editableQuestion }) => {
  const isEditMode = !!editableQuestion;

  const qT = partial('question');

  if (isEditMode) {
    surveyJson.completeText = qT('edit');
  } else {
    surveyJson.completeText = qT('add');
  }

  const survey = new Model(surveyJson);
  const { id } = useConfig();
  const {
    questions,
    pickedQuestions,
    setPickedQuestions,
    eventId,
    fetchQuestions,
    pickedQuestionId,
  } = useContext(EventContext);

  const createSharedQuestion = useCallback(async (sender) => {
    try {
      const foundSharedQuestion = questions.find(
        (q) =>
          (q.question?.question).toLowerCase() ===
          sender.data.question[0].question.toLowerCase()
      );
      if (foundSharedQuestion) {
        toast.error(qT('simillarQuestionsAvailableInLibrary'));
      } else {
        await Api.questions.createSharedQuestion(
          {
            domain: 'event',
            question: sender.data.question[0],
            domainId: eventId,
          },
          id
        );
        const { data } = await Api.questions.fetchDomainSpecificQuestions(
          eventId,
          id
        );
        setPickedQuestions(data.question);
        fetchQuestions();
        toast.success(qT('questionsAdded'));
      }
    } catch (error) {
      toast.error(error);
    }
  }, []);

  const updateQuestions = useCallback(
    async (sender, questionType = 'shared') => {
      const updateQuestion = sender.data.question[0];
      try {
        if (questionType === 'domain') {
          const currentPickedQuestions = [...pickedQuestions];
          const index = currentPickedQuestions.findIndex(
            (question) => question.id === editableQuestion.id
          );
          if (index !== -1) {
            currentPickedQuestions[index] = updateQuestion;
          }
          await Api.questions.updateQuestion(
            pickedQuestionId,
            {
              type: questionType,
              question: currentPickedQuestions,
            },
            id
          );
          const { data } = await Api.questions.fetchDomainSpecificQuestions(
            eventId,
            id
          );
          setPickedQuestions(data.question);
        } else {
          await Api.questions.updateQuestion(
            editableQuestion.id,
            {
              type: questionType,
              question: updateQuestion,
              domain: 'event',
            },
            id
          );
          fetchQuestions();
        }
        toast.success(qT('questionsUpdated'));
      } catch (error) {
        toast.error(error);
      }
    },
    []
  );

  survey.onComplete.add(async (sender) => {
    setShowModal();
    if (isEditMode) {
      updateQuestions(survey, editableQuestion?.type);
    } else {
      createSharedQuestion(sender);
    }
  });

  if (isEditMode) {
    survey.data = { question: [editableQuestion.question] };
  }

  return <Survey model={survey} />;
};

export default FormBuilder;
