import React, { useState } from 'react';
import PropTypes from 'prop-types';
import minusIcon from '../../../shared/img/icons/minus-icon.svg';
import plusIcon from '../../../shared/img/icons/plus-icon.svg';
import Checkbox from '../../../shared/components/custom/Checkbox';
import CheckboxIcon from '../../../shared/img/icons/checkmark-icon.svg';
import { partial } from '../../../util/translation/translation';

const p = partial('box_officeShare');
const s = partial('shared');

const TicketSelector = (props) => {
  const [scatteredSeats, setScatteredSeats] = useState(false);
  const [autoSeats, setAutoSeats] = useState(false);

  const priceGroupSelector = () => {
    return props.priceGroups.map((priceGroup, index) => {
      return (
        <div
          key={priceGroup.id}
          className={
            priceGroup.amount
              ? 'selected selector amount-selector'
              : 'selector amount-selector'
          }
        >
          <div>
            <p
              className="title"
              style={{
                maxWidth: '150px',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              {priceGroup.title}
            </p>
          </div>
          <div className="center" style={{ minWidth: '48px' }}>
            <button
              onClick={() => props.handlePriceGroups(index, -1)}
              value="-1"
              name={priceGroup.id}
              className="no-button-style"
            >
              <img src={minusIcon} alt="Minus icon" />
            </button>
            <p className="amount">{priceGroup.amount}</p>
            <button
              onClick={() => props.handlePriceGroups(index, 1)}
              className="no-button-style"
            >
              <img src={plusIcon} alt="Minus icon" />
            </button>
          </div>
        </div>
      );
    });
  };

  const fieldSelector = () => {
    return props.fields.map((field, index) => {
      return (
        <button
          disabled={field.available === 0 || field.available === undefined}
          onClick={() => props.handleField(index)}
          key={`FieldSelector-${field.id}`}
          style={{ borderLeft: `10px solid ${field.field_type.colour}` }}
          className={
            props.selectedField &&
            props.selectedField.id === props.fields[index].id
              ? 'selected selector field-selector no-button-style'
              : 'selector field-selector no-button-style'
          }
        >
          <p>{field.name}</p>
          <div>
            <p>
              {field.available ? field.available : 0} {p('available2Small')}
            </p>
          </div>
        </button>
      );
    });
  };

  const rowSelector = () => {
    const total = props.getPriceGroupsAmount();
    return props.rows.map((row, index) => {
      return (
        <button
          disabled={row.available_seats === 0 || row.available_seats < total}
          key={`RowSelector-${row.id}`}
          onClick={() => props.handleRow(index, autoSeats)}
          className={
            props.selectedRow && props.selectedRow.id === props.rows[index].id
              ? 'selector selected no-button-style'
              : 'selector no-button-style'
          }
        >
          <div>
            <p>
              {s('row')} {row.number}
            </p>
          </div>
          <div>
            <p>
              {row.available_seats}{' '}
              {row.available_seats !== 1
                ? `${p('available2Small')}`
                : `${p('availableSmall')}`}
            </p>
          </div>
        </button>
      );
    });
  };

  const seatSelector = () => {
    return props.seats.map((seat, index) => {
      return (
        <button
          disabled={props.tickets === 0 && !seat.active}
          onClick={() =>
            props.handleSeats(index, !!seat.active, scatteredSeats, autoSeats)
          }
          key={`SeatSelector-${seat.seats_id}`}
          style={
            index > 0 &&
            seat.seats_seat_id !== props.seats[index - 1].seats_seat_id + 1
              ? { borderTop: '5px solid #eeeeee' }
              : { borderRadius: 0 }
          }
          className={`selector seat-selector no-button-style ${seat.active && 'selected'}`}
        >
          <div>
            <p>Sete {seat.seats_seat_id}</p>
          </div>
          {seat.active ? (
            <img className="checkbox" src={CheckboxIcon} alt="Checkbox" />
          ) : (
            <div className="circle" />
          )}
        </button>
      );
    });
  };

  return (
    <div className="ticket-selector">
      <div className="card">
        <div className="header">
          <h1>{p('selectNumber')}</h1>
          <div className="ticket-selector-header-checkbox">
            <p>{p('scatteredPlacement')}</p>
            <Checkbox
              single
              toggled={scatteredSeats}
              handleToggle={(index, state) => setScatteredSeats(state)}
              index={1}
            />
          </div>
        </div>
        <div className="scroll">{priceGroupSelector()}</div>
      </div>
      <div className="card">
        <div className="header">
          <h1>{p('selectField')}</h1>
          <div className="ticket-selector-header-checkbox">
            <p>{p('autoSelectSeats')}</p>
            <Checkbox
              single
              toggled={autoSeats}
              handleToggle={(index, state) => setAutoSeats(state)}
              index={1}
            />
          </div>
        </div>

        <div className="scroll">{fieldSelector()}</div>
      </div>
      <div className="card">
        <div className="header">
          <h1>{p('selectRow')}</h1>
        </div>
        <div className="scroll">
          {props.selectedField &&
          props.selectedField.field_type.name === 'unmarked' ? (
            <div className="header">
              <h5>{p('rowIsAuto')}</h5>
            </div>
          ) : (
            <>{rowSelector()}</>
          )}
        </div>
      </div>
      <div className="card">
        <div className="header">
          <h1>{p('selectSeat')}</h1>
        </div>
        <div className="scroll">
          {props.selectedField &&
          props.selectedField.field_type.name === 'unmarked' ? (
            <div className="header">
              <h5>{p('seatIsAuto')}</h5>
            </div>
          ) : (
            <>{seatSelector()}</>
          )}
        </div>
      </div>
    </div>
  );
};

TicketSelector.propTypes = {
  priceGroups: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handlePriceGroups: PropTypes.func.isRequired,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ).isRequired,
  handleField: PropTypes.func.isRequired,
  selectedField: PropTypes.shape({
    id: PropTypes.number,
    field_type: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ).isRequired,
  handleRow: PropTypes.func.isRequired,
  selectedRow: PropTypes.shape({
    id: PropTypes.number,
  }),
  seats: PropTypes.arrayOf(
    PropTypes.shape({
      seats_seat_id: PropTypes.number,
    })
  ).isRequired,
  handleSeats: PropTypes.func.isRequired,
  tickets: PropTypes.number.isRequired,
  getPriceGroupsAmount: PropTypes.func.isRequired,
};

TicketSelector.defaultProps = {
  selectedField: null,
  selectedRow: null,
};

export default TicketSelector;
