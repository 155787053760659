import React from 'react';
import moment from 'moment';
import { partial } from '../../../util/translation/translation';

const s = partial('shared');

const AppNewsCardPreview = ({ data, style, size }) => {
  if (size === 'sm') {
    return (
      <div
        style={{
          display: 'inline-block',
          margin: '0 10px 0 0',
          background: '#fff',
          width: 200,
          marginBottom: 20,
          borderRadius: 8,
          boxShadow: '0px 3px 57px -12px rgba(0, 0, 0, 0.29)',
          ...style,
        }}
      >
        <img
          style={{
            minHeight: 110,
            background: 'rgba(0, 0, 0, 0.29)',
            borderTopRightRadius: 8,
            borderTopLeftRadius: 8,
          }}
          src={data?.headingImage?.croppedImage}
          alt=""
        />
        <div className="p-2">
          <strong className="bold mb-1" style={{ color: '#000' }}>
            {data?.title || s('heading')}
          </strong>
          <p className="mt-1" style={{ opacity: 0.7 }}>
            {moment(data?.publicationDate).fromNow()}
          </p>
        </div>
      </div>
    );
  }
  return (
    <div
      style={{
        background: '#fff',
        width: 325,
        marginBottom: 20,
        borderRadius: 8,
        boxShadow: '0px 2px 8px 0px rgba(0,0,0,0.08)',
        ...style,
      }}
    >
      <img
        style={{
          minHeight: 180,
          background: 'rgba(0, 0, 0, 0.29)',
          borderTopRightRadius: 8,
          borderTopLeftRadius: 8,
        }}
        src={data?.headingImage?.croppedImage}
        alt=""
      />
      <div className="p-2">
        <h4 className="bold mb-1" style={{ color: '#000' }}>
          {data?.title || s('heading')}
        </h4>
        <p className="mt-0" style={{ opacity: 0.7 }}>
          {data?.description?.substring(0, 100) || s('description')}{' '}
          {data?.description?.length > 100 ? '...' : ''}
        </p>
        <p className="mt-1" style={{ opacity: 0.7 }}>
          {moment(data?.publicationDate).fromNow()}
        </p>
      </div>
    </div>
  );
};

export default AppNewsCardPreview;
