import t, { partial } from '../../../util/translation/translation';

const p = partial('validateErrors');
const validateNewsContact = (values) => {
  const errors = {};
  if (values?.type === 'push' && !values.title) {
    errors.title = `${p('titleFieldCantBeEmpty')}!`;
  }

  if (!values.message) {
    errors.message = `${p('messageFieldCantBeEmpty')}!`;
  }

  if (
    values?.timeToSend === 'SCHEDULED' &&
    (!values?.scheduleTime || new Date(values?.scheduleTime) < Date.now())
  ) {
    errors.scheduleTime = `${t('ScheduledMessagesPage.selectValidDate')}`;
  }

  return errors;
};

export default validateNewsContact;
