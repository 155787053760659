import React, { useState } from 'react';
import { TabContent, TabPane } from 'reactstrap';
import NavTab from './NavTab';

const EasyTabs = ({ children, tabnames }) => {
  const [activeTab, setActiveTab] = useState('1');
  return (
    <>
      <NavTab activeTab={activeTab} setTab={setActiveTab} tabs={tabnames} />
      <TabContent activeTab={activeTab}>
        {(Array.isArray(children) ? children : [children]).map((tab, index) => (
          <TabPane tabId={`${index + 1}`}>{tab}</TabPane>
        ))}
      </TabContent>
    </>
  );
};

export default EasyTabs;
