import React from 'react';
import { Col } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import renderField from '../../../shared/components/custom/Field';
import renderCheckBoxField from '../../../shared/components/form/CheckBox';
import { partial } from '../../../util/translation/translation';
import useConfig from '../../../util/useConfig';
// import config from '../../../config';

const renderPriceGroupsField = ({ fields, priceGroups }) => {
  const config = useConfig();
  const s = partial('shared');
  return (
    <>
      {fields.map((priceGroup, index) => (
        <Col key={`renderPriceGroups${priceGroup}`} lg={4} md={6} sm={12}>
          <div className="form__form-group">
            <div className="space-between">
              <span className="form__form-group-label">
                {priceGroups[index].title}
              </span>
              <Field
                name={`${priceGroup}.buyable`}
                component={renderCheckBoxField}
                disableDefault
                type="checkbox"
                className="colored-click"
              />
            </div>
            <div className="form__form-group-field">
              <Field
                name={`${priceGroup}.price`}
                component={renderField}
                placeholder={`${s('packagePrice')} (${priceGroups[index].percentage})`}
                type="number"
              />
              <div className="form__form-group-icon">
                <p>{config.currencyShortSmall}</p>
              </div>
            </div>
          </div>
        </Col>
      ))}
    </>
  );
};

renderPriceGroupsField.propTypes = {
  fields: PropTypes.shape({}).isRequired,
  priceGroups: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      percentage: PropTypes.string,
    })
  ).isRequired,
};

const reduxF = reduxForm({
  form: 'packagesForm',
});

export default reduxF(renderPriceGroupsField);
