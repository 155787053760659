import React, { useState, useEffect } from 'react';
import {
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Table,
  Badge,
  UncontrolledTooltip,
  Row,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes, { any } from 'prop-types';
import CheckboxMarkedCircle from 'mdi-react/CheckboxMarkedCircleIcon';
import CloseCircle from 'mdi-react/CloseCircleIcon';
import DotsHorizontalIcon from 'mdi-react/DotsHorizontalIcon';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import AlertIcon from 'mdi-react/AlertIcon';
import Select from 'react-select';
import ArrowDownIcon from 'mdi-react/ArrowDownIcon';
import ArrowUpIcon from 'mdi-react/ArrowUpIcon';
import Checkbox from '../../../../../shared/components/custom/Checkbox';
import Pagination from '../../../../../shared/components/pagination/Pagination';
import { partial } from '../../../../../util/translation/translation';

const MatchTicketsList = ({
  tickets,
  fields,
  rows,
  selectedField,
  selectedRow,
  selectedMatch,
  handleField,
  handleRow,
  handleMatch,
  searchText,
  handleInput,
  handleSearch,
  handleReleaseTicketsModal,
  handleShareTicketsModal,
  onChangePage,
  handleToggle,
  pastMatches,
  futureMatches,
  setEditUsersTickets,
  editUsersTickets,
  matchPrices,
  ticketStates,
  filter,
  setFilter,
  options,
  updateSort,
}) => {
  const [masterToggled, setMasterToggled] = useState(false);
  const [shareableTickets, setShareableTickets] = useState(false);
  const [ticketsAreShared, setTicketsAreShared] = useState(false);
  const p = partial('MatchTicketsList');
  const s = partial('shared');
  const t = partial('TicketStates');

  useEffect(() => {
    const toggled = tickets.data.filter((ticket) => ticket.toggled);
    setMasterToggled(tickets.data.every((ticket) => ticket.toggled));

    setShareableTickets(
      toggled.every((ticket) => !ticket.user_id && !ticket.reservation_id) &&
        toggled.length > 0
    );
    setTicketsAreShared(
      toggled.filter((ticket) => !!ticket.user_id || ticket.reservation_id)
        .length > 0
    );
    setEditUsersTickets(
      toggled.every(
        (ticket) => ticket.user_id !== null && !ticket.reservation_id
      ) && toggled.length > 0
    );
  }, [tickets]);

  const translatePayment = (ticket) => {
    if (ticket.season_ticket_id) {
      return p('seasonTicket');
    } else if (ticket.assigned_by_id) {
      return `${p('assignedBy')} ${ticket.assigned_by_firstname} ${ticket.assigned_by_lastname}`;
    }
    let cameFrom = ticket.came_from ? ticket.came_from : 'App';
    if (cameFrom === 'Websale') {
      cameFrom = 'Web';
    }
    switch (ticket.payment_method) {
      case 'Billable':
        return `${cameFrom} - ${s('invoice')} (${ticket.company_name})`;
      case 'Kiosk':
        return `${cameFrom} - ${s('kiosk')}`;
      case 'DagsVip':
        return `${cameFrom} - ${s('dayVIP')}`;
      case 'Websale':
        return 'Web - Vipps';
      case null:
        return '-';
      default: {
        return `${cameFrom} - ${ticket.payment_method}`;
      }
    }
  };

  const badgeColor = (ticketState) => {
    switch (ticketState) {
      case 'ACTIVE':
        return 'success';
      case 'REDEEMED':
        return 'warning';
      case 'INACTIVE':
        return 'info';
      case 'EXPIRED':
        return 'dark';
      case 'SENDING':
        return 'primary';
      case 'RESERVED':
        return 'danger';
      case 'RESERVED/RELEASABLE':
        return 'danger';
      case 'RELEASED':
        return 'dark';
      default:
        return 'dark';
    }
  };

  const handleMassAssign = () => {
    const pickedKey = editUsersTickets ? 'ticket_id' : 'id';
    const toggledTicketIDs = tickets.data
      .filter((ticket) => ticket.toggled)
      .map((ticket) => ticket[pickedKey]);
    handleShareTicketsModal(true, toggledTicketIDs);
  };

  const handleDeleteTickets = () => {
    const toggledTickets = tickets.data.filter((ticket) => ticket.toggled);
    handleReleaseTicketsModal(true, toggledTickets);
  };

  const renderMatchDropdown = () => {
    const mapMatches = (match) => (
      <DropdownItem
        className="width-100p"
        onClick={() => handleMatch(match)}
        key={`Match-${match.id}`}
      >
        {match.home_team.name} - {match.away_team.name} {match.date}
      </DropdownItem>
    );
    const future = futureMatches.map(mapMatches);
    let past = pastMatches.map(mapMatches);
    past = (
      <div key="past">
        <p className="line-both-sides">&nbsp;Tidligere kamper&nbsp;</p>
        {[...past]}
      </div>
    );
    return [...future, past];
  };

  const renderFieldDropdown = () => {
    return fields.map((field) => {
      return (
        <DropdownItem
          onClick={() => handleField(field)}
          key={`fieldDropdown-${field.id}`}
        >
          {s('field')} {field.name}
        </DropdownItem>
      );
    });
  };

  const renderClassNameRows = (toggled, seatDisabledGlobally, index) => {
    if (index === -1) {
      return 'transitionDuration-03 center-list';
    }
    if (toggled) {
      return 'listSelected transitionDuration-03 center-list';
    }
    if (seatDisabledGlobally) {
      return 'listDisabled transitionDuration-03 center-list';
    }
    return 'transitionDuration-03 center-list';
  };

  const renderDotsHorizontal = (ticket) => {
    if (ticket.reservation_id) {
      return (
        <div className="flex-centerVertHort">
          <button
            onClick={() => handleReleaseTicketsModal(true, [ticket])}
            className="outline-badge danger"
          >
            {p('deleteReservasion')}
          </button>
        </div>
      );
    }
    if (ticket.season_ticket_id || ticket.ticket_id) {
      return (
        <UncontrolledDropdown className="dashboard__table-more">
          <DropdownToggle>
            <DotsHorizontalIcon />
          </DropdownToggle>
          <DropdownMenu className="dropdown__menu">
            <Link
              to={`/tickets/match/edit/${ticket.ticket_id}${window.location.search}`}
            >
              <DropdownItem>{s('edit')}</DropdownItem>
            </Link>
            <DropdownItem
              onClick={() => handleReleaseTicketsModal(true, [ticket])}
              className="danger"
            >
              {s('delete')}
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      );
    }
    return (
      <div className="flex-centerVertHort">
        <Link
          to={`/tickets/match/assign/${selectedMatch.id}/${ticket.id}${window.location.search}`}
          className="outline-badge success"
        >
          {s('assign')}
        </Link>
      </div>
    );
  };

  const renderCheckIcon = (state) =>
    state ? (
      <CheckboxMarkedCircle size={12} className="icon-min-20" color="#399351" />
    ) : (
      <CloseCircle size={12} className="icon-min-20" color="#FF4861" />
    );

  const renderUser = (ticket) => {
    if (!ticket.user_id && !ticket.reservation_user_id) {
      return '-';
    }
    if (ticket.user_id) {
      return `${ticket.firstname} ${ticket.lastname}`;
    }
    return `${ticket.reservation_user_firstname} ${ticket.reservation_user_lastname}`;
  };

  const renderTickets = () => {
    return tickets.data.map((ticket, index) => {
      const seatDisabledGlobally = ticket.seat_state_id === 2;
      return (
        <tr
          style={{ borderColor: 'white' }}
          className={renderClassNameRows(
            ticket.toggled,
            seatDisabledGlobally,
            index
          )}
          key={`MatchTicket-${ticket.id}`}
        >
          <td>
            <Checkbox
              index={index}
              handleToggle={() => handleToggle(index)}
              toggled={ticket.toggled || false}
            />
          </td>
          <td>{`${ticket.field_name} \xa0 ${ticket.row_number} \xa0 ${ticket.seat_id}`}</td>
          <td>
            {seatDisabledGlobally && (
              <>
                <UncontrolledTooltip
                  placement="top"
                  target="UncontrolledTooltipExample"
                >
                  {p('seatDisabledGlobally')}
                </UncontrolledTooltip>
                <AlertIcon id="UncontrolledTooltipExample" />
              </>
            )}
            {ticket.match_seat_state_description
              ? ticket.match_seat_state_description
              : '-'}
          </td>
          <td>
            {!!ticket.ticket_state_name && (
              <Badge color={badgeColor(ticket.ticket_state_name)}>
                {t(ticket.ticket_state_name.toLowerCase())}
              </Badge>
            )}
          </td>
          <td>{renderUser(ticket)}</td>
          <td>{translatePayment(ticket)}</td>
          <td>{ticket.price_group_title ? ticket.price_group_title : '-'}</td>
          <td>
            {ticket.shareable !== null && renderCheckIcon(ticket.shareable)}
          </td>
          <td>{ticket.vip !== null && renderCheckIcon(ticket.vip)}</td>
          <td>{renderDotsHorizontal(ticket)}</td>
        </tr>
      );
    });
  };

  const renderFilterDropdown = (key, items) => {
    return items.map((item) => (
      <DropdownItem
        onClick={() =>
          setFilter((prev) => ({
            ...prev,
            [key]: item,
          }))
        }
        key={`${key}-${item.value}`}
      >
        {item.label}
      </DropdownItem>
    ));
  };

  const getSortIcon = (type) => {
    if (filter.sort.type !== type) {
      return null;
    } else if (filter.sort.order === 'asc') {
      return <ArrowDownIcon size={16} />;
    }
    return <ArrowUpIcon size={16} />;
  };

  return (
    <Card>
      <CardBody>
        <div className="card__title marginTop-0">
          <Row>
            <UncontrolledDropdown className="mb-3">
              <DropdownToggle className="icon icon--right" outline>
                {selectedMatch && selectedMatch.id !== undefined ? (
                  <p>
                    {selectedMatch.home_team.name} -{' '}
                    {selectedMatch.away_team.name} {selectedMatch.date}
                    <ChevronDownIcon />
                  </p>
                ) : (
                  <p>{p('MATCH')}</p>
                )}
              </DropdownToggle>
              <DropdownMenu className="dropdown__menu">
                {renderMatchDropdown()}
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown className="mb-3">
              <DropdownToggle className="icon icon--right" outline>
                <p>
                  Felt {selectedField?.name ?? s('all')} <ChevronDownIcon />
                </p>
              </DropdownToggle>
              <DropdownMenu className="dropdown__menu">
                <DropdownItem onClick={() => handleField(null)}>
                  {s('field')} {s('all')}
                </DropdownItem>
                {renderFieldDropdown()}
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown className="mb-3">
              <DropdownToggle className="icon icon--right" outline>
                <p>
                  {s('row')} {selectedRow?.number ?? s('all')}
                  <ChevronDownIcon />
                </p>
              </DropdownToggle>
              <DropdownMenu className="dropdown__menu">
                <DropdownItem onClick={() => handleRow(null)}>
                  {s('row')} {s('all')}
                </DropdownItem>
                {rows.map((row) => {
                  return (
                    <DropdownItem
                      onClick={() => handleRow(row)}
                      key={`rowDropdown-${row.id}`}
                    >
                      {s('row')} {row.number}
                    </DropdownItem>
                  );
                })}
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown className="mb-3">
              <DropdownToggle className="icon icon--right" outline>
                <p>
                  {p('ticketStatus')} {filter?.ticketState?.label ?? s('all')}
                  <ChevronDownIcon />
                </p>
              </DropdownToggle>
              <DropdownMenu className="dropdown__menu">
                {renderFilterDropdown('ticketState', [
                  { label: s('all'), value: null },
                  ...ticketStates,
                ])}
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown className="mb-3">
              <DropdownToggle className="icon icon--right" outline>
                <p>
                  {s('priceGroup')} {filter?.priceGroup?.label ?? s('all')}
                  <ChevronDownIcon />
                </p>
              </DropdownToggle>
              <DropdownMenu className="dropdown__menu">
                {renderFilterDropdown('priceGroup', [
                  { label: s('all'), value: null },
                  ...matchPrices,
                ])}
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown className="marginRight-15 mb-3">
              <Select
                className="select-filter"
                placeholder={s('filter')}
                options={options}
                value={filter.options}
                onChange={(value) =>
                  setFilter((prev) => ({ ...prev, options: value }))
                }
                multi
              />
            </UncontrolledDropdown>
            {editUsersTickets && (
              <button
                className=" marginRight-15 btn btn-primary"
                onClick={handleMassAssign}
              >
                {' '}
                {p('editMultipleTickets')}{' '}
              </button>
            )}
            {shareableTickets && (
              <button
                className=" marginRight-15 btn btn-primary"
                onClick={handleMassAssign}
              >
                {' '}
                {s('assign')}{' '}
              </button>
            )}
            {ticketsAreShared && (
              <button
                className=" marginRight-15 btn btn-danger"
                onClick={handleDeleteTickets}
              >
                {' '}
                {p('releaseSeat')}{' '}
              </button>
            )}
            <div className="form noWrap">
              <div className="form__form-group products-list__search flex">
                <input
                  value={searchText}
                  placeholder={`${s('search')}...`}
                  name="search"
                  onChange={handleInput}
                  onKeyPress={handleSearch}
                />
              </div>
            </div>
          </Row>
        </div>
        <div className="margin-20" />
        <Table responsive bordered>
          <thead>
            <tr>
              <th>
                <Checkbox
                  index={-1}
                  handleToggle={() => handleToggle(-1, masterToggled)}
                  toggled={masterToggled}
                />
              </th>
              <th onClick={() => updateSort('seats')}>
                {s('field')} / {s('row')} / {s('seat')}
                {getSortIcon('seats')}
              </th>
              <th>{p('seatStatus')}</th>
              <th onClick={() => updateSort('ticketStates')}>
                {p('ticketStatus')}
                {getSortIcon('ticketStates')}
              </th>
              <th onClick={() => updateSort('users')}>
                {s('user')}
                {getSortIcon('users')}
              </th>
              <th onClick={() => updateSort('paymentMethods')}>
                {s('paymentMethod')}
                {getSortIcon('paymentMethods')}
              </th>
              <th onClick={() => updateSort('priceGroups')}>
                {s('priceGroup')}
                {getSortIcon('priceGroups')}
              </th>
              <th onClick={() => updateSort('shareable')}>
                {p('canBeShared')}
                {getSortIcon('shareable')}
              </th>
              <th onClick={() => updateSort('vip')}>
                {p('hasVip')}
                {getSortIcon('vip')}
              </th>
              <th />
            </tr>
          </thead>
          <tbody>{renderTickets()}</tbody>
        </Table>
        <Pagination
          items={tickets.total || 0}
          rowsPerPage={[100, 250, 500]}
          onChangePage={onChangePage}
        />
      </CardBody>
    </Card>
  );
};

MatchTicketsList.propTypes = {
  tickets: PropTypes.shape({
    total: PropTypes.number,
    data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  fields: PropTypes.arrayOf(any).isRequired,
  rows: PropTypes.arrayOf(any).isRequired,
  selectedField: PropTypes.objectOf(any),
  selectedRow: PropTypes.objectOf(any),
  selectedMatch: PropTypes.objectOf(any),
  handleField: PropTypes.func.isRequired,
  handleRow: PropTypes.func.isRequired,
  handleMatch: PropTypes.func.isRequired,
  searchText: PropTypes.string.isRequired,
  handleInput: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleReleaseTicketsModal: PropTypes.func.isRequired,
  handleShareTicketsModal: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  setEditUsersTickets: PropTypes.func.isRequired,
  editUsersTickets: PropTypes.bool.isRequired,
  handleToggle: PropTypes.func.isRequired,
  pastMatches: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      home_team: PropTypes.shape({
        name: PropTypes.string,
      }),
      away_team: PropTypes.shape({
        name: PropTypes.string,
      }),
      date: PropTypes.string,
    })
  ).isRequired,
  futureMatches: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      home_team: PropTypes.shape({
        name: PropTypes.string,
      }),
      away_team: PropTypes.shape({
        name: PropTypes.string,
      }),
      date: PropTypes.string,
    })
  ).isRequired,
};

MatchTicketsList.defaultProps = {
  selectedMatch: null,
  selectedField: null,
  selectedRow: null,
};

export default MatchTicketsList;
