import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { partial } from '../../util/translation/translation';

const p = partial('contactSales');

const ContactSalesPage = () => {
  return (
    <Container>
      <Row>
        <Col>
          <img
            style={{ display: 'block', margin: '50px auto 0px', width: 250 }}
            src="/assets/files/admin/special.svg"
            alt="icon"
          />
          <h4 className="bold text-center">{p('title')}</h4>
          <p className="text-center">
            {p('text')} <br />
            {p('contact')} <a href={`mailto:${p('email')}`}>{p('email')}</a>
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default ContactSalesPage;
