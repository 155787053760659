import React from 'react';
import { Spinner } from 'reactstrap';

const Loading = () => (
  <div className="width-100p flex flex-Hcenter alignItems-center">
    <Spinner />
  </div>
);

export default Loading;
