import React, { PureComponent } from 'react';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import { partial } from '../../../util/translation/translation';
import ImageCropModal from '../custom/imageCropper';

const p = partial('dropZoneShare');
class DropZoneField extends PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    customHeight: PropTypes.bool,
    crop: PropTypes.any,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
        })
      ),
    ]).isRequired,
    className: PropTypes.string,
    meta: PropTypes.shape({
      touched: PropTypes.bool,
      error: PropTypes.string,
    }),
  };

  static defaultProps = {
    className: '',
    customHeight: false,
    meta: null,
    crop: false,
  };

  constructor() {
    super();
    this.state = {
      visible: false,
      imageFile: undefined,
      type: 'new',
    };
  }

  onSubmitImage = (image) => {
    this.props.onChange(image);
  };

  removeFile(index, e) {
    e.preventDefault();
    this.props.onChange(this.props.value.filter((val, i) => i !== index));
  }

  render() {
    const files = this.props.value;
    const { isEditable } = this.props;
    const { touched, error } = this.props.meta;
    return (
      <div className="dropzone--container">
        <div
          className={`dropzone dropzone--single${this.props.customHeight ? ' dropzone--custom-height' : ''} ${this.props.className}`}
        >
          <Dropzone
            className="dropzone__input"
            accept="image/jpeg, image/png, image/webp, image/gif, video/mp4, video/mov, video/ogg, video/mpeg, video/*"
            name={this.props.name}
            multiple={false}
            onDrop={(filesToUpload) => {
              if (this.props.crop) {
                if (filesToUpload?.length) {
                  const reader = new FileReader();
                  reader.addEventListener('load', () => {
                    this.setState({ imageFile: reader.result, visible: true });
                  });
                  reader.readAsDataURL(filesToUpload[0]);
                }
              } else {
                this.props.onChange(filesToUpload);
              }
            }}
          >
            {(!files || files.length === 0) && (
              <div className="dropzone__drop-here">
                <span className="lnr lnr-upload" />
                {p('dropFilesToUpload')}
              </div>
            )}
          </Dropzone>
          {files && Array.isArray(files) && files.length > 0 && (
            <div className="dropzone__img">
              <img src={files[0].preview} alt="drop-img" />
              <p className="dropzone__img-name">{files[0].name}</p>
              {isEditable && (
                <button
                  className="dropzone__img-edit"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ type: 'edit', visible: true });
                  }}
                >
                  {p('edit')}
                </button>
              )}
              <button
                className="dropzone__img-delete"
                onClick={(e) => this.removeFile(0, e)}
              >
                {p('remove')}
              </button>
            </div>
          )}
          {this.state.visible && (
            <ImageCropModal
              crop={this.props.crop}
              locked={this.props.locked}
              src={this.state.imageFile || files[0].preview}
              visible={this.state.visible}
              handleModal={() => this.setState({ visible: false })}
              action={this.onSubmitImage}
              type={this.state.type}
            />
          )}
        </div>
        {touched && error && (
          <span className="form__form-group-error">{error}</span>
        )}
      </div>
    );
  }
}

const renderDropZoneField = (props) => (
  <DropZoneField
    {...props.input}
    meta={props.meta}
    className={props.className}
    customHeight={props.customHeight}
    crop={props.crop}
  />
);

renderDropZoneField.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  customHeight: PropTypes.bool,
  className: PropTypes.string,
  crop: PropTypes.any,
};

renderDropZoneField.defaultProps = {
  meta: null,
  customHeight: false,
  className: '',
};

export default renderDropZoneField;
