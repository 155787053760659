import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import Api from '../../util/api';
import Loading from '../../shared/components/custom/Loading';
import { partial } from '../../util/translation/translation';
import MessagesAPIForm from './components/MessagesAPIForm';

const s = partial('shared');

const MessagesAPIFormPage = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const onSubmit = async (values) => {
    setLoading(true);
    const payload = {
      entityName: values.entityName,
    };
    try {
      await Api.userDevices.createApiAccess(payload);
      setLoading(false);
      history.replace('/messages?tab=3');
    } catch (err) {
      toast.error(err || s('somethingWentWrong'));
      setLoading(false);
    }
  };
  return (
    <Container>
      <Loading loading={loading} />
      <Row>
        <Col md={12}>
          <h3 className="page-title">{s('create')}</h3>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <MessagesAPIForm onSubmit={onSubmit} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default MessagesAPIFormPage;
