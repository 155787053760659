/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Row, Col, Container } from 'reactstrap';
import LinkVariantIcon from 'mdi-react/LinkVariantIcon';
import moment from 'moment';
import TimetableIcon from 'mdi-react/TimetableIcon';
import renderField from '../../../shared/components/custom/Field';
import renderDropZoneField from '../../../shared/components/form/DropZoneMS';
import validateQuestionaireForm from './validateQuestionaireForm';
import renderSwitchField from '../../../shared/components/custom/Switch';
import { partial } from '../../../util/translation/translation';
import { getImageHash } from '../../../util/functions';
import renderSelectField from '../../../shared/components/form/Select';
import TextEditor2 from '../../../shared/components/text-editor/TextEditor2';

const QuestionaireForm = ({ questionaire, handleSubmit, initialize }) => {
  const s = partial('shared');
  const p = partial('questionaire');

  const questionaireTypes = ['QUESTIONAIRE', 'QUIZ', 'POLL'].map((item) => ({
    label: p(`questionaireType-${item}`),
    value: item,
  }));

  useEffect(() => {
    if (questionaire) {
      initialize({
        headingImage: questionaire.imageUrl
          ? {
              name: 'questionaire image',
              preview: questionaire.imageUrl,
              croppedImage: questionaire.imageUrl,
              hash: getImageHash(questionaire.imageUrl),
            }
          : undefined,
        title: questionaire.title,
        description: questionaire.description,
        visible: !!questionaire.visible,
        editAnswers: !!questionaire.editAnswers,
        startDate: moment(questionaire.startDate).format('YYYY-MM-DDTHH:mm'),
        endDate: moment(questionaire.endDate).format('YYYY-MM-DDTHH:mm'),
        // type: { label: p(`questionaireType-${questionaire.type}`), value: questionaire.type },
        dynamicLink: questionaire.dynamicLink,
        subtitle: questionaire.subtitle,
      });
    }
  }, [questionaire]);
  return (
    <form className="form" onSubmit={handleSubmit}>
      <Container>
        <Row>
          <Col lg={6}>
            <div
              style={{
                backgroundColor: '#F4F4F6',
                borderRadius: 4,
                borderColor: '#D7DAE0',
                borderStyle: 'solid',
                borderWidth: 1,
                marginBottom: 10,
                width: '100%',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  borderBottomWidth: 1,
                  borderBottomStyle: 'dashed',
                  borderColor: '#D7DAE0',
                  padding: '10px 10px 10px 10px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      fontSize: 16,
                      lineHeight: '24px',
                      color: 'black',
                      fontWeight: 'bold',
                    }}
                  >
                    {s('details')}
                  </div>
                </div>
              </div>
              <div style={{ padding: '10px' }}>
                <Container>
                  <Row>
                    <Col>
                      <div className="form__form-group">
                        <span className="form-header">{s('title')}*</span>
                        <Field
                          name="title"
                          component={renderField}
                          placeholder={s('title')}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="form__form-group">
                        <span className="form-header">{s('subtitle')}*</span>
                        <Field
                          name="subtitle"
                          component={renderField}
                          placeholder={s('subtitle')}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="form__form-group">
                        <span className="form-header">{s('description')}*</span>
                        <Field
                          name="description"
                          component={TextEditor2}
                          header={[2, 3, false]}
                          placeholder={s('description')}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    {/* <Col>
                      <div className="form__form-group">
                        <span className="form-header">{p('selectType')}</span>
                        <div className="form__form-group-field">
                          <Field
                            name="type"
                            placeholder={p('selectType')}
                            options={questionaireTypes}
                            component={renderSelectField}
                          />
                        </div>
                      </div>
                    </Col> */}
                  </Row>
                  <Row>
                    <Col>
                      <div className="form__form-group">
                        <span className="form">{s('startDate')}*</span>
                        <div className="form__form-group-field">
                          <div className="form__form-group-icon">
                            <TimetableIcon />
                          </div>
                          <Field
                            name="startDate"
                            component={renderField}
                            type="datetime-local"
                          />
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div className="form__form-group">
                        <span className="form">{s('endDate')}*</span>
                        <div className="form__form-group-field">
                          <div className="form__form-group-icon">
                            <TimetableIcon />
                          </div>
                          <Field
                            name="endDate"
                            component={renderField}
                            type="datetime-local"
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <div
              style={{
                backgroundColor: '#F4F4F6',
                borderRadius: 4,
                borderColor: '#D7DAE0',
                borderStyle: 'solid',
                borderWidth: 1,
                marginBottom: 10,
                width: '100%',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  borderBottomWidth: 1,
                  borderBottomStyle: 'dashed',
                  borderColor: '#D7DAE0',
                  padding: '10px 10px 10px 10px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      fontSize: 16,
                      lineHeight: '24px',
                      color: 'black',
                      fontWeight: 'bold',
                    }}
                  >
                    {s('image')}
                  </div>
                </div>
              </div>
              <div style={{ padding: '10px' }}>
                <div className="form__form-group">
                  <Field
                    saveBase64Cropped
                    name="headingImage"
                    maxSize={3}
                    component={renderDropZoneField}
                    displayCrop
                    crop="BANNER_CROP"
                  />
                  <span className="form-header">
                    <strong>{s('note')}:</strong> {s('recommendedImageSize')}{' '}
                    16:9 (1035 x 570 px)
                  </span>
                </div>
              </div>
            </div>
            <Col>
              <div className="form__form-group flex-Vcenter">
                <Field
                  name="visible"
                  component={renderSwitchField}
                  label={[`${s('active')}`, `${s('inActive')}`]}
                  defaultChecked
                  className="colored-click"
                />
              </div>
            </Col>
            <Col>
              <div className="form__form-group flex-Vcenter">
                <Field
                  name="editAnswers"
                  component={renderSwitchField}
                  label={[p('editAnswers'), p('notEditAnswers')]}
                  defaultChecked
                  className="colored-click"
                />
              </div>
            </Col>
            {questionaire ? (
              <Row>
                <Col>
                  <div className="form__form-group">
                    <span className="form-header marginRight-8">
                      {s('universalLink')}
                    </span>
                    <div className="form__form-group-field">
                      <Field name="dynamicLink" component="input" disabled />
                      <button
                        type="button"
                        className="form__form-group-icon"
                        onClick={() =>
                          navigator.clipboard.writeText(
                            questionaire.dynamicLink
                          )
                        }
                      >
                        <LinkVariantIcon />
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
            ) : null}
          </Col>
        </Row>
      </Container>
    </form>
  );
};

const reduxF = reduxForm({
  form: 'questionaire_form',
  validate: validateQuestionaireForm,
});

export default reduxF(QuestionaireForm);
