import React, { useState, useEffect } from 'react';
import {
  Col,
  Row,
  Container,
  Card,
  CardBody,
  TabPane,
  TabContent,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import moment from 'moment';
import Api from '../../util/api';
import { partial } from '../../util/translation/translation';
import Loading from '../../shared/components/custom/Loading';
import UserTierForm from './components/UserTierForm';
import UserTierList from './components/UserTierList';
import AssignTierPage from './components/AssignTierPage';
import UploadTierCSVPage from './components/UploadTierCSVPage';
import NavTab from '../../shared/components/custom/NavTab';
import DeleteModal from '../../shared/components/custom/DeleteModal';
import useConfig from '../../util/useConfig';
// import config from '../../config';

const p = partial('UserTiers');
const s = partial('shared');

const UserTiersFormPage = () => {
  const config = useConfig();
  const [availableFeatures, setAvailableFeatures] = useState([]);
  const [deleteAffiliationId, setDeleteAffiliationId] = useState(null);
  const [currentTier, setCurrentTier] = useState(null);
  const { tierID } = useParams();
  const [loading, setLoading] = useState(false);
  const [tierLoading, setTierLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('1');
  const [reverse, setReverse] = useState(false);
  const [tierUsers, setTierUsers] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [modalVis, setModalVis] = useState(false);

  const [pageData, setPageData] = useState({
    pageSize: 10,
    page: 1,
  });
  const editMode = tierID !== undefined;
  const history = useHistory();
  const getTierFeatures = async () => {
    setTierLoading(true);
    try {
      const promises = [Api.tiers.getTierFeatures(config)];
      if (tierID) {
        promises.push(Api.tiers.getTier(tierID, config));
      }
      const [featuresResp, tierResp] = await Promise.all(promises);
      setAvailableFeatures(featuresResp.data);
      setCurrentTier(tierResp?.data);
    } catch (err) {
      toast.error(err);
    } finally {
      setTierLoading(false);
    }
  };
  const getTierUsers = async () => {
    setLoading(true);
    try {
      const { pageSize, page } = pageData;
      const {
        data: { tierData, total },
      } = await Api.tiers.getTierUsers(tierID, page, pageSize, reverse, config);
      const cloudIds = tierData.map((item) => item.cloudId);
      const { data: userData } = await Api.users.getUsersByCloudIds(cloudIds);
      const combinedData = userData
        .map((usr) => {
          const foundAffiliation = tierData.find(
            (item) => item.cloudId === usr.userCloudId
          );
          return {
            ...usr,
            tier: {
              dueDate: foundAffiliation.dueDate,
              name: foundAffiliation.tierName,
              affiliationId: foundAffiliation.affiliationId,
            },
          };
        })
        .sort((a, b) => {
          const first = reverse ? b : a;
          const second = reverse ? a : b;
          return moment(first.tier.dueDate).diff(moment(second.tier.dueDate));
        });
      setTierUsers(combinedData);
      setTotalItems(total);
    } catch (err) {
      toast.error(err);
    } finally {
      setLoading(false);
    }
  };
  const onChangePage = (pager) => {
    const { pageSize, currentPage } = pager;
    if (pageData.page !== currentPage || pageData.pageSize !== pageSize) {
      setPageData({
        pageSize,
        page: currentPage,
      });
    }
  };
  const handleModal = (state, deleteId) => {
    setModalVis(state);
    setDeleteAffiliationId(deleteId);
  };
  const deleteAffiliation = async () => {
    setLoading(true);
    try {
      await Api.tiers.deleteUserAffiliation(deleteAffiliationId, config);
      handleModal(false, null);
      getTierUsers();
    } catch (err) {
      toast.error(err);
      setLoading(false);
    }
  };
  useEffect(() => {
    getTierFeatures();
  }, []);
  useEffect(() => {
    if (editMode) {
      getTierUsers();
    }
  }, [pageData, reverse]);
  const onSubmit = async ({
    name,
    description,
    pickedFeatures,
    previousPrice,
    image,
    cardBackground,
    price1,
    price12,
  }) => {
    // const description = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    const payload = {
      description,
      price1,
      // price3,
      // price6,
      price12,
      featureIds: pickedFeatures?.map((item) => item.id) || [],
      name,
      previousPrice: previousPrice || undefined,
      currency: config.currencyCaps,
    };
    setLoading(true);
    try {
      if (editMode) {
        payload.image =
          (await image?.promise?.('tier', 'originalUrl', tierID)) ||
          image?.preview.includes('blob')
            ? undefined
            : image?.preview || null;
        payload.image1 =
          (await cardBackground?.promise?.(
            'tier-background-image',
            'cardUrl',
            tierID
          )) || cardBackground?.preview.includes('blob')
            ? undefined
            : cardBackground?.preview || null;
        await Api.tiers.editTier(tierID, payload, config);
      } else {
        const { data: resTier } = await Api.tiers.createTier(payload, config);
        await image?.promise?.('tier', 'originalUrl', resTier.id);
        await cardBackground?.promise?.(
          'tier-background-image',
          'cardUrl',
          resTier.id
        );
      }
      setLoading(false);
      toast.success(p('tierSaved'));
      history.replace('/user_tiers');
    } catch (err) {
      setLoading(false);
      toast.error(s('somethingWentWrong'));
    }
  };
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  return (
    <Container>
      <Loading loading={loading || tierLoading} />
      <DeleteModal
        type={p('tier')}
        visible={modalVis}
        handleModal={handleModal}
        modalAction={() => deleteAffiliation()}
      />
      <Row>
        <Col md={12}>
          <Card>
            <CardBody className="p-0">
              <NavTab
                tabs={[
                  s('details'),
                  { disabled: !editMode, tab: s('users') },
                  { disabled: !editMode, tab: s('assignUser') },
                  { disabled: !editMode, tab: `${s('upload')} CSV` },
                ]}
                activeTab={activeTab}
                setTab={toggle}
                size="sm"
              />
              <div className="tabs tabs--justify tabs--bordered-top overflow-unset mx-3">
                <div className="tabs__wrap">
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <UserTierForm
                        tier={currentTier}
                        onSubmit={onSubmit}
                        availableFeatures={availableFeatures}
                      />
                    </TabPane>
                    {editMode && (
                      <>
                        <TabPane tabId="2">
                          <UserTierList
                            handleModal={handleModal}
                            tierUsers={tierUsers}
                            totalItems={totalItems}
                            setReverse={setReverse}
                            reverse={reverse}
                            onChangePage={onChangePage}
                          />
                        </TabPane>
                        <TabPane tabId="3">
                          <AssignTierPage
                            tierID={tierID}
                            getTierUsers={getTierUsers}
                          />
                        </TabPane>
                        <TabPane tabId="4">
                          <UploadTierCSVPage
                            tierID={tierID}
                            getTierUsers={getTierUsers}
                            changeTab={toggle}
                          />
                        </TabPane>
                      </>
                    )}
                  </TabContent>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default UserTiersFormPage;
