import React, { useState, useEffect } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import PropTypes from 'prop-types';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import t, { partial } from '../../../util/translation/translation';

const p = partial('ProductsModal');
const ProductsModal = (props) => {
  const [categoryDraggedItem, setCategoryDraggedItem] = useState({});
  const [productDraggedItem, setProductDraggedItem] = useState({});

  useEffect(() => {
    if (props.visible === true) {
      props.getProducts();
    } else if (props.categories.length !== 0) {
      props.setProducts([]);
    }
  }, [props.visible]);

  function categoryOnDragStart(e, index) {
    const targetName = e.target.getAttribute('name');
    if (targetName === 'category') {
      setCategoryDraggedItem(props.categories[index]);
      setProductDraggedItem({});
      e.dataTransfer.effectAllowed = 'move';
      e.dataTransfer.setData('text/html', e.target.parentNode);
    }
  }

  function categoryOnDragOver(index) {
    if (categoryDraggedItem.id === undefined) {
      return;
    }
    const draggedOverItem = props.categories[index];

    if (categoryDraggedItem === draggedOverItem) {
      return;
    }

    const items = props.categories.filter(
      (item) => item !== categoryDraggedItem
    );
    items.splice(index, 0, categoryDraggedItem);
    props.setProducts(items);
  }

  function onDragEnd() {
    setCategoryDraggedItem({});
    setProductDraggedItem({});
  }

  function productOnDragStart(e, categoryIndex, productIndex) {
    const targetName = e.target.getAttribute('name');
    if (targetName === 'product') {
      setProductDraggedItem(
        props.categories[categoryIndex].products[productIndex]
      );
      setCategoryDraggedItem({});
      e.dataTransfer.effectAllowed = 'move';
      e.dataTransfer.setData('text/html', e.target.parentNode);
    }
  }

  function productOnDragOver(categoryIndex, productIndex) {
    if (productDraggedItem.id === undefined) {
      return;
    }
    if (props.categories[categoryIndex].id !== productDraggedItem.category_id) {
      return;
    }
    const draggedOverItem =
      props.categories[categoryIndex].products[productIndex];
    if (productDraggedItem === draggedOverItem) {
      return;
    }
    const items = props.categories[categoryIndex].products.filter(
      (item) => item !== productDraggedItem
    );
    items.splice(productIndex, 0, productDraggedItem);
    const { categories } = props;
    categories[categoryIndex].products = items;
    props.setProducts(categories);
  }

  return (
    <Modal
      isOpen={props.visible}
      toggle={props.toggle}
      className="kiosk-modal products-modal"
    >
      <ModalBody>
        <div className="kiosk-modal-header">
          <h3>{p('changeRange')}</h3>
          <button onClick={props.toggle}>
            <CloseIcon />
          </button>
        </div>
        <div className="kiosk-modal-body">
          <p className="kiosk-modal-modal-info">{p('sortItems')}</p>

          <RenderProductCategories
            categories={props.categories}
            setProductHidden={props.setProductHidden}
            categoryOnDragStart={categoryOnDragStart}
            categoryOnDragOver={categoryOnDragOver}
            productOnDragStart={productOnDragStart}
            productOnDragOver={productOnDragOver}
            onDragEnd={onDragEnd}
            categoryDraggedItem={categoryDraggedItem}
            productDraggedItem={productDraggedItem}
            handleUpdateProductState={props.handleUpdateProductState}
          />
          <div className="pull-right">
            <button onClick={props.saveKioskProductOrder} className="primary">
              {p('saveOrder')}
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

ProductsModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      products: PropTypes.arrayOf(
        PropTypes.shape({
          category_id: PropTypes.number,
        })
      ),
    })
  ).isRequired,
  getProducts: PropTypes.func.isRequired,
  setProducts: PropTypes.func.isRequired,
  setProductHidden: PropTypes.func.isRequired,
  saveKioskProductOrder: PropTypes.func.isRequired,
  handleUpdateProductState: PropTypes.func.isRequired,
};

const RenderProductCategories = (props) => {
  return props.categories.map((category, index) => {
    return (
      <div key={`RenderProductCategories-${category.id}`}>
        <div
          className={
            category === props.categoryDraggedItem
              ? 'placeholder products-modal-category'
              : 'products-modal-category'
          }
          name="category"
          draggable
          onDragStart={(e) => props.categoryOnDragStart(e, index)}
          onDragEnd={props.onDragEnd}
        >
          <div
            className="products-modal-category-header"
            onDragOver={() => props.categoryOnDragOver(index)}
          >
            <h4>::: {category.name}</h4>
            <button
              draggable="false"
              className={category.hidden === true ? 'upwards' : ''}
              onClick={() => props.setProductHidden(index)}
            >
              <ChevronDownIcon color="white" />
            </button>
          </div>
          <div
            className={
              category.hidden
                ? 'products-modal-category-body hide'
                : 'products-modal-category-body'
            }
          >
            <RenderProducts
              products={category.products}
              productOnDragStart={props.productOnDragStart}
              productOnDragOver={props.productOnDragOver}
              onDragEnd={props.onDragEnd}
              categoryIndex={index}
              productDraggedItem={props.productDraggedItem}
              handleUpdateProductState={props.handleUpdateProductState}
            />
          </div>
        </div>
      </div>
    );
  });
};

RenderProductCategories.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      hidden: PropTypes.bool.isRequired,
    })
  ).isRequired,
  setProductHidden: PropTypes.func.isRequired,
  handleUpdateProductState: PropTypes.func.isRequired,
};

const RenderProducts = (props) => {
  return props.products.map((product, index) => {
    return (
      <div
        key={`RenderProducts-${product.id}`}
        className={
          product === props.productDraggedItem
            ? 'placeholder product-item'
            : 'product-item'
        }
        name="product"
        draggable
        onDragStart={(e) =>
          props.productOnDragStart(e, props.categoryIndex, index)
        }
        onDragOver={() => props.productOnDragOver(props.categoryIndex, index)}
        onDragEnd={props.onDragEnd}
      >
        <p>
          ::: {product.name} - {product.subtitle}
        </p>
        <button
          onClick={() =>
            props.handleUpdateProductState(props.categoryIndex, index)
          }
          className="product-item-state"
          style={{
            backgroundColor: product.active === 1 ? '#6dd954' : '#e23d51',
          }}
        >
          <span>
            {product.active === 1
              ? t('shared.activeCaps')
              : t('shared.inActiveCaps')}
          </span>
        </button>
      </div>
    );
  });
};

RenderProducts.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      subtitle: PropTypes.string,
    })
  ).isRequired,
  handleUpdateProductState: PropTypes.func.isRequired,
};

export default ProductsModal;
