import React, { useEffect, useState } from 'react';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { Table, Button, Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import CheckboxMarkedCircleIcon from 'mdi-react/CheckboxMarkedCircleIcon';
import CloseCircleIcon from 'mdi-react/CloseCircleIcon';
import ArrowUpIcon from 'mdi-react/ArrowUpIcon';
import ArrowDownIcon from 'mdi-react/ArrowDownIcon';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import { splitEvery } from 'ramda';
import assignUserToTierValidate from './assignUserToTierValidate';
import renderField from '../../../shared/components/custom/Field';
import renderCheckBoxField from '../../../shared/components/form/CheckBox';
import t, { partial } from '../../../util/translation/translation';
import Api from '../../../util/api';
import Pagination from '../../../shared/components/pagination/Pagination';
import { capitalizeFirstLetter } from '../../../util/functions';
import Checkbox from '../../../shared/components/custom/Checkbox';
import Loading from '../../../shared/components/custom/Loading';
import useConfig from '../../../util/useConfig';
import FormBox from '../../../shared/components/custom/FormBox';

const p = partial('UserTiers');
const s = partial('shared');

const AssignTierPage = ({
  formValues,
  tierID,
  getTierUsers,
  initialize,
  validate,
}) => {
  const config = useConfig();
  const [masterToggled, setMasterToggled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [users, setUsers] = useState([]);
  const [usersCount, setUsersCount] = useState(0);
  const [stateData, setStateData] = useState({
    pageSize: 50,
    page: 1,
    order: 'email',
    reverse: 0,
  });
  const getUsers = async () => {
    setLoading(true);
    try {
      const { pageSize, page, order, reverse } = stateData;
      const { total, users: teamUsers } = await Api.users.getTeamUsers(
        page,
        pageSize,
        { [order]: reverse ? 'desc' : 'asc' },
        searchValue,
        config
      );
      const usersCloudIds = teamUsers.map((usr) => usr.userCloudId);
      const chunkedUserIds = splitEvery(200, usersCloudIds);
      const fetchedUsersTiers = await Promise.all(
        chunkedUserIds.map((particalCloudIds) =>
          Api.tiers.userTiersByCloudIDs(particalCloudIds, config)
        )
      );
      const usersTiers = fetchedUsersTiers.reduce((acc, curr) => {
        return [...acc, ...curr.data];
      }, []);
      const mergedUsers = teamUsers.map((usr, idx) => ({
        ...usr,
        tier: usersTiers[idx],
        toggled: false,
        isAlreadyInTier: usersTiers[idx].allTierIds.includes(tierID),
      }));
      setUsers(mergedUsers);
      setUsersCount(total);
      setLoading(false);
    } catch (e) {
      toast.error(t('UsersTab.fetchingUsersFailed'));
      setLoading(false);
    }
  };
  const handleAssignUser = async () => {
    const e = validate(formValues);
    if (e?.dueDate) {
      return toast.error(e?.dueDate);
    }
    const pickedUsers = users.filter((item) => item.toggled);
    const { dueDate, neverEnd } = formValues;
    setLoading(true);
    try {
      await Promise.all(
        pickedUsers.map((usr) =>
          Api.tiers.assignUserToTier(
            {
              dueDate,
              neverEnd,
              tierId: tierID,
              cloudId: usr.userCloudId,
            },
            config
          )
        )
      );
      getTierUsers();
      getUsers();
      initialize({
        dueDate: null,
        neverEnd: false,
      });
      setLoading(false);
      toast.success(t('EditUserPage.userWasUpdated'));
    } catch (err) {
      toast.error(err || `${s('somethingWentWrong')}`);
      setLoading(false);
    }
    return 0;
  };
  useEffect(() => {
    getUsers();
  }, [stateData]);
  const onChangePage = (pager) => {
    if (
      stateData.page !== pager.currentPage ||
      stateData.pageSize !== pager.pageSize
    ) {
      setStateData((old) => ({
        ...old,
        page: pager.currentPage,
        pageSize: pager.pageSize,
      }));
    }
  };
  const handleToggle = (idx) => {
    if (idx === -1) {
      setMasterToggled(!masterToggled);
      const newUsers = users.map((item) => ({
        ...item,
        toggled: !item.toggled,
      }));
      setUsers(newUsers);
    } else {
      const newUsers = [...users];
      newUsers[idx].toggled = !newUsers[idx].toggled;
      setUsers(newUsers);
    }
  };
  const renderClassNameRows = (toggled, disabled) => {
    if (toggled) {
      return 'listSelected transitionDuration-03 overflow-visible-hard';
    }
    if (disabled) {
      return 'listDisabled transitionDuration-03 center-list';
    }
    return 'transitionDuration-03 overflow-visible-hard';
  };
  const listUsers = () => {
    return users.map((user, idx) => {
      const { isAlreadyInTier } = user;
      const phoneColor = isAlreadyInTier ? { color: 'white' } : undefined;
      return (
        <tr
          key={`User-${user.userCloudId}`}
          className={renderClassNameRows(user.toggled, isAlreadyInTier)}
        >
          {!isAlreadyInTier ? (
            <td>
              <Checkbox
                index={idx}
                disabled={isAlreadyInTier}
                handleToggle={() => handleToggle(idx)}
                toggled={user.toggled}
              />
            </td>
          ) : (
            <td />
          )}
          <td className="checkbox-position">
            {isAlreadyInTier ? (
              <CheckboxMarkedCircleIcon color="#399351" />
            ) : (
              <CloseCircleIcon color="#FF4861" />
            )}
          </td>
          <td>{user.firstname}</td>
          <td>{user.lastname}</td>
          <td>
            <Link to={`/users/edit/${user.userCloudId}`} style={phoneColor}>
              {user.phoneNumber}
            </Link>
          </td>
          <td>{user.email}</td>
        </tr>
      );
    });
  };
  const handleSort = (order) => {
    const reverse =
      stateData.order === order
        ? Number(!stateData.reverse)
        : Number(stateData.reverse);
    setStateData((old) => ({
      ...old,
      order,
      reverse,
    }));
  };
  const renderSort = (cellOrder) => {
    if (stateData.order !== cellOrder) {
      return undefined;
    }
    return stateData.reverse ? (
      <ArrowUpIcon size={16} />
    ) : (
      <ArrowDownIcon size={16} />
    );
  };
  return (
    <>
      <Loading loading={loading} />
      <div className="form">
        <Row className="width-100p">
          <Col md={6}>
            <div className="form__form-group">
              <p className="form-header">{s('search')}</p>
              <div className="form__form-group products-list__search">
                <input
                  placeholder={t('UsersTab.searchForUser')}
                  value={searchValue}
                  onChange={(event) => setSearchValue(event.target.value)}
                  onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                      getUsers();
                    }
                  }}
                  name="search"
                />
                <MagnifyIcon />
              </div>
            </div>
          </Col>
          <Col md={6}>
            <FormBox title={s('endDate')}>
              <Row className="px-2">
                <Col md={6}>
                  {!formValues?.neverEnd && (
                    <div className="form__form-group">
                      <p className="form-header">{s('endDate')}</p>
                      <Field
                        name="dueDate"
                        component={renderField}
                        type="date"
                      />
                    </div>
                  )}
                </Col>
                <Col md={6}>
                  <div className="form__form-group">
                    <p className="form-header">{p('neverEnding')}</p>
                    <Field
                      name="neverEnd"
                      component={renderCheckBoxField}
                      className="colored-click"
                    />
                  </div>
                </Col>
              </Row>
            </FormBox>
          </Col>
        </Row>
        <Row className="width-100p">
          <Col md={6} />
          <Col md={6}>
            <Button
              className="mt-2"
              color="success"
              disabled={!users.filter((item) => item.toggled).length > 0}
              onClick={handleAssignUser}
            >
              {s('save')}
            </Button>
          </Col>
        </Row>
      </div>
      <div
        style={{
          display: 'flex',
          paddingBottom: 5,
          background: '#fff',
          borderRadius: 6,
          border: '1px solid rgba(0,0,0,0.08)',
        }}
      >
        <Table responsive striped>
          <thead>
            <tr>
              <th>{s('select')}</th>
              <th>{capitalizeFirstLetter(t('UserTiers.affiliation'))}</th>
              <th className="sortable" onClick={() => handleSort('firstname')}>
                {s('firstname')} {renderSort('firstname')}
              </th>
              <th className="sortable" onClick={() => handleSort('lastname')}>
                {s('lastname')} {renderSort('lastname')}
              </th>
              <th className="sortable" onClick={() => handleSort('username')}>
                {s('username')} {renderSort('username')}
              </th>
              <th className="sortable" onClick={() => handleSort('email')}>
                Email {renderSort('email')}
              </th>
            </tr>
          </thead>
          <tbody>{listUsers()}</tbody>
        </Table>
      </div>
      <Pagination
        rowsPerPage={[50, 100, 250, 500]}
        items={usersCount}
        onChangePage={onChangePage}
      />
    </>
  );
};

const reduxF = reduxForm({
  form: 'assign_user_to_tier_form',
  validate: assignUserToTierValidate,
});

const mapStateToProps = (state) => ({
  formValues: getFormValues('assign_user_to_tier_form')(state),
});

export default connect(mapStateToProps)(reduxF(AssignTierPage));
