import React, { useEffect } from 'react';
import { Button, Col, Container, FormGroup, Label, Row } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import renderField from '../../../shared/components/custom/Field';
import renderDropZoneFieldNew from '../../../shared/components/form/DropZoneNew';
import { partial } from '../../../util/translation/translation';

const s = partial('shared');
const p = partial('ProfileForm');

const ProfileForm = ({ handleSubmit, initialize, profile, clubConfig }) => {
  useEffect(() => {
    if (profile) {
      initialize({
        ...profile,
        ...(clubConfig?.config?.socials ? clubConfig.config.socials : {}),
        imageUrl: profile.imageUrl
          ? [{ src: profile.imageUrl, value: profile.imageUrl }]
          : undefined,
      });
    }
  }, [profile]);

  return (
    <form onSubmit={handleSubmit} className="form">
      <Container>
        <FormGroup row>
          <Col sm={2}>
            <Label htmlFor="contactMail">{p('contactMail')}</Label>
          </Col>
          <Col sm={2}>
            <Field name="contactMail" component={renderField} type="text" />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={2}>
            <Label htmlFor="imageUrl">{s('logo')}</Label>
          </Col>
          <Col sm={2}>
            <Field name="imageUrl" component={renderDropZoneFieldNew} />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={2}>
            <Label htmlFor="contactPhone">{p('contactPhone')}</Label>
          </Col>
          <Col sm={2}>
            <Field name="contactPhone" component={renderField} type="text" />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={2}>
            <Label htmlFor="homepage">{p('homePage')}</Label>
          </Col>
          <Col sm={2}>
            <Field name="homepage" component={renderField} type="text" />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={2}>
            <Label htmlFor="address">{p('address')}</Label>
          </Col>
          <Col sm={2}>
            <Field name="address" component={renderField} type="text" />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={2}>
            <Label htmlFor="orgNr">{p('orgNr')}</Label>
          </Col>
          <Col sm={2}>
            <Field name="orgNr" component={renderField} type="text" />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={2}>
            <Label htmlFor="facebook">{p('facebook')}</Label>
          </Col>
          <Col sm={2}>
            <Field name="facebook" component={renderField} type="text" />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={2}>
            <Label htmlFor="instagram">{p('instagram')}</Label>
          </Col>
          <Col sm={2}>
            <Field name="instagram" component={renderField} type="text" />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={2}>
            <Label htmlFor="twitter">{p('twitter')}</Label>
          </Col>
          <Col sm={2}>
            <Field name="twitter" component={renderField} type="text" />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={2}>
            <Label htmlFor="youtube">{p('youtube')}</Label>
          </Col>
          <Col sm={2}>
            <Field name="youtube" component={renderField} type="text" />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={2}>
            <Label htmlFor="tiktok">{p('tiktok')}</Label>
          </Col>
          <Col sm={2}>
            <Field name="tiktok" component={renderField} type="text" />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={2}>
            <Label htmlFor="linkedin">{p('linkedin')}</Label>
          </Col>
          <Col sm={2}>
            <Field name="linkedin" component={renderField} type="text" />
          </Col>
        </FormGroup>
        <Row>
          <Button color="primary" type="submit">
            {s('edit')}
          </Button>
          <Link to="/settings">
            <Button color="secondary">{s('close')}</Button>
          </Link>
        </Row>
      </Container>
    </form>
  );
};

const rf = reduxForm({
  form: 'profileForm',
});

export default rf(ProfileForm);
