import React from 'react';
import { Card, CardBody, Container, Table, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '../../util/api';

const MatchAdminListPage = ({ matches = [], league = {} }) => {
  const renderMatches = (_matches) =>
    _matches.map((match) => (
      <tr key={`admnon=${match.id}`}>
        <td>
          <Link
            to={`/clubadmin/matches/edit/${match.id}`}
          >{`${match.homeTeam?.name} - ${match.awayTeam?.name}`}</Link>
        </td>
        <td>{match.matchStart}</td>
        <td>{match.administrativeTeam?.name}</td>
      </tr>
    ));
  const createMatchesForLeague = async () => {
    try {
      if (!league.teams || league.teams.length === 0) {
        toast.error('No teams linked to this league');
        return;
      }
      await api.clubadmin.leagues.createMatchesForLeague(league.id);
    } catch (e) {
      toast.error('Error syncing dailies');
    }
  };

  return (
    <Container>
      <Card>
        {league.provider === 'IBIS' && (
          <Button color="info" onClick={createMatchesForLeague}>
            Create matches
          </Button>
        )}
        <CardBody>
          <Table responsive bordered striped>
            <thead>
              <tr>
                <th>Match</th>
                <th>Date</th>
                <th>Managed by</th>
              </tr>
            </thead>
            <tbody>{renderMatches(matches)}</tbody>
          </Table>
        </CardBody>
      </Card>
    </Container>
  );
};
export default MatchAdminListPage;
