import React from 'react';
import { partial } from '../../../../util/translation/translation';

const WidgetConfirm = ({ setClose, setConfirm }) => {
  const dT = partial('DashboardPage');
  const sT = partial('shared');

  const handleConfirm = (value) => {
    setClose(value);
    setConfirm(false);
  };

  return (
    <div
      style={{
        zIndex: 10000,
        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
        background: '#fff',
        padding: '3rem 2.3rem',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <svg
        width="51"
        height="50"
        viewBox="0 0 51 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="25.5" cy="25" r="25" fill="#ED9E45" />
        <path
          d="M28.3333 12L27.7222 29.8573H23.2778L22.6481 12H28.3333ZM22.5
            35.3859C22.5 34.6322 22.7716 34.0082 23.3148 33.5136C23.8704 33.0072 24.5988 32.7541
            25.5 32.7541C26.4136 32.7541 27.142 33.0072 27.6852 33.5136C28.2284 34.0082 28.5 34.6322
            28.5 35.3859C28.5 36.1159 28.2284 36.7341 27.6852 37.2405C27.142 37.7468 26.4136 38 25.5
            38C24.5988 38 23.8704 37.7468 23.3148 37.2405C22.7716 36.7341 22.5 36.1159 22.5 35.3859Z"
          fill="white"
        />
      </svg>
      <h3 style={{ marginTop: '2rem', color: '#000' }}>
        {dT('confirmHeading')}?
      </h3>
      <div style={{ marginTop: '2rem' }}>{dT('confirmMessage')}</div>
      <div style={{ display: 'flex', margin: '2rem' }}>
        <button
          onClick={() => handleConfirm(true)}
          style={{
            border: 'none',
            padding: '10px 22px',
            background: '#4181FF',
            color: '#fff',
            borderRadius: '5px',
          }}
        >
          {sT('yes')}
        </button>
        <button
          onClick={() => handleConfirm(false)}
          style={{
            marginLeft: '10px',
            padding: '10px 22px',
            background: '#fff',
            border: '1px solid #4D576A',
            borderRadius: '5px',
          }}
        >
          {sT('no')}
        </button>
      </div>
    </div>
  );
};
export default WidgetConfirm;
