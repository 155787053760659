import React, { useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Button, Col, Label, Row } from 'reactstrap';
import renderField from '../../../shared/components/custom/Field';
import renderSelectField from '../../../shared/components/form/Select';
import renderCheckBoxField from '../../../shared/components/form/CheckBox';

const SwishForm = ({ handleSubmit, initialize, vendor, teams }) => {
  useEffect(() => {
    if (vendor) {
      initialize({
        ...vendor,
        teamId: {
          ...(vendor.team ? vendor.team : {}),
          label: vendor.team?.name,
          value: vendor.team?.id,
        },
      });
    }
  }, [vendor]);
  return (
    <form className="form" onSubmit={handleSubmit}>
      <Row>
        <Col md={6}>
          <div className="form__form-group">
            <Label htmlFor="teamId">Connected team</Label>
            <Field
              name="teamId"
              component={renderSelectField}
              options={teams.map((team) => ({
                label: team.name,
                value: `${team.id}`,
              }))}
            />
          </div>
        </Col>
        <Col md={6}>
          <div className="form__form-group">
            <Label htmlFor="name">Name</Label>
            <Field name="name" component={renderField} />
          </div>
        </Col>
        <Col md={6}>
          <div className="form__form-group">
            <Label htmlFor="config.alias">Swish merchant number</Label>
            <Field name="config.alias" component={renderField} type="" />
          </div>
        </Col>
        <Col md={6}>
          <div className="form__form-group">
            <Label htmlFor="teamId">Sandbox environment</Label>
            <Field name="config.sandbox" component={renderCheckBoxField} />
          </div>
        </Col>
        <Col md={12}>
          <Button color="success">{vendor ? 'Edit' : 'Create'}</Button>
        </Col>
      </Row>
    </form>
  );
};
const rf = reduxForm({
  form: 'swish_form',
});
export default rf(SwishForm);
