import React from 'react';

export default function PlayersIcon(props) {
  return (
    <img
      src="/assets/files/questions/players.svg"
      alt="Players question"
      {...props}
    />
  );
}
