import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import PropTypes from 'prop-types';
import NavTab from '../../shared/components/custom/NavTab';
import UsersTab from './components/UsersTab';
import UserRolesTab from './components/UserRolesTab';
import t, { partial } from '../../util/translation/translation';
import SpotlightSegmentsPage from './SpotlightSegmentsPage';

const UsersPage = () => {
  const [activeTab, setActiveTab] = useState('');
  const s = partial('shared');

  return (
    <Container>
      <div className="tabs tabs--justify tabs--bordered-bottom">
        <div className="tabs__wrap">
          <NavTab
            activeTab={activeTab}
            setTab={setActiveTab}
            tabs={[s('users'), s('roles'), t('HighlightBannerPage.segments')]}
          />
        </div>
      </div>
      {activeTab === '1' && <UsersTab />}
      {activeTab === '2' && <UserRolesTab />}
      {activeTab === '3' && <SpotlightSegmentsPage />}
    </Container>
  );
};

UsersPage.propTypes = {
  me: PropTypes.shape({
    permissions: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default connect((state) => ({
  me: state.admin.me,
}))(UsersPage);
