import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Card,
  CardBody,
  TabPane,
  TabContent,
  Col,
  Button,
} from 'reactstrap';
import { useParams, useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { getFormValues, isValid, touch } from 'redux-form';
import { connect } from 'react-redux';
import moment from 'moment';
import QuestionaireForm from './components/QuestionaireForm';
import NavTab from '../../shared/components/custom/NavTab';
import Api from '../../util/api';
import Loading from '../../shared/components/custom/Loading';
import useModal from '../../shared/hooks/useModal';
import DeleteModal from '../../shared/components/custom/DeleteModal';
// import AuctionHouseBids from './components/AuctionHouseBids';
import QuestionaireAccessForm from './components/QuestionaireAccessForm';
import t, { partial } from '../../util/translation/translation';
import useConfig from '../../util/useConfig';
import TabHeader from '../../shared/components/scheduledMessages/TabHeader';
// import AuctionHouseContactForm from './components/AuctionHouseContactForm';
import LazyTabPane from '../../shared/components/custom/LazyTabPane';
import OfferTypes from '../offers/types';
import QuestionaireQuestionPage from './components/QuestionaireQuestionPage';
import QuestionaireContactForm from './components/QuestionaireContactForm';
import QuestionaireStatsPage from './components/QuestionaireStatsPage';

const QuestionaireFormPage = ({
  questionaireAccessFormValues,
  questionaireFormValues,
  questionaireAccessFormValid,
  questionaireFormValid,
  dispatch,
}) => {
  const { questionaireID } = useParams();
  const history = useHistory();
  const config = useConfig();
  const [handleModal, visible, deleteID] = useModal();
  const [groupsLevelsTiers, setGroupsLevelsTiers] = useState({
    tiers: [],
    groups: [],
    levels: [],
  });
  const [tab, setTab] = useState('0');
  const [questionaire, setQuestionaire] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingAccess, setLoadingAccess] = useState(false);
  const [access, setAccess] = useState({});
  const [numberOfPendingMessagesPerEvent, setNumberOfPendingMessagesPerEvent] =
    useState(0);
  const [scheduleMessageID, setScheduleMessageID] = useState(null);
  const [scheduleMessagesForEvent, setScheduleMessagesForEvent] = useState([]);
  const [messageStatus, setMessageStatus] = useState('Scheduled');
  const [totalAmountOfScheduledMessages, setTotalAmountOfScheduledMessages] =
    useState(0);
  const [startDate, setStartDate] = useState();
  const p = partial('questionaire');
  const s = partial('shared');
  const e = partial('EventsForm');
  const o = partial('OffersFormPage');

  const getQuestionaire = async () => {
    setLoading(true);
    try {
      const resQuestionaire =
        await Api.questionaire.getQuestionaire(questionaireID);
      setQuestionaire(resQuestionaire);
      setLoading(false);
    } catch (err) {
      toast.error(JSON.stringify(err));
      setLoading(false);
    }
  };
  const getScheduleMessagesByServiceId = async (page = 1, pageSize = 10) => {
    try {
      let messageByEventId = [];

      if (messageStatus === t('ScheduledMessagesPage.filterByStatus')) {
        messageByEventId =
          await Api.scheduleMessages.getScheduleMessagesByServiceId(
            questionaireID,
            page,
            pageSize
          );
      } else {
        messageByEventId =
          await Api.scheduleMessages.getScheduleMessagesByServiceIdByStatus(
            questionaireID,
            page,
            pageSize,
            messageStatus
          );
      }
      if (messageStatus === 'Scheduled') {
        setNumberOfPendingMessagesPerEvent(messageByEventId?.data?.total || 0);
      }
      setScheduleMessagesForEvent(messageByEventId.data.data);
      setTotalAmountOfScheduledMessages(messageByEventId.data.total);
    } catch (error) {
      toast.error(error);
    }
  };
  const handleContact = async (values) => {
    const { title, message, type, timeToSend, notificationLabel } = values;
    const scheduledDate =
      timeToSend === 'SCHEDULED' ? moment.utc(startDate).format() : null;
    const payload = {
      title,
      message,
      type,
      tiers: [],
      levels: [],
      groups: [],
      notificationLabel: notificationLabel?.value || null,
    };
    try {
      if (timeToSend === 'SCHEDULED' && scheduleMessageID) {
        await Api.questionaire.updateScheduledMessage(
          questionaireID,
          scheduleMessageID,
          { ...payload, scheduledDate }
        );
        toast.success(`${t('ScheduledMessagesPage.scheduleMessageUpdated')}`);
      } else if (timeToSend === 'INSTANT') {
        await Api.questionaire.contactQuestionaire(questionaireID, payload);
      } else {
        await Api.questionaire.contactQuestionaire(questionaireID, {
          ...payload,
          scheduledDate,
        });
        toast.success(`${t('ScheduledMessagesPage.messageScheduled')}`);
      }
      if (timeToSend === 'INSTANT') {
        if (type === OfferTypes.CommunicationTypes.push) {
          toast.success(o(`${'pushNotificationSent'}`));
        } else if (type === OfferTypes.CommunicationTypes.email) {
          toast.success(o(`${'emailSent'}`));
        } else {
          toast.success(o(`${'smsSent'}`));
        }
      }
    } catch (_e) {
      toast.error(s(`${'somethingWentWrong'}`));
    }
    getScheduleMessagesByServiceId();
    setScheduleMessageID(null);
  };
  const getQuestionaireAccess = async () => {
    setLoadingAccess(true);
    try {
      const [resAccess, resLevels, resGroups, { data: resTiers }] =
        await Promise.all([
          Api.questionaire.getQuestionaireAccess(questionaireID),
          Api.club.getLevels(),
          Api.club.getGroups(),
          Api.tiers.allTiers(config),
        ]);
      const mappedTiers = [
        { label: e('allTiers'), value: [] },
        { label: s('ignore'), value: null },
        ...resTiers.map((item) => ({ label: item.name, value: item.id })),
      ];
      const mappedGroups = [
        { label: e('allGroups'), value: [] },
        { label: s('ignore'), value: null },
        ...resGroups.map((item) => ({ label: item.value, value: item.value })),
      ];
      const mappedLevels = [
        { label: e('allLevels'), value: [] },
        { label: s('ignore'), value: null },
        ...resLevels.map((item) => ({ label: item.value, value: item.value })),
      ];
      setGroupsLevelsTiers({
        tiers: mappedTiers,
        groups: mappedGroups,
        levels: mappedLevels,
      });
      setLoadingAccess(false);
      setAccess(resAccess);
    } catch (err) {
      toast.error(err || s('somethingWentWrong'));
      setLoadingAccess(false);
    }
  };
  const getGroupsTiersLevels = async () => {
    setLoadingAccess(true);
    try {
      const [resLevels, resGroups, { data: resTiers }] = await Promise.all([
        Api.club.getLevels(),
        Api.club.getGroups(),
        Api.tiers.allTiers(config),
      ]);
      const mappedTiers = [
        { label: e('allTiers'), value: [] },
        { label: s('ignore'), value: null },
        ...resTiers.map((item) => ({ label: item.name, value: item.id })),
      ];
      const mappedGroups = [
        { label: e('allGroups'), value: [] },
        { label: s('ignore'), value: null },
        ...resGroups.map((item) => ({ label: item.value, value: item.value })),
      ];
      const mappedLevels = [
        { label: e('allLevels'), value: [] },
        { label: s('ignore'), value: null },
        ...resLevels.map((item) => ({ label: item.value, value: item.value })),
      ];
      setGroupsLevelsTiers({
        tiers: mappedTiers,
        groups: mappedGroups,
        levels: mappedLevels,
      });
      setLoadingAccess(false);
    } catch (err) {
      toast.error(err || s('somethingWentWrong'));
      setLoadingAccess(false);
    }
  };

  useEffect(() => {
    if (questionaireID) {
      getQuestionaire();
      getQuestionaireAccess();
    } else {
      getGroupsTiersLevels();
    }
  }, [questionaireID]);

  const onQuestionaireSubmit = async (values) => {
    const payload = {
      // type: values.type.value,
      type: 'POLL',
      description: values.description,
      image: values.headingImage.croppedBase64 || values.headingImage.preview,
      visible: values.visible,
      editAnswers: values.editAnswers,
      startDate: moment(values.startDate),
      endDate: moment(values.endDate),
      title: values.title,
      subtitle: values.subtitle,
    };
    setLoading(true);
    let finalQuestionaireId = null;
    const clone = window.location.pathname.split('/')[3] === 'clone';
    try {
      if (questionaireID && !clone) {
        await Api.questionaire.updateQuestionaire(questionaireID, payload);
      } else {
        const createQuestionaireResponse =
          await Api.questionaire.createQuestionaire(payload);
        finalQuestionaireId = createQuestionaireResponse.id;
      }
    } catch (err) {
      toast.error(err || s('somethingWentWrong'));
    }
    setLoading(false);
    if (clone) {
      // eslint-disable-next-line consistent-return
      return { finalId: finalQuestionaireId, isNew: true };
    }
    // eslint-disable-next-line consistent-return
    return {
      finalId: questionaireID || finalQuestionaireId,
      isNew: !!finalQuestionaireId,
    };
  };

  const deleteQuestionaire = async () => {
    setLoading(true);
    try {
      await Api.questionaire.deleteQuestionaire(deleteID);
      toast.success(p('questionaireRemoved'));
    } catch (err) {
      toast.success(p('failedToRemoveQuestionaire'));
    } finally {
      handleModal();
      setLoading(false);
    }
  };
  const pollQuestionaireAccess = async (
    _questionaireId,
    callBack,
    iteration = 0
  ) => {
    if (iteration > 10) {
      toast.error(p('updatingAccessFailed'));
      history.push('/questionnaires');
      return;
    }
    try {
      await Api.questionaire.getQuestionaireAccess(_questionaireId);
      callBack();
    } catch (err) {
      setTimeout(
        () => pollQuestionaireAccess(_questionaireId, callBack, iteration + 1),
        1000
      );
    }
  };
  const getAccessValues = (searchArray) => {
    if (searchArray.length === 0) {
      return null;
    }
    const foundItem = searchArray.find(
      (item) => Array.isArray(item.value) || item.value === null
    );
    if (foundItem) {
      return foundItem.value;
    }
    return searchArray.map((item) => item.value);
  };
  const onAccessSubmit = async (_questionaireId, values) => {
    const payload = {
      tiers: getAccessValues(values?.tier || []),
      groups: getAccessValues(values?.group || []),
      levels: getAccessValues(values?.level || []),
      noAccessView: values?.noAccessView || false,
    };
    setLoadingAccess(true);
    try {
      await Api.questionaire.updateQuestionaireAccess(_questionaireId, payload);
      history.push(`/questionnaires/edit/${_questionaireId}?tab=2`);
      toast.success(p('questionaireUpdated'));
    } catch (err) {
      toast.error(p('updatingAccessFailed'));
    }
    setLoadingAccess(false);
  };
  const handleQuestionaireSubmit = async () => {
    if (!questionaireFormValid) {
      dispatch(
        touch(
          'questionaire_form',
          'title',
          'headingImage',
          'description',
          'subtitle',
          'visible',
          'startDate',
          'endDate',
          'type'
        )
      );
      return;
    }
    if (!questionaireAccessFormValid) {
      dispatch(touch('questionaire_access_form', 'level', 'group', 'tier'));
      return;
    }
    const questionaireData = await onQuestionaireSubmit(questionaireFormValues);
    if (!questionaireData) {
      return;
    }
    if (questionaireData.isNew) {
      setLoadingAccess(true);
      pollQuestionaireAccess(questionaireData.finalId, () =>
        onAccessSubmit(questionaireData.finalId, questionaireAccessFormValues)
      );
    } else {
      await onAccessSubmit(
        questionaireData.finalId,
        questionaireAccessFormValues
      );
    }
  };

  return (
    <Container>
      <DeleteModal
        visible={visible}
        handleModal={handleModal}
        type={p('theQuestionaire')}
        modalAction={deleteQuestionaire}
      />
      <Loading loading={loading || loadingAccess} />
      <Row>
        <Col>
          <Card>
            <CardBody className="p-0">
              <NavTab
                tabs={[
                  { tab: `${s('details')}` },
                  {
                    tab: `${s('questions')}`,
                    disabled:
                      !questionaireID ||
                      window.location.pathname.split('/')[3] === 'clone',
                  },
                  {
                    tab: `${p('answers')}`,
                    disabled:
                      !questionaireID ||
                      window.location.pathname.split('/')[3] === 'clone',
                  },
                  {
                    tab: (
                      <TabHeader
                        count={numberOfPendingMessagesPerEvent}
                        heading={s('communication')}
                        iconSize="22"
                        limit={9}
                      />
                    ),
                    disabled:
                      !questionaireID ||
                      window.location.pathname.split('/')[3] === 'clone',
                  },
                ]}
                setTab={setTab}
                activeTab={tab}
                size="sm"
              />
              <TabContent activeTab={tab}>
                <TabPane tabId="1" className="m-3">
                  <QuestionaireForm
                    type={questionaireID ? 'edit' : 'create'}
                    questionaire={questionaire}
                  />
                  <QuestionaireAccessForm
                    access={access}
                    groupsLevelsTiers={groupsLevelsTiers}
                  />
                  <Col md={12}>
                    <Button color="primary" onClick={handleQuestionaireSubmit}>
                      {questionaireID ? s('save') : s('create')}
                    </Button>
                    <Button
                      type="button"
                      onClick={() => history.push('/questionnaires')}
                    >
                      {s('close')}
                    </Button>
                  </Col>
                </TabPane>
                <LazyTabPane activeTab={tab} tabId="2" className="m-3">
                  <QuestionaireQuestionPage
                    questionaireId={questionaireID}
                    hasAnswers={questionaire?.hasAnswers}
                  />
                </LazyTabPane>
                <LazyTabPane activeTab={tab} tabId="3" className="m-3">
                  <QuestionaireStatsPage
                    questionaireId={questionaireID}
                    title={questionaire?.title}
                  />
                </LazyTabPane>
                <TabPane tabId="4" className="m-3">
                  <QuestionaireContactForm
                    onSubmit={handleContact}
                    questionaire={questionaire}
                    scheduleMessageID={scheduleMessageID}
                    setScheduleMessageID={setScheduleMessageID}
                    scheduleMessagesForEvent={scheduleMessagesForEvent}
                    getScheduleMessagesByServiceId={
                      getScheduleMessagesByServiceId
                    }
                    setScheduleMessagesForEvent={setScheduleMessagesForEvent}
                    totalAmountOfScheduledMessages={
                      totalAmountOfScheduledMessages
                    }
                    messageStatus={messageStatus}
                    setMessageStatus={setMessageStatus}
                    tiers={groupsLevelsTiers.tiers}
                    startDate={startDate}
                    setStartDate={setStartDate}
                  />
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  questionaireAccessFormValues: getFormValues('questionaire_access_form')(
    state
  ),
  questionaireFormValues: getFormValues('questionaire_form')(state),
  questionaireAccessFormValid: isValid('questionaire_access_form')(state),
  questionaireFormValid: isValid('questionaire_form')(state),
});
export default connect(mapStateToProps)(QuestionaireFormPage);
