import { partial } from '../../../../../../util/translation/translation';

const validatePresale = (values) => {
  const p = partial('validateErrors');
  const errors = {};

  if (!values.user) {
    errors.user = p('userFieldCantBeEmpty');
  }

  if (!values.reservation) {
    errors.reservation = p('reservationFieldCantBeEmpty');
  }

  return errors;
};

export default validatePresale;
