import { partial } from '../../../util/translation/translation';

const attendanceValidate = (values) => {
  const p = partial('AttendanceValidate');

  const errors = {};
  if (!values.phoneNumber) {
    errors.phoneNumber = `${p('phoneFormEmptyError')}`;
  } else if (values.phoneNumber.length <= 4) {
    errors.phoneNumber = `${p('shortPhonenumberError')}`;
  }

  if (!values.firstname) {
    errors.firstname = `${p('firstnameEmptyError')}`;
  }

  if (!values.lastname) {
    errors.lastname = `${p('lastnameEmptyError')}`;
  }

  return errors;
};

export default attendanceValidate;
