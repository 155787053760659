import React, { Component } from 'react';
import {
  Container,
  Col,
  Row,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import moment from 'moment';
import { toast } from 'react-toastify';
import classnames from 'classnames';
import { getFormValues } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import UserForm from './components/UserForm';
import PermissionForm from './components/PermissionForm';
import Api from '../../util/api';
import Loading from '../../shared/components/custom/Loading';
import { partial } from '../../util/translation/translation';
import config from '../../config';

const p = partial('CreateUserPage');
const s = partial('shared');

class CreateUsersPage extends Component {
  static propTypes = {
    formValues: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      countryCode: PropTypes.string,
      username: PropTypes.string,
      password: PropTypes.string,
      verified: PropTypes.bool,
      gender: PropTypes.string,
      postalCode: PropTypes.string,
      birthday: PropTypes.string,
      email: PropTypes.string,
    }),
    history: PropTypes.shape({
      replace: PropTypes.func,
    }).isRequired,
  };

  static defaultProps = {
    formValues: null,
  };

  constructor() {
    super();
    this.state = {
      loading: false,
      activeTab: '1',
      permissions: [],
    };
  }

  componentDidMount() {
    this.getPermissions();
  }

  getPermissions = async () => {
    try {
      const availableRoles = await Api.users.getAvailableRoles();
      const mapped = availableRoles.map((role) => ({
        ...role,
        toggled: false,
      }));
      this.setState({ permissions: mapped });
    } catch (err) {
      toast.error(err || p('couldNotFetchPolicies'));
    }
  };

  handleToggle = (e) => {
    const permissions = [...this.state.permissions];

    this.state.permissions[e].toggled = !this.state.permissions[e].toggled;
    this.setState({ permissions });
  };

  handleSubmit = async () => {
    const payload = {
      password: this.props.formValues.password,
      country_code: this.props.formValues.countryCode,
      phoneNumber: this.props.formValues.username,
      firstname: this.props.formValues.firstName,
      lastname: this.props.formValues.lastName,
      gender: this.props.formValues.gender,
      zip: this.props.formValues.postalCode,
      date_of_birth:
        this.props.formValues.birthday &&
        moment(this.props.formValues.birthday).format(),
      email: this.props.formValues.email,
      teamName: config?.cloudIdName,
    };
    if (this.props.formValues.password?.length < 8) {
      return toast.error(p('passwordTooShort'));
    }
    try {
      this.setState({ loading: true });
      const { data: teamUserResponse } = await Api.users.createUser(payload);
      toast.success(p('userWasCreated'));
      const toggledPermisisons = this.state.permissions.filter(
        (item) => item.toggled
      );
      if (toggledPermisisons.length > 0) {
        try {
          const promises = toggledPermisisons.map((perm) =>
            Api.users.updateUserRoles(teamUserResponse.id, perm.name, true)
          );
          await Promise.all(promises);
          toast.success(p('userWasAssignedRoles'));
        } catch (e) {
          this.setState({ loading: false });
          toast.error(e || p('errorGivingUserPolicies'));
        }
      }
      this.setState({ loading: false });
      return this.props.history.replace('/users');
    } catch (err) {
      this.setState({ loading: false });
      const errorMessage = err.data.message;
      if (errorMessage.includes('emailExists')) {
        return toast.error(p('emailExists'));
      }
      if (errorMessage.includes('phoneExists')) {
        return toast.error(p('phoneExists'));
      }
      if (errorMessage.includes('firebaseError')) {
        return toast.error(p('invalidEmailPhone'));
      } else {
        return toast.error(err || p('userWasNotCreated'));
      }
    }
  };

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  render() {
    return (
      <Container>
        <Row>
          <Col>
            <h3 className="page-title">{p('newUser')}</h3>
          </Col>
        </Row>
        <Card>
          <CardBody>
            <div className="tabs tabs--justify tabs--bordered-top overflowY-hidden">
              <div className="tabs__wrap">
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === '1',
                      })}
                      onClick={() => {
                        this.toggle('1');
                      }}
                    >
                      {s('user')}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === '2',
                      })}
                      onClick={() => {
                        this.toggle('2');
                      }}
                    >
                      {s('policies')}
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1">
                    <UserForm onSubmit={this.handleSubmit} type="create" />
                  </TabPane>
                  <TabPane tabId="2">
                    <PermissionForm
                      permissions={this.state.permissions}
                      handleToggle={this.handleToggle}
                    />
                  </TabPane>
                </TabContent>
              </div>
            </div>
          </CardBody>
        </Card>
        <Loading loading={this.state.loading} />
      </Container>
    );
  }
}

export default withRouter(
  connect((state) => ({
    formValues: getFormValues('UserForm')(state),
  }))(CreateUsersPage)
);
