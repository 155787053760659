import React from 'react';

export default function RadioIcon(props) {
  return (
    <img
      src="/assets/files/questions/radio.svg"
      alt="Radio question"
      {...props}
    />
  );
}
