import { partial } from '../../../util/translation/translation';

const validate = (values) => {
  const p = partial('validateErrors');
  const errors = {};
  if (!values.tier) {
    errors.tier = p('tierCantBeEmpty');
  }
  if (!values.neverEnd && !values.dueDate) {
    errors.dueDate = p('endDateFieldCantBeEmpty');
  }
  return errors;
};

export default validate;
