import React from 'react';
import { isSpotlightExclusive, getCircleStyleFromSize } from './SpotlightUtils';
import ExclusiveLabel from './ExclusiveLabel';

const CircleItem = ({ tile, access, format, size }) => {
  const { headingImage } = tile;
  const isExclusive = isSpotlightExclusive(access);

  return (
    <div
      style={{
        ...getCircleStyleFromSize(size),
        borderRadius: 300,
        boxShadow: '0px 5px 15px -5px #000000',
        position: 'relative',
      }}
    >
      <div>
        <img
          resizeMode="cover"
          src={headingImage?.preview}
          style={{ ...getCircleStyleFromSize(size), borderRadius: 300 }}
          alt=""
        />
      </div>
      <ExclusiveLabel visible={isExclusive} format={format} />
    </div>
  );
};

export default CircleItem;
