import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { Col, Container, Row, CardBody, Card } from 'reactstrap';
import Api from '../../util/api';
import DiscountCodeForm from './components/DiscountCodeForm';
import Loading from '../../shared/components/custom/Loading';
import { partial } from '../../util/translation/translation';
import { formatDateForApi } from '../../util/date-and-time';

const s = partial('shared');
const p = partial('DiscountCodeFormPage');

const DiscountCodeFormPage = () => {
  const [discountCode, setDiscountCode] = useState(null);
  const [matches, setMatches] = useState([]);
  const [loading, setLoading] = useState(false);

  const { discountCodeId } = useParams();
  const history = useHistory();

  const getDiscountCode = async () => {
    if (discountCodeId) {
      setLoading(true);
      try {
        const resDiscountCode = await Api.discountCode.show(discountCodeId);

        setDiscountCode(resDiscountCode.data);
        setLoading(false);
      } catch (err) {
        toast.error(err);
        setLoading(false);
      }
    }
  };

  const getMatches = async () => {
    try {
      const resMatches = await Api.matches.getFutureMatches(100);
      setMatches(resMatches.data);
    } catch (err) {
      toast.error(p('unableToFetchMatches'));
    }
  };

  useEffect(() => {
    getMatches();
    getDiscountCode();
  }, [discountCodeId]);

  const onSubmit = async (values) => {
    const payload = {
      name: values.name,
      sale_start: formatDateForApi(values.saleStart),
      sale_end: values.saleEnd ? formatDateForApi(values.saleEnd) : null,
      description: values.description,
      amount: values.amount,
      price: values.price,
      code: values.code,
      season: values.season === 'season',
      use_percentage: values.usePercentage,
      specific_matches:
        values.season === 'specificMatches'
          ? values.specificMatches.map((match) => +match.value)
          : [],
    };
    setLoading(true);
    if (discountCodeId) {
      try {
        await Api.discountCode.update(payload, discountCodeId);
        toast.success(`${p('discountCodeUpdated')}`);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        toast.error(err || `${p('discountCodeUpdatingERROR')}`);
      }
    } else {
      try {
        await Api.discountCode.create(payload);
        toast.success(`${p('discountCodeCreated')}`);
        history.replace('/discounts?tab=2');
      } catch (err) {
        setLoading(false);
        toast.error(err || `${p('discountCodeCreatingERROR')}`);
      }
    }
  };

  return (
    <Container>
      <Loading loading={loading} />
      <Row>
        <Col>
          <h3 className="page-title">
            {discountCodeId ? `${s('edit')}` : `${s('create')}`}{' '}
            {s('discountCode')}
          </h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <DiscountCodeForm
                discountCode={discountCode}
                onSubmit={onSubmit}
                matches={matches}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default DiscountCodeFormPage;
