import { SET_EDIT_SEASON_DATA } from '../actions/seasonActions';

const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_EDIT_SEASON_DATA:
      return action.seat;
    default:
      return state;
  }
}
