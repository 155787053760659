import React from 'react';
import {
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import DotsHorizontalIcon from 'mdi-react/DotsHorizontalIcon';
import { Link } from 'react-router-dom';
import t, { partial } from '../../../util/translation/translation';

const NewsProvidersList = ({ providers }) => {
  const s = partial('shared');
  const renderProviders = () => {
    return providers.map((provider) => {
      return (
        <tr key={`newsProvider-${provider.id}`} className="center-list">
          <td>
            <Link to={`/news_providers/edit/${provider.id}`}>
              {provider.type}
            </Link>
          </td>
          <td>{provider?.category?.name || ''}</td>
          <td>
            <UncontrolledDropdown className="dashboard__table-more">
              <DropdownToggle>
                <DotsHorizontalIcon />
              </DropdownToggle>
              <DropdownMenu className="dropdown__menu">
                <Link to={`/news_providers/edit/${provider.id}`}>
                  <DropdownItem>{s('edit')}</DropdownItem>
                </Link>
              </DropdownMenu>
            </UncontrolledDropdown>
          </td>
        </tr>
      );
    });
  };
  return (
    <div className="mt-4">
      <div className="flex space-between">
        <div>
          <h3 className="page-title mb-0">{t('NewsPage.newsProviders')}</h3>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          paddingBottom: 5,
          marginBottom: 10,
          background: '#fff',
          borderRadius: 6,
          boxShadow: '0px 2px 8px 0px rgba(0,0,0,0.08)',
        }}
      >
        <Table responsive striped>
          <thead>
            <tr>
              <th>
                <div className="flex alignItems-center">{s('type')}</div>
              </th>
              <th>
                <div className="flex alignItems-center">{s('category')}</div>
              </th>
              <th />
            </tr>
          </thead>
          <tbody>{renderProviders()}</tbody>
        </Table>
      </div>
    </div>
  );
};

export default NewsProvidersList;
