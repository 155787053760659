import React, { useEffect, useState } from 'react';
import { Container, Col, Card, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import MatchTypeForm from './components/MatchTypeForm';
import Api from '../../util/api';
import Loading from '../../shared/components/custom/Loading';
import { convertImageToBlob } from '../../util/image-functions';
import { partial } from '../../util/translation/translation';

const MatchTypeFormPage = ({ match }) => {
  const [loading, setLoading] = useState(false);
  const [matchType, setMatchType] = useState(null);
  const history = useHistory();
  const p = partial('settingsObjectsShared');

  const getMatchType = async () => {
    setLoading(true);
    try {
      const resMatchType = await Api.settings.getMatchType(
        match.params.matchID
      );
      setMatchType(resMatchType.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(p('errorFetchingMatchType'));
    }
  };

  useEffect(() => {
    if (match.params.matchID) {
      getMatchType();
    }
  }, [match.params.matchID]);

  const handleSubmit = async (values) => {
    let icon = values?.icon?.[0];
    if (values.icon === undefined || values.icon.length < 1) {
      toast.error(p('mustUploadMatchTypePicture'));
      return;
    } else if (values.icon[0] instanceof Blob) {
      icon = await convertImageToBlob(values.icon[0]);
    }

    const payload = {
      name: values.name,
      season_inclusion: values.seasonInclusion,
      icon,
    };

    try {
      setLoading(true);
      if (match.params.matchID) {
        await Api.settings.updateMatchType(payload, match.params.matchID);
        toast.success(p('matchTypeWasUpdated'));
        setLoading(false);
      } else {
        await Api.settings.createMatchType(payload);
        history.replace('/settings?tab=3');
        toast.success(p('matchTypeWasUpdated'));
      }
    } catch (err) {
      toast.error(err);
      setLoading(false);
    }
  };

  return (
    <Container>
      <Loading loading={loading} />
      <Col>
        <h3 className="page-title">
          {match.params.matchID ? p('editMatchType') : p('newMatchType')}
        </h3>
      </Col>
      <Col>
        <Card>
          <CardBody>
            <MatchTypeForm matchType={matchType} onSubmit={handleSubmit} />
          </CardBody>
        </Card>
      </Col>
    </Container>
  );
};

MatchTypeFormPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func,
  }).isRequired,
};

export default MatchTypeFormPage;
