import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Api from '../../util/api';
import Loading from '../../shared/components/custom/Loading';
import LeagueForm from './components/LeagueForm';
import MatchAdminListPage from './MatchAdminListPage';

const LeagueAdminCreatePage = () => {
  const { leagueId } = useParams();
  const history = useHistory();
  const [league, setLeague] = useState(undefined);
  const [seasons, setSeasons] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchLeague = async (id) => {
    setLoading(true);
    try {
      const { data } = await Api.clubadmin.leagues.fetch(id);
      setLeague(data);
    } catch (e) {
      toast.error(e);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (value) => {
    const image = value.imageUrl ? value.imageUrl[0].value : undefined;
    const noPlayerImageUrl = value.noPlayerImageUrl
      ? value.noPlayerImageUrl[0].value
      : undefined;
    const payload = {
      ...value,
      imageUrl: image,
      noPlayerImageUrl,
      season: value.season.value,
    };
    if (leagueId) {
      payload.id = +leagueId;
    }
    setLoading(true);
    try {
      const { data } = leagueId
        ? await Api.clubadmin.leagues.update(payload)
        : await Api.clubadmin.leagues.create(payload);
      if (leagueId && data) {
        fetchLeague(leagueId);
      } else {
        history.push(`/clubadmin/league/edit/${data.id}`);
      }
    } catch (e) {
      toast.error(e);
    } finally {
      setLoading(false);
    }
  };
  const fetchSeasons = async () => {
    try {
      const { data } = await Api.clubadmin.seasons.all();
      setSeasons(data);
    } catch (e) {
      toast.error(e);
    }
  };
  useEffect(() => {
    if (leagueId) {
      fetchLeague(leagueId);
    }
    fetchSeasons();
  }, [leagueId]);

  return (
    <Container>
      <Loading loading={loading} />
      <Row>
        <Col>
          <h3 className="page-title">{league ? 'Edit' : 'Create'} League</h3>
        </Col>
      </Row>
      <Row>
        <Card>
          <CardBody>
            <LeagueForm
              league={league}
              seasons={seasons}
              onSubmit={handleSubmit}
            />
            {league && (
              <MatchAdminListPage matches={league.matches} league={league} />
            )}
          </CardBody>
        </Card>
      </Row>
    </Container>
  );
};

export default LeagueAdminCreatePage;
