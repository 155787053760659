import React, { useState, useEffect } from 'react';
import {
  Button,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import ArrowDownIcon from 'mdi-react/ArrowDownIcon';
import Api from '../../../util/api';
import { partial } from '../../../util/translation/translation';

const PackageConflictForm = ({ handleSubmit, errors, setErrors }) => {
  const [fields, setFields] = useState([]);
  const [selectedField, setSelectedField] = useState({});
  const [selectedRow, setSelectedRow] = useState({});
  const [selectedSeat, setSelectedSeat] = useState({});
  const [errorId, setErrorId] = useState(undefined);
  const [rows, setRows] = useState([]);
  const [selectRow, setSelectRow] = useState([]);
  const [seats, setSeats] = useState([]);
  const p = partial('PackageConflictForm');
  const s = partial('shared');

  const assignTicket = (seat, row, field, error) => async () => {
    const payload = {
      seat_ids: [seat.id],
      match_id: error.conflict.seat_match.match.id,
      ticket_state_id: error.original.ticket_state.id,
      price_group_id: error.original.price_group.id,
      user_id: error.original.user.id,
      vip: error.original.vip,
      shareable: error.original.shareable,
      payment_id: error.original.payment_id,
      notify_user: false,
    };
    try {
      await Api.tickets.assignTickets(payload);
      setErrors(errors.filter((err) => err.conflict.id !== error.conflict.id));
    } catch (e) {
      toast.error(p('savingTicketFailed'));
    }
  };

  const handleFieldClick = (field, error) => async () => {
    setErrorId(error);
    setSelectRow([]);
    setSeats([]);
    setSelectedField((prevState) => ({ ...prevState, [error]: field }));
    if (rows.length === 0) {
      try {
        const res = await Api.tickets.getRows();
        setRows(res.data);
        setSelectRow(res.data.filter((row) => row.field.id === field.id));
      } catch (e) {
        toast.error(p('fetchingRowsFailed'));
      }
    } else {
      setSelectRow(rows.filter((row) => row.field.id === field.id));
    }
  };
  const handleRowClick = (row, error) => async () => {
    setSelectedRow((prevState) => ({ ...prevState, [error.conflict.id]: row }));
    try {
      const res = await Api.tickets.getSeats(
        error.conflict.seat_match.match.id,
        row.field.id,
        row.id,
        ''
      );
      setSeats(
        res.data.filter(
          (seat) => !seat.match_seat_state_id || seat.match_seat_state_id === 1
        )
      );
    } catch (e) {
      toast.error(p('fetchingSeatsFailed'));
    }
  };
  const handleSeatClick = (seat, error) => () => {
    setSelectedSeat((prevState) => ({
      ...prevState,
      [error.conflict.id]: seat,
    }));
  };
  useEffect(() => {
    const getFields = async () => {
      try {
        const res = await Api.tickets.getFields(
          errors[0].conflict.seat_match.match.id
        );
        setFields(res.data);
      } catch (e) {
        toast.error(p('fetchingFieldsFailed'));
      }
    };
    if (errors.length > 0 && fields.length === 0) {
      getFields();
    }
  }, [errors, fields]);
  const listConflicts = () => {
    return errors.map((error) => {
      // eslint-disable-next-line camelcase
      const { seat_match, id } = error.conflict;
      const { user } = error.original;
      return (
        <tr key={`userconflict-${id}`}>
          <td>{`${user.firstname} ${user.lastname}`}</td>
          <td>{`${seat_match.match.match_name}`}</td>
          <td>{`${s('field')}: ${seat_match.seat.row.field.name}, ${s('row')}: ${seat_match.seat.row.number}, ${s('seat')}: ${seat_match.seat.seat_id}`}</td>
          <td>
            <UncontrolledDropdown>
              <DropdownToggle>
                <p>
                  {selectedField[id] ? selectedField[id].name : s('field')}{' '}
                  <ArrowDownIcon />
                </p>
              </DropdownToggle>
              <DropdownMenu className="dropdown__menu" positionFixed={false}>
                {fields.map((field) => (
                  <DropdownItem
                    key={`Field:${field.id}`}
                    onClick={handleFieldClick(field, id)}
                  >
                    {field.name}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          </td>
          <td>
            <UncontrolledDropdown>
              <DropdownToggle>
                <p>
                  {selectedRow[id] ? selectedRow[id].number : s('row')}
                  <ArrowDownIcon />
                </p>
              </DropdownToggle>
              <DropdownMenu
                className="dropdown__menu"
                modifiers={{
                  setMaxHeight: {
                    enabled: true,
                    order: 890,
                    fn: (data) => {
                      return {
                        ...data,
                        styles: {
                          ...data.styles,
                          overflow: 'auto',
                          maxHeight: '100px',
                        },
                      };
                    },
                  },
                }}
              >
                {selectRow.length > 0 &&
                  errorId === id &&
                  selectRow.map((row) => (
                    <DropdownItem
                      key={`row:${row.id}`}
                      onClick={handleRowClick(row, error)}
                    >
                      {row.number}
                    </DropdownItem>
                  ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          </td>
          <td>
            <UncontrolledDropdown>
              <DropdownToggle>
                <p>
                  {selectedSeat[id] ? selectedSeat[id].seat_id : s('seat')}
                  <ArrowDownIcon />
                </p>
              </DropdownToggle>
              <DropdownMenu
                className="dropdown__menu"
                modifiers={{
                  setMaxHeight: {
                    enabled: true,
                    order: 890,
                    fn: (data) => {
                      return {
                        ...data,
                        styles: {
                          ...data.styles,
                          overflow: 'auto',
                          maxHeight: '100px',
                        },
                      };
                    },
                  },
                }}
              >
                {seats.length > 0 &&
                  errorId === id &&
                  seats.map((seat) => (
                    <DropdownItem
                      key={`seat:${seat.id}`}
                      onClick={handleSeatClick(seat, error)}
                    >
                      {seat.seat_id}
                    </DropdownItem>
                  ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          </td>
          <td>
            <Button
              color="primary"
              onClick={assignTicket(
                selectedSeat[id],
                selectedField[id],
                selectedSeat[id],
                error
              )}
              disabled={
                !selectedSeat[id] || !selectedSeat[id] || !selectedRow[id]
              }
            >
              {s('assign')}
            </Button>
          </td>
        </tr>
      );
    });
  };

  return (
    <form className="form" onSubmit={handleSubmit}>
      <Container>
        <Table responsive bordered>
          <thead>
            <tr>
              <th>{s('user')}</th>
              <th>{s('match')}</th>
              <th>{p('originalSeat')}</th>
              <th>{p('moveToField')}</th>
              <th>{p('moveToRow')}</th>
              <th>{p('moveToSeat')}</th>
              <th>{s('perform')}</th>
            </tr>
          </thead>
          <tbody>{listConflicts()}</tbody>
        </Table>
      </Container>
    </form>
  );
};

PackageConflictForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      original: PropTypes.shape({
        id: PropTypes.number,
        seat_match_id: PropTypes.number,
        assigned_by: PropTypes.number,
        season_ticket_id: PropTypes.number,
        payment_id: PropTypes.number,
        shareable: PropTypes.number,
        vip: PropTypes.number,
        created_at: PropTypes.string,
        updated_at: PropTypes.string,
        user_notified: PropTypes.number,
        user: PropTypes.shape({
          id: PropTypes.number,
        }),
        ticket_state: PropTypes.shape({
          id: PropTypes.number,
        }),
        price_group: PropTypes.shape({
          id: PropTypes.number,
        }),
      }),
      conflict: PropTypes.shape({
        id: PropTypes.number,
        seat_match_id: PropTypes.number,
        assigned_by: PropTypes.number,
        season_ticket_id: PropTypes.number,
        payment_id: PropTypes.number,
        shareable: PropTypes.number,
        vip: PropTypes.number,
        created_at: PropTypes.string,
        updated_at: PropTypes.string,
        user_notified: PropTypes.number,
        user: PropTypes.shape({
          id: PropTypes.number,
          firstname: PropTypes.string,
          app_version: PropTypes.string,
          zip: PropTypes.string,
          date_of_birth: PropTypes.string,
          gender: PropTypes.string,
          remember_token: PropTypes.string,
          created_at: PropTypes.string,
          updated_at: PropTypes.string,
          token: PropTypes.string,
          verified: PropTypes.number,
          facebook_id: PropTypes.string,
          fansaldo: PropTypes.number,
          profile_image_id: PropTypes.number,
          fansaldo_name: PropTypes.string,
        }),
        seat_match: PropTypes.shape({
          id: PropTypes.number,
          created_at: PropTypes.string,
          updated_at: PropTypes.string,
          match: PropTypes.shape({
            id: PropTypes.number,
            ignore_stadium: PropTypes.number,
            season_id: PropTypes.number,
            match_type_id: PropTypes.number,
            apply_fees: PropTypes.number,
            match_name: PropTypes.string,
          }),
          seat: PropTypes.shape({
            seat_id: PropTypes.number,
            row: PropTypes.shape({
              number: PropTypes.number,
              field: PropTypes.shape({
                name: PropTypes.string,
              }),
            }),
          }),
        }),
      }),
    })
  ).isRequired,
  setErrors: PropTypes.func.isRequired,
};

export default PackageConflictForm;
