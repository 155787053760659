import React from 'react';
import configs from '../../configs';

const HomePage = () => {
  const renderClubs = () => {
    return Object.values(configs).map((value) => {
      return value;
    });
  };

  renderClubs();
  return (
    <div>
      <p>HomePage</p>
    </div>
  );
};

export default HomePage;
