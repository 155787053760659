import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { connect } from 'react-redux';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { useLocation, Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import AlertCircleOutlineIcon from 'mdi-react/AlertCircleOutlineIcon';
import TimetableIcon from 'mdi-react/TimetableIcon';
import moment from 'moment';
import { toast } from 'react-toastify';
import renderField from '../../../shared/components/custom/Field';
import renderTextAreaField from '../../../shared/components/custom/TextArea';
import renderRadioButtonField from '../../../shared/components/form/RadioButton';
import t, { partial } from '../../../util/translation/translation';
import Loading from '../../../shared/components/custom/Loading';
import Api from '../../../util/api';
import useModal from '../../../shared/hooks/useModal';
import DeleteModal from '../../../shared/components/custom/DeleteModal';
import validateAuctionContact from './validateAuctionContact';

const AuctionContactForm = ({
  auctionItem,
  setScheduleMessageID,
  scheduleMessageID,
  scheduleMessagesForEvent,
  getScheduleMessagesByServiceId,
  startDate,
  setStartDate,
  initialize,
  messageStatus,
  formValues,
  handleSubmit,
}) => {
  const p = partial('EventsContactForm');
  const m = partial('MessagesForm');

  const { state: locationState } = useLocation();

  const [loading, setLoading] = useState(false);
  const [handleModal, visible, deleteId] = useModal();

  const initializeEdit = (message) => {
    setScheduleMessageID(message.id);
    initialize({
      title:
        message?.payload?.placeholders?.TITLE ||
        message?.payload?.placeholders?.SUBJECT,
      message: message?.payload?.placeholders?.BODY,
      type: message?.payload?.channels[0]?.channel,
      scheduleTime: message.date ? setStartDate(new Date(message.date)) : null,
      timeToSend: 'SCHEDULED',
    });

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const removeMessage = async (messageId) => {
    setLoading(true);
    try {
      await Api.auctions.deleteScheduledMessage(messageId);
      handleModal();
      getScheduleMessagesByServiceId();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(err || 'messageDeletingERROR');
    }
  };

  const clearFields = () => {
    setScheduleMessageID(null);
    initialize({
      title: '',
      message: '',
      type: 'email',
      scheduleTime: setStartDate(''),
      timeToSend: 'INSTANT',
    });
  };
  useEffect(() => {
    setScheduleMessageID(null);
  }, [auctionItem, messageStatus]);

  useEffect(() => {
    if (locationState?.messageId && scheduleMessagesForEvent.length > 0) {
      const messageToShow = scheduleMessagesForEvent.find(
        (msg) => msg.id === locationState?.messageId
      );
      initializeEdit(messageToShow);
    }
  }, [scheduleMessagesForEvent]);

  const renderSubmitButtons = () => {
    let buttonName = '';
    if (scheduleMessageID) {
      buttonName = t('ScheduledMessagesPage.updateMessage');
    } else if (formValues?.timeToSend === 'SCHEDULED') {
      buttonName = t('ScheduledMessagesPage.scheduleMessage');
    } else {
      buttonName = p('sendMessage');
    }
    return (
      <div>
        <Button type="submit" style={{ display: 'block' }} color="primary">
          {buttonName}
        </Button>
        <Link
          className="py-3"
          style={{ textDecoration: 'underline' }}
          to="/messages?tab=2"
        >
          {t('MessagesForm.scheduledAndHistory')}
        </Link>
      </div>
    );
  };
  const filterPassedTime = (time) => {
    if (auctionItem?.startDate) {
      const isSameDay =
        moment(auctionItem.startDate).diff(moment(time), 'days') === 0;
      if (isSameDay) {
        return moment(time).isSameOrAfter(moment(auctionItem.startDate));
      }
      return true;
    }
    return true;
  };
  return (
    <form onSubmit={handleSubmit} className="form d-flex">
      <Loading loading={loading} />
      <Container className="padding-0">
        <DeleteModal
          visible={visible}
          handleModal={handleModal}
          type="message"
          modalAction={() => removeMessage(deleteId)}
        />
        <Row>
          <div className="col-12 col-xl-6">
            <Col md={12}>
              <span className="form-header">{t('shared.type')}</span>
              <div className="radio-horizontal">
                <Col md={5}>
                  <div className="form__form-group">
                    <div className="form__form-group-field">
                      <Field
                        name="type"
                        component={renderRadioButtonField}
                        label="SMS"
                        radioValue="sms"
                        disabled
                      />
                    </div>
                  </div>
                </Col>
                <Col md={5}>
                  <div className="form__form-group">
                    <div className="form__form-group-field">
                      <Field
                        name="type"
                        component={renderRadioButtonField}
                        label="Email"
                        radioValue="email"
                        disabled
                      />
                    </div>
                  </div>
                </Col>
                <Col md={5}>
                  <div className="form__form-group">
                    <div className="form__form-group-field">
                      <Field
                        name="type"
                        component={renderRadioButtonField}
                        label="Push"
                        radioValue="push"
                        defaultChecked
                      />
                    </div>
                  </div>
                </Col>
              </div>
            </Col>
            <Col md={12}>
              <div className="form__form-group">
                <span className="form-header">{t('shared.title')}</span>
                {formValues?.type === 'sms' ? (
                  <div
                    className="form__form-group-field"
                    style={{ margin: '5px 10px' }}
                  >
                    <Field
                      name="title"
                      placeholder={`${t('shared.title')}`}
                      component={renderField}
                      disabled
                    />
                  </div>
                ) : (
                  <Field
                    name="title"
                    placeholder={`${t('shared.title')}`}
                    component={renderField}
                    emoji
                  />
                )}
              </div>
            </Col>
            <Col md={12}>
              <div className="form__form-group">
                <span className="form-header">{t('shared.content')}</span>
                <Field
                  name="message"
                  placeholder={p('messageContent')}
                  component={renderTextAreaField}
                  emoji
                />
              </div>
            </Col>
            <Col md={12}>
              <span className="form-header">
                {t('ScheduledMessagesPage.timeToSend')}
              </span>
              <div className="radio-horizontal">
                <Col md={5}>
                  <div className="form__form-group margin-0">
                    <div className="form__form-group-field">
                      <Field
                        name="timeToSend"
                        component={renderRadioButtonField}
                        label={t('shared.instant')}
                        radioValue="INSTANT"
                        defaultChecked
                      />
                    </div>
                  </div>
                </Col>
                <Col md={5}>
                  <div className="form__form-group">
                    <div className="form__form-group-field">
                      <Field
                        name="timeToSend"
                        component={renderRadioButtonField}
                        label={t('shared.scheduled')}
                        radioValue="SCHEDULED"
                      />
                    </div>
                  </div>
                </Col>
              </div>
            </Col>
            {formValues?.timeToSend === 'SCHEDULED' ? (
              <Col md={9}>
                <div className="form__form-group" style={{ zIndex: 100 }}>
                  <span className="form-header">
                    {t('ScheduledMessagesPage.scheduleTime')}
                  </span>
                  <div className="form__form-group-field">
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      showTimeSelect
                      timeFormat="p"
                      timeIntervals={15}
                      dateFormat="Pp"
                      minDate={moment(auctionItem?.startDate).toDate()}
                      filterTime={filterPassedTime}
                      placeholderText="DD / MM / YYYY  -- : --"
                      disabledKeyboardNavigation
                    />
                    <div className="form__form-group-icon">
                      <TimetableIcon />
                    </div>
                  </div>
                </div>
              </Col>
            ) : (
              ''
            )}
          </div>
          <div className="col-12 col-xl-6 mb-4">
            <Col md={12}>
              <div className="p-2 d-flex align-items-center border p-3">
                <div className="modal-icon mr-4">
                  <AlertCircleOutlineIcon size="38" />
                </div>
                <div className="d-flex flex-column">
                  <p>{p('pushMessageGroups')}</p>
                  <p>{m('AppStorePushGuidelines')}</p>
                </div>
              </div>
            </Col>
          </div>
        </Row>
      </Container>
      <Container>
        {renderSubmitButtons()}
        {scheduleMessageID && (
          <Button onClick={clearFields}>{t('shared.clear')}</Button>
        )}
      </Container>
    </form>
  );
};

const reduxF = reduxForm({
  form: 'auction_contact_form',
  validate: validateAuctionContact,
});
const mapStateToProps = (state) => ({
  formValues: getFormValues('auction_contact_form')(state),
});
export default connect(mapStateToProps)(reduxF(AuctionContactForm));
