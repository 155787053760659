import React from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { partial } from '../../../util/translation/translation';
import RenderMatches from '../../matches/components/RenderMatches';
import { Widget, WidgetHeader } from './shared';
import Api from '../../../util/api';
import Loading from '../../shared/Spinner';

const NextMatches = (props) => {
  const dT = partial('DashboardPage');
  const p = partial('NextMatches');

  const { data: currentSeason } = useQuery({
    queryKey: ['get-seasons'],
    queryFn: () => Api.seasons.getSeasons(),
    select: ({ data: seasonData }) => {
      const [season] = seasonData;
      return season;
    },
  });

  const { data: matchInfo, isLoading } = useQuery({
    queryKey: ['upcoming-matches'],
    queryFn: () => Api.matches.getMatches(currentSeason?.id),
    enabled: !!currentSeason?.id,
    select: ({ data: matchData }) => {
      return matchData.future_matches
        .sort((a, b) => moment(a.match_starts_at) - moment(b.match_starts_at))
        .filter((_, i) => i < 5);
    },
  });

  return (
    <Widget>
      <WidgetHeader
        title={dT('upcomingMatches')}
        isBorder={false}
        bottomSpace="sm"
      >
        <Link to="/matches">{dT('viewAll')}</Link>
      </WidgetHeader>
      {isLoading ? (
        <Loading />
      ) : (
        <RenderMatches
          matches={matchInfo}
          handleEdit={props.handleEdit}
          handleModal={props.handleModal}
        />
      )}
      {matchInfo?.length === 0 && (
        <p className="textAlign-center">{p('noMatchesRegistered')}</p>
      )}
    </Widget>
  );
};

export default NextMatches;
