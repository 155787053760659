import React, { useEffect } from 'react';
import { Field, reduxForm, propTypes as reduxFormProps } from 'redux-form';
import { Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from '../validators/priceGroupValidate';
import RenderField from '../../../shared/components/custom/Field';
import RenderCheckBoxField from '../../../shared/components/form/CheckBox';
import { partial } from '../../../util/translation/translation';

const PriceGroupForm = ({ initialize, priceGroup, handleSubmit }) => {
  const p = partial('settingsObjectsShared');
  const s = partial('shared');

  useEffect(() => {
    if (priceGroup) {
      initialize({
        title: priceGroup.title,
        description: priceGroup.description,
        percentage: priceGroup.percentage,
        sortOrder: priceGroup.sort_order,
        control: !!priceGroup.control,
        buyable: !!priceGroup.buyable,
      });
    }
  }, [priceGroup]);

  return (
    <form className="form" onSubmit={handleSubmit}>
      <Col md={6}>
        <div className="form__form-group">
          <span className="form-header">{s('title')}</span>
          <Field
            name="title"
            component={RenderField}
            placeholder={p('nameOnPriceGroup')}
          />
        </div>
      </Col>
      <Col md={6}>
        <div className="form__form-group">
          <span className="form-header">{s('description')}</span>
          <Field
            name="description"
            component={RenderField}
            placeholder={s('description')}
          />
        </div>
      </Col>
      <Col md={6}>
        <div className="form__form-group">
          <span className="form-header">{s('pricePercent')}</span>
          <Field
            name="percentage"
            component={RenderField}
            placeholder={s('pricePercent')}
            type="number"
          />
        </div>
      </Col>
      <Col md={6}>
        <div className="form__form-group">
          <span className="form-header">{p('SortingOrder')}</span>
          <Field
            name="sortOrder"
            component={RenderField}
            placeholder={s('order')}
            type="number"
          />
        </div>
      </Col>
      <Col md={5} sm={9} xs={12}>
        <div className="form__form-group">
          <Field
            name="control"
            component={RenderCheckBoxField}
            className="colored-click"
            label={p('requiresControl')}
          />
          <Field
            name="buyable"
            component={RenderCheckBoxField}
            className="colored-click"
            label={p('canBePurchased')}
          />
        </div>
      </Col>
      <Col md={12}>
        <Button type="submit" color="primary">
          {priceGroup ? s('save') : s('create')}
        </Button>
        <Link className="color-unset" to="/settings?tab=2">
          <Button type="button">{s('close')}</Button>
        </Link>
      </Col>
    </form>
  );
};

PriceGroupForm.propTypes = {
  ...reduxFormProps,
  priceGroup: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    percentage: PropTypes.string.isRequired,
    sort_order: PropTypes.number.isRequired,
    control: PropTypes.number.isRequired,
    buyable: PropTypes.number.isRequired,
  }),
  handleSubmit: PropTypes.func.isRequired,
};

PriceGroupForm.defaultProps = {
  priceGroup: null,
};

const reduxF = reduxForm({
  form: 'price_group_form',
  validate,
});

export default reduxF(PriceGroupForm);
