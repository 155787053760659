import React from 'react';

export default function DownArrowIcon(props) {
  return (
    <img
      src="/assets/files/questions/down-arrow.svg"
      alt="Move question down"
      {...props}
    />
  );
}
