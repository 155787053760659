import React, { useEffect, useState } from 'react';
import { Container, Col, Card, CardBody } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Api from '../../util/api';
import Loading from '../../shared/components/custom/Loading';
import SeasonForm from './components/SeasonForm';
import { partial } from '../../util/translation/translation';

const SeasonCreatePage = () => {
  const history = useHistory();
  const [seasonPriceGroups, setSeasonPriceGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const p = partial('seasonObjectsShared');

  const getInitialData = async () => {
    setLoading(true);
    try {
      const resLastSeasonPrices = await Api.seasons.getLastSeasonPrices();
      setSeasonPriceGroups(
        resLastSeasonPrices.data.map((seasonPrices) => ({
          id: seasonPrices.id ?? seasonPrices.price_group_id,
          ...seasonPrices,
        }))
      );
      setLoading(false);
    } catch (err) {
      toast.error(err || p('wrongWhileRetrievingNecessaryInformation'));
      setLoading(false);
    }
  };

  useEffect(() => {
    getInitialData();
  }, []);

  const convertValues = (values) => {
    return Object.entries(values).reduce((acc, value) => {
      let variable;
      return value.reduce((object, element, index) => {
        const obj = object;
        if (index === 0) {
          variable = element.split('-');
        } else {
          if (obj.priceGroups === undefined) {
            obj.priceGroups = [];
          }
          if (
            (variable[0] === 'checkbox' ||
              variable[0] === 'input' ||
              variable[0] === 'releasable') &&
            !obj.priceGroups.some((pG) => pG.id === variable[1])
          ) {
            obj.priceGroups.push({ id: variable[1] });
          }
          const pGIndex = obj.priceGroups.findIndex(
            (pG) => pG.id === variable[1]
          );
          switch (variable[0]) {
            case 'input':
              obj.priceGroups[pGIndex].price = Number(element);
              obj.priceGroups[pGIndex].title = seasonPriceGroups[pGIndex].title;
              obj.priceGroups[pGIndex].description =
                seasonPriceGroups[pGIndex].description;
              obj.priceGroups[pGIndex].season_id =
                seasonPriceGroups[pGIndex].season_id;
              obj.priceGroups[pGIndex].custom =
                seasonPriceGroups[pGIndex].custom;
              obj.priceGroups[pGIndex].price_group_id =
                seasonPriceGroups[pGIndex].price_group_id;
              break;

            case 'checkbox':
              obj.priceGroups[pGIndex].buyable = element;
              break;

            case 'releasable':
              obj.priceGroups[pGIndex].releasable = element;
              break;

            default:
              obj[value[0]] = element;
              break;
          }
        }
        return object;
      }, acc);
    }, {});
  };

  const handleSubmit = async (unConvertedValues) => {
    const values = convertValues(unConvertedValues);
    const seasonPayload = {
      name: values.name,
      start_date: values.startDate,
      end_date: values.endDate,
      ticket_sale_start: values.saleStart,
      ticket_sale_end: values.saleEnd,
      sms_to_user: false,
      push_to_user: false,
      external_url: values.externalURL,
      season_prices: values.priceGroups.map((priceGroup) => ({
        ...priceGroup,
        custom: !!priceGroup.custom,
        id: priceGroup.price_group_id,
      })),
    };
    setLoading(true);
    try {
      await Api.seasons.createSeason(seasonPayload);
      toast.success(p('seasonHasBeenCreated'));
      history.replace('/seasons');
    } catch (err) {
      toast.error(err || p('wrongWhenCreatingSeason'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Loading loading={loading} />
      <Col>
        <p className="page-title">{p('newSeason')}</p>
      </Col>
      <Col>
        <Card>
          <CardBody>
            <SeasonForm
              onSubmit={handleSubmit}
              seasonPriceGroups={seasonPriceGroups}
            />
          </CardBody>
        </Card>
      </Col>
    </Container>
  );
};

export default SeasonCreatePage;
