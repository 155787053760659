import React, { useState, useEffect } from 'react';
import { ModalBody, Alert } from 'reactstrap';
import { getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import moment from 'moment';
import t from '../../../util/translation/translation';
import { componentsFromState } from './newsUtils';

const AppComponent = ({ item }) => {
  switch (item.type) {
    case 'IMAGE':
      return (
        <div>
          {item.value.image && (
            <img className="mt-3 mb-1 px-2" src={item.value.image} alt="News" />
          )}
          {item.value.author ? (
            <p
              style={{
                fontSize: 10,
                lineHeight: '13px',
                padding: '0 10px 10px',
                marginBottom: 10,
              }}
            >
              {item.value.author}
            </p>
          ) : null}
        </div>
      );
    case 'RICH_TEXT':
    case 'RICH_TEXT2':
      return (
        <div
          className="app-preview-html"
          dangerouslySetInnerHTML={{
            __html: item.value.text
              .replace(/<p>/g, '')
              .replace(/<\/p>/g, '</br>'),
          }}
        />
      );
    case 'IMAGE_PHOTOGRAPHER':
      return (
        <div
          style={{
            fontSize: 16,
            backgroundColor: 'gray',
            padding: '0 10px 0 10px',
          }}
        >
          {item.value.text}
        </div>
      );
    case 'HEADER':
      return (
        <div
          style={{
            fontSize: 16,
            marginTop: 10,
            fontWeight: 'bold',
            padding: '0 10px 0 10px',
          }}
        >
          {item.value.text}
        </div>
      );
    case 'DELIMITER':
      return <div style={{ height: 15 }} />;
    case 'YOUTUBE_VIDEO':
      return (
        <iframe
          style={{ margin: '10px 0 10px 0', padding: '0 10px 0 10px' }}
          width="325"
          height="156"
          src={`https://www.youtube.com/embed/${item.value.video}`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        />
      );
    case 'I_FRAME':
      return (
        <div dangerouslySetInnerHTML={{ __html: item.value.iframe.trim() }} />
      );
    case 'BUTTON':
      return (
        <div className="mx-2">
          <Link
            className="btn btn-primary btn-sm btn-block"
            to={item.value.link}
          >
            {item.value.text || ''}
          </Link>
        </div>
      );
    case 'SPORTALITY_VIDEO':
      return (
        <div>
          <iframe
            style={{ margin: '10px 0 10px 0', padding: '0 10px 0 10px' }}
            width="325"
            height="156"
            src={`https://embed.staylive.tv/video/${item.value.video}?autoplay=0`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          />
          {item.value.author ? (
            <p style={{ width: 500, fontSize: 16, padding: '0 10px 0 10px' }}>
              {item.value.author}
            </p>
          ) : null}
        </div>
      );
    case 'CUSTOM_VIDEO':
      return (
        <div>
          <iframe
            style={{ margin: '10px 0 10px 0', padding: '0 10px 0 10px' }}
            width="325"
            height="156"
            src={`${item.value.video?.preview || item.value.video}`}
            title="Custom video player"
            frameBorder="0"
            sandbox
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          />
        </div>
      );
    default:
      return null;
  }
};
const HeadingImage = ({ url }) => {
  return <img style={{ width: 325, height: 182 }} src={url} alt="Heading" />;
};
const HeaderContent = ({ news }) => {
  if (news?.headingVideo) {
    return (
      <iframe
        style={{ margin: '0 0 0 0', padding: '0 0 0 0' }}
        width="325"
        height="182"
        src={news?.headingVideo}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      />
    );
  }
  if (news?.headingImage) {
    return <HeadingImage url={news?.headingImage} />;
  }
  return null;
};

const AppNewsPagePreview = ({ components, selectedItems, news }) => {
  if (!components || !news) {
    return (
      <ModalBody
        className="appLayout"
        style={{ margin: '0 auto', padding: 0, width: 325, background: '#fff' }}
      >
        <span
          style={{
            textAlign: 'center',
            display: 'block',
            background: '#000',
            padding: '12px 10px',
            color: '#fff',
            boxShadow: 'rgba(0, 0, 0, 0.29) 0px 3px 57px -12px',
          }}
        >
          {t('shared.news')}
        </span>
        <HeaderContent news={news} />
        <div className="mb-3">
          <div
            style={{
              width: 325,
              fontSize: 26,
              margin: '5px 0 5px 0',
              fontWeight: 'bold',
              padding: '0 10px 0 10px',
            }}
          >
            {news?.title}
          </div>
          <small style={{ marginLeft: 12, opacity: 0.7 }}>
            {t('NewsFormPage.published')}:{' '}
            {moment(news?.publicationdate).fromNow()}, {t('shared.by')}{' '}
            {news?.author}
          </small>
        </div>
        {!selectedItems && (
          <Alert color="warning" className="mt-2">
            {t('NewsFormPage.saveToSeePreview')}
          </Alert>
        )}
      </ModalBody>
    );
  }

  const [mappedItems, setMappedItems] = useState([]);

  useEffect(() => {
    const refreshItems = async () => {
      const mappedItemsResponse = await componentsFromState(
        selectedItems,
        components
      );
      setMappedItems(mappedItemsResponse);
    };
    refreshItems();
  }, [selectedItems, components]);
  return (
    <ModalBody
      className="appLayout"
      style={{
        margin: '0 auto',
        padding: 0,
        width: 325,
        background: '#fff',
        borderBottomRightRadius: 6,
        borderBottomLeftRadius: 6,
        boxShadow: '0px 2px 8px 0px rgba(0,0,0,0.08)',
      }}
    >
      <span
        style={{
          textAlign: 'center',
          display: 'block',
          background: '#000',
          padding: '12px 10px',
          color: '#fff',
          boxShadow: 'rgba(0, 0, 0, 0.29) 0px 3px 57px -12px',
        }}
      >
        {t('shared.news')}
      </span>
      <HeaderContent news={news} />
      <div className="mb-3">
        <div
          style={{
            width: 325,
            fontSize: 26,
            margin: '5px 0 5px 0',
            fontWeight: 'bold',
            padding: '0 10px 0 10px',
          }}
        >
          {news?.title}
        </div>
        <small style={{ marginLeft: 12, opacity: 0.7 }}>
          {t('NewsFormPage.published')}:{' '}
          {moment(news?.publicationdate).fromNow()}, {t('shared.by')}{' '}
          {news?.author}
        </small>
      </div>
      <div className="pb-3 news">
        {mappedItems.map((item) => (
          <AppComponent item={item} key={`${item.type}-${item.order}`} />
        ))}
      </div>
    </ModalBody>
  );
};

const mapStateToProps = (state) => ({
  components: getFormValues('news_components_form')(state),
});
export default connect(mapStateToProps)(AppNewsPagePreview);
