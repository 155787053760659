import React from 'react';

export default function TextFieldIcon(props) {
  return (
    <img
      src="/assets/files/questions/text-field.svg"
      alt="textfield"
      {...props}
    />
  );
}
