import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  TabPane,
  TabContent,
} from 'reactstrap';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router';
import NavTab from '../../shared/components/custom/NavTab';
import DetailsForm from './components/DetailsForm';
import Assortment from './components/Assortment';
import { convertImageToBlob } from '../../util/image-functions';
import Api from '../../util/api';
import Loading from '../../shared/components/custom/Loading';
import { partial } from '../../util/translation/translation';

const s = partial('shared');
const p = partial('KioskFormPage');

const KioskFormPage = () => {
  const { kioskID } = useParams();
  const history = useHistory();

  const [tab, setTab] = useState('0');
  const [loading, setLoading] = useState(!!kioskID);
  const [kiosk, setKiosk] = useState(null);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);

  const sortProducts = (unsortedProducts) =>
    unsortedProducts.sort((product1, product2) => {
      if (product1.toggled === product2.toggled) return 0;
      return product1.toggled ? -1 : 1;
    });

  const getProducts = async (kioskProducts) => {
    try {
      const [resCategories, resProductList] = await Promise.all([
        Api.cnc.getProductCategories(),
        Api.cnc.getProductList(),
      ]);
      const mappedProducts = resProductList.data.map((product) => ({
        ...product,
        toggled: kioskProducts.some(
          (kioskProduct) => product.id === kioskProduct.id
        ),
      }));
      sortProducts(mappedProducts);
      setProducts(mappedProducts);
      setCategories(resCategories.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(err || `${p('productlistFetchingERROR')}`);
    }
  };

  const getKiosk = async () => {
    setLoading(true);
    try {
      const resKiosk = await Api.kiosks.getKioskByID(kioskID);
      setKiosk(resKiosk.data);
      getProducts(resKiosk.data.products);
    } catch (err) {
      setLoading(false);
      toast.error(err || `${p('kioskFetchingERROR')}!`);
    }
  };

  useEffect(() => {
    if (kioskID) {
      getKiosk();
    }
  }, [kioskID]);
  const hasImageThenConvert = async (image) => {
    const hasImage = Array.isArray(image) && image.length === 1;
    if (!hasImage) {
      return null;
    }
    if (image[0].size === undefined) {
      return image[0];
    }
    try {
      return {
        ...image[0],
        preview: await convertImageToBlob(image[0]),
      };
    } catch (err) {
      toast.error(err || `${s('imageUploadingERROR')}`);
      throw err;
    }
  };
  const onSubmitDetails = async (values) => {
    const kioskImage = await hasImageThenConvert(values.image);
    const payload = {
      name: values.name,
      tax: parseInt(values.tax, 10),
      image: kioskImage,
    };
    setLoading(true);
    try {
      if (kioskID) {
        await Api.cnc.updateKiosks(kioskID, payload);
        toast.success(`${p('kioskUpdated')}`);
        setLoading(false);
      } else {
        await Api.cnc.createKiosk(payload);
        toast.success(`${p('kioskCreated')}`);
        history.replace('/cnc');
      }
    } catch (err) {
      toast.error(err || `${p('kioskSavingERROR')}`);
      setLoading(false);
    }
  };

  const handleProductToggle = async (productId) => {
    const index = products.findIndex((product) => product.id === productId);
    products[index].toggled = !products[index].toggled;
    sortProducts(products);
    const payload = {
      product_ids: products
        .filter((product) => product.toggled)
        .map((product) => product.id),
    };
    setLoading(true);
    try {
      await Api.cnc.updateProducts(kioskID, payload);
      setProducts(products);
      setLoading(false);
    } catch (err) {
      toast.error(err || `${p('productUpdatingSomethingWentWrongERROR')}`);
      setLoading(false);
    }
  };

  return (
    <Container>
      <Loading loading={loading} />
      <Row>
        <Col>
          <Card>
            <CardBody>
              <NavTab
                activeTab={tab}
                setTab={setTab}
                tabs={[
                  { tab: `${s('kiosk')}` },
                  { tab: `${s('products')}`, disabled: !kioskID },
                ]}
              />
              <TabContent activeTab={tab}>
                <TabPane tabId="1">
                  <DetailsForm
                    type={kioskID ? 'edit' : 'create'}
                    kiosk={kiosk}
                    onSubmit={onSubmitDetails}
                  />
                </TabPane>
                <TabPane tabId="2">
                  <Assortment
                    products={products}
                    categories={categories}
                    handleToggle={handleProductToggle}
                  />
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default KioskFormPage;
