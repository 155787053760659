import t, { partial } from '../../../util/translation/translation';

const p = partial('validateErrors');
const validateEventsContact = (values) => {
  const errors = {};
  if (values?.type === 'push' && !values.title) {
    errors.title = `${p('titleFieldCantBeEmpty')}!`;
  }

  if (!values.content) {
    errors.content = `${p('messageFieldCantBeEmpty')}!`;
  }

  if (
    values.timeToSend === 'SCHEDULED' &&
    (!values.scheduleTime || new Date(values.scheduleTime) < Date.now())
  ) {
    errors.scheduleTime = `${t('ScheduledMessagesPage.selectValidDate')}`;
  }

  return errors;
};

export default validateEventsContact;
