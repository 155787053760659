import React, { useEffect } from 'react';
import { Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import renderField from '../../../shared/components/custom/Field';
import renderCheckBoxField from '../../../shared/components/form/CheckBox';
import validate from './validate';
import { partial } from '../../../util/translation/translation';
import renderSelectField from '../../../shared/components/form/Select';
import TooltipWithIcon from '../../../shared/components/custom/TooltipWithIcon';

const SeasonForm = (props) => {
  const p = partial('seasonObjectsShared');
  const s = partial('shared');

  useEffect(() => {
    let fields = {};
    if (props.seasonPriceGroups.length > 0) {
      fields = props.seasonPriceGroups.reduce((object, priceGroup) => {
        const obj = { ...object };
        obj[`input-${priceGroup.id.toString()}`] = priceGroup.price;
        obj[`checkbox-${priceGroup.id.toString()}`] = !!priceGroup.buyable;
        obj[`releasable-${priceGroup.id.toString()}`] = !!priceGroup.releasable;
        return obj;
      }, {});
    }
    if (props.season) {
      fields = {
        ...fields,
        name: props.season.name,
        startDate: moment(props.season.start_date).format('YYYY-MM-DD'),
        endDate: moment(props.season.end_date).format('YYYY-MM-DD'),
        saleStart: moment(props.season.ticket_sale_start).format('YYYY-MM-DD'),
        saleEnd: moment(props.season.ticket_sale_end).format('YYYY-MM-DD'),
        externalURL: props.season?.external_url ?? '',
        ntb_id: props.ntbStages.find(
          (stage) => stage.value === `${props.season.ntb_id}`
        ),
      };
    }
    props.initialize(fields);
  }, [props.seasonPriceGroups]);

  const renderSeasonPriceGroups = () => {
    return props.seasonPriceGroups.map((priceGroup) => (
      <Col key={`RenderPriceGroups-${priceGroup.id}`} lg={4} md={12} sm={12}>
        <div className="form__form-group">
          <div className="space-between">
            <span className="form-header">{priceGroup.title}</span>
            <div className="flex">
              <Field
                name={`checkbox-${priceGroup.id.toString()}`}
                component={renderCheckBoxField}
                label={p('canBePurchased')}
                className="colored-click marginRight-8"
              />
              <Field
                name={`releasable-${priceGroup.id.toString()}`}
                component={renderCheckBoxField}
                label={p('hasPickupDeadline')}
                className="colored-click"
              />
            </div>
          </div>
          <div className="marginTop-5" />
          <Field
            name={`input-${priceGroup.id.toString()}`}
            component={renderField}
            placeholder={p('seasonName')}
            type="number"
          />
        </div>
      </Col>
    ));
  };

  return (
    <form className="form" onSubmit={props.handleSubmit}>
      <Col md={12} style={{ margin: '0 0 10px' }}>
        <h4>{p('seasonInfo')}</h4>
      </Col>
      <Col lg={4} md={12} sm={12}>
        <div className="form__form-group">
          <span className="form-header">{p('nameNotRequired')}</span>
          <div className="marginTop-5" />
          <Field
            name="name"
            component={renderField}
            placeholder={p('seasonName')}
          />
        </div>
      </Col>
      <Col lg={4} md={12} sm={12}>
        <div className="form__form-group">
          <span className="form-header">{s('startDate')}</span>
          <div className="marginTop-5" />
          <Field name="startDate" component={renderField} type="date" />
        </div>
      </Col>
      <Col lg={4} md={12} sm={12}>
        <div className="form__form-group">
          <span className="form-header">{s('endDate')}</span>
          <div className="marginTop-5" />
          <Field name="endDate" component={renderField} type="date" />
        </div>
      </Col>
      <Col lg={4} md={12} sm={12}>
        <div className="form__form-group">
          <span className="form-header">{p('seasonTicketSaleStart')}</span>
          <div className="marginTop-5" />
          <Field name="saleStart" component={renderField} type="date" />
        </div>
      </Col>
      <Col lg={4} md={12} sm={12}>
        <div className="form__form-group">
          <span className="form-header">{p('seasonTicketSaleEnd')}</span>
          <div className="marginTop-5" />
          <Field name="saleEnd" component={renderField} type="date" />
        </div>
      </Col>
      <Col lg={4} md={12}>
        <div className="form__form-group">
          <span className="form-header">{p('externalURL')}</span>
          <TooltipWithIcon
            id="balance-balance"
            text={p('externalURLDescription')}
          />
          <div className="marginTop-5" />
          <Field
            name="externalURL"
            placeholder={p('externalURL')}
            component={renderField}
          />
        </div>
      </Col>
      <Col lg={4} md={12} sm={12}>
        <div className="form__form-group">
          <span className="form-header">{p('seasonNtbId')}</span>
          <div className="marginTop-5" />
          <Field
            name="ntb_id"
            component={renderSelectField}
            options={props.ntbStages}
          />
        </div>
      </Col>
      {props.seasonPriceGroups.length > 0 && (
        <>
          <Col md={12} style={{ margin: '10px 0' }}>
            <h4>{s('prices')}</h4>
          </Col>
          {renderSeasonPriceGroups()}
        </>
      )}
      <Col md={12}>
        <Button color="primary">
          {props.type === 'create' ? s('create') : s('save')}
        </Button>
        <Link to="/seasons">
          <Button type="button" color="secondary">
            {s('cancel')}
          </Button>
        </Link>
      </Col>
    </form>
  );
};

SeasonForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  seasonPriceGroups: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  ntbStages: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  season: PropTypes.shape({
    name: PropTypes.string,
    start_date: PropTypes.string,
    end_date: PropTypes.string,
    ticket_sale_start: PropTypes.string,
    ticket_sale_end: PropTypes.string,
    ntb_id: PropTypes.number,
  }),
  type: PropTypes.string,
  initialize: PropTypes.func.isRequired,
  seasonForm: PropTypes.shape({
    createTickets: PropTypes.bool,
    sms: PropTypes.bool,
    push: PropTypes.bool,
  }),
};

SeasonForm.defaultProps = {
  type: 'create',
  season: undefined,
  seasonForm: {},
};

const reduxF = reduxForm({
  form: 'season-form',
  validate,
});

export default connect((state) => ({
  seasonForm: getFormValues('season-form')(state),
}))(reduxF(SeasonForm));
