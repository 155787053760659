import React, { Component } from 'react';
import { Collapse } from 'reactstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import ChevronUpIcon from 'mdi-react/ChevronUpIcon';

export default class SidebarCategory extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.string,
    isNew: PropTypes.bool,
    children: PropTypes.arrayOf(PropTypes.element).isRequired,
  };

  static defaultProps = {
    icon: '',
    isNew: false,
  };

  constructor() {
    super();
    this.state = {
      collapse: false,
    };
  }

  toggle = () => {
    this.setState({ collapse: !this.state.collapse });
  };

  render() {
    const { title, icon, isNew, children } = this.props;
    const categoryClass = classNames({
      'sidebar__category-wrap': true,
      'sidebar__category-wrap--open': this.state.collapse,
    });

    return (
      <div className={categoryClass}>
        <button
          className="sidebar__link sidebar__category"
          onClick={this.toggle}
        >
          {icon ? <span className={`sidebar__link-icon icon-${icon}`} /> : ''}
          <p className="sidebar__link-title">
            {title}
            {isNew && <span className="sidebar__category-new" />}
          </p>
          {!this.state.collapse && (
            <ChevronDownIcon
              style={{
                marginLeft: 10,
                position: 'absolute',
                right: 20,
                top: 6,
                color: 'rgb(198, 202, 210)',
              }}
            />
          )}
          {this.state.collapse && (
            <ChevronUpIcon
              style={{
                marginLeft: 10,
                position: 'absolute',
                right: 20,
                top: 6,
              }}
            />
          )}
        </button>
        <Collapse
          isOpen={this.state.collapse}
          className="sidebar__submenu-wrap"
        >
          <ul className="sidebar__submenu">
            <div>{children}</div>
          </ul>
        </Collapse>
      </div>
    );
  }
}
