import React, { useEffect } from 'react';
import { Button, Col, Label } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import renderSelectField from '../../../shared/components/form/Select';
import renderField from '../../../shared/components/custom/Field';

const SeasonForm = ({ handleSubmit, initialize, season }) => {
  useEffect(() => {
    if (season) {
      initialize({
        year: season.year,
        type: { label: season.type, value: season.value },
      });
    }
  }, [season]);

  return (
    <form onSubmit={handleSubmit} className="form">
      <Col md={6}>
        <div className="form__form-group">
          <Label htmlFor="type">Season</Label>
          <Field
            name="type"
            component={renderSelectField}
            options={[
              { label: 'full', value: 'full' },
              { label: 'half', value: 'half' },
            ]}
          />
        </div>
      </Col>
      <Col md={6}>
        <div className="form__form-group">
          <Label htmlFor="year">Year</Label>
          <Field
            name="year"
            component={renderField}
            type="number"
            value={new Date().getFullYear()}
          />
        </div>
      </Col>
      <Col md={12}>
        <Button type="submit" color="info">
          Submit
        </Button>
        <Link to="/clubadmin?tab=3">
          <Button color="secondary">Close</Button>
        </Link>
      </Col>
    </form>
  );
};

const rf = reduxForm({
  form: 'seasonAdmin',
});

export default rf(SeasonForm);
