const capitalizeFirstLetter = (word) =>
  word.charAt(0).toUpperCase() + word.substring(1);

const MatchCenterHelpers = {
  getSponsor: (match) => {
    const sponsor = {
      name: { label: '', value: '' },
      image: '',
      companyLogo: '',
    };
    if (!match.settings) {
      return sponsor;
    }
    if (match.settings.settings?.sponsor) {
      sponsor.name = {
        label: match.settings.settings.sponsor.name,
        value: match.settings.settings.sponsor.id,
      };
      sponsor.image = match.settings.settings.sponsor.image;
      sponsor.companyLogo = match.settings.settings.sponsor.companyLogo;
    }
    return sponsor;
  },
  getCompany: (match, companies) => {
    const company = { label: '', value: '' };
    if (!companies) return company;
    const index = companies.findIndex(
      (c) => c.id === match.playerRatingCompany?.companyId
    );
    if (index !== -1) {
      company.label = companies[index].name;
      company.value = companies[index].id;
    }
    return company;
  },
  getBannerOptions: (match) => {
    const options = match.settings?.settings?.bannerOptions;
    return {
      gamecenter: options ? options.gamecenter : true,
      mvp: options ? options.mvp : true,
      playpredict: options ? options.playpredict : false,
      lineup: options ? options.lineup : true,
    };
  },
  getMatchCenterOptions: (match) => {
    const options = match.settings?.settings?.matchCenterOptions;
    return {
      showTickets: options ? options.tickets : true,
      showMatchSponsor: options ? options.matchSponsor : false,
    };
  },
  addDash: (value) => (value !== '' && value ? value : '-'),
  getLinks: (match) =>
    match.settings?.settings?.links?.map((link) => ({
      link_name: link.name,
      link_link: link.link,
      link_type: { label: capitalizeFirstLetter(link.type), value: link.type },
    })) || [],
  getTicket: (match) => {
    const ticket = { ticket_name: '', ticket_link: '', externalId: '' };
    if (match.settings?.settings?.ticket) {
      const matchTicket = match.settings?.settings?.ticket;
      ticket.ticket_name = matchTicket.name;
      ticket.ticket_link = matchTicket.link;
      ticket.externalId = matchTicket.externalId;
    }
    return ticket;
  },
};

export default MatchCenterHelpers;
