import React, { useState } from 'react';
import { Col, Row, Button, Label } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import renderCountryPhone from '../../log_in/components/CountryPhone';
import renderField from '../../../shared/components/custom/Field';
import validateUsername from './findInputValidate';
import { partial } from '../../../util/translation/translation';
import FormBox from '../../../shared/components/custom/FormBox';

const s = partial('shared');
const p = partial('AddEmployee');

const FindUserForm = ({ handleSubmit }) => {
  const [searchType, setSearchType] = useState('phone');

  return (
    <Col lg={12} md={12}>
      {/* <Col md={12}>
        <div className="mb-0">
          <strong>{p('addExistingUser')}</strong>
        </div>
      </Col> */}
      <FormBox title={p('addExistingUser')}>
        <Col md={12}>
          <p className="mb-2 mt-2">{p('searchForExistingUserInstruction')}</p>
          <Row>
            <Col lg={6} md={12} className="mb-3">
              <Label
                htmlFor="phone"
                className={`${searchType === 'phone' && 'bold'}`}
              >
                <input
                  id="phone"
                  className="margin-5"
                  type="radio"
                  name="searchType"
                  value={searchType}
                  onChange={() => setSearchType('phone')}
                  defaultChecked
                />
                {s('phone')}
              </Label>
              <Label
                htmlFor="email"
                className={`${searchType === 'email' && 'bold'}`}
              >
                <input
                  id="email"
                  className="margin-5"
                  type="radio"
                  name="searchType"
                  value={searchType}
                  onChange={() => setSearchType('email')}
                />
                {s('email')}
              </Label>
            </Col>
          </Row>
          <form className="form" onSubmit={handleSubmit}>
            <Row>
              {(!searchType || searchType === 'email') && (
                <Col lg={10} md={12}>
                  <div className="form__form-group">
                    <Field
                      name="email"
                      component={renderField}
                      placeholder={s('email')}
                    />
                  </div>
                </Col>
              )}
              {searchType === 'phone' && (
                <Col lg={10} md={12}>
                  <div className="form__form-group">
                    <Field
                      name="phone"
                      component={renderCountryPhone}
                      placeholder={s('phone')}
                    />
                  </div>
                </Col>
              )}
              <Col lg={2} md={12}>
                <Button
                  className="grey-button no-style"
                  type="submit"
                  size="sm"
                >
                  {s('search')}
                </Button>
              </Col>
            </Row>
          </form>
        </Col>
      </FormBox>
    </Col>
  );
};

FindUserForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const reduxF = reduxForm({
  form: 'findUserForm',
  validate: validateUsername,
});

export default reduxF(FindUserForm);
