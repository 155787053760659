import React from 'react';

const ValueDisplayCounter = ({ id, text, icon, color }) => {
  let colors = {};
  switch (color) {
    case 'primary':
      colors = {
        background: '#D7E9FE',
        color: '#0469dc',
        borderColor: '#0469dc',
      };
      break;
    case 'success':
      colors = {
        background: '#E2F4E6',
        color: '#399351',
        borderColor: '#399351',
      };
      break;
    default:
      colors = {
        background: '#E2F4E6',
        color: '#399351',
        borderColor: '#399351',
      };
      break;
  }

  const style = {
    marginRight: 10,
    padding: 6,
    borderRadius: 6,
    border: '1px solid',
    display: 'inline-block',
    ...colors,
  };

  return (
    <div id={id} style={style}>
      {icon}
      <div style={{ display: 'inline-block', marginLeft: 5 }}>
        <span style={{ verticalAlign: 'sub' }}>{text}</span>
      </div>
    </div>
  );
};

export default ValueDisplayCounter;
