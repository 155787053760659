import React, { useEffect, useState } from 'react';
import { Col, Row, Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm, getFormValues } from 'redux-form';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import TimetableIcon from 'mdi-react/TimetableIcon';
import LinkVariantIcon from 'mdi-react/LinkVariantIcon';
import moment from 'moment';
import renderField from '../../../shared/components/custom/Field';
import renderSelectField from '../../../shared/components/form/Select';
import renderCheckBoxField from '../../../shared/components/form/CheckBox';
import validate from './detailsValidate';
import Api, { getClubName } from '../../../util/api';

// import config from '../../../config';
import t, { partial } from '../../../util/translation/translation';
import TooltipWithIcon from '../../../shared/components/custom/TooltipWithIcon';
import useConfig from '../../../util/useConfig';
import { dateIsFuture } from '../../../util/date-and-time';

const Details = (props) => {
  const config = useConfig();
  const p = partial('Details');
  const s = partial('shared');
  const [stageList, SetStageList] = useState([]);
  const [matchList, SetMatchList] = useState([]);
  const teamName = getClubName();

  const onChangeStage = async ({ id }) => {
    props.change('stageNTBId', id);
    const { data: matchesData } =
      await Api.clubadmin.match.getLeagueWithMatches(id);
    const teamMatches = matchesData.matches.filter(
      (elem) =>
        dateIsFuture(elem.matchStart) &&
        config.teams.some(
          (team) => team.id === elem.homeTeam.id || team.id === elem.awayTeam.id
        )
    );
    const mappedTeamMatches = teamMatches
      .sort(
        (a, b) =>
          new Date(a.matchStart).getTime() - new Date(b.matchStart).getTime()
      )
      .map((elem) => ({
        ...elem,
        label: `${elem.homeTeam.shortName}-${elem.awayTeam.shortName} ${moment(elem.matchStart).format('DD MMM YYYY')}`,
        value: `${elem.id}`,
      }));
    SetMatchList(mappedTeamMatches);
  };

  useEffect(() => {
    const initializeExistingMatch = async () => {
      const presale = moment(
        props.match.sale_start
          ? props.match.sale_start
          : props.match.match_starts_at
      ).add(props.match.presale_duration, 'hours');
      const initObject = {
        homeTeam: {
          label: props.match.home_team.name,
          value: props.match.home_team.id.toString(),
        },
        awayTeam: {
          label: props.match.away_team.name,
          value: props.match.away_team.id.toString(),
        },
        matchType: {
          label: props.match.match_type.name,
          value: props.match.match_type.id.toString(),
        },
        presaleCheck: !!props.match.presale_duration,
        presale: presale && presale.format('YYYY-MM-DDTHH:mm'),
        controlImageSelect: {
          label: props.selectedControlImage.name,
          value: props.selectedControlImage.id
            ? props.selectedControlImage.id.toString()
            : undefined,
        },
        matchStartsAt: props.match.match_starts_at
          ? moment(props.match.match_starts_at).format('YYYY-MM-DDTHH:mm')
          : null,
        saleStart: props.match.sale_start
          ? moment(props.match.sale_start).format('YYYY-MM-DDTHH:mm')
          : null,
        saleStartWeb: props.match.sale_start_web
          ? moment(props.match.sale_start_web).format('YYYY-MM-DDTHH:mm')
          : null,
        doorsOpenAt: props.match.doors_open_at
          ? moment(props.match.doors_open_at).format('HH:mm')
          : null,
        season: props.match.season && props.match.season,
        saleEnd: props.match.sale_end
          ? moment(props.match.sale_end).format('YYYY-MM-DDTHH:mm')
          : null,
        useQr: !!props.match.use_qr,
        useWebsale: !!props.match.use_websale,
        externalMatchID: props.match.external_match_id,
        sponsor:
          props.match.new_company_id &&
          props.sponsorOptions.find(
            (sponsor) => sponsor.value === props.match.new_company_id.toString()
          )
            ? props.sponsorOptions.find(
                (sponsor) =>
                  sponsor.value === props.match.new_company_id.toString()
              )
            : undefined,
        deepLink: `${config.app_name}://ticket/match/${props.match.id}`,
        universalLink: `https://websale.fangroup.io/${teamName}/${props.match.id}/tickets`,
        externalLink: props.match.match_url,
        claimStartDate: props.match.open_date
          ? moment(props.match.open_date).format('YYYY-MM-DDTHH:mm')
          : undefined,
        claimEndDate: props.match.release_date
          ? moment(props.match.release_date).format('YYYY-MM-DDTHH:mm')
          : undefined,
      };
      let teamStages = [];
      try {
        const seas = props.seasonOptions.find(
          (se) => se.id === props.match.season_id
        );
        const res = await Api.clubadmin.match.getLeaguesForTeams(
          seas.ntb_id,
          config.teams.map(({ id }) => id)
        );
        teamStages = res.data;
        const mappedStages = teamStages.map((item) => ({
          ...item,
          label: item.name,
          value: `${item.id}`,
        }));
        SetStageList(mappedStages);
        if (mappedStages.length === 1) {
          onChangeStage({ id: mappedStages[0].value });
        }
      } catch (e) {
        SetStageList([]);
      }

      if (props.match.ntb_match_id && props.match.ntb_match_id !== 0) {
        try {
          const { data: _match } = await Api.clubadmin.match.fetch(
            props.match.ntb_match_id
          );
          const { data: leagueMatches } = await Api.clubadmin.leagues.fetch(
            props.match.ntb_stage_id
          );
          const teamMatches = leagueMatches.matches.filter(
            (match) =>
              moment(match.matchStart).diff(moment(), 'seconds') > 0 &&
              config.teams.some(
                (team) =>
                  team.id === match.homeTeam.id || team.id === match.awayTeam.id
              )
          );
          const mappedMatches = teamMatches.map((item) => ({
            ...item,
            label: `${item.homeTeam.shortName}-${item.awayTeam.shortName} ${moment(item.matchStart).format('DD MMM YYYY')}`,
            value: `${item.id}`,
          }));
          SetMatchList(mappedMatches);
          const foundMatch = _match;

          initObject.matchNTBId = props.match.ntb_match_id;
          if (foundMatch) {
            initObject.selectedMatch = {
              label: `${foundMatch.homeTeam.shortName}-${foundMatch.awayTeam.shortName} ${moment(
                foundMatch.matchStart
              ).format('DD MMM YYYY')}`,
              value: `${foundMatch.id}`,
            };
          }
        } catch (e) {
          toast.error('Could not find match');
        }
      }
      if (props.match.ntb_stage_id && props.match.ntb_stage_id !== 0) {
        const foundStage = teamStages.find(
          (item) => item.id === props.match.ntb_stage_id
        );
        initObject.stageNTBId = props.match.ntb_stage_id;
        initObject.selectedStage = {
          label: foundStage?.name,
          value: `${foundStage?.id}`,
        };
      }
      props.initialize(initObject);
    };
    const initializeNewMatch = async () => {
      const res = await Api.clubadmin.match.getLeaguesForTeams(
        props.seasonId,
        config.teams?.map(({ id }) => id)
      );
      const teamStages = res.data;
      const mappedStages = teamStages.map((item) => ({
        ...item,
        label: item.name,
        value: `${item.id}`,
      }));
      props.initialize({
        controlImageSelect: props?.controlImages?.[0]
          ? props.controlImages[0]
          : undefined,
      });
      SetStageList(mappedStages);
    };
    if (props.type === 'edit') {
      if (props.match.id !== undefined) {
        initializeExistingMatch();
      }
    } else {
      initializeNewMatch();
    }
  }, [props.match]);

  const onChangeMatch = (values) => {
    props.change('matchNTBId', values.id);
    const matchStart = moment(values.matchStart)
      .utc(false)
      .format('YYYY-MM-DDTHH:mm');
    props.change('matchStartsAt', matchStart);
    const pickedHomeTeam = props.teams.find(
      (item) => item.ntb_id === values.homeTeam?.id
    );
    const pickedAwayTeam = props.teams.find(
      (item) => item.ntb_id === values.awayTeam?.id
    );
    if (pickedHomeTeam) {
      props.change('homeTeam', {
        label: pickedHomeTeam.name,
        value: pickedHomeTeam.id,
      });
    }
    if (pickedAwayTeam) {
      props.change('awayTeam', {
        label: pickedAwayTeam.name,
        value: pickedAwayTeam.id,
      });
    }
  };
  const { formValues } = props;
  return (
    <form onSubmit={props.handleSubmit} className="form">
      <Col lg={6}>
        <div className="card__title marginBottom-10">
          <h5 className="bold-text">{p('matchInformation')}</h5>
        </div>
        <Row>
          <Col>
            <div className="form__form-group">
              <div className="flex-row">
                <span className="form-header">{p('stage')}</span>
                <TooltipWithIcon
                  id="stageToolTipDescription"
                  text={p('stageEnabledDescription')}
                />
              </div>
              <Field
                name="selectedStage"
                component={renderSelectField}
                options={stageList}
                onChange={onChangeStage}
                placeholder={
                  stageList.length ? p('selectStage') : p('selectTeamWithNTBID')
                }
                disabled={!stageList.length}
              />
            </div>
          </Col>
          <Col>
            <div className="form__form-group">
              <div className="flex-row">
                <span className="form-header">{p('match')}</span>
                <TooltipWithIcon
                  id="matchToolTipDescription"
                  text={p('matchEnabledDescription')}
                />
              </div>
              <Field
                name="selectedMatch"
                component={renderSelectField}
                options={matchList}
                onChange={onChangeMatch}
                placeholder={
                  matchList.length
                    ? p('selectMatch')
                    : p('noMatchesAvailableForStage')
                }
                disabled={!matchList.length}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="form__form-group">
              <span className="form-header">{p('homeTeam')}*</span>
              <Field
                name="homeTeam"
                component={renderSelectField}
                options={props.teams}
                placeholder={p('chooseHomeTeam')}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="form__form-group">
              <span className="form-header">{p('awayTeam')}*</span>
              <Field
                name="awayTeam"
                component={renderSelectField}
                options={props.teams}
                placeholder={p('chooseAwayTeam')}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={9}>
            <div className="form__form-group">
              <span className="form-header">{p('matchStart')}*</span>
              <div className="form__form-group-field">
                <Field
                  name="matchStartsAt"
                  component={renderField}
                  type="datetime-local"
                />
                <div className="form__form-group-icon">
                  <TimetableIcon />
                </div>
              </div>
            </div>
          </Col>
          <Col md={3}>
            <div className="form__form-group">
              <span className="form-header">{p('doorsOpen')}*</span>
              <Field name="doorsOpenAt" component={renderField} type="time" />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="form__form-group">
              <span className="form-header">{s('matchType')}*</span>
              <Field
                name="matchType"
                component={renderSelectField}
                type="text"
                options={props.matchTypes}
                placeholder={p('chooseMatchType')}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="form__form-group">
              <span className="form-header">{p('matchSponsor')}</span>
              <Field
                name="sponsor"
                component={renderSelectField}
                options={props.sponsorOptions}
                placeholder={p('chooseMatchSponsor')}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className="flex-row">
              <h5 className="bold-text marginBottom-10 marginRight-8">
                {p('preSale')}
              </h5>
              <TooltipWithIcon id="B-billett" text={p('presaleDescription')} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="flex">
              <div className="form__form-group-field flex-1">
                <Field
                  name="presaleCheck"
                  component={renderCheckBoxField}
                  label={p('preSaleActivated')}
                  className="colored-click"
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <div className="flex">
              <div className="flex-1" />
              <div className="flex-10" />
            </div>
            <div className="form__form-group flex-10">
              <span className="form-header">{p('preSaleExpires')}*</span>
              <div className="marginTop-5" />
              <div className="form__form-group-field">
                <Field
                  name="presale"
                  component={renderField}
                  type="datetime-local"
                  disabled={formValues && !formValues.presaleCheck}
                />
                <div className="form__form-group-icon">
                  <TimetableIcon />
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <div className="flex-row">
              <h5 className="bold-text marginBottom-10 marginRight-8">
                {p('pickUpDeadline')}
              </h5>
              <TooltipWithIcon
                id="B-billett"
                text={p('reservationDescription')}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <div className="form__form-group flex-10">
              <span className="form-header">{p('canBeRetrievedFrom')}</span>
              <div className="marginTop-5" />
              <div className="form__form-group-field">
                <Field
                  name="claimStartDate"
                  component={renderField}
                  type="datetime-local"
                />
                <div className="form__form-group-icon">
                  <TimetableIcon />
                </div>
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <div className="form__form-group flex-10">
              <span className="form-header">
                {p('collectionDeadlineExpires')}
              </span>
              <div className="marginTop-5" />
              <div className="form__form-group-field">
                <Field
                  name="claimEndDate"
                  component={renderField}
                  type="datetime-local"
                />
                <div className="form__form-group-icon">
                  <TimetableIcon />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Col>
      <Col lg={6}>
        <div className="form__form-group">
          <div className="card__title marginBottom-10">
            <h5 className="bold-text">{p('ticketControl')}</h5>
          </div>
          <Row>
            <Col xl={7} md={12}>
              <span className="form-header">{p('chooseControlImg')}</span>
              <div className="flex">
                {props.selectedControlImage.control_image_data ? (
                  <img
                    src={props.selectedControlImage.control_image_data}
                    alt="Control Preview"
                    style={{ width: 'auto', height: '32px' }}
                  />
                ) : (
                  ''
                )}
                <Field
                  name="controlImageSelect"
                  component={renderSelectField}
                  placeholder="Velg kontrollbilde"
                  options={props.controlImages}
                  onChange={(e) => {
                    props.getControlImage(Number(e.value));
                  }}
                />
              </div>
            </Col>
            <Col xl={2} md={12}>
              <div className="form__form-group">
                <span className="form-header">{p('QRticket')}</span>
                <div className="form__form-group-field">
                  <Field
                    name="useQr"
                    component={renderCheckBoxField}
                    label={p('useQr')}
                    className="colored-click"
                  />
                </div>
              </div>
            </Col>
            {props.type === 'create' && (
              <Col xl={3} md={12}>
                <div className="form__form-group">
                  <span className="form-header">
                    {s('sendPushNotification')}
                  </span>
                  <div className="form__form-group-field">
                    <Field
                      name="notify_user"
                      component={renderCheckBoxField}
                      label={t('seasonObjectsShared.sendPush')}
                      className="colored-click"
                    />
                  </div>
                </div>
              </Col>
            )}
          </Row>
          <Row>
            <Col xl={7} md={12}>
              <div className="form__form-group">
                <span className="form-header">{s('saleStart')}*</span>
                <div className="form__form-group-field">
                  <Field
                    name="saleStart"
                    component={renderField}
                    type="datetime-local"
                  />
                  <div className="form__form-group-icon">
                    <TimetableIcon />
                  </div>
                </div>
              </div>
            </Col>
            <Col xl={5} md={12}>
              <div className="form__form-group">
                <span className="form-header">{p('accesibleInWeb')}</span>
                <div className="form__form-group-field">
                  <Field
                    name="useWebsale"
                    component={renderCheckBoxField}
                    label={p('useWebsale')}
                    className="colored-click"
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="form__form-group">
                <span className="form-header">{s('saleEnd')}*</span>
                <div className="form__form-group-field">
                  <Field
                    name="saleEnd"
                    component={renderField}
                    type="datetime-local"
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="form__form-group">
                <span className="form-header">
                  {p('externalTicketSolution')}
                </span>
                <Field
                  name="externalLink"
                  component={renderField}
                  placeholder={p('linkToExternalTicketSolution')}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="form__form-group">
                <span className="form-header">{p('externalMatchId')}</span>
                <Field
                  name="externalMatchID"
                  component={renderField}
                  type="text"
                  placeholder={`${p('externalMatchIdHockeyLive')} (Optional)`}
                />
              </div>
            </Col>
          </Row>
          {props.match.id && (
            <Row>
              <Col>
                <div className="form__form-group">
                  <span className="form-header marginRight-8">
                    {p('universalLinkMatch')}
                  </span>
                  <TooltipWithIcon
                    text={p('universalLinkExplanation')}
                    id="universalLinkMatch"
                  />
                  <div className="form__form-group-field">
                    <Field name="universalLink" component="input" disabled />
                    <div className="form__form-group-icon">
                      <LinkVariantIcon />
                    </div>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="form__form-group">
                  <span className="form-header marginRight-8">
                    {p('deepLinkMatch')}
                  </span>
                  <TooltipWithIcon
                    text={p('deepLinkExplanation')}
                    id="deepLinkMatch"
                  />
                  <div className="form__form-group-field">
                    <Field name="deepLink" component="input" disabled />
                    <div className="form__form-group-icon">
                      <LinkVariantIcon />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          )}
        </div>
      </Col>
      <Row>
        <Col>
          <ButtonToolbar>
            <Button color="primary" type="submit">
              {props.type === 'create' ? `${s('create')}` : `${s('save')}`}
            </Button>
            <Link to="/matches">
              <Button type="button" color="secondary">
                {s('close')}
              </Button>
            </Link>
          </ButtonToolbar>
        </Col>
      </Row>
    </form>
  );
};

Details.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  teams: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  matchTypes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  controlImages: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  selectedControlImage: PropTypes.PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    control_image_data: PropTypes.string,
  }).isRequired,
  getControlImage: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  formValues: PropTypes.shape({
    presaleCheck: PropTypes.bool,
  }),
  match: PropTypes.shape({
    id: PropTypes.number,
    match_starts_at: PropTypes.string,
    presale_duration: PropTypes.number,
    sale_start: PropTypes.string,
    sale_start_web: PropTypes.string,
    sale_end: PropTypes.string,
    doors_open_at: PropTypes.string,
    match_url: PropTypes.string,
    ntb_match_id: PropTypes.number,
    ntb_stage_id: PropTypes.number,
    home_team: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      ntb_id: PropTypes.string,
    }),
    away_team: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      ntb_id: PropTypes.string,
    }),
    match_type: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    season: PropTypes.number,
    use_qr: PropTypes.number,
    use_websale: PropTypes.number,
    external_match_id: PropTypes.string,
    control_image_id: PropTypes.number,
    company_id: PropTypes.number,
  }),
  type: PropTypes.string,
  sponsorOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  enabledFeatures: PropTypes.shape({
    hasFetched: PropTypes.bool,
    tickets: PropTypes.bool,
  }).isRequired,
};

Details.defaultProps = {
  match: {},
  type: 'create',
  formValues: undefined,
};

const reduxF = reduxForm({
  form: 'detailsForm',
  validate,
});

export default connect((state) => ({
  formValues: getFormValues('detailsForm')(state),
}))(reduxF(Details));
