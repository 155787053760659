import React, { useEffect, useState } from 'react';
import {
  Container,
  Col,
  Row,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  ModalFooter,
} from 'reactstrap';
import { useQuery, useMutation } from '@tanstack/react-query';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import useConfig from '../../util/useConfig';
import NextMatches from './components/NextMatches';
import UpcomingEvents from './components/events/UpcomingEvents';
import UpcomingOffers from './components/offers/UpcomingOffers';
import TierUsers from './components/TierUsers';
import CompanyLevels from './components/CompanyLevels';
import SeasonTickets from './components/SeasonTickets';
import TicketSales from './components/TicketSales';
import ActiveUsers from './components/ActiveUsers';
import Api from '../../util/api';
import { partial } from '../../util/translation/translation';
import ConfirmWidget from './components/shared/WidgetConfirm';
import Config from '../../config';

const WidgetTypes = {
  upcomingMatches: { component: <NextMatches />, colSize: 12 },
  upcomingEvents: { component: <UpcomingEvents />, colSize: 6 },
  upcomingOffers: { component: <UpcomingOffers />, colSize: 6 },
  activeUsers: { component: <ActiveUsers />, colSize: 6 },
  companiesByLevel: { component: <CompanyLevels />, colSize: 6 },
  usersByTier: { component: <TierUsers />, colSize: 6 },
  seasonTickets: { component: <SeasonTickets />, colSize: 6 },
  ticketSales: { component: <TicketSales />, colSize: 6 },
};

const MONOLITH_WIDGETS = ['ticketSales', 'seasonTickets', 'upcomingMatches'];

const DashboardPage = () => {
  const dT = partial('DashboardPage');
  const sT = partial('shared');

  const { id } = useConfig();

  const [widgets, setWidgets] = useState([]);
  const [modifyWidgets, setModifyWidgets] = useState(false);
  const [enabledWidgets, setEnabledWidgets] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [closeWithoutSaving, setCloseWithoutSaving] = useState(false);

  const { data: allWidgets } = useQuery({
    queryKey: ['all-widgets'],
    queryFn: () =>
      Api.dashboard.getAllWidgets().then(({ data }) => ({
        data: data.filter((item) => {
          if (Config.monolithEnabled) {
            return true;
          }
          return !MONOLITH_WIDGETS.includes(item.name);
        }),
      })),
  });

  const { data: activeWidgets } = useQuery({
    queryKey: ['active-widgets'],
    enabled: Array.isArray(allWidgets?.data),
    queryFn: () =>
      Api.dashboard.getActiveWidgets(id).then(({ data }) => ({
        data: {
          activeWidgets: data.activeWidgets.filter((item) => {
            if (Config.monolithEnabled) {
              return true;
            }
            return !MONOLITH_WIDGETS.includes(item.name);
          }),
        },
      })),
  });

  const { mutate } = useMutation({
    mutationFn: () =>
      Api.dashboard.upsertActiveWidgets(id, { activeWidgets: enabledWidgets }),
  });

  useEffect(() => {
    if (allWidgets?.data && Array.isArray(allWidgets.data)) {
      setWidgets(allWidgets.data.map((w) => w.name));
    }
  }, [allWidgets, closeWithoutSaving]);

  const filterWidgets = () => {
    setWidgets((prev) =>
      prev.filter((w) => !enabledWidgets.map((aw) => aw.name).includes(w))
    );
  };

  useEffect(() => {
    if (activeWidgets?.data?.activeWidgets || closeWithoutSaving) {
      setEnabledWidgets(
        activeWidgets.data.activeWidgets.map((w) => ({
          name: w.name,
          col: w.col,
        }))
      );
      filterWidgets();
    }
  }, [activeWidgets, closeWithoutSaving]);

  useEffect(() => {
    if (closeWithoutSaving) {
      setCloseWithoutSaving(false);
      setModifyWidgets(false);
    }
  }, [closeWithoutSaving]);

  const addWidgets = (source, destination) => {
    const existingEnabledWidgets = [...enabledWidgets];
    const existingWidgets = [...widgets];
    const activeWidget = {
      name: existingWidgets[source.index],
      col: WidgetTypes[existingWidgets[source.index]].colSize,
    };
    existingEnabledWidgets.splice(destination.index, 0, activeWidget);
    existingWidgets.splice(source.index, 1);
    setEnabledWidgets(existingEnabledWidgets);
    setWidgets(existingWidgets);
  };

  const swapWidgets = (source, destination) => {
    const existingEnabledWidgets = [...enabledWidgets];
    const toBeSwaped = existingEnabledWidgets[destination.index];
    existingEnabledWidgets[destination.index] =
      existingEnabledWidgets[source.index];
    existingEnabledWidgets[source.index] = toBeSwaped;
    setEnabledWidgets(existingEnabledWidgets);
  };

  const removeWidgets = (source) => {
    const existingEnabledWidgets = [...enabledWidgets];
    const existingWidgets = [...widgets];
    existingWidgets.push(existingEnabledWidgets[source.index].name);
    existingEnabledWidgets.splice(source.index, 1);
    setEnabledWidgets(existingEnabledWidgets);
    setWidgets(existingWidgets);
  };

  const resetWidgetStyles = (type) => {
    const widgetPane = document.querySelectorAll('.enabled-widget');
    widgetPane.forEach((pane) => {
      pane.parentNode?.classList.add('widget-border-remove');
      pane.parentNode?.classList.remove('widget-border-top');
      pane.parentNode?.classList.remove('widget-border-bottom');
    });
    const activeWidget = document.querySelector(`.${type}-widget-active`);
    activeWidget?.classList.remove(`${type}-widget-active`);
    activeWidget?.classList.add(`${type}-widget`);
  };

  const modifyClasses = (element, type) => {
    element.classList.remove(`${type}-widget`);
    element.classList.add(`${type}-widget-active`);
  };

  const getWidgetElement = (type, draggableId) => {
    let widgetBeingDragged = null;
    const availableWidgets = document.querySelectorAll(`.${type}-widgets`);
    availableWidgets.forEach((w) => {
      if (w.dataset?.rbdDraggableId === draggableId) {
        widgetBeingDragged = w;
      }
    });
    return widgetBeingDragged.children[0];
  };

  const handleOnDragStart = ({ source, draggableId }) => {
    if (source.droppableId === 'widgets') {
      const child = getWidgetElement('available', draggableId);
      modifyClasses(child, 'available');
    } else {
      const child = getWidgetElement('enabled', draggableId);
      modifyClasses(child, 'enabled');
    }
  };

  const handleOnDragEnd = ({ source, destination }) => {
    resetWidgetStyles('available');
    resetWidgetStyles('enabled');
    if (!destination) return;
    if (
      source.droppableId === 'widgets' &&
      destination.droppableId === 'enabled'
    ) {
      addWidgets(source, destination);
    }
    if (
      source.droppableId === 'enabled' &&
      destination.droppableId === 'widgets'
    ) {
      removeWidgets(source);
    }
    if (
      source.droppableId === destination.droppableId &&
      source.droppableId === 'enabled'
    ) {
      swapWidgets(source, destination);
    }
    const widgetPane = document.querySelectorAll('.enabled-widget');
    widgetPane.forEach((pane) => {
      pane.parentNode?.classList.add('widget-border-remove');
    });
  };

  const handleOnDragUpdate = ({ destination }) => {
    if (!destination) return;
    if (destination.droppableId === 'enabled') {
      const widgetPane = document.querySelectorAll('.enabled-widget');
      widgetPane.forEach((pane) => {
        pane.parentNode?.classList.add('widget-border-remove');
        pane.parentNode?.classList.remove('widget-border-top');
        pane.parentNode?.classList.remove('widget-border-bottom');
      });
      const selectedWidget = widgetPane[destination.index];
      if (destination.index === 0) {
        selectedWidget?.parentNode?.classList.add('widget-border-top');
        selectedWidget?.parentNode?.classList.remove('widget-border-remove');
      }
      if (destination.index > 0) {
        widgetPane[destination.index - 1].parentNode?.classList.add(
          'widget-border-bottom'
        );
        widgetPane[destination.index - 1].parentNode?.classList.remove(
          'widget-border-remove'
        );
      }
    }
  };

  const showModal = () => {
    setModifyWidgets(true);
    filterWidgets();
  };

  const handleModalToggle = () => {
    setShowConfirm(true);
  };

  const handleSave = () => {
    setModifyWidgets(false);
    mutate();
  };

  return (
    <div>
      {showConfirm && (
        <ConfirmWidget
          setClose={setCloseWithoutSaving}
          setConfirm={setShowConfirm}
        />
      )}
      <Modal isOpen={modifyWidgets} centered>
        <ModalHeader toggle={handleModalToggle} className="widget-modal-header">
          <div className="widget-modal-header-text">
            {dT('addRemoveWidgets')}
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="widget-pane-wrapper">
            <DragDropContext
              onDragEnd={handleOnDragEnd}
              onDragStart={handleOnDragStart}
              onDragUpdate={handleOnDragUpdate}
            >
              <div className="widget-pane">
                <Droppable droppableId="widgets">
                  {(droppableProvided) => (
                    <div
                      ref={droppableProvided.innerRef}
                      {...droppableProvided.droppableProps}
                      className="widget-dropzone"
                    >
                      <div className="widget-pane-header">
                        {dT('availableWidgets')}
                      </div>
                      {widgets.map((w, i) => (
                        <Draggable
                          draggableId={`widgets-${w}`}
                          index={i}
                          key={w}
                        >
                          {(draggableProvided) => (
                            <div
                              className="available-widgets"
                              ref={draggableProvided.innerRef}
                              {...draggableProvided.dragHandleProps}
                              {...draggableProvided.draggableProps}
                            >
                              <div className="available-widget">
                                <img
                                  src="/assets/icons/widget_icon.svg"
                                  alt="Widget icon"
                                />
                                {dT(w)}
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {droppableProvided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
              <div className="widget-pane">
                <Droppable droppableId="enabled">
                  {(droppableProvided) => (
                    <div
                      ref={droppableProvided.innerRef}
                      {...droppableProvided.droppableProps}
                      className="widget-dropzone"
                    >
                      <div className="widget-pane-header">
                        {dT('activeWidgets')}
                      </div>
                      {enabledWidgets.map((e, i) => (
                        <Draggable
                          draggableId={`enabled-${e.name}`}
                          index={i}
                          key={e.name}
                        >
                          {(draggableProvided) => (
                            <div
                              className="enabled-widgets"
                              ref={draggableProvided.innerRef}
                              {...draggableProvided.dragHandleProps}
                              {...draggableProvided.draggableProps}
                            >
                              <div className="enabled-widget">
                                <img
                                  src="/assets/icons/widget_icon.svg"
                                  alt="Widget icon"
                                />
                                {dT(e.name)}
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {droppableProvided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            </DragDropContext>
          </div>
        </ModalBody>
        <ModalFooter className="widget-modal-footer">
          <Button className="widget-modal-footer-btn" onClick={handleSave}>
            {sT('save')}
          </Button>
        </ModalFooter>
      </Modal>
      <Container>
        <Row>
          <Col>
            <div className="widget-modify-wrapper">
              <button onClick={showModal} className="widget-modify-btn">
                <img src="/assets/icons/add_widgets.svg" alt="Add widget" />
                <div>{dT('addWidgets')}</div>
              </button>
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          {enabledWidgets.map((w) => (
            <Col md={12} lg={w.col} key={w.name}>
              {WidgetTypes[w.name]?.component}
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default DashboardPage;
