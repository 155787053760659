import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from 'reactstrap';
import { partial } from '../../../util/translation/translation';

const DateRangePicker = ({ isOpen, onCancel, onApply }) => {
  const s = partial('shared');
  const [customStartDate, setCustomStartDate] = useState('');
  const [customEndDate, setCustomEndDate] = useState('');
  const [startDateError, setStartDateError] = useState('');
  const [endDateError, setEndDateError] = useState('');
  const [rangeError, setRangeError] = useState('');
  const [dateConstraints, setDateConstraints] = useState({
    minDate: '',
    maxDate: '',
  });

  useEffect(() => {
    const today = new Date();
    const maxDate = today.toISOString().split('T')[0];

    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(today.getMonth() - 3);
    const minDate = threeMonthsAgo.toISOString().split('T')[0];

    setDateConstraints({ minDate, maxDate });
  }, [isOpen]);

  const validateDates = () => {
    setStartDateError('');
    setEndDateError('');
    setRangeError('');

    const startDate = new Date(customStartDate);
    const endDate = new Date(customEndDate);
    const minDate = new Date(dateConstraints.minDate);

    let isValid = true;

    if (!customStartDate) {
      setStartDateError('Start date is required');
      isValid = false;
    } else if (isNaN(startDate.getTime())) {
      setStartDateError('Invalid start date');
      isValid = false;
    } else if (startDate < minDate) {
      setStartDateError('Start date cannot be more than 3 months in the past');
      isValid = false;
    }

    if (!customEndDate) {
      setEndDateError('End date is required');
      isValid = false;
    } else if (isNaN(endDate.getTime())) {
      setEndDateError('Invalid end date');
      isValid = false;
    }

    if (isValid && startDate > endDate) {
      setEndDateError('End date must be after start date');
      isValid = false;
    }

    if (isValid && startDate > new Date()) {
      setStartDateError('Start date cannot be a future date');
      isValid = false;
    }

    return isValid;
  };

  const handleApply = () => {
    if (validateDates()) {
      const startDate = new Date(customStartDate);
      const endDate = new Date(customEndDate);
      onApply(startDate, endDate);
      resetForm();
    }
  };

  const handleCancel = () => {
    resetForm();
    onCancel();
  };

  const resetForm = () => {
    setCustomStartDate('');
    setCustomEndDate('');
    setStartDateError('');
    setEndDateError('');
    setRangeError('');
  };

  return (
    <Modal isOpen={isOpen} toggle={handleCancel} size="sm">
      <ModalHeader toggle={handleCancel} className="border-bottom-0">
        Select custom date range
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="startDate" className="small">
              {s('startDate')}
            </Label>
            <Input
              type="date"
              id="startDate"
              value={customStartDate}
              onChange={(e) => setCustomStartDate(e.target.value)}
            />
            {startDateError && (
              <div className="text-danger small mt-1">{startDateError}</div>
            )}
          </FormGroup>

          <FormGroup>
            <Label for="endDate" className="small">
              {s('endDate')}
            </Label>
            <Input
              type="date"
              id="endDate"
              value={customEndDate}
              onChange={(e) => setCustomEndDate(e.target.value)}
            />
            {endDateError && (
              <div className="text-danger small mt-1">{endDateError}</div>
            )}
            {rangeError && (
              <div className="text-danger small mb-2">{rangeError}</div>
            )}
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter className="border-top-0">
        <Button color="secondary" onClick={handleCancel} outline>
          {s('cancel')}
        </Button>
        <Button color="primary" onClick={handleApply}>
          Apply
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DateRangePicker;
