import React, { useEffect } from 'react';
import { Button, Col, Container, Label, Row, Table } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { useRecoilValue } from 'recoil';
import { Link } from 'react-router-dom';
import countries from 'i18n-iso-countries';
import renderField from '../../../shared/components/custom/Field';
import renderDropZoneFieldNew from '../../../shared/components/form/DropZoneNew';
import RenderSwitchField from '../../../shared/components/custom/Switch';
import renderSelectField from '../../../shared/components/form/Select';
import userLanguageState from '../../../recoil/userLanguage/selector';

const TeamForm = ({
  handleSubmit,
  team,
  initialize,
  players,
  teamLeagues,
  availableLeagues,
}) => {
  const userLanguage = useRecoilValue(userLanguageState);
  const countryOptions = Object.entries(countries.getNames(userLanguage)).map(
    ([iso, country]) => ({
      name: country,
      iso,
      value: iso,
      label: country,
    })
  );
  useEffect(() => {
    if (team) {
      initialize({
        ...team,
        logoUrl: team.logoUrl
          ? [{ src: team.logoUrl, value: team.logoUrl }]
          : undefined,
        teamLeagues,
      });
    }
  }, [team, teamLeagues]);

  return (
    <>
      <form onSubmit={handleSubmit} className="form">
        <Container>
          <Row>
            <Col md={6}>
              <Col md={12}>
                <div className="form__form-group">
                  <Label htmlFor="name">Name</Label>
                  <Field name="name" component={renderField} />
                </div>
              </Col>
              <Col md={12}>
                <div className="form__form-group">
                  <Label htmlFor="shortName">Short name</Label>
                  <Field name="shortName" component={renderField} type="" />
                </div>
              </Col>
              <Col md={12}>
                <div className="form__form-group">
                  <Label htmlFor="noPlayerTintColor">Tint Color</Label>
                  <Field
                    name="noPlayerTintColor"
                    component={renderField}
                    type=""
                  />
                </div>
              </Col>

              <Col md={12}>
                <div className="form__form-group">
                  <Label htmlFor="externalProviderId">
                    External provider id
                  </Label>
                  <Field
                    name="externalProviderId"
                    component={renderField}
                    type=""
                  />
                </div>
              </Col>
              <Col md={12}>
                <div className="form__form-group">
                  <Label htmlFor="externalProviderId">Leagues for team</Label>
                  <Field
                    name="teamLeagues"
                    component={renderSelectField}
                    multiple
                    options={availableLeagues}
                  />
                </div>
              </Col>
              <Col md={12}>
                <div className="form__form-group">
                  <Label htmlFor="logoUrl">Logo</Label>
                  <Field name="logoUrl" component={renderDropZoneFieldNew} />
                </div>
              </Col>
            </Col>
            <Col md={6}>
              <Col md={12}>
                <Table responsive bordered>
                  <thead>
                    <tr>
                      <th>Players</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {players.map((player) => (
                      <tr key={player.id}>
                        <td>{player.fullname}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Row>
                <Col md={6}>
                  <div className="form__form-group">
                    <Label htmlFor="name">Firstname</Label>
                    <Field name="player" component={renderField} />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form__form-group">
                    <Label htmlFor="name">Lastname</Label>
                    <Field name="player" component={renderField} />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <div className="form__form-group">
                    <Label htmlFor="name">Jerseyname</Label>
                    <Field name="player" component={renderField} />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form__form-group">
                    <span className="form-header">Birth date</span>
                    <Field
                      name="birthday"
                      component={renderField}
                      type="date"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={2}>
                  <div className="form__form-group">
                    <Label htmlFor="name">Height</Label>
                    <Field name="player" component={renderField} />
                  </div>
                </Col>
                <Col md={2}>
                  <div className="form__form-group">
                    <Label htmlFor="name">Weight</Label>
                    <Field name="player" component={renderField} />
                  </div>
                </Col>
                <Col md={2}>
                  <div className="form__form-group">
                    <Label htmlFor="name">Number</Label>
                    <Field name="player" component={renderField} />
                  </div>
                </Col>
                <Col md={3}>
                  <div className="form__form-group">
                    <Label htmlFor="name">Shots</Label>
                    <Field name="player" component={renderField} />
                  </div>
                </Col>
                <Col md={3}>
                  <div className="form__form-group">
                    <Label htmlFor="name">Position</Label>
                    <Field name="player" component={renderField} />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <div className="form__form-group">
                    <span className="form-header">Nationality</span>
                    <Field
                      name="country"
                      component={renderSelectField}
                      options={countryOptions}
                      placeholder="Choose country"
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form__form-group flex-Vcenter">
                    <Field
                      name="active"
                      defaultChecked
                      label={['Active', 'Inactive']}
                      component={RenderSwitchField}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <Col md={12}>
                <div className="form__form-group">
                  <Label htmlFor="logoUrl">Player Image</Label>
                  <Field name="logoUrl" component={renderDropZoneFieldNew} />
                </div>
              </Col>
            </Col>
            <Col md={12} style={{ marginTop: '15px' }}>
              <Button color="primary">Update Player</Button>
              <Link to="/clubadmin">
                <Button>Cancel</Button>
              </Link>
            </Col>
          </Row>
          <Col md={12} style={{ marginTop: '15px' }}>
            <Button color="primary">{team ? 'Save' : 'Create'}</Button>
            <Link to="/clubadmin">
              <Button color="secondary">Close</Button>
            </Link>
          </Col>
        </Container>
      </form>
    </>
  );
};

const rf = reduxForm({
  form: 'team_admin_form',
});

export default rf(TeamForm);
