import React, { useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Button, Col, Label } from 'reactstrap';
import renderField from '../../../shared/components/custom/Field';
import renderSelectField from '../../../shared/components/form/Select';

const VippsForm = ({ handleSubmit, initialize, vendor, teams }) => {
  useEffect(() => {
    if (vendor) {
      initialize(vendor);
    }
  }, [vendor]);
  return (
    <form className="form" onSubmit={handleSubmit}>
      <Col md={6}>
        <div className="form__form-group">
          <Label htmlFor="teamId">Connected team</Label>
          <Field
            name="teamId"
            component={renderSelectField}
            options={teams.map((team) => ({
              label: team.name,
              value: `${team.id}`,
            }))}
          />
        </div>
      </Col>
      <Col md={6}>
        <div className="form__form-group">
          <Label htmlFor="name">Name</Label>
          <Field name="name" component={renderField} />
        </div>
      </Col>
      <Col md={6}>
        <div className="form__form-group">
          <Label htmlFor="config.merchant_id">Merchant id</Label>
          <Field name="config.merchant_id" component={renderField} type="" />
        </div>
      </Col>

      <Col md={6}>
        <div className="form__form-group">
          <Label htmlFor="config.secret">Secret</Label>
          <Field name="config.secret" component={renderField} type="password" />
        </div>
      </Col>
      <Col md={6}>
        <div className="form__form-group">
          <Label htmlFor="config.access_sub_key">Access subscription key</Label>

          <Field name="config.access_sub_key" component={renderField} type="" />
        </div>
      </Col>

      <Col md={6}>
        <div className="form__form-group">
          <Label htmlFor="config.subscriptionId">Subscription Id</Label>

          <Field name="config.subscriptionId" component={renderField} type="" />
        </div>
      </Col>
      <Col md={6}>
        <div className="form__form-group">
          <Label htmlFor="config.id">Client id</Label>

          <Field name="config.id" component={renderField} type="" />
        </div>
      </Col>
      <Button color="success">{vendor ? 'Edit' : 'Create'}</Button>
    </form>
  );
};
const rf = reduxForm({
  form: 'vipps_form',
});
export default rf(VippsForm);
