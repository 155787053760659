import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { DoughnutChart, Widget, WidgetHeader, EmptyGraphData } from './shared';
import useConfig from '../../../util/useConfig';
import Api from '../../../util/api';
import Loading from '../../shared/Spinner';
import helpers from '../helpers';
import { partial } from '../../../util/translation/translation';

const TierUsers = () => {
  const dT = partial('DashboardPage');
  const config = useConfig();
  const [chartData, setChartData] = useState({});

  const { data, isLoading } = useQuery({
    queryKey: ['tiers-user-count'],
    queryFn: () => Api.tiers.getTiersWithUserCount(config),
  });

  useEffect(() => {
    if (data?.data) {
      const tiers = data.data;
      const { labels, dataset, colors } = helpers.getSortedChartData(
        tiers,
        'name',
        'userCount'
      );
      setChartData({
        labels,
        datasets: [
          {
            data: dataset,
            backgroundColor: colors,
          },
        ],
      });
    }
  }, [data]);

  const renderChart =
    chartData.datasets && chartData.datasets[0].data.length > 0 ? (
      <DoughnutChart chartData={chartData} />
    ) : (
      <EmptyGraphData message={dT('noData')} />
    );

  return (
    <Widget>
      <WidgetHeader
        title={dT('usersByTier')}
        subhead={dT('usersByTierSubheading')}
      />
      {isLoading ? <Loading /> : renderChart}
    </Widget>
  );
};

export default TierUsers;
