import React from 'react';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import DotsHorizontalIcon from 'mdi-react/DotsHorizontalIcon';
import PropTypes from 'prop-types';
import { partial } from '../../../util/translation/translation';

const s = partial('shared');
const TableMenu = ({ route, onDeleteClick }) => (
  <UncontrolledDropdown className="dashboard__table-more">
    <DropdownToggle>
      <DotsHorizontalIcon />
    </DropdownToggle>
    <DropdownMenu className="dropdown__menu">
      <Link to={route}>
        <DropdownItem>{s('edit')}</DropdownItem>
      </Link>
      <DropdownItem onClick={onDeleteClick} className="danger">
        {s('delete')}
      </DropdownItem>
    </DropdownMenu>
  </UncontrolledDropdown>
);

TableMenu.propTypes = {
  route: PropTypes.string.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
};

export default TableMenu;
