import React from 'react';
import { Table } from 'reactstrap';
import skeletonTableLoader from '../../../shared/components/custom/SkeletonLoaders';

const DeviceTable = ({ devices, clubName, isLoading, loginData }) => {
  const currentDevices = devices.slice(0, 2);
  return (
    <div
      style={{
        display: 'flex',
        marginBottom: 10,
        paddingBottom: 5,
        background: '#fff',
        borderRadius: 6,
        boxShadow: '0px 2px 8px 0px rgba(0,0,0,0.08)',
      }}
    >
      <Table responsive striped>
        <thead>
          <tr>
            <th>Stream name</th>
            <th>Active users</th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan="2">
                <div className="d-flex justify-content-center align-items-center">
                  {skeletonTableLoader(1, 1, 70)}
                </div>
              </td>
            </tr>
          ) : loginData.length === 0 ? (
            <tr>
              <td colSpan="2">
                <div className="d-flex justify-content-center align-items-center">
                  <p className="">0</p>
                </div>
              </td>
            </tr>
          ) : (
            currentDevices.map((device) => (
              <tr key={device.os}>
                <td>
                  {clubName} {device.os}
                </td>
                <td className="text-end">{device.uniqueUserCount}</td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default DeviceTable;
