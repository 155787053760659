import React from 'react';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';
import { connect } from 'react-redux';
import moment from 'moment';
// import config from '../../../config';
import { partial } from '../../../util/translation/translation';
import useConfig from '../../../util/useConfig';

const p = partial('box_officeShare');
const s = partial('shared');
const PrintTicket = (props) => {
  const config = useConfig();
  const { onLoad, mini } = props;
  const cutLine = (index) => {
    const hasQr = props.enabledQR ? 1 : 0;
    return (index + 1 + hasQr) % 3 !== 0 ? 'cut-line' : '';
  };

  const getQRCodeAll = () => {
    if (props.tickets.length === 0) {
      return '';
    }
    const ticketIDs = props.tickets
      .reduce((acc, ticket) => `${acc}${ticket.id},`, '')
      .slice(0, -1);
    return btoa(
      `${props.tickets[0].user_id}:${props.tickets[0].match.id}:${ticketIDs}`
    );
  };

  const renderTickets = () => {
    return props.tickets.map((ticket, index) => {
      return (
        <React.Fragment key={`renderTickets-${ticket.id}`}>
          <div className={`printer-box ${mini ? 'mini' : 'a4'}`}>
            <div className="printer-box-container">
              <div className="printer-border">
                <div className="printer-border-container">
                  <div className="printer-border-circle" />
                  <div className="printer-border-circle" />
                  <div className="printer-border-circle" />
                  <div className="printer-border-circle" />
                  <div className="printer-border-circle" />
                  <div className="printer-border-circle" />
                  <div className="printer-border-circle" />
                  <div className="printer-border-circle" />
                </div>
              </div>
              {mini === true && (
                <div className="printer-box-reference mini">
                  <p>{ticket.payment.transaction_id}</p>
                </div>
              )}
              <div className="printer-box-left">
                <div className="flex">
                  <img
                    onLoad={onLoad}
                    className="printer-box-hometeam-logo"
                    src={ticket?.match?.home_team?.logo?.url}
                    alt="Home team logo"
                  />
                  <div className="printer-box-match">
                    <div>
                      <p className="printer-box-hometeam-text">
                        {ticket.match.home_team.name}
                      </p>
                      <p className="printer-box-awayteam-text">
                        vs <strong>{ticket.match.away_team.name}</strong>
                      </p>
                    </div>
                    <div className="printer-box-margin">
                      <p className="printer-box-info-text">
                        {ticket?.match?.match_type?.name}
                      </p>
                      <p className="printer-box-match-start-text">
                        {moment(ticket.match.match_starts_at).format(
                          'D.MM.YYYY'
                        )}{' '}
                        -&nbsp;
                        {p('beforeTimeFormat')}
                        {moment(ticket.match.match_starts_at).format('HH.mm')}
                      </p>
                      <p className="printer-box-doors-open-text">
                        {p('doorsOpenAt')}{' '}
                        {moment(ticket.match.doors_open_at).format('HH.mm')}
                      </p>
                    </div>
                    {ticket.match?.company?.print_image?.url && (
                      <div className="printer-box-sponsor">
                        <p>{p('matchSponsor')}</p>
                        <img
                          onLoad={onLoad}
                          src={ticket.match.company.print_image.url}
                          alt=""
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <p className="printer-ticket-info-text printer-ticket-info-margin">
                    <strong>{ticket.price_group.title.toUpperCase()}</strong>{' '}
                    {Number(ticket.price)} kr
                  </p>
                  <div className="printer-stadium">
                    <img
                      onLoad={onLoad}
                      className="printer-pin"
                      src="/assets/files/boxoffice/pin.svg"
                      alt=""
                    />
                    <p className="printer-ticket-info-text">
                      <strong>{config.stadium_name}</strong>,{' '}
                      {config.stadium_address}
                    </p>
                  </div>
                </div>
                {mini === true && (
                  <div className="printer-box-tfg-mini">
                    <img
                      onLoad={onLoad}
                      src="/assets/files/boxoffice/FangroupHorizontal.svg"
                      alt=""
                    />
                  </div>
                )}
              </div>
              {mini === false && (
                <div className="printer-box-reference">
                  <p>
                    {p('referenceNumber')}: {ticket.payment.transaction_id}
                  </p>
                </div>
              )}
              <div className="printer-box-right">
                <div className="printer-box-location">
                  <p>{s('field')}:</p>
                  <p>{ticket.seat_match.seat.row.field.name}</p>
                </div>
                <div className="printer-box-location-row-seat">
                  <div className="printer-box-location">
                    <p>{s('row')}:</p>
                    <p>{ticket.seat_match.seat.row.number}</p>
                  </div>
                  <div className="printer-box-location">
                    <p>{s('seat')}:</p>
                    <p>{ticket.seat_match.seat.seat_id}</p>
                  </div>
                </div>
                <QRCode
                  renderAs="svg"
                  className="printer-qr"
                  value={btoa(
                    `${ticket.user_id}:${ticket.match.id}:${ticket.id}`
                  )}
                />
              </div>
              <div className="printer-border printer-border-right">
                <div className="printer-border-container">
                  <div className="printer-border-circle" />
                  <div className="printer-border-circle" />
                  <div className="printer-border-circle" />
                  <div className="printer-border-circle" />
                  <div className="printer-border-circle" />
                  <div className="printer-border-circle" />
                  <div className="printer-border-circle" />
                  <div className="printer-border-circle" />
                </div>
              </div>
            </div>
            {mini === false && (
              <div className="printer-fangroup">
                <p>{p('deliveredBy')}</p>
                <img
                  onLoad={onLoad}
                  src="/assets/files/boxoffice/FangroupHorizontal.svg"
                  alt=""
                />
              </div>
            )}
          </div>
          {mini === false && <div className={cutLine(index)} />}
        </React.Fragment>
      );
    });
  };
  return (
    <>
      {props.tickets.length > 1 && props.enabledQR === true && (
        <>
          <div className={`printer-box ${mini ? 'mini' : 'a4'}`}>
            <div className="printer-box-container">
              <div className="printer-border">
                <div className="printer-border-container">
                  <div className="printer-border-circle" />
                  <div className="printer-border-circle" />
                  <div className="printer-border-circle" />
                  <div className="printer-border-circle" />
                  <div className="printer-border-circle" />
                  <div className="printer-border-circle" />
                  <div className="printer-border-circle" />
                  <div className="printer-border-circle" />
                </div>
              </div>
              {mini === true && (
                <div className="printer-box-reference mini">
                  <p>{props.tickets?.[0]?.payment?.transaction_id}</p>
                </div>
              )}
              <div className="printer-box-left">
                <div className="flex">
                  <img
                    onLoad={onLoad}
                    className="printer-box-hometeam-logo"
                    src={props.tickets[0]?.match?.home_team?.logo?.url}
                    alt="Home team logo"
                  />
                  <div className="printer-box-match">
                    <div>
                      <p className="printer-box-hometeam-text">
                        {props.tickets[0].match.home_team.name}
                      </p>
                      <p className="printer-box-awayteam-text">
                        vs{' '}
                        <strong>{props.tickets[0].match.away_team.name}</strong>
                      </p>
                    </div>
                    <div className="printer-box-margin">
                      <p className="printer-box-info-text">
                        {props.tickets[0]?.match?.match_type?.name}
                      </p>
                      <p className="printer-box-match-start-text">
                        {moment(props.tickets[0].match.match_starts_at).format(
                          'D.MM.YYYY'
                        )}{' '}
                        -&nbsp;
                        {p('beforeTimeFormat')}
                        {moment(props.tickets[0].match.match_starts_at).format(
                          'HH.mm'
                        )}
                      </p>
                      <p className="printer-box-doors-open-text">
                        {p('doorsOpenAt')}{' '}
                        {moment(props.tickets[0].match.doors_open_at).format(
                          'HH.mm'
                        )}
                      </p>
                    </div>
                    {props.tickets[0].match?.company?.print_image?.url && (
                      <div className="printer-box-sponsor">
                        <p>{p('matchSponsor')}</p>
                        <img
                          onLoad={onLoad}
                          src={props.tickets[0].match.company.print_image.url}
                          alt=""
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <p className="printer-ticket-info-text printer-ticket-info-margin">
                    <strong>{props.tickets.length} Billetter</strong>
                  </p>
                  <div className="printer-stadium">
                    <img
                      onLoad={onLoad}
                      className="printer-pin"
                      src="/assets/files/boxoffice/pin.svg"
                      alt=""
                    />
                    <p className="printer-ticket-info-text">
                      <strong>{config.stadium_name}</strong>,{' '}
                      {config.stadium_address}
                    </p>
                  </div>
                </div>
                {mini === true && (
                  <div className="printer-box-tfg-mini">
                    <img
                      onLoad={onLoad}
                      src="/assets/files/boxoffice/FangroupHorizontal.svg"
                      alt=""
                    />
                  </div>
                )}
              </div>
              {mini === false && (
                <div className="printer-box-reference">
                  <p>
                    {p('referenceNumber')}:{' '}
                    {props.tickets[0].payment.transaction_id}
                  </p>
                </div>
              )}
              <div className="printer-box-right">
                <div className="printer-box-location">
                  <p>{s('field')}:</p>
                  <p>{props.tickets[0].seat_match.seat.row.field.name}</p>
                </div>
                <div className="printer-box-location">
                  <p>{s('row')}:</p>
                  <p>{props.tickets[0].seat_match.seat.row.number}</p>
                </div>
                <div className="printer-box-location">
                  <p>{s('seats')}:</p>
                  <p>
                    {props.tickets.map(
                      (ticket, index) =>
                        `${ticket.seat_match.seat.seat_id}${index + 1 !== props.tickets.length ? ',' : ''} `
                    )}
                  </p>
                </div>
                <QRCode
                  renderAs="svg"
                  className="printer-qr"
                  value={btoa(getQRCodeAll())}
                />
              </div>
              <div className="printer-border printer-border-right">
                <div className="printer-border-container">
                  <div className="printer-border-circle" />
                  <div className="printer-border-circle" />
                  <div className="printer-border-circle" />
                  <div className="printer-border-circle" />
                  <div className="printer-border-circle" />
                  <div className="printer-border-circle" />
                  <div className="printer-border-circle" />
                  <div className="printer-border-circle" />
                </div>
              </div>
            </div>
            <div className="printer-fangroup">
              <p>{p('deliveredBy')}</p>
              <img
                onLoad={onLoad}
                src="/assets/files/boxoffice/FangroupHorizontal.svg"
                alt=""
              />
            </div>
          </div>
          {mini === false && <div className="cut-line" />}
        </>
      )}
      {renderTickets()}
    </>
  );
};

PrintTicket.defaultProps = {
  selectedField: null,
};

PrintTicket.propTypes = {
  selectedField: PropTypes.shape({
    field_type: PropTypes.shape({
      is_unmarked: PropTypes.number,
    }),
  }),
  tickets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      printImage: PropTypes.string,
      match: PropTypes.shape({
        id: PropTypes.number,
        home_team: PropTypes.shape({
          name: PropTypes.string,
        }),
        away_team: PropTypes.shape({
          name: PropTypes.string,
        }),
        match_starts_at: PropTypes.string,
        doors_open_at: PropTypes.string,
      }),
      user_id: PropTypes.number.isRequired,
      reference: PropTypes.string.isRequired,
      price: PropTypes.string.isRequired,
      seat_match_id: PropTypes.number,
      seat_match: PropTypes.shape({
        seat: PropTypes.shape({
          seat_id: PropTypes.number,
          row: PropTypes.shape({
            number: PropTypes.number,
            field: PropTypes.shape({
              name: PropTypes.string,
            }),
          }),
        }),
      }),
      payment: PropTypes.shape({
        transaction_id: PropTypes.string,
      }),
      price_group: PropTypes.shape({
        title: PropTypes.string,
      }),
    })
  ).isRequired,
  me: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  enabledQR: PropTypes.bool.isRequired,
  mini: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  me: state.admin.me,
});

export default connect(mapStateToProps)(PrintTicket);
