import React, { useState, useEffect } from 'react';
import { Row, Col, Container, Alert } from 'reactstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { toast } from 'react-toastify';

import Api from '../../../util/api';
import t, { partial } from '../../../util/translation/translation';
import Loading from '../../../shared/components/custom/Loading';
import LibraryQuestionItem from './LibraryQuestionItem';
import PickedQuestionItem from './PickedQuestionItem';
import EditQuestionModal from './EditQuestionModal';
import EmptyStateInfo from '../../../shared/components/custom/EmptyStateInfo';

const QuestionaireQuestionPage = ({ questionaireId, hasAnswers }) => {
  const [loading, setLoading] = useState(true);
  const [pickedQuestions, setPickedQuestions] = useState([]);
  const [libraryQuestions, setLibraryQuestions] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const [editingQuestion, setEditingQuestion] = useState(null);
  const [editingQuestionIndex, setEditingQuestionIndex] = useState(null);
  const qT = partial('question');
  const s = partial('shared');
  const p = partial('questionaire');

  const getQuestionaireQuestions = async () => {
    try {
      const [questionaireQuestions, { data: allQuestionsData }] =
        await Promise.all([
          Api.questionaire.getQuestionsForQuestionaire(questionaireId),
          Api.questionaire.getQuestions(
            { page: 1, take: 1000 },
            {
              orderBy: 'createdAt',
              direction: 'DESC',
            }
          ),
        ]);
      setPickedQuestions(
        questionaireQuestions.map((item) => ({ ...item, isSaved: true }))
      );
      setLibraryQuestions(allQuestionsData);
      setLoading(false);
    } catch (error) {
      toast.error(s('somethingWentWrong'));
    }
  };

  useEffect(() => {
    getQuestionaireQuestions();
  }, [questionaireId]);

  const removeIndicator = () => {
    document.querySelectorAll('.picked-question-card').forEach((element) => {
      element?.parentNode.classList.remove(
        'prepend-indicator',
        'append-indicator'
      );
    });
  };

  const handleOnUpdate = ({ source, destination }) => {
    if (destination && source.droppableId === 'list') {
      removeIndicator();
      const cardElement = document.querySelectorAll('.picked-question-card')[
        destination.index === pickedQuestions.length
          ? destination.index - 1
          : destination.index
      ];
      if (destination.index <= pickedQuestions.length - 1) {
        cardElement?.parentNode.classList.add('prepend-indicator');
      } else {
        cardElement?.parentNode.classList.add('append-indicator');
      }
    }
  };

  const handleOnDrag = ({ source, destination }) => {
    removeIndicator();
    const existingPicked = [...pickedQuestions];
    const existingList = [...libraryQuestions];

    let sourceQuestion = {
      ...existingList[source.index],
      isRequired: false,
    };

    if (!destination) return;
    if (source.droppableId === 'pick' && destination.droppableId === 'list') {
      return;
    }
    if (
      source.droppableId === destination.droppableId &&
      source.droppableId === 'list'
    ) {
      return;
    }

    if (source.droppableId === 'pick') {
      sourceQuestion = {
        ...existingList[source.index],
        isRequired: false,
      };
    }

    if (source.droppableId === destination.droppableId) {
      const toBeSwaped = existingPicked[destination.index];
      existingPicked[destination.index] = existingPicked[source.index];
      existingPicked[source.index] = toBeSwaped;
    } else if (
      destination.index !== 0 &&
      destination.index !== existingPicked.length
    ) {
      existingPicked.splice(destination.index, 0, sourceQuestion);
    } else if (destination.index === 0) {
      existingPicked.unshift(sourceQuestion);
    } else if (destination.index === existingPicked.length) {
      existingPicked.push(sourceQuestion);
    }
    // setEditability((prevEditability) => [
    //   ...prevEditability,
    //   sourceQuestion.id,
    // ]);
    setPickedQuestions(existingPicked);
  };

  const handleAddQuestions = async () => {
    try {
      const payload = {
        questions: pickedQuestions.map((item) => ({
          title: item.title,
          image: item.imageUrl,
          description: item.description,
          type: item.type,
          value: item.value,
          isRequired: item.isRequired,
          headingVideos: item.headingVideos || [],
          imageHeaderType: item.imageHeaderType,
        })),
      };
      await Api.questionaire.linkQustions(questionaireId, payload);
      toast.success(qT('questionsAdded'));
    } catch (error) {
      toast.error('Failed to save questions');
    }
  };

  const handleMoveQuestion = (question, direction) => {
    const index = pickedQuestions.findIndex((q) => q.id === question.id);
    const newIndex = index + direction;
    if (newIndex < 0 || newIndex >= pickedQuestions.length) return;

    const updatedQuestions = [...pickedQuestions];
    [updatedQuestions[index], updatedQuestions[newIndex]] = [
      updatedQuestions[newIndex],
      updatedQuestions[index],
    ];

    setPickedQuestions(updatedQuestions);
  };
  const deleteQuestion = (index) => {
    const updatedQuestions = [...pickedQuestions];
    updatedQuestions.splice(index, 1);
    setPickedQuestions(updatedQuestions);
  };

  const handlePromptClose = () => {
    setShowModal(false);
    setEditingQuestion(null);
    setEditingQuestionIndex(null);
  };
  const handleAddQuestion = () => {
    setShowModal(true);
    setEditingQuestion(null);
    setEditingQuestionIndex(null);
  };
  const handleEditQuestion = (question, index) => {
    setShowModal(true);
    setEditingQuestion(question);
    setEditingQuestionIndex(index);
  };
  const handleSaveQuestion = (newQuestion) => {
    const mappedItem = {
      ...newQuestion,
      isRequired: false,
    };
    if (!editingQuestion) {
      setPickedQuestions((prev) => [...prev, mappedItem]);
    } else {
      const updatedQuestions = [...pickedQuestions];
      updatedQuestions[editingQuestionIndex] = mappedItem;
      setPickedQuestions(updatedQuestions);
    }
    setShowModal(false);
    setEditingQuestion(null);
    setEditingQuestionIndex(null);
  };
  const handleSetRequired = (index) => {
    const updatedQuestions = [...pickedQuestions];
    updatedQuestions[index] = {
      ...updatedQuestions[index],
      isRequired: !updatedQuestions[index].isRequired,
    };
    setPickedQuestions(updatedQuestions);
  };
  if (loading) return <Loading loading={loading} />;
  return (
    <Container>
      <EditQuestionModal
        showModal={showModal}
        editableQuestion={editingQuestion}
        onSave={handleSaveQuestion}
        onClose={handlePromptClose}
      />
      <Row style={{ minHeight: '100vh' }}>
        <DragDropContext onDragEnd={handleOnDrag} onDragUpdate={handleOnUpdate}>
          <Col lg={8} md={7}>
            <Droppable droppableId="pick">
              {(provided) => (
                <div>
                  <div
                    className="question-list flex-column"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {!pickedQuestions.length && (
                      <div
                        className="flex-Vcenter p-5 justify-content-center"
                        style={{
                          background: 'rgb(249, 250, 251)',
                          border: '2px dashed rgb(215, 218, 224)',
                          borderRadius: 4,
                        }}
                      >
                        <EmptyStateInfo
                          icon="layout"
                          title={s('emptyStateTitle')}
                          text={qT('addQuestionsFromLibrary')}
                        />
                      </div>
                    )}
                    {pickedQuestions.map((q, index) => (
                      <Draggable
                        key={q.id}
                        draggableId={`pick-${q.id}`}
                        index={index}
                      >
                        {(draggableProvided) => (
                          <div
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.draggableProps}
                            {...draggableProvided.dragHandleProps}
                          >
                            <PickedQuestionItem
                              hasAnswers={hasAnswers}
                              question={q}
                              onMoveUp={() => handleMoveQuestion(q, -1)}
                              onMoveDown={() => handleMoveQuestion(q, 1)}
                              onDelete={() => deleteQuestion(index)}
                              onEdit={() => handleEditQuestion(q, index)}
                              setRequired={(val) =>
                                handleSetRequired(index, val)
                              }
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                  {!hasAnswers && (
                    <div
                      style={{
                        marginTop: 16,
                        color: '#0469DC',
                        fontWeight: '500',
                        fontSize: 14,
                      }}
                      onClick={handleAddQuestion}
                      role="button"
                      tabIndex={0}
                    >
                      {p('addQuestion')}
                    </div>
                  )}
                </div>
              )}
            </Droppable>
            {pickedQuestions.length > 0 && !hasAnswers && (
              <Row className="mt-3">
                <Col className="ml-3 col-12">
                  <Row>
                    <Alert className="mt-1" color="warning">
                      {p('cantEditQuestions')}
                    </Alert>
                  </Row>
                </Col>
                <Col>
                  <button
                    className="btn btn-primary"
                    onClick={handleAddQuestions}
                  >
                    {qT('update')}
                  </button>
                </Col>
              </Row>
            )}
          </Col>
          <Col lg={4} md={5}>
            <span style={{ fontSize: 16, fontWeight: '600' }}>
              {t('question.library')}
            </span>
            <Droppable droppableId="list">
              {(droppableProvided) => (
                <div
                  className="question-library"
                  ref={droppableProvided.innerRef}
                  {...droppableProvided.droppableProps}
                >
                  {libraryQuestions.map((q, i) => (
                    <Draggable
                      draggableId={String(`list-${q.id}`)}
                      index={i}
                      key={q.id}
                    >
                      {(draggableProvided) => (
                        <div
                          className="question"
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.dragHandleProps}
                          {...draggableProvided.draggableProps}
                        >
                          <LibraryQuestionItem question={q} />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {droppableProvided.placeholder}
                </div>
              )}
            </Droppable>
          </Col>
        </DragDropContext>
      </Row>
    </Container>
  );
};

export default QuestionaireQuestionPage;
