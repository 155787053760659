/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Field, reduxForm, getFormValues } from 'redux-form';
import {
  Card,
  CardBody,
  Table,
  ButtonToolbar,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Button,
  Row,
  Col,
} from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import CheckboxMarkedCircleIcon from 'mdi-react/CheckboxMarkedCircleIcon';
import CloseCircleIcon from 'mdi-react/CloseCircleIcon';
import DotsHorizontalIcon from 'mdi-react/DotsHorizontalIcon';
import { toast } from 'react-toastify';
import assignTierValidate from './assignTierValidate';
import renderField from '../../../shared/components/custom/Field';
import renderCheckBoxField from '../../../shared/components/form/CheckBox';
import t, { partial } from '../../../util/translation/translation';
import renderSelectField from '../../../shared/components/form/Select';
import { isInfiniteDate } from '../../../util/functions';
import Loading from '../../../shared/components/custom/Loading';
import Api from '../../../util/api';

const p = partial('UserTiers');
const s = partial('shared');

const AssignTierForm = ({
  availableTiers,
  currentUserAffiliatons,
  handleSubmit,
  initialize,
  formValues,
  handleModal,
}) => {
  const tierOptions = availableTiers.map((item) => ({
    label: item.name,
    value: item.id,
  }));
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    initialize({
      tier: tierOptions[0],
      dueDate: null,
      neverEnd: null,
    });
  }, [availableTiers, currentUserAffiliatons]);
  const renderUserAffiliations = () => {
    return currentUserAffiliatons.map((affiliation) => {
      const dueDate = isInfiniteDate(affiliation.dueDate)
        ? p('neverEnding')
        : moment(affiliation.dueDate).format('YYYY-MM-DD');
      return (
        <tr key={`Affiliation-${affiliation.id}`} className="center-list">
          <td>{affiliation.tier.name}</td>
          <td>{dueDate}</td>
          <td>{affiliation.addedByAdmin ? 'Admin' : 'User'}</td>
          <td>
            {affiliation.subscriptionActive ? (
              <CheckboxMarkedCircleIcon color="#399351" />
            ) : (
              <CloseCircleIcon color="#FF4861" />
            )}
          </td>
          <td>
            <Button
              size="sm"
              disabled={
                !affiliation.addedByAdmin && !affiliation.subscriptionActive
              }
              onClick={() => handleModal(true, affiliation.id)}
              color={affiliation.addedByAdmin ? 'danger' : 'primary'}
            >
              {affiliation.addedByAdmin ? s('delete') : p('terminate')}
            </Button>
          </td>
        </tr>
      );
    });
  };
  return (
    <form className="form" onSubmit={handleSubmit}>
      <Loading loading={isLoading} />
      <Row className="width-100p">
        <Col md={6}>
          <div className="form__form-group">
            <p className="form-header">{p('userTier')}</p>
            <Field
              name="tier"
              component={renderSelectField}
              options={tierOptions}
              placeholder={p('selectTier')}
              className="textTransform-capitalize"
            />
          </div>
        </Col>
        <Col md={6}>
          {!formValues?.neverEnd && (
            <div className="form__form-group">
              <p className="form-header">{s('endDate')}</p>
              <Field name="dueDate" component={renderField} type="date" />
            </div>
          )}
        </Col>
      </Row>
      <div className="form__form-group">
        <div className="form__form-group-field">
          <Field
            name="neverEnd"
            component={renderCheckBoxField}
            className="colored-click"
            label={p('neverEnding')}
          />
        </div>
      </div>
      <Row>
        <Col>
          <Button color="primary" type="submit">
            {s('save')}
          </Button>
        </Col>
      </Row>
      {currentUserAffiliatons.length > 0 && (
        <Col md={12} lg={12}>
          <div
            style={{
              display: 'flex',
              paddingBottom: 5,
              background: '#fff',
              borderRadius: 6,
              border: '1px solid rgba(0,0,0,0.08)',
            }}
          >
            <Table responsive striped>
              <thead>
                <tr>
                  <th>{s('name')}</th>
                  <th>{s('endDate')}</th>
                  <th>{t('MatchTicketsList.assignedBy')}</th>
                  <th>{s('subscriptionActive')}</th>
                  <th>{t('UserTiers.terminate')}</th>
                </tr>
              </thead>
              <tbody>{renderUserAffiliations()}</tbody>
            </Table>
          </div>
        </Col>
      )}
    </form>
  );
};

const reduxF = reduxForm({
  form: 'assign_tier_form',
  validate: assignTierValidate,
});

const mapStateToProps = (state) => ({
  formValues: getFormValues('assign_tier_form')(state),
});

export default connect(mapStateToProps)(reduxF(AssignTierForm));
