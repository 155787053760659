import React from 'react';
import DragHandle from './DragHandle';
import DeleteIcon from './icons/DeleteIcon';
import EditPencilIcon from './icons/EditPencilIcon';
import { getQuestionType } from './QuestionType';
import Utilities from './utilities';

export default function LibraryQuestionItem(props) {
  const { question, index } = props;
  const questionTypeLabel = getQuestionType(question.question.question_type);

  return (
    <>
      <div className="question-actions">
        <button
          className="action-button"
          onClick={() => props.onEdit(question)}
        >
          <EditPencilIcon />
        </button>
        <button
          className="action-button red-border"
          onClick={() => props.onDelete(question)}
        >
          <DeleteIcon />
        </button>
      </div>
      <div
        className="library-question-card"
        onMouseEnter={() =>
          Utilities.previewQuestion(
            question.question,
            index,
            'library-question-card'
          )
        }
        onMouseLeave={() => Utilities.clearPreview()}
      >
        <div className="d-flex align-items-center">
          <div className="d-flex align-items-center justify-content-center draggable-handle">
            <DragHandle />
          </div>
          <div>
            <div className="question-content">
              {Utilities.trim(question.question.question)}
            </div>
            <div className="question-type">{questionTypeLabel?.label}</div>
          </div>
        </div>
      </div>
    </>
  );
}
