import DotsHorizontalIcon from 'mdi-react/DotsHorizontalIcon';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  Badge,
} from 'reactstrap';
import ArrowUpIcon from 'mdi-react/ArrowUpIcon';
import ArrowDownIcon from 'mdi-react/ArrowDownIcon';
import moment from 'moment';
import Loading from '../../shared/components/custom/Loading';
import useModal from '../../shared/hooks/useModal';
import Api from '../../util/api';
import DeleteModal from '../../shared/components/custom/DeleteModal';
import Pagination from '../../shared/components/pagination/Pagination';
import t, { partial } from '../../util/translation/translation';
import { formatDateTime, formatDate } from '../../util/date-and-time';

const QuestionaireListPage = ({ setLoading }) => {
  const [questionaires, setQuestionaires] = useState([]);
  const [handleModal, visible, deleteID] = useModal();
  const [pagination, setPagination] = useState({
    total: 0,
    currentPage: 1,
    perPage: 10,
  });
  const [order, setOrder] = useState({
    orderBy: 'createdAt',
    desc: true,
  });
  const UNLIMITED_DATE = '9999-12-31T00:00:00.000Z';
  const p = partial('questionaire');
  const s = partial('shared');

  const fetchQuestionaires = async (
    currentPage = 1,
    pageSize = 10,
    orderObject = {
      orderBy: 'createdAt',
      desc: true,
    }
  ) => {
    setLoading(true);
    try {
      const reqQuestionaires = await Api.questionaire.getQuestionaires(
        { page: currentPage, take: pageSize },
        {
          orderBy: orderObject.orderBy,
          direction: orderObject.desc ? 'DESC' : 'ASC',
        }
      );
      setQuestionaires(reqQuestionaires.data);
      setPagination((old) => ({ ...old, total: reqQuestionaires.count }));
      setLoading(false);
      const questionaireIds = reqQuestionaires.data.map((item) => item.id);
      const { data: viewsData } =
        await Api.questionaire.getQuestionairesViewAmount({ questionaireIds });
      const mapped = reqQuestionaires.data.map((item) => ({
        ...item,
        views:
          viewsData.find((itemView) => itemView.itemId === item.id)?.views || 0,
      }));
      setQuestionaires(mapped);
    } catch (err) {
      toast.error(p('failedToFetchQuestionaires'));
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchQuestionaires();
  }, []);
  const deleteQuestionaire = async () => {
    setLoading(true);
    try {
      await Api.questionaire.deleteQuestionaire(deleteID);
      toast.success(p('questionaireDeleted'));
    } catch (err) {
      toast.error(p('questionaireDeleteError'));
    } finally {
      handleModal();
      fetchQuestionaires(pagination.currentPage, pagination.perPage, order);
    }
  };

  const onChangePage = (pager) => {
    if (
      pager.pageSize !== pagination.perPage ||
      pager.currentPage !== pagination.currentPage
    ) {
      setPagination((old) => ({
        ...old,
        currentPage: pager.currentPage,
        perPage: pager.pageSize,
      }));
      fetchQuestionaires(pager.currentPage, pager.pageSize, order);
    }
  };

  const renderSort = (cellOrder) => {
    if (order.orderBy !== cellOrder) {
      return undefined;
    }
    return order.desc ? <ArrowDownIcon size={16} /> : <ArrowUpIcon size={16} />;
  };
  const handleSort = (incommingOrder) => {
    const newOrder = {
      orderBy: incommingOrder,
      desc: incommingOrder === order.orderBy ? !order.desc : true,
    };
    setOrder(newOrder);
    setPagination({
      total: 0,
      currentPage: 1,
      perPage: 10,
    });
    fetchQuestionaires(1, 10, newOrder);
  };
  const getAvailableFor = (questionaire) => {
    const { access } = questionaire;
    if (!access) {
      return s('all');
    }
    if (access.levels || access.groups || access.tiers || access.segments) {
      return t('OfferForm.targetAudience');
    }
    return s('all');
  };
  const getBadgeStatus = (questionaire) => {
    if (!questionaire.visible) {
      return {
        color: 'danger',
        text: s('inActive'),
      };
    }
    if (moment().isAfter(moment(questionaire.endDate))) {
      return {
        color: 'secondary',
        text: s('closed'),
      };
    }
    if (moment().isBefore(moment(questionaire.startDate))) {
      return {
        color: 'warning',
        text: s('scheduled'),
      };
    }
    return {
      color: 'success',
      text: s('active'),
    };
  };

  const renderQuestionaires = () => {
    return questionaires.map((questionaire, i) => {
      const badge = getBadgeStatus(questionaire);
      return (
        <tr
          style={{ background: i % 2 ? '#fff' : '#F9FAFB' }}
          key={`questionaire-${questionaire.id}`}
        >
          <td>
            <div className="flex-Vcenter">
              <Link to={`/questionnaires/edit/${questionaire.id}`}>
                {questionaire.title}
              </Link>
            </div>
          </td>
          <td>{formatDate(questionaire.createdAt)}</td>
          <td>{getAvailableFor(questionaire)}</td>
          <td>
            {formatDateTime(questionaire.startDate)} -{' '}
            {questionaire.endDate === UNLIMITED_DATE
              ? ''
              : formatDateTime(questionaire.endDate)}
          </td>
          <td>
            {questionaire.views !== undefined ? (
              questionaire.views
            ) : (
              <Loading loading size={40} />
            )}
          </td>
          <td>{questionaire.answersCount}</td>
          <td>
            <Badge color={badge.color}>{badge.text}</Badge>
          </td>
          <td>
            <UncontrolledDropdown className="dashboard__table-more">
              <DropdownToggle>
                <DotsHorizontalIcon />
              </DropdownToggle>
              <DropdownMenu className="dropdown__menu">
                <Link to={`/questionnaires/edit/${questionaire.id}`}>
                  <DropdownItem>{s('edit')}</DropdownItem>
                </Link>
                <Link to={`/questionnaires/clone/${questionaire.id}`}>
                  <DropdownItem>{s('clone')}</DropdownItem>
                </Link>
                <DropdownItem
                  onClick={() => handleModal(questionaire.id)}
                  className="danger"
                >
                  {s('delete')}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </td>
        </tr>
      );
    });
  };

  return (
    <>
      <DeleteModal
        visible={visible}
        type={p('theQuestionaire')}
        handleModal={handleModal}
        modalAction={deleteQuestionaire}
      />
      <Row>
        <Col>
          <div className="float-right marginTop-0">
            <Link to="/questionnaires/create">
              <Button size="sm" color="primary">
                {p('createQuestionaire')}
              </Button>
            </Link>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div
            style={{
              display: 'flex',
              paddingBottom: 5,
              background: '#fff',
              borderRadius: 6,
              boxShadow: '0px 2px 8px 0px rgba(0,0,0,0.08)',
            }}
          >
            <Table responsive striped>
              <thead>
                <tr>
                  <th
                    style={{ color: '#4D576A' }}
                    className="sortable"
                    onClick={() => handleSort('title')}
                  >
                    <div className="flex alignItems-center">
                      {s('title')} {renderSort('title')}
                    </div>
                  </th>
                  <th
                    style={{ color: '#4D576A' }}
                    className="sortable"
                    onClick={() => handleSort('createdAt')}
                  >
                    <div className="flex alignItems-center">
                      {s('created')} {renderSort('createdAt')}
                    </div>
                  </th>
                  <th style={{ color: '#4D576A' }}>
                    <div className="flex alignItems-center">
                      {t('EventsForm.availableFor')}
                    </div>
                  </th>
                  <th style={{ color: '#4D576A' }}>
                    <div className="flex alignItems-center">{s('date')}</div>
                  </th>
                  <th style={{ color: '#4D576A' }}>
                    <div className="flex alignItems-center">{s('views')}</div>
                  </th>
                  <th
                    style={{ color: '#4D576A' }}
                    onClick={() => handleSort('answersCount')}
                  >
                    <div className="flex alignItems-center">
                      {p('responses')} {renderSort('answersCount')}
                    </div>
                  </th>
                  <th style={{ color: '#4D576A' }}>
                    <div className="flex alignItems-center">{s('status')}</div>
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>{renderQuestionaires()}</tbody>
            </Table>
          </div>
          <Pagination
            items={pagination.total}
            onChangePage={onChangePage}
            initialPage={pagination.currentPage}
          />
        </Col>
      </Row>
    </>
  );
};

export default QuestionaireListPage;
