import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import Api from '../../util/api';
import Loading from '../../shared/components/custom/Loading';
import TeamForm from './components/TeamForm';

const TeamAdminCreatePage = () => {
  const { teamId } = useParams();
  const [team, setTeam] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [players, setPlayers] = useState([]);
  const [teamLeagues, setTeamLeagues] = useState([]);
  const [availableLeagues, setAvailableLeagues] = useState([]);

  const fetchTeam = async (id) => {
    setLoading(true);
    try {
      const { data } = await Api.clubadmin.teams.fetch(id);
      setTeam(data);
      const { data: _players } = await Api.clubadmin.teams.getPlayers(id);
      setPlayers(_players);
    } catch (e) {
      toast.error(e);
    } finally {
      setLoading(false);
    }
  };

  const fetchLeagues = async () => {
    try {
      const { data } = await Api.clubadmin.leagues.getLeaguesForTeam(teamId);
      setTeamLeagues(
        data.map((item) => ({ label: item.name, value: `${item.id}` }))
      );
    } catch (e) {
      toast.error(e);
    } finally {
      setLoading(false);
    }
  };
  const handleSubmit = async (allValues) => {
    setLoading(true);
    try {
      const { teamLeagues: selectedTeamLeagues, ...values } = allValues;
      const { data } = teamId
        ? await Api.clubadmin.teams.update(teamId, {
            ...values,
            logoUrl: values.logoUrl ? values.logoUrl[0].value : undefined,
          })
        : await Api.clubadmin.teams.create(values);
      if (teamId) {
        const mappedLeagues = selectedTeamLeagues.map((item) => ({
          id: Number(item.value),
        }));
        await Api.clubadmin.leagues.setLeaguesForTeam(teamId, {
          leagues: mappedLeagues,
        });
        await fetchLeagues();
      }

      setTeam(data);
    } catch (e) {
      toast.error(e);
    } finally {
      setLoading(false);
    }
  };
  const fetchAllLeagues = async () => {
    try {
      const { data } = await Api.clubadmin.leagues.all();
      setAvailableLeagues(
        data.map((item) => ({ label: item.name, value: `${item.id}` }))
      );
    } catch (e) {
      toast.error(e);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (teamId) {
      fetchTeam(teamId);
      fetchLeagues();
      fetchAllLeagues();
    }
  }, [teamId]);

  return (
    <Container>
      <Loading loading={loading} />
      <Row>
        <Col>
          <h3 className="page-title">{teamId ? 'Edit' : 'Create'} Team</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <TeamForm
                teamLeagues={teamLeagues}
                availableLeagues={availableLeagues}
                players={players}
                team={team}
                onSubmit={handleSubmit}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default TeamAdminCreatePage;
