import React, { Component } from 'react';
import { Col, Row, Container, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import PropTypes, { any } from 'prop-types';
import renderRadioButtonField from '../../../../../../../shared/components/form/RadioButton';
import renderCheckBoxField from '../../../../../../../shared/components/form/CheckBox';
import renderSelectField from '../../../../../../../shared/components/form/Select';
import renderSearchSelectField from '../../../../../../../shared/components/custom/SearchSelect';
import validate from '../validate';
import { partial } from '../../../../../../../util/translation/translation';

const p = partial('EditTicketForm');
const s = partial('shared');
const t = partial('TicketStates');

class EditTicketForm extends Component {
  static propTypes = {
    getUser: PropTypes.func.isRequired,
    userOptions: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      })
    ).isRequired,
    ticketStates: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      })
    ).isRequired,
    matchPrices: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      })
    ).isRequired,
    handleSubmit: PropTypes.func.isRequired,
    matchTicket: PropTypes.objectOf(any).isRequired,
    initialize: PropTypes.func.isRequired,
  };

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.matchTicket === this.props.matchTicket ||
      nextProps.matchTicket.id === undefined
    ) {
      return;
    }
    this.props.initialize({
      ticketState: nextProps.matchTicket.ticket_state !== null &&
        nextProps.matchTicket.ticket_state.id && {
          label: t(nextProps.matchTicket.ticket_state.name.toLowerCase()),
          value: nextProps.matchTicket.ticket_state.id.toString(),
        },
      priceGroup: nextProps.matchTicket.price_group !== null &&
        nextProps.matchTicket.price_group.id && {
          label: nextProps.matchTicket.price_group.title,
          value: nextProps.matchTicket.price_group.id.toString(),
        },
      shareable:
        nextProps.matchTicket.shareable !== null
          ? nextProps.matchTicket.shareable.toString()
          : '1',
      vip:
        nextProps.matchTicket.vip !== null
          ? nextProps.matchTicket.vip.toString()
          : '0',
      user: nextProps.matchTicket.user !== null &&
        nextProps.matchTicket.user.id && {
          label: `${nextProps.matchTicket.user.firstname} ${nextProps.matchTicket.user.lastname} (${nextProps.matchTicket.user.username})`,
          value: nextProps.matchTicket.user.id.toString(),
        },
    });
  }

  render() {
    const { handleSubmit } = this.props;
    return (
      <form className="form" onSubmit={handleSubmit}>
        <Container>
          <Row>
            <Col md={6}>
              <div className="form__form-group">
                <span className="form-header">{s('ticketSale')}</span>
                <div className="marginTop-5" />
                <Field
                  name="ticketState"
                  component={renderSelectField}
                  options={this.props.ticketStates}
                  placeholder={p('chooseTicketStatus')}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="form__form-group">
                <span className="form-header">{s('priceGroup')}</span>
                <div className="marginTop-5" />
                <Field
                  name="priceGroup"
                  component={renderSelectField}
                  options={this.props.matchPrices}
                  placeholder={p('choosePriceGroup')}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <span className="form-header">{p('shareableTicket')}</span>
              <div className="form__form-group margin-0">
                <div className="form__form-group-field">
                  <Field
                    name="shareable"
                    component={renderRadioButtonField}
                    label={s('yes')}
                    radioValue="1"
                    defaultChecked
                  />
                </div>
              </div>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="shareable"
                    component={renderRadioButtonField}
                    label={s('no')}
                    radioValue="0"
                  />
                </div>
              </div>
            </Col>
            <Col md={3}>
              <span className="form-header">{p('vipTicket')}</span>
              <div className="form__form-group margin-0">
                <div className="form__form-group-field">
                  <Field
                    name="vip"
                    component={renderRadioButtonField}
                    label={s('yes')}
                    radioValue="1"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="vip"
                    component={renderRadioButtonField}
                    label={s('no')}
                    radioValue="0"
                    defaultChecked
                  />
                </div>
              </div>
            </Col>
            {this.props.matchTicket.user_id === null && (
              <Col md={6}>
                <span className="form-header">{p('pushToReciever')}</span>
                <div className="form__form-group">
                  <div className="form__form-group-field">
                    <Field
                      name="push"
                      component={renderCheckBoxField}
                      className="colored-click"
                    />
                  </div>
                </div>
              </Col>
            )}
          </Row>
          <Row>
            <Col md={6}>
              <div className="form__form-group">
                <span className="form-header">{s('user')}</span>
                <div className="marginTop-5" />
                <Field
                  name="user"
                  component={renderSearchSelectField}
                  placeholder={p('searchNameOrNumber')}
                  options={this.props.userOptions}
                  search={(text) => this.props.getUser(text)}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Button type="submit" color="primary">
                {s('save')}
              </Button>
              <Link
                className="color-unset"
                to={`/tickets${window.location.search}`}
              >
                <Button type="button">{s('close')}</Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </form>
    );
  }
}

export default reduxForm({
  form: 'edit_match_ticket',
  validate,
})(EditTicketForm);
