import React, { useEffect, useState, useMemo } from 'react';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { Button, Col, Row } from 'reactstrap';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import AlertCircleOutlineIcon from 'mdi-react/AlertCircleOutlineIcon';
import { connect } from 'react-redux';
import TimetableIcon from 'mdi-react/TimetableIcon';
import validate from '../validate';
import RenderField from '../../../shared/components/custom/Field';
import RenderTextAreaField from '../../../shared/components/custom/TextArea';
import RenderSelectField from '../../../shared/components/form/Select';
import renderRadioButtonField from '../../../shared/components/form/RadioButton';
import t, { partial } from '../../../util/translation/translation';
import Api from '../../../util/api';
import useConfig from '../../../util/useConfig';
import { TargetAudience } from './types';
import Config from '../../../config';

const p = partial('MessagesForm');
const s = partial('shared');
const o = partial('OfferForm');

const MessagesForm = ({
  handleSubmit,
  smsForm,
  checkRecieversAndSendMessage,
  initialize,
  change,
  me,
  setScheduleMessageID,
  formValues,
  scheduleMessageID,
  clearContactPage,
  setClearContactPage,
  startDate,
  setStartDate,
}) => {
  const config = useConfig();
  const [screens, setScreens] = useState([]);
  const [notificationLabels, setNotificationLabels] = useState([]);
  const [selectedScreen, setSelectedScreen] = useState(undefined);
  const [groups, setGroups] = useState([]);
  const [targetAudience, setTargetAudience] = useState(TargetAudience.all);
  const [levels, setLevels] = useState([]);
  const [tiers, setTiers] = useState([]);
  const [segments, setSegments] = useState([]);

  const getScreens = async () => {
    try {
      const initialData = [
        {
          label: 'events',
          value: '/more/eventStack/events',
          values: [{ label: 'frontpage', value: '-1' }],
        },
        {
          label: 'lotteries',
          value: '/more/lotteryStack/lotteriesScreen',
          values: [{ label: 'frontpage', value: '-1' }],
        },
        {
          label: 'auctions',
          value: '/more/auctions/Auctions',
          values: [{ label: 'frontpage', value: '-1' }],
        },
        {
          label: 'news',
          value: '/home/news/false',
          values: [{ label: 'frontpage', value: '-1' }],
        },
      ];
      if (Config.monolithEnabled) {
        const { data: screenData } = await Api.userDevices.getScreens();
        screenData
          .filter((item) => ['matches', 'externalStore'].includes(item.label))
          .forEach((item) => initialData.push(item));
      }
      const dat = initialData.map((mainScreens) => ({
        ...mainScreens,
        label: p(mainScreens.label),
        values: mainScreens?.values?.map((screen, index) => ({
          ...screen,
          label:
            index === 0 &&
            !['sportalityClubNews', 'sportalityLeagueNews'].includes(
              mainScreens.label
            )
              ? `${p(screen.label)} ${p(mainScreens.label)}`
              : screen.label,
        })),
      }));
      dat.unshift({
        label: p('homeScreen'),
        value: '/home/false',
        values: [{ label: '', value: '-1' }],
      });
      setScreens(dat);
      setSelectedScreen(dat[0]);
      initialize({
        type: dat[0],
        // group: undefined,
        mode: dat[0]?.values[0],
        media: 'push',
        targetAudience: 'all',
        groups: [
          {
            label: 'Ignore',
            value: null,
          },
        ],
        levels: [
          {
            label: 'Ignore',
            value: null,
          },
        ],
        tiers: [
          {
            label: 'Ignore',
            value: null,
          },
        ],
        segments: [
          {
            label: 'Ignore',
            value: null,
          },
        ],
        timeToSend: 'INSTANT',
      });
    } catch (err) {
      toast.error(err);
    }
  };

  const getData = async () => {
    try {
      const [
        resLevels,
        resGroups,
        resTiers,
        resSegments,
        resNotificationLabels,
      ] = await Promise.all([
        Api.club.getLevels(),
        Api.club.getGroups(),
        Api.events.fetchTiers(config), // check this route
        Api.spotlight.getSegments(),
        Api.userDevices.getAllNotificationLabels(),
      ]);
      setLevels([
        {
          label: 'Ignore',
          value: null,
        },
        {
          label: s('all'),
          value: [],
        },
        ...resLevels.map((level) => {
          const { id, value } = level;
          return {
            value: id,
            label: value,
          };
        }),
      ]);
      setNotificationLabels(
        resNotificationLabels.map((label) => ({
          value: label.id,
          label: label.title,
        }))
      );
      setGroups([
        {
          label: 'Ignore',
          value: null,
        },
        {
          label: s('all'),
          value: [],
        },
        ...resGroups.map((group) => {
          const { id, value } = group;
          return {
            value: id,
            label: value,
          };
        }),
      ]);

      setTiers([
        {
          label: 'Ignore',
          value: null,
        },
        {
          label: s('all'),
          value: [],
        },
        ...resTiers,
      ]);

      setSegments([
        {
          label: 'Ignore',
          value: null,
        },
        {
          label: s('all'),
          value: [],
        },
        ...resSegments.map((segment) => ({
          value: segment.id,
          label: segment.name,
        })),
      ]);
    } catch (err) {
      toast.error(err);
    }
  };
  const handleClearSelectInput = () => {
    if (smsForm.group) {
      const groupLength = smsForm.group.length;
      const allGroupIndex = smsForm.group.findIndex(
        (item) =>
          item.value?.type === 'internal' && item.value?.groupId === 'all'
      );
      if (groupLength > 1 && allGroupIndex !== -1) {
        const isLast = allGroupIndex === groupLength - 1;
        if (isLast) {
          change('group', [smsForm.group[allGroupIndex]]);
        } else {
          const filteredGroups = smsForm.group.filter(
            (item) =>
              !(
                item.value?.type === 'internal' && item.value?.groupId === 'all'
              )
          );
          change('group', filteredGroups);
        }
      }
    }
  };

  const clearFields = () => {
    setScheduleMessageID(null);
    setClearContactPage(false);
    setTargetAudience('all');
    initialize({
      media: 'push',
      heading: '',
      message: '',
      type: screens[0],
      mode: selectedScreen?.values[0],
      group: '',
      notificationLabel: null,
      externalUrlAddress: '',
      timeToSend: 'INSTANT',
      targetAudience: 'all',
      groups: [
        {
          label: 'Ignore',
          value: null,
        },
      ],
      levels: [
        {
          label: 'Ignore',
          value: null,
        },
      ],
      tiers: [
        {
          label: 'Ignore',
          value: null,
        },
      ],
      segments: [
        {
          label: 'Ignore',
          value: null,
        },
      ],
      scheduleTime: setStartDate(''),
    });
  };

  useEffect(() => {
    clearFields();
  }, [clearContactPage]);

  const adjustDropdowns = (fieldName) => {
    if (smsForm[fieldName] && smsForm[fieldName].length > 1) {
      const [old, ...newOnes] = smsForm[fieldName];
      const newestOne = smsForm[fieldName][smsForm[fieldName].length - 1];
      if (old.value === null || Array.isArray(old.value)) {
        change(fieldName, newOnes);
      }
      if (newestOne.value === null || Array.isArray(newestOne.value)) {
        change(fieldName, [newestOne]);
      }
    }
  };

  useEffect(() => {
    adjustDropdowns('groups');
    adjustDropdowns('levels');
    adjustDropdowns('tiers');
    adjustDropdowns('segments');
  }, [smsForm.levels, smsForm.groups, smsForm.tiers, smsForm.segments]);

  useEffect(() => {
    if (me?.username !== '') {
      getScreens();
      getData();
    }
  }, [me]);
  useEffect(() => {
    handleClearSelectInput();
  }, [smsForm.group]);

  const submitButtonName = useMemo(() => {
    let buttonName = '';
    if (scheduleMessageID) {
      buttonName = `${t('ScheduledMessagesPage.updateMessage')}`;
    } else if (formValues?.timeToSend === 'SCHEDULED') {
      buttonName = t('ScheduledMessagesPage.scheduleMessage');
    } else {
      buttonName = p('sendMessage');
    }
    return buttonName;
  }, [scheduleMessageID, formValues]);

  return (
    <form className="form">
      <Col md={12}>
        <div>
          <div className="radio-horizontal">
            <div className="form__form-group margin-0">
              <div className="form__form-group-field">
                <Field
                  name="media"
                  component={renderRadioButtonField}
                  label={p('pushMessage')}
                  radioValue="push"
                  defaultChecked
                />
              </div>
            </div>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="media"
                  component={renderRadioButtonField}
                  label="SMS"
                  radioValue="sms"
                />
              </div>
            </div>
          </div>
        </div>
      </Col>
      <Col>
        <div className="flex-1 marginRight-15">
          <Row>
            <Col md={12}>
              <div className="form__form-group">
                <span className="form__form-group-label ">{s('title')}</span>
                {smsForm.media === 'sms' ? (
                  <Field
                    style={{ margin: '-10px !important' }}
                    name="heading"
                    component={RenderField}
                    placeholder={`${s('title')}`}
                    disabled={smsForm.media === 'sms'}
                  />
                ) : (
                  <div
                    className="form__form-group-field"
                    style={{ margin: '-5px -10px' }}
                  >
                    <Field
                      style={{ margin: '-10px !important' }}
                      name="heading"
                      component={RenderField}
                      placeholder={`${s('title')}`}
                      emoji
                    />
                  </div>
                )}
              </div>
            </Col>
            <Col md={12}>
              <div className="form__form-group">
                <span className="form__form-group-label ">{s('message')}</span>
                <div
                  className="form__form-group-field"
                  style={{ margin: '-5px -10px' }}
                >
                  <Field
                    name="message"
                    component={RenderTextAreaField}
                    placeholder={s('message')}
                    emoji
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="flex">
                <span className="form mr-1">{o('targetAudience')}</span>
              </div>
              <div className="radio-horizontal">
                <div className="form__form-group margin-0">
                  <div className="form__form-group-field">
                    <Field
                      name="targetAudience"
                      component={renderRadioButtonField}
                      label={s('all')}
                      radioValue={TargetAudience.all}
                      onChange={(e) => setTargetAudience(e)}
                    />
                  </div>
                </div>
                <div className="form__form-group margin-0">
                  <div className="form__form-group-field">
                    <Field
                      name="targetAudience"
                      component={renderRadioButtonField}
                      label={s('custom')}
                      radioValue="custom"
                      onChange={(e) => setTargetAudience(e)}
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <div className="form__form-group">
            {targetAudience === 'custom' && (
              <>
                <Row>
                  <Col md={12}>
                    <div className="form__form-group">
                      <span className="form-header">
                        {t('HighlightBannerForm.selectSegments')}
                      </span>
                      <Field
                        name="segments"
                        placeholder={t('HighlightBannerForm.selectSegments')}
                        options={segments}
                        component={RenderSelectField}
                        multiple
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <div className="form__form-group">
                      <span className="form-header">{o('selectLevels')}</span>
                      <Field
                        name="levels"
                        placeholder={o('selectLevels')}
                        options={levels}
                        component={RenderSelectField}
                        multiple
                      />
                    </div>
                    <div className="form__form-group">
                      <span className="form-header">{p('chooseGroup')}</span>
                      <Field
                        name="groups"
                        placeholder={p('chooseGroup')}
                        options={groups}
                        component={RenderSelectField}
                        multiple
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <div className="form__form-group">
                      <span className="form-header">Choose a tier</span>
                      <Field
                        name="tiers"
                        placeholder="Choose a tier"
                        options={tiers}
                        component={RenderSelectField}
                        multiple
                      />
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </div>
          <Row>
            <Col md={6}>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {s('categoryLanding')}
                </span>
                <div className="form__form-group-field d-flex">
                  <Field
                    name="type"
                    component={RenderSelectField}
                    placeholder={p('chooseScreen')}
                    options={screens}
                    onChange={(event, newValue) => {
                      setSelectedScreen(newValue);
                      change('mode', newValue?.values?.[0] ?? '');
                    }}
                    disabled={smsForm.media === 'sms'}
                  />
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="form__form-group ">
                <span className="form__form-group-label">{s('landing')}</span>
                <div className="form__form-group-field d-flex marginRight-15">
                  <Field
                    disabled={
                      !selectedScreen ||
                      selectedScreen.values?.length === 0 ||
                      smsForm.media === 'sms' ||
                      selectedScreen.values === undefined
                    }
                    name="mode"
                    component={RenderSelectField}
                    placeholder={p('chooseScreen')}
                    options={selectedScreen?.values ?? []}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {s('notificationLabel')}
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="notificationLabel"
                    component={RenderSelectField}
                    placeholder={p('chooseNotificationLabel')}
                    options={notificationLabels}
                    disabled={smsForm.media === 'sms'}
                  />
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {p('addExternalUrl')}
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="externalUrlAddress"
                    component={RenderField}
                    placeholder={p('externalUrlPlaceholder')}
                    disabled={smsForm.media === 'sms'}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <span className="form-header">
                {t('ScheduledMessagesPage.timeToSend')}
              </span>
              <div className="radio-horizontal">
                <div className="form__form-group margin-0">
                  <div className="form__form-group-field">
                    <Field
                      name="timeToSend"
                      component={renderRadioButtonField}
                      label={t('shared.instant')}
                      radioValue="INSTANT"
                      defaultChecked
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <div className="form__form-group-field">
                    <Field
                      name="timeToSend"
                      component={renderRadioButtonField}
                      label={t('shared.scheduled')}
                      radioValue="SCHEDULED"
                    />
                  </div>
                </div>
              </div>
            </Col>
            <Col md={9}>
              {formValues?.timeToSend === 'SCHEDULED' && (
                <div className="form__form-group" style={{ zIndex: 100 }}>
                  <span className="form-header">
                    {t('ScheduledMessagesPage.scheduleTime')}
                  </span>
                  <div className="form__form-group-field">
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      locale="pt-BR"
                      showTimeSelect
                      timeFormat="p"
                      timeIntervals={15}
                      dateFormat="Pp"
                      minDate={new Date()}
                      placeholderText="DD / MM / YYYY  -- : --"
                      disabledKeyboardNavigation
                    />
                    <div className="form__form-group-icon">
                      <TimetableIcon />
                    </div>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </div>
      </Col>
      <div className="flex-1 marginLeft-8">
        <Col md={12} className="my-1">
          <span>&nbsp;</span>
          <div className="flex align-items-center p-3 border">
            <div className="modal-icon m-2">
              <AlertCircleOutlineIcon size="38" />
            </div>

            <div>
              <p>{p('pushInformation')}</p>
              <p>{p('AppStorePushGuidelines')}</p>
            </div>
          </div>
        </Col>
      </div>
      <Col md={12}>
        <div className="marginTop-15">
          <Button
            type="button"
            color="primary"
            onClick={handleSubmit((values) => {
              checkRecieversAndSendMessage(false, values);
            })}
          >
            {submitButtonName}
          </Button>
          {scheduleMessageID && (
            <Button onClick={clearFields}>{t('shared.clear')}</Button>
          )}
          <Button
            type="button"
            onClick={handleSubmit((values) => {
              checkRecieversAndSendMessage(true, values);
            })}
            disabled={formValues?.timeToSend === 'SCHEDULED'}
          >
            {p('checkAmountOfRecievers')}
          </Button>
          <Button
            type="button"
            onClick={handleSubmit((values) => {
              checkRecieversAndSendMessage(false, values, me);
            })}
            disabled={formValues?.timeToSend === 'SCHEDULED'}
          >
            {p('testToSelf', { number: me?.username })}
          </Button>
        </div>
      </Col>
    </form>
  );
};

MessagesForm.propTypes = {
  me: PropTypes.shape({
    username: PropTypes.string,
  }),
  change: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  smsForm: PropTypes.shape({
    media: PropTypes.string,
    targetAudience: PropTypes.string,
    timeToSend: PropTypes.string,
  }),
  initialize: PropTypes.func.isRequired,
  checkRecieversAndSendMessage: PropTypes.func.isRequired,
};

MessagesForm.defaultProps = {
  smsForm: {
    media: 'push',
    targetAudience: 'all',
    timeToSend: 'INSTANT',
  },
  me: { username: '' },
};

const mapStateToProps = (state) => ({
  formValues: getFormValues('send_messages_form')(state),
});

export default reduxForm({
  form: 'send_messages_form',
  validate,
})(connect(mapStateToProps)(MessagesForm));
