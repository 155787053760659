import React, { useState, useEffect } from 'react';
import {
  Card,
  CardBody,
  Button,
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import CheckboxMarkedCircleIcon from 'mdi-react/CheckboxMarkedCircleIcon';
import CloseCircleIcon from 'mdi-react/CloseCircleIcon';
import DotsHorizontalIcon from 'mdi-react/DotsHorizontalIcon';
import DeleteModal from '../../../shared/components/custom/DeleteModal';
import Loading from '../../../shared/components/custom/Loading';
import Api from '../../../util/api';
import { partial } from '../../../util/translation/translation';

const SeatMap = () => {
  const p = partial('SeatMap');
  const s = partial('shared');
  const [fields, setFields] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState({
    visible: false,
    id: null,
    removeWarning: p('seatRemoveWarning'),
  });

  const getFields = async () => {
    setLoading(true);
    try {
      const resFields = await Api.stadium.getFieldsOfOneStadium();
      setLoading(false);
      setFields(resFields.data);
    } catch (err) {
      toast.error(err || p('errorFetchingFields'));
      setLoading(false);
    }
  };
  useEffect(() => {
    getFields();
  }, []);

  const modalAction = async () => {
    if (modal.visible === false) {
      return;
    }
    setLoading(true);
    try {
      await Api.stadium.deleteField(modal.id);
      toast.success(p('fieldWasDeleted'));
    } catch (err) {
      toast.error(err || p('errorWhenDeletingStadium'));
    }
    getFields();
    setModal({
      ...modal,
      visible: false,
    });
  };

  const handleModal = (id = false) => {
    setModal({ ...modal, visible: id !== false, id: id !== false && id });
  };

  const listFields = () => {
    return fields.map((field) => (
      <tr key={`listFields-${field.id}`}>
        <td>
          <Link to={`/stadiums/fields/edit/${field.id}`}>{field.name}</Link>
        </td>
        <td>{field.field_type.display_name}</td>
        <td>{field.rows_count}</td>
        <td>{field.seats_count}</td>
        <td>
          {field.buyable ? (
            <CheckboxMarkedCircleIcon color="#4CE1B7" />
          ) : (
            <CloseCircleIcon color="#FD4C62" />
          )}
        </td>
        <td>
          {field.sellable ? (
            <CheckboxMarkedCircleIcon color="#4CE1B7" />
          ) : (
            <CloseCircleIcon color="#FD4C62" />
          )}
        </td>
        <td>
          {field.buyable_websale ? (
            <CheckboxMarkedCircleIcon color="#4CE1B7" />
          ) : (
            <CloseCircleIcon color="#FD4C62" />
          )}
        </td>
        <td>
          <UncontrolledDropdown className="dashboard__table-more">
            <DropdownToggle>
              <DotsHorizontalIcon />
            </DropdownToggle>
            <DropdownMenu className="dropdown__menu">
              <Link to={`/stadiums/fields/edit/${field.id}`}>
                <DropdownItem>{s('edit')}</DropdownItem>
              </Link>
              <DropdownItem
                className="danger"
                onClick={() => handleModal(field.id)}
              >
                {s('delete')}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </td>
      </tr>
    ));
  };

  return (
    <>
      <DeleteModal
        visible={modal.visible}
        type="feltet"
        handleModal={handleModal}
        removeWarning={modal.removeWarning}
        modalAction={modalAction}
      />
      <Loading loading={loading} />
      <Card>
        <CardBody>
          <div className="card__title marginTop-0">
            <p className="page-title">{p('editFields')}</p>
            <Link to="/stadiums/fields/create">
              <Button color="primary" className="btn btn-primary btn-sm">
                {p('createField')}
              </Button>
            </Link>
          </div>
          <Table responsive bordered>
            <thead>
              <tr>
                <th>{s('fieldName')}</th>
                <th>{s('fieldType')}</th>
                <th>{p('numberOfRows')}</th>
                <th>{p('numberOfSeats')}</th>
                <th>{p('availableInApp')}</th>
                <th>{p('availableInBoxOffice')}</th>
                <th>{p('availableInWebsale')}</th>
                <th />
              </tr>
            </thead>
            <tbody>{listFields()}</tbody>
          </Table>
        </CardBody>
      </Card>
    </>
  );
};

export default SeatMap;
