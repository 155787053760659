import React from 'react';
import PropTypes from 'prop-types';
import t, { partial } from '../../../util/translation/translation';

const p = partial('ListKiosks');
const ListKiosks = (props) => {
  return (
    <div className="flex-center">
      <div className="kiosk-list-container">
        <div className="kiosk-list-header">
          <h3>
            {p('selectKiosk')} ({props.kiosks.length})
          </h3>
        </div>
        <div className="kiosk-list">
          <RenderKiosks
            kiosks={props.kiosks}
            setSelectedKiosk={props.setSelectedKiosk}
            selectedKiosk={props.selectedKiosk}
          />
        </div>
      </div>
    </div>
  );
};

ListKiosks.propTypes = {
  selectedKiosk: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  setSelectedKiosk: PropTypes.func.isRequired,
  kiosks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const RenderKiosks = (props) => {
  return props.kiosks.map((kiosk) => {
    return (
      <button
        key={`kiosk-${kiosk.id}`}
        onClick={() => props.setSelectedKiosk(kiosk)}
        className={
          kiosk.id === props.selectedKiosk.id
            ? 'kiosk-list-item active'
            : 'kiosk-list-item'
        }
      >
        <div className="kiosk-list-item-left">
          {kiosk.image && kiosk.image.url !== undefined && (
            <img
              className="kiosk-list-item-image"
              src={kiosk.image.url}
              alt={p('kioskImage')}
            />
          )}
          <div className="kiosk-list-item-information">
            <h4>{kiosk.name}</h4>
            <p className="">
              {kiosk.auction === 1
                ? `${p('openAuctions')}: ${kiosk.auction_count}`
                : `${p('openOrder')}: ${kiosk.order_count}`}
            </p>
          </div>
        </div>
        <div className="kiosk-list-item-state">
          <div
            style={{ backgroundColor: kiosk.open ? '#6dd954' : '#E23D51' }}
            className="kiosk-list-item-circle"
          />
          <h4>{kiosk.open ? t('shared.open') : t('shared.closed')}</h4>
        </div>
      </button>
    );
  });
};

RenderKiosks.propTypes = {
  selectedKiosk: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  kiosks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      image: PropTypes.shape({
        url: PropTypes.string,
      }),
      name: PropTypes.string,
      open: PropTypes.number,
      auction: PropTypes.number,
      auction_count: PropTypes.number,
      order_count: PropTypes.number,
    })
  ).isRequired,
};

export default ListKiosks;
