import React from 'react';
import { Link } from 'react-router-dom';
import RefreshIcon from 'mdi-react/RefreshIcon';
import CartIcon from 'mdi-react/CartIcon';
import PrinterOffIcon from 'mdi-react/PrinterOffIcon';
import PrinterIcon from 'mdi-react/PrinterIcon';
import FormatListBulletedIcon from 'mdi-react/FormatListBulletedIcon';
import PropTypes from 'prop-types';
import { partial } from '../../../util/translation/translation';

const p = partial('AlternativeNavbar');
const s = partial('shared');
const AlternativeNavbar = (props) => {
  return (
    <div className="kiosk-navbar">
      <div className="flex align-center">
        {props.selectedKiosk.id ? (
          <button className="back-button" to="/" onClick={props.getKiosks}>
            <div className="back-symbol" />
          </button>
        ) : (
          <Link className="back-button" to="/">
            <div className="back-symbol" />
          </Link>
        )}
        <button className="no-style" onClick={props.getKiosks}>
          <p className="kiosk-navbar-title">
            {props.selectedKiosk.id === undefined
              ? p('noKioskSelected')
              : props.selectedKiosk.name}
          </p>
        </button>
      </div>
      <div className="flex align-center">
        <button onClick={props.toggleAutomaticPrint} className="navbar-refresh">
          {props.automaticPrint ? (
            <PrinterIcon size={18} className="icon-margin" color="white" />
          ) : (
            <PrinterOffIcon size={18} className="icon-margin" color="white" />
          )}
        </button>
        {props.selectedKiosk.id !== undefined && (
          <button onClick={props.handleRefresh} className="navbar-refresh">
            <RefreshIcon color="white" />
          </button>
        )}
        {props.selectedKiosk.auction === 0 && (
          <button onClick={props.toggleProductsModal} className="navbar-info">
            <CartIcon size={18} className="icon-margin" color="white" />
            <p>{p('assortment')}</p>
          </button>
        )}
        {props.selectedKiosk.auction === 0 && (
          <button onClick={props.toggleHistoryModal} className="navbar-info">
            <FormatListBulletedIcon
              size={22}
              className="icon-margin"
              color="white"
            />
            <p>{p('history')}</p>
          </button>
        )}
        {props.selectedKiosk.id !== undefined && (
          <button onClick={props.handleKioskState} className="navbar-info">
            <div
              style={
                props.selectedKiosk.open
                  ? { backgroundColor: '#6dd954' }
                  : { backgroundColor: '#E23D51' }
              }
              className="navbar-circle"
            />
            <p>{props.selectedKiosk.open ? s('openCaps') : s('closedCaps')}</p>
          </button>
        )}
      </div>
    </div>
  );
};

AlternativeNavbar.propTypes = {
  selectedKiosk: PropTypes.shape({
    id: PropTypes.number,
    auction: PropTypes.number,
    name: PropTypes.string,
    open: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  }).isRequired,
  getKiosks: PropTypes.func.isRequired,
  handleRefresh: PropTypes.func.isRequired,
  handleKioskState: PropTypes.func.isRequired,
  toggleHistoryModal: PropTypes.func.isRequired,
  toggleProductsModal: PropTypes.func.isRequired,
  toggleAutomaticPrint: PropTypes.func.isRequired,
  automaticPrint: PropTypes.bool.isRequired,
};

export default AlternativeNavbar;
