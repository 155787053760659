import React, { useEffect } from 'react';
import { Field, reduxForm, propTypes as reduxFormProps } from 'redux-form';
import { Button, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import validateGroupsForm from './validateGroupForm';
import renderField from '../../../shared/components/custom/Field';
import { partial } from '../../../util/translation/translation';
import renderSelectField from '../../../shared/components/form/Select';

const GroupForm = ({ handleSubmit, group, initialize, cancel, positions }) => {
  const s = partial('shared');
  useEffect(() => {
    if (group && group.name) {
      initialize({
        order: group.order,
        id: group.id,
        name: group.name,
        values: group.values.map((v) => ({
          label: v,
          value: v,
        })),
      });
    }
  }, [group]);

  return (
    <form className="form" onSubmit={handleSubmit}>
      <div className="form__form-group">
        <span className="form-header">{s('groupName')}</span>
        <Field
          name="name"
          component={renderField}
          placeholder={s('groupName')}
        />
        <Field
          name="values"
          multiple
          component={renderSelectField}
          options={positions.map((position) => ({
            label: position,
            value: position,
          }))}
        />
      </div>
      <Row>
        <Col>
          <Button type="submit" size="sm" color="primary">
            {group && group.type === 'edit' ? s('save') : s('create')}
          </Button>
          {cancel && (
            <Button type="button" size="sm" onClick={cancel}>
              {s('cancel')}
            </Button>
          )}
        </Col>
      </Row>
    </form>
  );
};

GroupForm.propTypes = {
  ...reduxFormProps,
  handleSubmit: PropTypes.func.isRequired,
  group: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      type: PropTypes.string,
      name: PropTypes.string,
    }),
  ]),
  cancel: PropTypes.func,
};

GroupForm.defaultProps = {
  group: false,
  cancel: null,
};

const reduxF = reduxForm({
  form: '_group_form',
  validate: validateGroupsForm,
});

export default reduxF(GroupForm);
