import { partial } from '../../../util/translation/translation';

const p = partial('validateErrors');

const validateAccess = (values) => {
  const errors = {};
  if (values.targetAudience === 'targetAudience') {
    if (values.group.length === 0) {
      errors.group = p('groupFieldCantBeEmpty');
    }
    if (values.level.length === 0) {
      errors.level = p('levelFieldCantBeEmpty');
    }
    if (values.tier.length === 0) {
      errors.tier = p('tierCantBeEmpty');
    }
  }
  return errors;
};

export default validateAccess;
