import { SET_BALANCE_NAME } from '../actions/balanceActions';

const initialState = {
  name: 'Saldo',
  hasFetched: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_BALANCE_NAME:
      return {
        ...state,
        name: action.name,
        hasFetched: true,
      };
    default:
      return state;
  }
}
