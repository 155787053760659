import React, { useEffect, useMemo, useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Button, Row, Col, Table, ListGroup, Input } from 'reactstrap';
import PropTypes from 'prop-types';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import { toast } from 'react-toastify';
import ArrowDownIcon from 'mdi-react/ArrowDownIcon';
import ArrowUpIcon from 'mdi-react/ArrowUpIcon';
import renderField from '../../../../shared/components/custom/Field';
import t, { partial } from '../../../../util/translation/translation';
import validate from './validateCategoryForm';
import Api from '../../../../util/api';

const s = partial('shared');
const AddCategoryForm = ({
  selectedCategory,
  handleSubmit,
  isEditConfig,
  isCloneConfig,
  initialize,
  allInputs,
  setAllInputs,
}) => {
  useEffect(() => {
    initialize({
      title: !isCloneConfig ? selectedCategory?.title : '',
      maxPerAccount: selectedCategory?.maxAccountAttendees,
      maxParticipants: selectedCategory?.maxAttendees,
    });
  }, [selectedCategory, isCloneConfig]);

  const [allCompanies, setAllCompanies] = useState([]);
  const [allCompanyConfigs, setAllCompanyConfigs] = useState([]);
  const [error, setError] = useState([]);
  const [sort, setSort] = useState({
    field: 'name',
    direction: 'asc',
  });

  const getCompanies = async () => {
    try {
      const resCompanies = await Api.companies.getCompaniesForClub();

      const structureAllCompanies = resCompanies.map((company) => ({
        ...company,
        isVisible: true,
        error: false,
      }));
      setAllCompanies(structureAllCompanies);

      const companyConfigs = selectedCategory.companyConfigs.reduce(
        (acc, companyConfig) => {
          acc[companyConfig.companyId] = {
            configId: companyConfig.id,
            maxAttendes: companyConfig.maxAttendes,
          };
          return acc;
        },
        {}
      );

      setAllCompanyConfigs(companyConfigs);
    } catch (err) {
      toast.error(err || `${s('400')}`);
    }
  };

  const searchCompanies = (searchText) => {
    setAllCompanies(
      allCompanies.map((company) => ({
        ...company,
        isVisible:
          company.name.toLowerCase().includes(searchText.toLowerCase()) ||
          company.companyLevel
            ?.toLowerCase()
            .includes(searchText.toLowerCase()),
      }))
    );
  };

  const handleInputs = (id, textValue, index) => {
    const inputsCopy = [...allInputs];
    const allErrors = [...error];
    const allCompaniesCopy = [...allCompanies];
    const value = parseInt(textValue, 10);
    if (Number.isNaN(value)) {
      allErrors.push({ id: index });
      allCompaniesCopy[index].error =
        `${t('EventCategories.maxParticipantsNotANumber')}`;
      setAllCompanies(allCompaniesCopy);
      setError(allErrors);
    } else if (value > selectedCategory.maxAttendees) {
      allErrors.push({ id: index });
      allCompaniesCopy[index].error =
        `${t('EventCategories.maxPartcipnatsExceeded')}`;
      setAllCompanies(allCompaniesCopy);
      setError(allErrors);
    } else if (value < 0) {
      allErrors.push({ id: index });
      allCompaniesCopy[index].error =
        `${t('EventCategories.maxPartcipnatsCheckLimits')}`;
      setAllCompanies(allCompaniesCopy);
      setError(allErrors);
    } else {
      const filteredErrors = allErrors.filter((obj) => {
        return obj.id !== index;
      });
      setError(filteredErrors);
      if (inputsCopy[index]) {
        inputsCopy[index].maxAttendes = value;
      } else {
        inputsCopy.push({
          configurationId: id,
          maxAttendes: value,
        });
      }
      allCompaniesCopy[index].error = null;
      setAllCompanies(allCompaniesCopy);
    }
    setAllInputs(inputsCopy);
  };

  const handleSort = (field) => {
    const getReversedSortDirection = (direction) => {
      return direction === 'asc' ? 'desc' : 'asc';
    };
    setSort((prev) => ({
      field,
      direction:
        prev.field === field ? getReversedSortDirection(prev.direction) : 'asc',
    }));
  };

  const renderSort = (cellOrder) => {
    return sort.direction === 'asc' && sort.field === cellOrder ? (
      <ArrowUpIcon size={16} />
    ) : (
      <ArrowDownIcon size={16} />
    );
  };

  useEffect(() => {
    getCompanies();
  }, [isEditConfig, sort.field, sort.direction]);

  function sortData(tableData, sortByKey, reverse) {
    if (!sortByKey) return tableData;

    const sortedData = allCompanies.sort((a, b) => {
      if (a[sortByKey] === null) {
        return 1;
      }
      if (b[sortByKey] === null) {
        return -1;
      }
      if (a[sortByKey].trim() === b[sortByKey].trim()) {
        return 0;
      }
      return a[sortByKey].trim() > b[sortByKey].trim() ? -1 : 1;
    });

    if (reverse) {
      return sortedData.reverse();
    }

    return sortedData;
  }

  const companiesToShow = useMemo(() => {
    sortData(allCompanies, sort.field, sort.direction === 'desc');
    return allCompanies.filter((company) => company.isVisible);
  }, [allCompanies, sort.field, sort.direction]);

  return (
    <form
      className="form"
      onSubmit={handleSubmit}
      style={{ height: '100%', width: '100%' }}
    >
      <div className="form__form-group">
        <span className="form-header bold">{s('title')}</span>
        <Field name="title" component={renderField} placeholder={s('title')} />
      </div>
      {/* <div className="form__form-group">
        <span className="form-header bold">
          {t('EventsForm.defaultMaxNumberOfParticipant')}
        </span>
        <TooltipWithIcon text="The number of participants will be set as default for every company" id="visibilityInfoTooltip" />

        <Field
          name="maxPerAccount"
          component={renderField}
          type="number"
        />
      </div> */}
      <div className="form__form-group">
        <span className="form-header bold">
          {t('EventsForm.numberOfParticipantOnEvent')}
        </span>
        <Field name="maxParticipants" component={renderField} type="number" />
      </div>
      {(isEditConfig || isCloneConfig) && (
        <div className="form__form-group">
          <span className="form-header bold">{s('companies')}</span>
          <div className="form__form-group-field">
            <Input
              type="text"
              placeholder={`${t('EventCategories.searchByCompanyNameOrLevel')}`}
              onChange={(e) => searchCompanies(e.target.value)}
              name="search"
            />
            <div className="form__form-group-icon">
              <MagnifyIcon />
            </div>
          </div>
          <div className="form__form-group-field">
            <div className="my-2 d-flex justify-content-end">
              <p>
                {companiesToShow.length} {s('companies')}
              </p>
            </div>
          </div>
          <span>&nbsp;</span>
          {companiesToShow.length > 0 && (
            <ListGroup className="companies-list-group grey-border" flush>
              <Table responsive="xl" bordered>
                <thead>
                  <tr>
                    <th
                      style={{ width: '25%' }}
                      className="sortable"
                      onClick={() => handleSort('name')}
                    >
                      {s('name')}
                      {renderSort('name')}
                    </th>
                    <th
                      className="sortable text-center"
                      onClick={() => handleSort('companyLevel')}
                    >
                      {s('level')}
                      {renderSort('companyLevel')}
                    </th>
                    <th size="xl" className="text-center">
                      {t('EventsForm.maxNumberOfParticipant')}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {companiesToShow.map((company, index) => (
                    <tr key={company.id}>
                      <td>
                        {company?.images?.companyImage &&
                        company?.images?.companyImage !== 'NULL' ? (
                          <img
                            className="company__profile__avatar mr-2"
                            src={company?.images?.companyImage}
                            alt="logo"
                          />
                        ) : (
                          <span className="company__profile__avatar_placeholder mr-2">
                            {company?.name[0]?.toUpperCase()}
                          </span>
                        )}
                        {company.name}
                      </td>
                      <td className="text-center">
                        {company.companyLevel || '- -'}
                      </td>
                      <td>
                        <Col className="d-flex flex-column align-items-center justify-content-center">
                          <Input
                            className="text-center"
                            type="text"
                            defaultValue={
                              allCompanyConfigs[company.id]?.maxAttendes
                            }
                            onChange={(e) =>
                              handleInputs(
                                allCompanyConfigs[company.id]?.configId,
                                e.target.value,
                                index
                              )
                            }
                            style={{ height: '25px', width: '55px' }}
                          />
                          {company?.error ? (
                            <span
                              style={{
                                fontSize: '10px',
                                color: '#ad4444',
                              }}
                            >
                              {company?.error}
                            </span>
                          ) : (
                            <span>&nbsp;</span>
                          )}
                        </Col>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </ListGroup>
          )}
        </div>
      )}
      <Row>
        {error.length > 0 ? (
          <Col>
            <span style={{ fontSize: '12px', color: '#AD444F' }}>
              {t('EventCategories.anErrorOccured')}
            </span>
          </Col>
        ) : (
          <Col>
            <Button
              name="companyInputBtn"
              color="primary"
              type="submit"
              disabled={error.length > 0}
            >
              {selectedCategory ? `${s('save')}` : `${s('create')}`}
            </Button>
          </Col>
        )}
      </Row>
    </form>
  );
};

AddCategoryForm.propTypes = {
  selectedLevel: PropTypes.shape({
    title: PropTypes.string,
  }),
};

AddCategoryForm.defaultProps = {
  selectedLevel: null,
};

const reduxF = reduxForm({
  form: 'AddCategoryForm',
  validate,
});
export default reduxF(AddCategoryForm);
