import React, { useState } from 'react';
import { Container, TabContent, TabPane } from 'reactstrap';

import { partial } from '../../util/translation/translation';
import NavTab from '../../shared/components/custom/NavTab';
import AppLayoutPage from './AppLayoutPage';
import WebsiteLayoutPage from './WebsiteLayoutPage';
import config from '../../config';

const LayoutPage = () => {
  const [tab, setTab] = useState('1');
  const p = partial('appLayout');
  const links = [
    p('appLayout'),
    config.clubWebsiteEnabled ? p('websiteLayout') : null,
  ].filter(Boolean);
  return (
    <Container>
      <NavTab activeTab={tab} tabs={links} setTab={setTab} />
      <TabContent activeTab={tab}>
        <TabPane tabId="1">
          <AppLayoutPage />
        </TabPane>
        {config.clubWebsiteEnabled && (
          <TabPane tabId="2">
            <WebsiteLayoutPage />
          </TabPane>
        )}
      </TabContent>
    </Container>
  );
};

export default LayoutPage;
