import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const TextEditor2 = ({
  input,
  header = [1, 2, 3, false],
  fontOptions = ['bold', 'italic', 'underline', 'strike', 'blockquote'],
}) => {
  const modules = {
    toolbar: [
      [{ header }],
      fontOptions,
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link'],
      ['clean'],
    ],
  };
  const toolbar = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
  ];

  return (
    <div className="text-editor">
      <ReactQuill
        modules={modules}
        toolbar={toolbar}
        theme="snow"
        value={input?.value}
        onChange={input.onChange}
      />
    </div>
  );
};
export default TextEditor2;
