import { useState } from 'react';
import Api from '../../../util/api.js';

export const useUserLoginData = () => {
  const [loginData, setLoginData] = useState([]);
  const [rawData, setRawData] = useState(null);
  const [totalSessions, setTotalSessions] = useState(0);
  const [sessionsPerUser, setSessionsPerUser] = useState(0);
  const [activeUsers, setActiveUsers] = useState(0);
  const [deviceData, setDeviceData] = useState([]);
  const [screenData, setScreenData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const processData = (response, type) => {
    try {
      if (!response) return;

      let transformedData = [];

      if (response.byDate && response.byDate.length > 0) {
        const sortedData = [...response.byDate].sort(
          (a, b) => new Date(a.date) - new Date(b.date)
        );

        if (type === 'all') {
          transformedData = sortedData.map((item) => ({
            date: item.date,
            allUsers: item.allUserCount,
          }));
        } else if (type === 'unique') {
          transformedData = sortedData.map((item) => ({
            date: item.date,
            uniqueUsers: item.uniqueUserCount,
          }));
        } else {
          transformedData = sortedData.map((item) => ({
            date: item.date,
            allUsers: item.allUserCount,
            uniqueUsers: item.uniqueUserCount,
          }));
        }
      }

      setLoginData(transformedData || []);
      setTotalSessions(response?.totalSessionsForAllUsers || 0);
      setSessionsPerUser(
        typeof response?.averageSessionPerUser === 'number'
          ? parseFloat(response.averageSessionPerUser.toFixed(2))
          : 0
      );

      const totalUnique =
        response?.byDate?.reduce((max, item) => {
          return Math.max(max, item.uniqueUserCount || 0);
        }, 0) || 0;

      setActiveUsers(totalUnique);
      setDeviceData(response?.byDevice || []);
    } catch (e) {
      console.error('Process data error:', e);
    }
  };

  const fetchUserLoginStats = async (timeframeParam) => {
    try {
      setIsLoading(true);
      const data =
        await Api.analytics.getAllFirebaseUserLoginsByDate(timeframeParam);
      const response = data.data;

      setRawData(response);
      processData(response, 'all');

      const filteredScreenData =
        response?.screenViews
          ?.filter((sv) => sv.screenName !== null)
          .sort((a, b) => b.viewCount - a.viewCount) || [];
      console.log('News filteredScreenData-------', filteredScreenData);

      setScreenData(filteredScreenData);
      setIsLoading(false);

      return response;
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching login stats:', error);
      setLoginData([]);
      setTotalSessions(0);
      setSessionsPerUser(0);
      setScreenData([]);
      setDeviceData([]);
      return null;
    }
  };

  const fetchCustomDateStats = async (startDate, endDate) => {
    try {
      setIsLoading(true);
      const formattedStartDate = startDate.toISOString().split('T')[0];
      const formattedEndDate = endDate.toISOString().split('T')[0];

      const data = await Api.analytics.getAllFirebaseUserLoginsByCustomDate(
        'custom',
        formattedStartDate,
        formattedEndDate
      );

      const response = data.data;
      setRawData(response);

      processData(response, 'all');

      const filteredScreenData =
        response?.screenViews
          ?.filter((sv) => sv.screenName !== null)
          .sort((a, b) => a.viewCount - b.viewCount) || [];

      setScreenData(filteredScreenData);
      setIsLoading(false);

      return response;
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching custom date login stats:', error);
      setLoginData([]);
      setTotalSessions(0);
      setSessionsPerUser(0);
      setScreenData([]);
      setDeviceData([]);
      return null;
    }
  };

  return {
    loginData,
    rawData,
    totalSessions,
    sessionsPerUser,
    activeUsers,
    deviceData,
    screenData,
    isLoading,
    fetchUserLoginStats,
    fetchCustomDateStats,
    processData,
  };
};
