import React from 'react';

export default function DeleteIcon(props) {
  return (
    <img
      src="/assets/files/questions/delete-bin.svg"
      alt="delete question"
      {...props}
    />
  );
}
