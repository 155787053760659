import React, { PureComponent } from 'react';
import Select, { Creatable } from 'react-select';
import PropTypes from 'prop-types';

let doneTyping = null;

class SelectField extends PureComponent {
  static propTypes = {
    promptText: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    creatable: PropTypes.bool,
    search: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.object,
          PropTypes.number,
        ]),
        label: PropTypes.string,
      }),
    ]).isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.object,
          PropTypes.number,
        ]),
        label: PropTypes.string,
      })
    ),
  };

  static defaultProps = {
    placeholder: '',
    creatable: false,
    options: undefined,
    promptText: 'Create',
  };

  handleChange = (selectedOption) => {
    this.props.onChange(selectedOption);
  };

  handleSearch = (text) => {
    if (text !== '') {
      doneTyping = clearTimeout(doneTyping);
      doneTyping = setTimeout(() => this.searchAfterTyping(text), 100);
    }
  };

  searchAfterTyping = (text) => {
    this.props.search(text);
  };

  render() {
    const { value, name, placeholder, creatable, promptText } = this.props;
    return (
      <>
        {creatable ? (
          <Creatable
            name={name}
            value={value}
            onChange={this.handleChange}
            options={this.props.options}
            className="form__form-group-select"
            placeholder={placeholder}
            promptTextCreator={(text) => {
              return `${promptText} "${text}"`;
            }}
            onInputChange={(text) => {
              this.props.search(text);
              return text;
            }}
            isClearable
          />
        ) : (
          <Select
            name={name}
            value={value}
            onChange={this.handleChange}
            options={this.props.options}
            clearable={false}
            className="form__form-group-select"
            placeholder={placeholder}
            onInputChange={this.handleSearch}
          />
        )}
      </>
    );
  }
}

const renderSearchSelect = (props) => (
  <div className="form__form-group-input-wrap">
    <SelectField
      {...props.input}
      options={props.options}
      placeholder={props.placeholder}
      search={props.search}
      creatable={props.creatable}
      promptText={props.promptText}
    />
    {props.meta.touched && props.meta.error && (
      <span className="form__form-group-error">{props.meta.error}</span>
    )}
  </div>
);

renderSearchSelect.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.number,
      ]),
      label: PropTypes.string,
    })
  ),
  placeholder: PropTypes.string,
  search: PropTypes.func.isRequired,
  creatable: PropTypes.bool,
  promptText: PropTypes.string,
};

renderSearchSelect.defaultProps = {
  meta: null,
  options: undefined,
  placeholder: '',
  creatable: false,
  promptText: 'Create',
};

export default renderSearchSelect;
