import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loading from '../../shared/components/custom/Loading';
import DiscountsForm from './components/VolumeDiscountsForm';
import Api from '../../util/api';
import { partial } from '../../util/translation/translation';
import { formatDateForApi } from '../../util/date-and-time';

const s = partial('shared');
const p = partial('VolumeDiscountFormPage');

const VolumeDiscountFormPage = () => {
  const [loading, setLoading] = useState(false);
  const [volumeDiscountInfo, setVolumeDiscountInfo] = useState(null);
  const [priceGroups, setPriceGroups] = useState([]);
  const [seasonPriceGroups, setSeasonPriceGroups] = useState([]);

  const history = useHistory();
  const { volumeDiscountId } = useParams();

  const handlePriceGroups = (prices) =>
    prices.map((price) => ({
      label: price.title,
      value: price.id.toString(),
    }));

  const onSubmit = async (formValues) => {
    const {
      name,
      price,
      description,
      saleStart,
      saleEnd,
      rules,
      seasonTicketSale,
      hideInApp,
    } = formValues;
    const payload = {
      name,
      description,
      price,
      sale_start: formatDateForApi(saleStart),
      sale_end: saleEnd ? formatDateForApi(saleEnd) : null,
      active: 1,
      rules: rules.map((rule) => ({
        id: rule.priceGroup.value,
        quantity: rule.quantity,
        modifier: rule.modifier.value,
      })),
      season: seasonTicketSale,
      show_in_app: !hideInApp,
    };
    if (volumeDiscountId) {
      try {
        await Api.packages.updateDiscount(volumeDiscountId, payload);
        toast.success(`${p('discountUpdated')}'!'`);
        history.replace('/discounts');
      } catch (err) {
        toast.error(err || `${p('discountUpdatingERROR')}`);
      }
    } else {
      try {
        await Api.packages.saveDiscount(payload);
        toast.success(`${p('discountUpdated')}'!'`);
        history.replace('/discounts');
      } catch (err) {
        toast.error(err || `${p('discountUpdatingERROR')}`);
      }
    }
  };

  const getData = async () => {
    setLoading(true);
    try {
      const [resPriceGroups, resSeasonPriceGroups, resVolumeDiscountInfo] =
        await Promise.all([
          Api.tickets.getPriceGroups(),
          Api.packages.getCurrentSeasonPrices(),
          volumeDiscountId && Api.packages.getDiscount(volumeDiscountId),
        ]);
      setPriceGroups(handlePriceGroups(resPriceGroups.data));
      setSeasonPriceGroups(handlePriceGroups(resSeasonPriceGroups.data));
      if (resVolumeDiscountInfo) {
        setVolumeDiscountInfo(resVolumeDiscountInfo.data);
      }
    } catch (err) {
      toast.error(err || `${p('necessaryInfoFetchingERROR')}`);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, [volumeDiscountId]);

  return (
    <Container>
      <Loading loading={loading} />

      <Row>
        <Col md={12}>
          <h3 className="page-title">
            {volumeDiscountId ? `${s('edit')}` : `${s('create')}`}{' '}
            {s('discountSmall')}
          </h3>
        </Col>
      </Row>
      <Row>
        <DiscountsForm
          priceGroups={priceGroups}
          onSubmit={onSubmit}
          volumeDiscountInfo={volumeDiscountInfo}
          seasonPriceGroups={seasonPriceGroups}
        />
      </Row>
    </Container>
  );
};

export default VolumeDiscountFormPage;
