import React, { useEffect, useState } from 'react';
import { Button, Col, Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Select from 'react-select';
import t, { partial } from '../../../util/translation/translation';
import Api from '../../../util/api';
import WebsiteSponsorForm from './WebsiteSponsorForm';

const WebsiteSponsor = () => {
  const ct = partial('Category');
  const s = partial('shared');
  const [companies, setCompanies] = useState([]);
  const [settings, setSettings] = useState([]);

  const handleSubmit = async () => {
    const selectedCompanies = companies
      .filter((company) => company.isSelected)
      .map((company) => company.id);

    try {
      await Promise.all(
        settings.map((setting) => Api.club.deleteSetting(setting.id))
      );
      await Promise.all(
        selectedCompanies.map((company) => {
          const payload = {
            key: 'WebsiteSponsorIdsList',
            value: company,
          };
          return Api.club.createSetting(payload);
        })
      );
      toast.success(`${s('created')}`);
    } catch (err) {
      toast.error(err || `${s('somethingWentWrong')}`);
    }
  };
  return (
    <Card>
      <CardBody>
        <div className="card__title margin-5">
          <p className="bold">{ct('websiteSponsor')}</p>
        </div>
        <WebsiteSponsorForm
          onSubmit={handleSubmit}
          setCompanies={setCompanies}
          setSettings={setSettings}
        />
      </CardBody>
    </Card>
  );
};

export default WebsiteSponsor;
