import React, { useState, useEffect, useContext, memo } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Col,
  Row,
  Button,
  Table,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  ButtonGroup,
} from 'reactstrap';
import DotsHorizontalIcon from 'mdi-react/DotsHorizontalIcon';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import useConfig from '../../../util/useConfig';
import { getBaseDateTime } from '../../../util/date-and-time';
import Api from '../../../util/api';
import useModal from '../../../shared/hooks/useModal';
import DeleteModal from '../../../shared/components/custom/DeleteModal';
import { OfferContext } from '../context';
import Pagination from '../../../shared/components/pagination/Pagination';
import skeletonTableLoader from '../../../shared/components/custom/SkeletonLoaders';
import Loading from '../../../shared/components/custom/Loading';
import { partial } from '../../../util/translation/translation';
import Creator from './Creator';
import IconButton from '../../../shared/components/custom/IconButton';

const OfferCard = ({ status }) => {
  const s = partial('shared');
  const f = partial('OffersFormPage');
  const c = partial('OfferCard');

  const {
    fetchAllOffers,
    filterOffersByCategory,
    deleteOfferById,
    getOfferViews,
    getAllOfferActivations,
  } = Api.offers;
  const [loading, setLoading] = useState(true);
  const [offers, setOffers] = useState([]);
  const [filteredOffers, setFilteredOffers] = useState([]);
  const { id } = useConfig();
  const [handleModal, visible, deleteID] = useModal();
  const [selectedCategory, setSelectedCategory] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const { categories } = useContext(OfferContext);
  const [categoriesWithFilter, setCategoriesWithFilter] = useState([]);
  const [paginationDetails, setPaginationDetails] = useState({
    first: 1,
    last: 1,
    previous: null,
    next: null,
    total: 0,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const fromatCategory = (data) => {
    if (data.includes('&')) {
      return data
        .split('&')
        .map((word) => word.trim().toLowerCase())
        .join()
        .replace(',', '_');
    }
    return data.toLowerCase().replace(' ', '_');
  };

  const getAllOffers = async (page, size) => {
    const { data } = await fetchAllOffers(id, page, size, status);

    setLoading(false);
    setOffers(data.offers);
    setFilteredOffers(data.offers);
    setPaginationDetails({
      first: data.first,
      last: data.last,
      previous: data.previous,
      next: data.next,
      total: data.total,
    });
    const offerIds = data.offers.map((offer) => offer.id);
    const [offerViewsResponse, offerActivationsResponse] = await Promise.all([
      getOfferViews({ offerIds }, status),
      getAllOfferActivations({ offerIds }, status),
    ]);
    const mappedOffers = data.offers.map((offer) => ({
      ...offer,
      views:
        offerViewsResponse.data.find((item) => item.offerId === offer.id)
          ?.views || 0,
      activations:
        offerActivationsResponse.data.find((item) => item.offerId === offer.id)
          ?.activations || 0,
    }));
    setOffers(mappedOffers);
    setFilteredOffers(mappedOffers);
  };

  useEffect(() => {
    getAllOffers(currentPage, pageSize);
  }, []);

  useEffect(() => {
    if (categories.length > 0) {
      setCategoriesWithFilter(categories);
    }
  }, [categories]);

  const pinOffer = async (offerId, isPinned) => {
    setLoading(true);
    await Api.offers.pinOfferById(offerId, { isPinned }, id);
    getAllOffers(currentPage, pageSize);
  };

  const renderTable = (offer) => (
    <tr key={offer.id}>
      <td>
        <Link to={`/offers/edit/${offer.id}`}>{offer.title}</Link>
      </td>
      <td>{c(fromatCategory(offer.category))}</td>
      <td>
        <strong>{s('start')}</strong>:{' '}
        {offer.startDate ? getBaseDateTime(offer.startDate) : '-'} <br />
        <strong>{s('end')}</strong>:{' '}
        {offer.endDate ? getBaseDateTime(offer.endDate) : '-'}
      </td>
      <td>
        <Creator offerId={offer.id} companyId={offer.companyId} />
      </td>
      <td className="text-center">
        {offer.views !== undefined
          ? offer.views
          : skeletonTableLoader(1, 1, 50)}
      </td>
      <td className="text-center">
        {offer.activations !== undefined
          ? offer.activations
          : skeletonTableLoader(1, 1, 50)}
      </td>
      <td>
        <ButtonGroup className="btn-group--icons edit-delete-btn-group">
          <IconButton
            onClick={() => pinOffer(offer.id, !offer?.isPinned)}
            id={`pin-${offer.id}`}
            type={
              offer?.isPinned
                ? IconButton.types.PIN_OFF
                : IconButton.types.PIN_ON
            }
          />
        </ButtonGroup>
      </td>
      <td>
        <UncontrolledDropdown className="dashboard__table-more">
          <DropdownToggle>
            <DotsHorizontalIcon />
          </DropdownToggle>
          <DropdownMenu className="dropdown__menu">
            <Link to={`/offers/edit/${offer.id}`}>
              <DropdownItem>{s('edit')}</DropdownItem>
            </Link>
            <Link to={`/offers/clone/${offer.id}`}>
              <DropdownItem>{s('clone')}</DropdownItem>
            </Link>
            <DropdownItem
              className="danger"
              onClick={() => handleModal(offer.id)}
            >
              {s('delete')}
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </td>
    </tr>
  );

  const handleFilterOffers = (category) => {
    setSelectedCategory(category.label);
    filterOffersByCategory(category.id, id, currentPage, pageSize, status).then(
      async (response) => {
        setFilteredOffers(response.data.offers);
        const offerIds = response.data.offers.map((offer) => offer.id);
        const [offerViewsResponse, offerActivationsResponse] =
          await Promise.all([
            getOfferViews({ offerIds }, status),
            getAllOfferActivations({ offerIds }, status),
          ]);
        const mappedOffers = response.data.offers.map((offer) => ({
          ...offer,
          views:
            offerViewsResponse.data.find((item) => item.offerId === offer.id)
              ?.views || 0,
          activations:
            offerActivationsResponse.data.find(
              (item) => item.offerId === offer.id
            )?.activations || 0,
        }));
        setFilteredOffers(mappedOffers);
      }
    );
  };

  const handleSearchOffers = (e) => {
    setSearchQuery(e.target.value);
    setFilteredOffers(
      offers.filter(
        (o) =>
          o.title.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1
      )
    );
  };

  const deleteOffer = async () => {
    try {
      setLoading(true);
      await deleteOfferById(deleteID, id);
      getAllOffers(currentPage, pageSize);
      toast.success(`${c('offerHasBeenDeleted')}`);
      handleModal();
    } catch (err) {
      setLoading(false);
      toast.error(err);
    }
  };

  const handlePagination = (paginationObject) => {
    if (
      paginationObject.pageSize !== pageSize ||
      paginationObject.currentPage !== currentPage
    ) {
      setCurrentPage(paginationObject.currentPage);
      setPageSize(paginationObject.pageSize);
      getAllOffers(paginationObject.currentPage, paginationObject.pageSize);
    }
  };

  return (
    <Row className="mt-3 mb-4">
      <Loading loading={loading} />
      <DeleteModal
        type="offer"
        visible={visible}
        handleModal={handleModal}
        modalAction={deleteOffer}
      />
      <Col>
        {status === 'active' && (
          <div className="flex justify-content-end">
            <Link to="/offers/create">
              <Button size="sm" color="primary">
                {f('createOffer')}
              </Button>
            </Link>
          </div>
        )}
        <div className="flex space-between">
          <div>
            <h3 className="page-title mb-0">
              {status === 'active' ? s('Active') : s('Expired')}
            </h3>
          </div>
          <div className="flex justify-content-end">
            <div className="form noWrap mr-3">
              <div className="form__form-group products-list__search flex">
                <input
                  value={searchQuery}
                  placeholder={s('search')}
                  onChange={handleSearchOffers}
                />
              </div>
            </div>
            <UncontrolledDropdown>
              <DropdownToggle
                className="icon icon--right mb-2"
                outline
                size="sm"
              >
                {selectedCategory === '' ? `${s('filter')}` : selectedCategory}{' '}
                <ChevronDownIcon />
              </DropdownToggle>
              <DropdownMenu className="dropdown__menu">
                {categoriesWithFilter.map((category) => (
                  <DropdownItem
                    key={category.value}
                    onClick={() => handleFilterOffers(category)}
                  >
                    {c(category.key)}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            paddingBottom: 5,
            background: '#fff',
            borderRadius: 6,
            boxShadow: '0px 2px 8px 0px rgba(0,0,0,0.08)',
          }}
        >
          <Table responsive striped>
            <thead>
              <tr>
                <th>{s('title')}</th>
                <th>{s('category')}</th>
                <th>{s('date')}</th>
                <th>{c('createdBy')}</th>
                <th>{s('views')}</th>
                <th>{s('activations')}</th>
                <th>{f('pinOffer')}</th>
                <th />
              </tr>
            </thead>
            <tbody>{filteredOffers.map((offer) => renderTable(offer))}</tbody>
          </Table>
        </div>
        <Pagination
          items={paginationDetails.total || 0}
          rowsPerPage={[10, 20, 30]}
          onChangePage={handlePagination}
        />
      </Col>
    </Row>
  );
};
export default memo(OfferCard);
