import configs from './configs';

let club;

const splitPath = window.location.pathname
  .slice(1, window.location.pathname.length)
  .split('/');
if (splitPath[0] !== '') {
  // eslint-disable-next-line prefer-destructuring
  club = splitPath[0];
}

/* const splitUrl = window.location.hostname.split('.');
if (splitUrl.length >= 3) {
  club = splitUrl[splitUrl.length - 3];
} */

export default configs?.[club];
