import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import {
  getAuth,
  RecaptchaVerifier,
  signInWithCustomToken,
} from 'firebase/auth';
import { useHistory } from 'react-router-dom';
import LogInForm from './components/LogInForm';
import Loading from '../../shared/components/custom/Loading';
// import config from '../../config';
import OneTimeCodeForm from './components/OneTimeCodeForm';
import { partial } from '../../util/translation/translation';
import app from '../_app/FirebaseConfig';
import Api from '../../util/api';
import useConfig from '../../util/useConfig';

const LoginPage = () => {
  const config = useConfig();
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [tab, setTab] = useState('username');
  const [user, setUser] = useState(null);
  const [confirmation, setConfirmation] = useState(null);
  const history = useHistory();
  const p = partial('LoginPage');
  function handleError(error) {
    if (error?.code) {
      switch (error.code) {
        case 'auth/invalid-phone-number':
          return toast.error(p('userNotFound'));
        case 'auth/invalid-verification-code':
          return toast.error(p('pleaseEnterCorrectCode'));
        case 'auth/too-many-requests': {
          toast.error(p('tooManyRequests'));
          return toast.success(p('proceedToEmailLogin'));
        }
        case 'INVALID_CODE':
          return toast.error(p('pleaseEnterCorrectCode'));
        case 'auth/code-expired': {
          setTab('username');
          return toast.error(p('codeExpired'));
        }
        default:
          return toast.error(error.code);
      }
    } else {
      return toast.error(error || p('errorSendingSMS'));
    }
  }
  async function sendCode({ username, password }) {
    try {
      const { phone, code } = username;
      const onlyPhone = phone?.replace(`+${code}`, '');
      let completePhone = onlyPhone;
      if (onlyPhone?.startsWith('0')) {
        completePhone = onlyPhone.slice(1, onlyPhone.length);
      }
      const phoneWithCode = `+${code}${completePhone}`;
      setButtonLoading(true);
      try {
        await Api.login.doesUserExist({ phone: phoneWithCode });
      } catch {
        throw Object.assign(new Error(), { code: 'auth/invalid-phone-number' });
      }
      const auth = getAuth(app);
      const applicationVerifier = new RecaptchaVerifier(
        'recaptcha-container',
        { size: 'invisible' },
        auth
      );
      const recaptchaToken = await applicationVerifier.verify();
      const {
        data: { sessionInfo },
      } = await Api.authorization.sendSignInWithPhone({
        phoneNumber: phoneWithCode,
        recaptchaToken,
      });
      setConfirmation(sessionInfo);
      setButtonLoading(false);
      toast.success(`${p('oneTimeCodeSentTo')} ${phoneWithCode}`);
      const payload = {
        country_code: '+47',
        username: phoneWithCode,
        password,
      };
      setUser(payload);
      setTab('password');
      setLoading(false);
    } catch (err) {
      handleError(err);
      setLoading(false);
      setButtonLoading(false);
    }
  }

  const verifyCode = async ({ code }) => {
    if (!user) {
      toast.error(p('userNotFound"'));
      setTab('username');
      return;
    }
    setLoading(true);
    try {
      const {
        data: { idToken },
      } = await Api.authorization.verifyPhoneCode({
        sessionInfo: confirmation,
        phoneNumber: user.username,
        code,
      });
      const {
        data: { token: customToken },
      } = await Api.authorization.getCustomToken(idToken);
      const auth = getAuth(app);
      await signInWithCustomToken(auth, customToken);
      setLoading(false);
    } catch (err) {
      handleError(err?.message ? { code: err?.message } : err);
      setLoading(false);
    }
  };
  const RenderTabs = () => {
    switch (tab) {
      case 'username':
        return (
          <LogInForm
            loading={buttonLoading}
            goToEmail={() => history.push('/email_login')}
            onSubmit={sendCode}
            goToForgot={() => {
              setTab('forgotPassword');
            }}
          />
        );
      case 'password':
        return (
          <OneTimeCodeForm
            username={user?.username ?? null}
            onSubmit={verifyCode}
          />
        );
      default:
        return null;
    }
  };
  return (
    <div className="account">
      <Loading loading={loading} />
      <div className="account__wrapper">
        <div className="account__card">
          <div className="account__head">
            <h3 className="account__title">
              {p('welcomeTo')}
              <span className="account__logo"> {config.club_name}</span>
            </h3>
          </div>
          <RenderTabs />
          <div id="recaptcha-container" />
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  isAuthenticated: state.admin.isAuthenticated,
});

export default connect(mapStateToProps)(LoginPage);
