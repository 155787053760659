import React, { useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  Col,
  Table,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from 'reactstrap';
import DotsHorizontalIcon from 'mdi-react/DotsHorizontalIcon';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import CheckboxMarkedCircleIcon from 'mdi-react/CheckboxMarkedCircleIcon';
import CloseCircleIcon from 'mdi-react/CloseCircleIcon';
import Api from '../../../util/api';
import Loading from '../../../shared/components/custom/Loading';
import { partial } from '../../../util/translation/translation';

const MatchTypeList = (props) => {
  const p = partial('settingsObjectsShared');
  const s = partial('shared');
  const [matchTypes, setMatchTypes] = useState([]);
  const [loading, setLoading] = useState(false);

  const getMatchTypes = async () => {
    setLoading(true);
    try {
      const resMatchTypes = await Api.settings.getMatchTypes();
      setMatchTypes(resMatchTypes.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMatchTypes();
  }, []);

  const renderMatchTypeList = () =>
    matchTypes.map((matchType) => (
      <tr className="center-list" key={`team-${matchType.id}`}>
        <td>
          <Link to={`/settings/match_type/edit/${matchType.id}`}>
            <img
              className="teamLogoTable"
              src={matchType.icon && matchType.icon.url}
              alt=""
            />
            {matchType.name}
          </Link>
        </td>
        <td className="vertical-alignMiddle">
          {matchType.season_inclusion ? (
            <CheckboxMarkedCircleIcon color="#399351" />
          ) : (
            <CloseCircleIcon color="#FF4861" />
          )}
        </td>
        <td className="vertical-alignMiddle">
          <UncontrolledDropdown className="dashboard__table-more">
            <DropdownToggle>
              <DotsHorizontalIcon />
            </DropdownToggle>
            <DropdownMenu className="dropdown__menu">
              <Link to={`/settings/match_type/edit/${matchType.id}`}>
                <DropdownItem>{s('edit')}</DropdownItem>
              </Link>
              <DropdownItem
                onClick={() =>
                  props.handleModal(
                    true,
                    matchType.id,
                    'kamptypen',
                    'deleteMatchType',
                    getMatchTypes
                  )
                }
                className="danger"
              >
                {s('delete')}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </td>
      </tr>
    ));

  return (
    <Col>
      <Loading loading={loading} />
      <Card>
        <CardBody>
          <div className="card__title marginTop-0">
            <p className="page-title">{p('matchTypes')}</p>
            <Link
              className="btn btn-primary btn-sm"
              to="/settings/match_type/create"
            >
              {p('createMatchTypes')}
            </Link>
          </div>
          <Table responsive bordered>
            <thead>
              <tr>
                <th>{s('name')}</th>
                <th>{p('partOfSeasonticket')}</th>
                <th />
              </tr>
            </thead>
            <tbody>{renderMatchTypeList()}</tbody>
          </Table>
        </CardBody>
      </Card>
    </Col>
  );
};

MatchTypeList.propTypes = {
  handleModal: PropTypes.func.isRequired,
};

export default MatchTypeList;
