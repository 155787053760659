import React, { useEffect, useState } from 'react';
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  TabPane,
  TabContent,
} from 'reactstrap';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import Details from './components/Details';
import Prices from './components/Prices';
import MatchFields from './components/MatchFields';
import Result from './components/Result';
import api from '../../util/api';
import Loading from '../../shared/components/custom/Loading';
import Attendance from './components/Attendance';
import NavTab from '../../shared/components/custom/NavTab';
import { partial } from '../../util/translation/translation';
import { formatDateForApi } from '../../util/date-and-time';

const moment = extendMoment(Moment);

const p = partial('MatchFormPage');
const s = partial('shared');

const MatchFormPage = ({ enabledFeatures }) => {
  const history = useHistory();
  const { matchID } = useParams();
  const editMode = !!matchID;
  const [activeTab, setActiveTab] = useState('1');
  const [teams, setTeams] = useState([]);
  const [matchTypes, setMatchTypes] = useState([]);
  const [controlImages, setControlImages] = useState([]);
  const [selectedControlImage, setSelectedControlImage] = useState({});
  const [loading, setLoading] = useState(true);
  const [seasons, setSeasons] = useState([]);
  const [match, setMatch] = useState({});
  const [priceGroups, setPriceGroups] = useState([]);
  const [fieldExclusions, setFieldExclusions] = useState([]);
  const [fieldTypes, setFieldTypes] = useState([]);
  const [sponsorOptions, setSponsorOptions] = useState([]);
  const mapOptions = (entity) => ({
    ...entity,
    label: entity.name.toString(),
    value: entity.id.toString(),
  });

  const getControlImage = async (controlImageId) => {
    try {
      const resControlImage = await api.matches.getControlImage(controlImageId);
      setSelectedControlImage(resControlImage.data);
    } catch (err) {
      toast.error(p('couldNotFetchPreviewImg'));
    }
  };

  const getInitialData = async () => {
    setLoading(true);
    try {
      const [
        resSeasons,
        resTeams,
        resMatchTypes,
        resSponsors,
        resPriceGroups,
        resFieldTypes,
        resFieldExclusions,
        resMatch,
      ] = await Promise.all([
        api.seasons.getSeasons(),
        api.matches.getTeams(),
        api.matches.getMatchTypes(),
        api.companies.getClubCompanies(),
        editMode && api.matches.getPriceGroups(matchID),
        editMode && api.matches.getFieldTypes(matchID),
        editMode && api.matches.getFieldExclusions(matchID),
        editMode && api.matches.getMatch(matchID),
      ]);
      setSeasons(resSeasons.data);
      const resControlImages = await api.matches.getAllControlImages();
      setControlImages(
        resControlImages.data.map((image) => ({
          value: image.id.toString(),
          label: `${image.name} (${image.image_used_count})`,
        }))
      );
      setTeams(resTeams.data.map(mapOptions));
      setMatchTypes(resMatchTypes.data.map(mapOptions));
      setSponsorOptions(resSponsors.map(mapOptions));
      setMatch(resMatch.data);
      setPriceGroups(resPriceGroups.data);
      setFieldTypes(resFieldTypes.data);
      setFieldExclusions(resFieldExclusions.data);
      setLoading(false);

      const controlImageID =
        resMatch.data?.control_image_id ?? resControlImages?.data?.[0]?.id;
      if (controlImageID) {
        getControlImage(controlImageID);
      }
    } catch (err) {
      toast.error(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    getInitialData();
  }, [matchID]);

  const handleSubmit = async (values) => {
    const matchStartsAt = moment(values.matchStartsAt);
    const doorsOpenAt = matchStartsAt
      .hour(values.doorsOpenAt.substring(0, 2))
      .minute(values.doorsOpenAt.substring(3, 5));
    const saleStart = values.saleStart ? moment(values.saleStart) : undefined;
    const saleEnd = values.saleEnd ? moment(values.saleEnd) : undefined;
    let presale;
    if (values.presaleCheck) {
      const momentPresale = moment(values.presale);
      let duration;
      if (saleStart) {
        duration = moment.duration(momentPresale.diff(saleStart));
      } else {
        duration = moment.duration(matchStartsAt.diff(momentPresale));
      }
      presale = duration.asHours();
    }
    const matchSeason = seasons.find((season) => {
      const startDate = moment(season.start_date);
      const endDate = moment(season.end_date);
      const range = moment().range(startDate, endDate);
      return range.contains(matchStartsAt);
    });

    if (!matchSeason) {
      toast.error(p('matchStartError'));
      return;
    }

    // AB-Billetter
    let openDate = null;
    if (values.saleStart) {
      openDate = formatDateForApi(saleStart);
    }
    if (values.claimStartDate) {
      openDate = formatDateForApi(values.claimStartDate);
    }
    setLoading(true);
    const payload = {
      home_team_id: Number(values.homeTeam.value),
      away_team_id: Number(values.awayTeam.value),
      match_starts_at: formatDateForApi(values.matchStartsAt),
      doors_open_at: formatDateForApi(doorsOpenAt),
      sale_start: values.saleStart ? formatDateForApi(values.saleStart) : '',
      sale_end: values.saleEnd ? formatDateForApi(saleEnd) : '',
      season_id: matchSeason.id,
      match_type_id: Number(values.matchType.value),
      control_image_id: selectedControlImage.id,
      presale_duration: values.presaleCheck ? `${presale}h` : '',
      use_qr: values.useQr,
      use_websale: values.useWebsale,
      external_match_id: values.externalMatchID,
      open_date: openDate,
      matchNTBId: values.matchNTBId,
      stageNTBId: values.stageNTBId,
      new_company_id: values.sponsor && values.sponsor.id,
      release_date: values.claimEndDate
        ? formatDateForApi(values.claimEndDate)
        : formatDateForApi(
            moment(values.matchStartsAt).subtract(1, 'day').hour(12).minute(0)
          ),
      match_url: values.externalLink,
      notify_user: values.notify_user,
    };

    if (editMode) {
      try {
        await api.matches.updateMatch(matchID, payload);
        toast.success(p('matchUpdated'));
      } catch (err) {
        toast.error(err || p('matchNotUpdatedError'));
      }
      setLoading(false);
    } else {
      try {
        const res = await api.matches.createMatch(payload);
        toast.success(p('matchCreated'));
        history.push(`/matches/edit/${res.data.id}`);
      } catch (err) {
        toast.error(err || p('matchNotCreatedError'));
        setLoading(false);
      }
    }
  };

  const handleMatchField = async (payload) => {
    setLoading(true);
    try {
      await api.matches.putFieldExclusions(matchID, payload);
      const resFieldExclusions = await api.matches.getFieldExclusions(matchID);
      setFieldExclusions(resFieldExclusions.data);
      setLoading(false);
    } catch {
      toast.error(p('couldNotUpdateMatchField'));
      setLoading(false);
    }
  };

  const handleResult = async (payload) => {
    setLoading(true);
    try {
      await api.matches.putResult(matchID, payload);
      toast.success(p('matchResultUpdated'));
    } catch {
      toast.error(p('UpdatingMatchError'));
    }
    setLoading(false);
  };

  return (
    <Container>
      <Loading loading={loading} />
      <Row>
        <Col md={12}>
          <h3 className="page-title">
            {editMode ? p('editMatch') : p('newMatch')}
          </h3>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <NavTab
                tabs={[
                  s('details'),
                  { disabled: !editMode || loading, tab: s('audience') },
                  { disabled: !editMode || loading, tab: s('prices') },
                  { disabled: !editMode || loading, tab: s('stadiumSetup') },
                  { disabled: !editMode || loading, tab: s('rating') },
                ]}
                activeTab={activeTab}
                setTab={setActiveTab}
                inline
              />
              <div className="tabs tabs--justify tabs--bordered-top overflow-unset">
                <div className="tabs__wrap">
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <Details
                        onSubmit={handleSubmit}
                        teams={teams}
                        matchTypes={matchTypes}
                        controlImages={controlImages}
                        selectedControlImage={selectedControlImage}
                        getControlImage={getControlImage}
                        seasonOptions={seasons}
                        match={match}
                        sponsorOptions={sponsorOptions}
                        type={editMode ? 'edit' : 'create'}
                        enabledFeatures={enabledFeatures}
                      />
                    </TabPane>
                    <TabPane tabId="2">
                      <Attendance matchID={matchID} match={match} />
                    </TabPane>
                    <TabPane tabId="3">
                      <Prices
                        priceGroups={priceGroups}
                        fieldTypes={fieldTypes}
                        matchID={matchID}
                      />
                    </TabPane>
                    <TabPane tabId="4">
                      <MatchFields
                        match={match}
                        fields={fieldExclusions}
                        handleMatchField={handleMatchField}
                      />
                    </TabPane>
                    <TabPane tabId="5">
                      <Result
                        handleResult={handleResult}
                        match={match}
                        editMode={editMode}
                        matchID={matchID}
                        companies={sponsorOptions}
                        seasons={seasons}
                      />
                    </TabPane>
                  </TabContent>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

MatchFormPage.propTypes = {
  enabledFeatures: PropTypes.shape({
    hasFetched: PropTypes.bool.isRequired,
    tickets: PropTypes.bool,
  }).isRequired,
};

export default connect((state) => ({
  enabledFeatures: state.enabledFeatures,
}))(MatchFormPage);
