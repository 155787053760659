import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import renderField from '../../../shared/components/custom/Field';
import { partial } from '../../../util/translation/translation';
import renderSelectField from '../../../shared/components/form/Select';
import Api from '../../../util/api';
import validateCompanyParticipants from './validateCompanyParticipants';

const CompanyCardForm = ({
  formValues,
  change,
  handleSubmit,
  companies,
  participant,
  initialize,
}) => {
  const p = partial('EventParticipantsPage');
  const [companyEmployees, setCompanyEmployees] = useState([]);
  const fetchCompanyEmployees = async (companyId) => {
    const { data: employees } = await Api.employees.getEmployees(companyId);
    const mappedEmployees = employees.map((item) => ({
      label: `${item.firstName} ${item.lastName}`,
      value: item,
    }));
    setCompanyEmployees(mappedEmployees);
  };

  useEffect(() => {
    if (participant) {
      const foundCompany = companies.find(
        (item) => item.id === participant.companyId
      );
      initialize({
        selectedCompany: { label: foundCompany.name, value: foundCompany },
        selectedEmployee: {
          label: `${participant.firstname} ${participant.lastname}`,
          value: participant,
        },
        guestAmount: participant.friendAmount,
      });
    }
  }, [participant]);
  const companyOptions = companies.map((item) => ({
    label: item.name,
    value: item,
  }));
  return (
    <form className="form" onSubmit={handleSubmit}>
      <div className="form__form-group mx-2">
        <span className="form-header">{p('company')}</span>
        <Field
          name="selectedCompany"
          placeholder={p('chooseCompany')}
          disabled={participant}
          options={companyOptions}
          component={renderSelectField}
          onChange={({ value: { id } }) => {
            fetchCompanyEmployees(id);
            change('selectedEmployee', null);
          }}
        />
      </div>
      <div className="form__form-group mx-2">
        <span className="form-header">{p('contactPerson')}</span>
        <Field
          name="selectedEmployee"
          placeholder={p('chooseContactPerson')}
          disabled={!formValues?.selectedCompany || participant}
          options={companyEmployees.filter((item) => !!item.value.userId)}
          component={renderSelectField}
        />
      </div>
      <div className="form__form-group mx-2">
        <span className="form-header">{p('numberOfGuests')}</span>
        <Field
          name="guestAmount"
          placeholder={p('chooseGuests')}
          disabled={!formValues?.selectedEmployee}
          options={companyEmployees}
          component={renderField}
          type="number"
        />
      </div>
      <Button color="primary" type="submit">
        {p('inviteParticipants')}
      </Button>
    </form>
  );
};

const reduxF = reduxForm({
  form: 'CompanyCardForm',
  validate: validateCompanyParticipants,
});

const mapStateToProps = (state) => ({
  formValues: getFormValues('CompanyCardForm')(state),
});

export default connect(mapStateToProps)(reduxF(CompanyCardForm));
