import React, { Component } from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import RenderFileInputField from '../../../shared/components/form/FileInput';
import { partial } from '../../../util/translation/translation';

const p = partial('settingsObjectsShared');
const s = partial('shared');

class SeasonTicketImportForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  };

  componentDidMount = () => {};

  render() {
    const { handleSubmit } = this.props;
    return (
      <form className="form" onSubmit={handleSubmit}>
        <h2 className="page-title">{p('seasonCardImport')}</h2>
        <div className="form__form-group">
          <span className="form__form-group-label">
            {p('csvImportTickets')}
          </span>
          <div className="form__form-group-field">
            <Field name="tickets" component={RenderFileInputField} />
          </div>
        </div>
        <Button type="submit" color="primary">
          {s('upload')}
        </Button>
      </form>
    );
  }
}

export default reduxForm({
  form: 'season_ticket_import_form',
})(SeasonTicketImportForm);
