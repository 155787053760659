import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Close from 'mdi-react/CloseIcon';
import moment from 'moment';
import t from '../../../util/translation/translation';
import { componentsFromState } from './newsUtils';

const AppComponent = ({ item }) => {
  switch (item.type) {
    case 'IMAGE':
      return (
        <div>
          {item.value.image && (
            <img
              className="my-3 px-2"
              style={{ height: 281, paddingBottom: 0 }}
              src={item.value.image}
              alt="News"
            />
          )}
          {item.value.author ? (
            <p
              style={{
                width: 500,
                fontSize: 10,
                lineHeight: '13px',
                padding: '0 10px 0 10px',
                marginBottom: 10,
              }}
            >
              {item.value.author}
            </p>
          ) : null}
        </div>
      );
    case 'RICH_TEXT':
      return (
        <div
          style={{ fontSize: 16, padding: '0 10px 0 10px' }}
          dangerouslySetInnerHTML={{ __html: item.value.text }}
        />
      );
    case 'IMAGE_PHOTOGRAPHER':
      return (
        <div
          style={{
            width: 500,
            fontSize: 16,
            backgroundColor: 'gray',
            padding: '0 10px 0 10px',
          }}
        >
          {item.value.text}
        </div>
      );
    case 'HEADER':
      return (
        <div
          style={{
            width: 500,
            fontSize: 20,
            marginTop: 10,
            fontWeight: 'bold',
            padding: '0 10px 0 10px',
          }}
        >
          {item.value.text}
        </div>
      );
    case 'DELIMITER':
      return <div style={{ height: 15 }} />;
    case 'YOUTUBE_VIDEO':
      return (
        <iframe
          style={{ margin: '10px 0 10px 0', padding: '0 10px 0 10px' }}
          width="500"
          height="240"
          src={`https://www.youtube.com/embed/${item.value.video}`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        />
      );
    case 'SPORTALITY_VIDEO':
      return (
        <div>
          <iframe
            style={{ margin: '10px 0 10px 0', padding: '0 10px 0 10px' }}
            width="500"
            height="240"
            src={`https://embed.staylive.tv/video/${item.value.video}?autoplay=0`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          />
          {item.value.author ? (
            <p style={{ width: 500, fontSize: 16, padding: '0 10px 0 10px' }}>
              {item.value.author}
            </p>
          ) : null}
        </div>
      );
    case 'CUSTOM_VIDEO':
      return (
        <div>
          <iframe
            style={{ margin: '10px 0 10px 0', padding: '0 10px 0 10px' }}
            width="500"
            height="240"
            src={item.value.video?.preview || item.value.video}
            title="Custom video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          />
        </div>
      );
    default:
      return null;
  }
};
const HeadingImage = ({ url }) => {
  return <img style={{ width: 500, height: 281 }} src={url} alt="Heading" />;
};
const HeaderContent = ({ news }) => {
  if (news.headingVideo) {
    return (
      <iframe
        style={{ margin: '0 0 0 0', padding: '0 0 0 0' }}
        width="500"
        height="281"
        src={news.headingVideo}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      />
    );
  }
  if (news.headingImage) {
    return <HeadingImage url={news.headingImage} />;
  }
  return null;
};

const AppPreviewModal = ({
  visible,
  toggle,
  components,
  selectedItems,
  news,
}) => {
  const [mappedItems, setMappedItems] = useState([]);

  useEffect(() => {
    const refreshItems = async () => {
      const mappedItemsResponse = await componentsFromState(
        selectedItems,
        components
      );
      setMappedItems(mappedItemsResponse);
    };
    refreshItems();
  }, [selectedItems, components]);
  return (
    <Modal
      isOpen={visible}
      toggle={toggle}
      backdrop
      style={{
        width: 500,
        height: '80vh',
        overflow: 'scroll',
        pointerEvents: 'all',
      }}
    >
      <ModalHeader className="navbar-modal-header">
        <div className="modal-header-title">{t('NewsForm.appPreview')}</div>
        <div className="float-right">
          <button className="marginBottom-0 builder-button" onClick={toggle}>
            <Close />
          </button>
        </div>
      </ModalHeader>
      <ModalBody className="appLayout" style={{ padding: 0 }}>
        <HeaderContent news={news} />
        <div className="mb-3">
          <div
            style={{
              width: 500,
              fontSize: 20,
              margin: '5px 0 5px 0',
              fontWeight: 'bold',
              padding: '0 10px 0 10px',
            }}
          >
            {news?.title}
          </div>
          <small style={{ marginLeft: 12, opacity: 0.7 }}>
            {t('NewsFormPage.published')}:{' '}
            {moment(news?.publicationdate).fromNow()}, {t('shared.by')}{' '}
            {news?.author}
          </small>
        </div>
        <div className="pb-3 news">
          {mappedItems.map((item) => (
            <AppComponent item={item} key={`${item.type}-${item.order}`} />
          ))}
        </div>
      </ModalBody>
    </Modal>
  );
};
export default AppPreviewModal;
