import React, { useEffect, useMemo } from 'react';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { Col, Row, Container } from 'reactstrap';
import { connect } from 'react-redux';
import renderSelectField from '../../../shared/components/form/Select';
import validate from './validateAccess';
import { partial } from '../../../util/translation/translation';
import renderRadioButtonField from '../../../shared/components/form/RadioButton';
import TooltipWithIcon from '../../../shared/components/custom/TooltipWithIcon';
import FormBox from '../../../shared/components/custom/FormBox';

const o = partial('OfferForm');
const s = partial('shared');
const n = partial('NewsForm');
const p = partial('NewsFormPage');

const AccessForm = (props) => {
  const {
    handleSubmit,
    news,
    initialize,
    groupsLevelsTiers,
    access,
    accessFormValues = {},
    change,
  } = props;
  const { tiers, levels, groups } = groupsLevelsTiers;
  const targetAudience = useMemo(
    () => accessFormValues?.targetAudience,
    [accessFormValues]
  );
  const getTargetAudience = () => {
    if (access.levels || access.groups || access.tiers) {
      return 'targetAudience';
    }
    return 'all';
  };
  const getSelectedFields = (objectField, title, searchArray) => {
    if (Array.isArray(access[objectField])) {
      if (access[objectField].length === 0) {
        return [{ label: p(`all${title}`), value: [] }];
      } else {
        return access[objectField].map((accessItem) =>
          searchArray.find((item) => item.value === accessItem)
        );
      }
    } else {
      return [{ label: p('ignore'), value: null }];
    }
  };
  useEffect(() => {
    initialize({
      targetAudience: getTargetAudience(),
      level: getSelectedFields('levels', 'Levels', levels),
      tier: getSelectedFields('tiers', 'Tiers', tiers),
      group: getSelectedFields('groups', 'Groups', groups),
      noAccessView:
        typeof access.noAccessView === 'boolean' ? access.noAccessView : true,
      visible: typeof access.visible === 'boolean' ? access.visible : false,
    });
  }, [news, access, groupsLevelsTiers]);

  const adjustDropdowns = (fieldName) => {
    if (accessFormValues[fieldName] && accessFormValues[fieldName].length > 1) {
      const [old, ...newOnes] = accessFormValues[fieldName];
      const newestOne =
        accessFormValues[fieldName][accessFormValues[fieldName].length - 1];
      if (old.value === null || Array.isArray(old.value)) {
        change(fieldName, newOnes);
      }
      if (
        newestOne &&
        (newestOne.value === null || Array.isArray(newestOne.value))
      ) {
        change(fieldName, [newestOne]);
      }
    }
  };
  useEffect(() => {
    adjustDropdowns('group');
    adjustDropdowns('level');
    adjustDropdowns('tier');
  }, [accessFormValues.level, accessFormValues.group, accessFormValues.tier]);
  const handleAudienceChange = (newAudience) => {
    if (newAudience === 'all') {
      change('level', [{ label: p('ignore'), value: null }]);
      change('tier', [{ label: p('ignore'), value: null }]);
      change('group', [{ label: p('ignore'), value: null }]);
    } else {
      change('level', [{ label: p('ignore'), value: null }]);
      change('tier', [{ label: p('ignore'), value: null }]);
      change('group', [{ label: p('ignore'), value: null }]);
    }
  };
  return (
    <form onSubmit={handleSubmit} className="form">
      <Container>
        <Row>
          <Col>
            <FormBox title={s('access')}>
              <div>
                <Row className="ml-1">
                  <Col>
                    <span className="form">{n('targetAudienceTitle')}</span>
                    <div className="form__form-group-field">
                      <div className="form__form-group">
                        <Field
                          name="targetAudience"
                          component={renderRadioButtonField}
                          radioValue="all"
                          label={n('targetAudience-all')}
                          defaultChecked
                          onChange={handleAudienceChange}
                        />
                        <Field
                          name="targetAudience"
                          label={n('targetAudience-custom')}
                          component={renderRadioButtonField}
                          radioValue="targetAudience"
                          onChange={handleAudienceChange}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg="6" md="6">
                    <span className="form">
                      {n('noAccessView')}
                      &nbsp;
                      <TooltipWithIcon
                        id="no-access-view-tooltip"
                        text={p('noAccessViewInfo')}
                      />
                    </span>
                    <div className="form__form-group-field">
                      <div className="form__form-group">
                        <Field
                          name="noAccessView"
                          component={renderRadioButtonField}
                          radioValue
                          label={s('all')}
                          defaultChecked
                        />
                        <Field
                          name="noAccessView"
                          label={o('targetAudience')}
                          component={renderRadioButtonField}
                          radioValue={false}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                {targetAudience !== 'all' ? (
                  <div>
                    <Col>
                      <div className="form__form-group">
                        <span className="form-header">
                          {o('partnerlevelList')}
                        </span>
                        <Field
                          name="level"
                          placeholder={o('selectLevels')}
                          options={levels}
                          component={renderSelectField}
                          multiple
                          disabled={targetAudience === 'all'}
                        />
                      </div>
                    </Col>
                    <Col>
                      <div className="form__form-group">
                        <span className="form-header">
                          {o('partnergroupList')}
                        </span>
                        <Field
                          name="group"
                          placeholder={o('selectGroup')}
                          options={groups}
                          component={renderSelectField}
                          multiple
                          disabled={targetAudience === 'all'}
                        />
                      </div>
                    </Col>
                    <Col>
                      <div className="form__form-group">
                        <span className="form-header">{s('userTiers')}</span>
                        <Field
                          name="tier"
                          placeholder={o('selectTier')}
                          options={tiers}
                          component={renderSelectField}
                          multiple
                          disabled={targetAudience === 'all'}
                        />
                      </div>
                    </Col>
                  </div>
                ) : null}
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    borderTopWidth: 1,
                    borderTopStyle: 'dashed',
                    borderBottomWidth: 1,
                    borderBottomStyle: 'dashed',
                    borderColor: '#D7DAE0',
                    padding: '10px 10px 10px 10px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      style={{
                        fontSize: 16,
                        lineHeight: '24px',
                        color: 'black',
                        fontWeight: 'bold',
                      }}
                    >
                      {n('newsStatus')}
                    </div>
                  </div>
                </div>
                <div style={{ padding: '10px 10px 0px 10px' }}>
                  <Row>
                    <Col>
                      <div className="form__form-group-field">
                        <div className="form__form-group">
                          <span>
                            <Field
                              name="visible"
                              component={renderRadioButtonField}
                              radioValue={false}
                              label={p('draft')}
                              defaultChecked
                            />
                            <span>
                              <TooltipWithIcon
                                id="draft-tooltip"
                                text={p('draftDescription')}
                              />
                            </span>
                          </span>
                          <span style={{ marginLeft: 30 }}>
                            <Field
                              name="visible"
                              label={p('published')}
                              component={renderRadioButtonField}
                              // eslint-disable-next-line react/jsx-boolean-value
                              radioValue={true}
                            />
                            <span>
                              <TooltipWithIcon
                                id="published-tooltip"
                                text={p('publishedDescription')}
                              />
                            </span>
                          </span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </FormBox>
          </Col>
        </Row>
      </Container>
    </form>
  );
};

const reduxF = reduxForm({
  form: 'access_form',
  validate,
});

const mapStateToProps = (state) => ({
  accessFormValues: getFormValues('access_form')(state),
});

export default connect(mapStateToProps)(reduxF(AccessForm));
