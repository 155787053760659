import React from 'react';
import { Col } from 'reactstrap';
import CheckboxMarkedCircle from 'mdi-react/CheckboxMarkedCircleIcon';
import CircleOutline from 'mdi-react/CircleOutlineIcon';
import ImageIcon from 'mdi-react/FileImageIcon';
import { partial } from '../../../util/translation/translation';

const p = partial('HighlightBannerForm');

const DescriptionComponent = (type) => (data) => {
  const {
    fieldValue,
    input: { onChange, value },
  } = data;
  const isSelected = value.toString() === fieldValue;
  const containerStyle = {
    width: 80,
    height: 142,
    alignSelf: 'center',
    alignItems: 'center',
    padding: '10px 0 10px 0',
    display: 'flex',
    flexDirection: 'column',
  };
  const innerContainer = {
    backgroundColor: isSelected ? '#C3DEFE' : '#D7DAE0',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  };
  const textBox = {
    width: 80,
    height: 40,
    borderBottomRightRadius: 8,
    borderBottomLeftRadius: 8,
  };
  return (
    <Col xs={12} sm={12} md={12} lg={6} className="mt-4">
      <button
        style={{
          backgroundColor: isSelected ? '#0469DC10' : 'white',
          borderWidth: 2,
          borderStyle: isSelected ? 'solid' : 'dashed',
          borderRadius: 8,
          borderColor: isSelected ? '#0469DC' : '#D7DAE0',
          width: 360,
          height: 170,
          alignItems: 'flex-start',
          display: 'flex',
          flexDirection: 'column',
          padding: 10,
        }}
        onClick={() => onChange(fieldValue)}
        type="button"
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <p
            style={{
              color: isSelected ? '#0469DC' : '#121B2B',
              fontSize: 12,
              fontWeight: 600,
            }}
          >
            {p(type)}
          </p>
          {isSelected ? (
            <CheckboxMarkedCircle
              size={12}
              className="icon-min-20"
              color="#0469DC"
            />
          ) : (
            <CircleOutline size={12} className="icon-min-20" color="#D7DAE0" />
          )}
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <div style={containerStyle}>
            <div style={{ ...innerContainer, height: 80, width: 80 }}>
              <ImageIcon size={20} color={isSelected ? '#0469DC' : '#121B2B'} />
            </div>
            {type === 'IMAGE_TEXT' && (
              <div
                style={{
                  ...textBox,
                  color: isSelected ? '#0469DC' : 'black',
                  background: 'white',
                  padding: 5,
                  textAlign: 'left',
                  fontWeight: 700,
                  fontSize: 12,
                  border: `1px solid ${isSelected ? 'rgb(195, 222, 254)' : 'rgb(215, 218, 224)'}`,
                }}
              >
                {p('text')}...
              </div>
            )}
          </div>
        </div>
      </button>
    </Col>
  );
};

export default DescriptionComponent;
