import DotsHorizontalIcon from 'mdi-react/DotsHorizontalIcon';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Button,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  TabContent,
  TabPane,
} from 'reactstrap';
import ArrowUpIcon from 'mdi-react/ArrowUpIcon';
import ArrowDownIcon from 'mdi-react/ArrowDownIcon';
import CheckIcon from '../../shared/components/custom/CheckIcon';
import Loading from '../../shared/components/custom/Loading';
import useModal from '../../shared/hooks/useModal';
import Api from '../../util/api';
import { formatCurrencyNumber } from '../../util/functions';
import DeleteModal from '../../shared/components/custom/DeleteModal';
import Pagination from '../../shared/components/pagination/Pagination';
import t, { partial } from '../../util/translation/translation';
import useConfig from '../../util/useConfig';
import { formatDate } from '../../util/date-and-time';
import NavTab from '../../shared/components/custom/NavTab';

const AuctionListPage = () => {
  const config = useConfig();
  const [auctionHouses, setAuctionHouses] = useState([]);
  const [loadingAuctions, setLoadingAuctions] = useState(true);
  const [handleModal, visible, deleteID] = useModal();
  const [pagination, setPagination] = useState({
    total: 0,
    currentPage: 1,
    perPage: 10,
  });
  const [order, setOrder] = useState({
    orderBy: 'createdAt',
    desc: true,
  });
  const [tab, setTab] = useState(1);
  const p = partial('auctionObjectsShared');
  const s = partial('shared');

  const fetchAuctionHouses = async (
    currentPage = 1,
    pageSize = 10,
    orderObject = {
      orderBy: 'createdAt',
      desc: true,
    }
  ) => {
    setLoadingAuctions(true);
    try {
      const resAuctionHouses = await Api.auctions.getAuctionHouses(
        { page: currentPage, take: pageSize },
        {
          orderBy: orderObject.orderBy,
          direction: orderObject.desc ? 'DESC' : 'ASC',
        }
      );
      setAuctionHouses(resAuctionHouses.data);
      setPagination((old) => ({ ...old, total: resAuctionHouses.count }));
      setLoadingAuctions(false);
    } catch (err) {
      toast.error(err || `${p('auctionRetrievingERROR')}`);
      setLoadingAuctions(false);
    }
  };
  useEffect(() => {
    fetchAuctionHouses();
  }, []);
  const deleteAuctionHouse = async () => {
    setLoadingAuctions(true);
    try {
      await Api.auctions.deleteAuctionHouse(deleteID);
      toast.success(`${p('auctionDeleted')}`);
    } catch (err) {
      toast.error(err || `${p('auctionDeletedERROR')}`);
    } finally {
      handleModal();
      fetchAuctionHouses(pagination.currentPage, pagination.perPage, order);
    }
  };

  const onChangePage = (pager) => {
    if (
      pager.pageSize !== pagination.perPage ||
      pager.currentPage !== pagination.currentPage
    ) {
      setPagination((old) => ({
        ...old,
        currentPage: pager.currentPage,
        perPage: pager.pageSize,
      }));
      fetchAuctionHouses(pager.currentPage, pager.pageSize, order);
    }
  };

  const renderSort = (cellOrder) => {
    if (order.orderBy !== cellOrder) {
      return undefined;
    }
    return order.desc ? <ArrowDownIcon size={16} /> : <ArrowUpIcon size={16} />;
  };
  const handleSort = (incommingOrder) => {
    const newOrder = {
      orderBy: incommingOrder,
      desc: incommingOrder === order.orderBy ? !order.desc : true,
    };
    setOrder(newOrder);
    setPagination({
      total: 0,
      currentPage: 1,
      perPage: 10,
    });
    fetchAuctionHouses(1, 10, newOrder);
  };

  const renderAuctionHouses = () => {
    return auctionHouses.map((auctionHouse, i) => (
      <tr
        style={{ background: i % 2 ? '#fff' : '#F9FAFB' }}
        key={`auction-house-${auctionHouse.id}`}
      >
        <td>
          <CheckIcon state={!!auctionHouse.isActive} />
        </td>
        <td>
          <div className="flex-Vcenter">
            <Link to={`/auctions/edit/${auctionHouse.id}?tab=2`}>
              {auctionHouse.name}
            </Link>
          </div>
        </td>
        <td>{formatDate(auctionHouse.createdAt)}</td>
        <td>
          <b>
            {formatCurrencyNumber(Number(auctionHouse.value))}{' '}
            {config.currencyCaps}
          </b>
        </td>
        <td>{auctionHouse.itemsCount}</td>
        <td>
          <UncontrolledDropdown className="dashboard__table-more">
            <DropdownToggle>
              <DotsHorizontalIcon />
            </DropdownToggle>
            <DropdownMenu className="dropdown__menu">
              <Link to={`/auctions/edit/${auctionHouse.id}`}>
                <DropdownItem>{s('edit')}</DropdownItem>
              </Link>
              <DropdownItem
                onClick={() => handleModal(auctionHouse.id)}
                className="danger"
              >
                {s('delete')}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </td>
      </tr>
    ));
  };

  return (
    <Container>
      <Loading loading={loadingAuctions} />
      <NavTab
        activeTab={tab}
        tabs={[t('auctionObjectsShared.auctionHouse')]}
        setTab={setTab}
      />
      <DeleteModal
        visible={visible}
        type={p('theAuctionHouseSmall')}
        handleModal={handleModal}
        modalAction={deleteAuctionHouse}
      />

      <TabContent activeTab={tab}>
        <TabPane tabId="1">
          <Row>
            <Col>
              <div className="float-right marginTop-0">
                <Link to="/auctions/create">
                  <Button size="sm" color="primary">
                    {p('createAuctionHouse')}
                  </Button>
                </Link>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div
                style={{
                  display: 'flex',
                  paddingBottom: 5,
                  background: '#fff',
                  borderRadius: 6,
                  boxShadow: '0px 2px 8px 0px rgba(0,0,0,0.08)',
                }}
              >
                <Table responsive striped>
                  <thead>
                    <tr>
                      <th
                        style={{ color: '#4D576A' }}
                        className="sortable"
                        onClick={() => handleSort('isActive')}
                      >
                        <div className="flex alignItems-center">
                          {s('active')} {renderSort('isActive')}
                        </div>
                      </th>
                      <th
                        style={{ color: '#4D576A' }}
                        className="sortable"
                        onClick={() => handleSort('name')}
                      >
                        <div className="flex alignItems-center">
                          {s('name')} {renderSort('name')}
                        </div>
                      </th>
                      <th
                        style={{ color: '#4D576A' }}
                        className="sortable"
                        onClick={() => handleSort('createdAt')}
                      >
                        <div className="flex alignItems-center">
                          {s('publicationDate')} {renderSort('createdAt')}
                        </div>
                      </th>
                      <th
                        style={{ color: '#4D576A' }}
                        className="sortable"
                        onClick={() => handleSort('value')}
                      >
                        <div className="flex alignItems-center">
                          {p('auctionValue')} {renderSort('value')}
                        </div>
                      </th>
                      <th
                        style={{ color: '#4D576A' }}
                        className="sortable"
                        onClick={() => handleSort('activeAuctions')}
                      >
                        <div className="flex alignItems-center">
                          {p('activeAuctions')} {renderSort('activeAuctions')}
                        </div>
                      </th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>{renderAuctionHouses()}</tbody>
                </Table>
              </div>
              <Pagination
                items={pagination.total}
                onChangePage={onChangePage}
                initialPage={pagination.currentPage}
              />
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </Container>
  );
};

export default AuctionListPage;
