import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import ChevronUpIcon from 'mdi-react/ChevronUpIcon';
import StarBoxIcon from 'mdi-react/StarBoxIcon';
// import CashRegisterIcon from 'mdi-react/CashRegisterIcon';
import { partial } from '../../../util/translation/translation';

const s = partial('shared');

const SidebarLink = ({
  title,
  icon,
  newBadge,
  route,
  onClick,
  multiple,
  premium,
  beta,
  disabled,
  collapse,
  style,
  active,
  open,
}) => (
  <NavLink
    style={{ ...style }}
    to={disabled ? '/contact-sales' : route}
    onClick={onClick}
    activeClassName="sidebar__link-active"
    exact
  >
    <li
      className={`sidebar__link ${active ? 'active' : ''} ${false && open ? 'open' : ''}`}
      style={{ opacity: disabled ? 0.3 : 1 }}
    >
      {icon === 'dot' && (
        <span style={{ ...style }} className="sidebar__link-dot">
          &#8226;
        </span>
      )}
      {!!icon && <span className={`sidebar__link-icon icon-${icon}`} />}
      <p className="sidebar__link-title">{title}</p>
      {newBadge && !collapse && (
        <Badge
          color="danger"
          style={{ marginLeft: 10, position: 'absolute', right: 20 }}
        >
          <span>{s('new')}</span>
        </Badge>
      )}
      {false && open && (
        <ChevronUpIcon
          style={{
            marginLeft: 10,
            position: 'absolute',
            right: 20,
            top: 6,
            color: '#0469DC',
          }}
        />
      )}
      {false && !open && open !== undefined && (
        <ChevronDownIcon
          style={{
            marginLeft: 10,
            position: 'absolute',
            right: 20,
            top: 6,
            color: '#C6CAD2',
          }}
        />
      )}
      {premium && !collapse && (
        <StarBoxIcon
          style={{
            marginLeft: 10,
            position: 'absolute',
            right: 20,
            top: 6,
            color: '#703FF3',
            width: 18,
          }}
        />
      )}
      {beta && !collapse && (
        <Badge
          color="secondary"
          style={{ marginLeft: 10, position: 'absolute', right: 20 }}
        >
          <span>Beta</span>
        </Badge>
      )}
      {multiple && (
        <span className="sidebar__category-icon icon-chevron-right" />
      )}
    </li>
  </NavLink>
);

SidebarLink.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  route: PropTypes.string,
  onClick: PropTypes.func,
  multiple: PropTypes.bool,
};

SidebarLink.defaultProps = {
  multiple: false,
  icon: '',
  route: '/',
  onClick: () => {},
};

export default SidebarLink;
