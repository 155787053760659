import { partial } from './translation/translation';

export const convertImageToBlob = (image) => {
  if (!image) {
    return;
  }
  const reader = new FileReader();
  const p = partial('convertImageToBlob');
  // eslint-disable-next-line consistent-return
  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(new DOMException(p('convertingImageERROR')));
    };
    reader.onloadend = async () => {
      resolve(reader.result);
    };
    if (image instanceof Blob) {
      reader.readAsDataURL(image);
    } else {
      reject(new DOMException(p('wrongImageType')));
    }
  });
};
export const blobUrlToBase64 = async (blobUrl) => {
  if (!blobUrl) {
    return null;
  }
  const response = await fetch(blobUrl);
  const blob = await response.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = () => {
      reader.abort();
      reject(new Error('Problem parsing input file.'));
    };
    reader.onloadend = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });
};
