import React, { PureComponent } from 'react';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import { partial } from '../../../util/translation/translation';
import { convertImageToBlob } from '../../../util/image-functions';

const p = partial('dropZoneShare');
class DropZoneFieldNew extends PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    customHeight: PropTypes.bool,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
        })
      ),
    ]).isRequired,
    className: PropTypes.string,
    meta: PropTypes.shape({
      touched: PropTypes.bool,
      error: PropTypes.string,
    }),
  };

  static defaultProps = {
    className: '',
    customHeight: false,
    meta: null,
  };

  constructor() {
    super();
    this.state = {};
  }

  removeFile(index, e) {
    e.preventDefault();
    this.props.onChange(this.props.value.filter((val, i) => i !== index));
  }

  async change(filesToUpload) {
    const images = await Promise.all(
      filesToUpload.map(async (file) => ({
        ...file,
        src: file.preview,
        value: await convertImageToBlob(file),
      }))
    );
    this.props.onChange(images);
  }

  render() {
    const files = this.props.value;
    const { touched, error } = this.props.meta;
    return (
      <div className="dropzone--container">
        <div
          className={`dropzone dropzone--single${this.props.customHeight ? ' dropzone--custom-height' : ''} ${this.props.className}`}
        >
          <Dropzone
            className="dropzone__input"
            accept="image/jpeg, image/png, image/webp, image/gif, video/mp4, video/mov, video/ogg, video/mpeg, video/*"
            name={this.props.name}
            multiple={false}
            onDrop={async (filesToUpload) => {
              await this.change(filesToUpload);
            }}
          >
            {(!files || files.length === 0) && (
              <div className="dropzone__drop-here">
                <span className="lnr lnr-upload" />
                {p('dropFilesToUpload')}
              </div>
            )}
          </Dropzone>
          {files && Array.isArray(files) && files.length > 0 && (
            <div className="dropzone__img">
              <img src={files[0].src} alt="drop-img" />
              <p className="dropzone__img-name">{files[0].name}</p>
              <button
                className="dropzone__img-delete"
                onClick={(e) => this.removeFile(0, e)}
              >
                {p('remove')}
              </button>
            </div>
          )}
        </div>
        {touched && error && (
          <span className="form__form-group-error">{error}</span>
        )}
      </div>
    );
  }
}

const renderDropZoneFieldNew = (props) => (
  <DropZoneFieldNew
    {...props.input}
    meta={props.meta}
    className={props.className}
    customHeight={props.customHeight}
  />
);

renderDropZoneFieldNew.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  customHeight: PropTypes.bool,
  className: PropTypes.string,
};

renderDropZoneFieldNew.defaultProps = {
  meta: null,
  customHeight: false,
  className: '',
};

export default renderDropZoneFieldNew;
