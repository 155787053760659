import DotsHorizontalIcon from 'mdi-react/DotsHorizontalIcon';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  ButtonToolbar,
  Card,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import DeleteModal from '../../shared/components/custom/DeleteModal';
import Loading from '../../shared/components/custom/Loading';
import useModal from '../../shared/hooks/useModal';
import Api from '../../util/api';
import t, { partial } from '../../util/translation/translation';

const SpotlightSegmentsPage = () => {
  const [loading, setLoading] = useState(false);
  const [segments, setSegments] = useState([]);
  const p = partial('HighlightBannerPage');
  const s = partial('shared');
  const getSpotlightSegments = async () => {
    setLoading(true);
    try {
      const resSegments = await Api.spotlight.getSegments();
      setSegments(resSegments);
      setLoading(false);
    } catch (err) {
      toast.error(err || p('errorFetchingSlider'));
      setLoading(false);
    }
  };

  const [handleModal, visible, deleteId] = useModal();
  useEffect(() => {
    getSpotlightSegments();
  }, []);

  const renderHighligts = () => {
    return segments.map((segment, i) => (
      <tr
        style={{ background: i % 2 ? '#fff' : '#F9FAFB' }}
        key={`segment-${segment.id}`}
      >
        <td>
          <div className="flex-Vcenter">
            <Link to={`users/segment/edit/${segment.id}`}>{segment.name}</Link>
          </div>
        </td>
        <td>{segment.description}</td>
        <td>
          <div className="flex-Vcenter">
            <Link to={`users/segment/edit/${segment.id}?tab=2`}>
              {segment.users.length}
            </Link>
          </div>
        </td>
        <td>
          <UncontrolledDropdown className="dashboard__table-more">
            <DropdownToggle>
              <DotsHorizontalIcon />
            </DropdownToggle>
            <DropdownMenu className="dropdown__menu">
              <Link to={`/users/segment/edit/${segment.id}`}>
                <DropdownItem>{t('shared.edit')}</DropdownItem>
              </Link>
              <DropdownItem
                onClick={() => handleModal(segment.id)}
                className="danger"
              >
                {t('shared.delete')}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </td>
      </tr>
    ));
  };

  const deleteSegment = async () => {
    setLoading(true);
    try {
      await Api.spotlight.deleteSegment(deleteId);
      handleModal();
      toast.success(p('segmentDeleted'));
      getSpotlightSegments();
    } catch (err) {
      setLoading(false);
      toast.error(err);
    }
  };
  return (
    <Card>
      <DeleteModal
        visible={visible}
        handleModal={handleModal}
        type={p('segments')}
        modalAction={deleteSegment}
      />
      <Row>
        <Col md="3">
          <h3 className="page-title">{p('segments')}</h3>
        </Col>
        <Col md="9">
          <ButtonToolbar className="margin-top-0 float-right">
            <Link className="btn btn-primary btn-sm" to="/users/segment/create">
              {p('createSegment')}
            </Link>
          </ButtonToolbar>
        </Col>
      </Row>
      <Row>
        <Col>
          <Loading loading={loading} />
          <div
            style={{
              display: 'flex',
              paddingBottom: 5,
              background: '#fff',
              borderRadius: 6,
              boxShadow: '0px 2px 8px 0px rgba(0,0,0,0.08)',
            }}
          >
            <Table responsive striped>
              <thead>
                <tr style={{ color: '#4D576A' }}>
                  <th className="bold">
                    <div className="flex alignItems-center">{s('title')}</div>
                  </th>
                  <th className="bold" style={{ color: '#4D576A' }}>
                    {p('description')}
                  </th>
                  <th className="bold" style={{ color: '#4D576A' }}>
                    {s('users')}
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>{renderHighligts()}</tbody>
            </Table>
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default SpotlightSegmentsPage;
