import React from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { Button, Spinner } from 'reactstrap';
import validateLogin from './validateLogin';
import t, { partial } from '../../../util/translation/translation';
import renderCountryPhone from './CountryPhone';

const p = partial('LogInForm');
const LogInForm = ({ handleSubmit, loading, goToEmail }) => {
  const formName = p('smsLogin');
  const EmailLogin = () => {
    return (
      <div
        className="form__form-group-field marginBottom-10 pointerButton"
        role="button"
        tabIndex={0}
        onClick={goToEmail}
      >
        <p>{p('emailLogin')}</p>
      </div>
    );
  };
  return (
    <form className="form" onSubmit={handleSubmit}>
      <div className="form__form-group">
        <span className="form__form-group-label">{formName}</span>
        <div className="form__form-group-field">
          <Field
            name="username"
            component={renderCountryPhone}
            placeholder={t('shared.username')}
            autoComplete="username"
          />
        </div>
      </div>
      <EmailLogin />
      <Button color="primary" className="width-100p" disabled={loading}>
        {loading ? <Spinner size="sm" /> : p('logIn')}
      </Button>
    </form>
  );
};

LogInForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const reduxF = reduxForm({
  form: 'log_in_form',
  validateLogin,
});

export default reduxF(LogInForm);
