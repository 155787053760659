import React from 'react';
import { Field, reduxForm, propTypes as reduxFormProps } from 'redux-form';
import { Col, Row, Container, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import attendanceValidate from './attendanceValidate';
import renderField from '../../../shared/components/custom/Field';
import { partial } from '../../../util/translation/translation';

const AttendanceForm = ({ handleSubmit, exportAttendance }) => {
  const s = partial('shared');
  const p = partial('AttendanceForm');
  return (
    <form
      onSubmit={handleSubmit}
      className="form"
      style={{ marginLeft: '-15px', marginRight: '-15px' }}
    >
      <Container>
        <Row>
          <Col md={6}>
            <div className="form__form-group">
              <span className="form-header">{s('phonenumber')}</span>
              <Field
                name="phoneNumber"
                placeholder={s('phonenumber')}
                component={renderField}
              />
            </div>
          </Col>
          <Col md={3}>
            <div className="form__form-group">
              <span className="form-header">{s('firstname')}</span>
              <Field
                name="firstname"
                placeholder={s('firstname')}
                component={renderField}
              />
            </div>
          </Col>
          <Col md={3}>
            <div className="form__form-group">
              <span className="form-header">{s('lastname')}</span>
              <Field
                name="lastname"
                placeholder={s('lastname')}
                component={renderField}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="marginBottom-10 flex space-between">
            <Button color="primary">{p('registerAttendance')}</Button>
            <Button onClick={exportAttendance} color="secondary">
              {s('export')} (CSV)
            </Button>
          </Col>
        </Row>
      </Container>
    </form>
  );
};

AttendanceForm.propTypes = {
  ...reduxFormProps,
  handleSubmit: PropTypes.func.isRequired,
  Attendance: PropTypes.shape({}),
  exportAttendance: PropTypes.func.isRequired,
};

AttendanceForm.defaultProps = {
  Attendance: null,
};

const reduxF = reduxForm({
  form: 'AttendanceForm',
  validate: attendanceValidate,
});

export default reduxF(AttendanceForm);
