import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import PropTypes, { any } from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import RenderFileInputField from '../../../shared/components/form/FileInput';
import { partial } from '../../../util/translation/translation';

const StadiumImportForm = (props) => {
  const p = partial('settingsObjectsShared');
  const s = partial('shared');

  const { handleSubmit, importDetails } = props;
  return (
    <Row>
      <Col md={6}>
        <form className="form" onSubmit={handleSubmit}>
          <h2 className="page-title">{p('studioImport')}</h2>
          <div className="form__form-group">
            <span className="form__form-group-label">{p('csvImport')}</span>
            <div className="form__form-group-field">
              <Field name="stadium" component={RenderFileInputField} />
            </div>
          </div>
          <Button type="submit" color="primary">
            {s('upload')}
          </Button>
        </form>
      </Col>
      <Col md={6}>
        <h2 className="page-title">{p('studioImport')}</h2>
        <h4>{s('imported')}</h4>
        <p>
          {s('fields')}: {importDetails.fields} {s('rows')}:{' '}
          {importDetails.rows} {s('seats')}: {importDetails.seats}
        </p>
        <h4>{p('newInThisImport')}</h4>
        <p>
          {s('fields')}: {importDetails.newFields.length} {s('rows')}:{' '}
          {importDetails.newRows.length} {s('seats')}:{' '}
          {importDetails.newSeats.length}
        </p>
      </Col>
    </Row>
  );
};

StadiumImportForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  importDetails: PropTypes.PropTypes.shape({
    fields: PropTypes.number.isRequired,
    rows: PropTypes.number.isRequired,
    seats: PropTypes.number.isRequired,
    newFields: PropTypes.arrayOf(any).isRequired,
    newRows: PropTypes.arrayOf(any).isRequired,
    newSeats: PropTypes.arrayOf(any).isRequired,
  }).isRequired,
};

export default reduxForm({
  form: 'stadium_import_form',
})(StadiumImportForm);
