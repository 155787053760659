import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { Field, reduxForm, change } from 'redux-form';
import moment from 'moment';
import { connect } from 'react-redux';
import { useParams, useLocation } from 'react-router';
import renderField from '../../../shared/components/custom/Field';
import renderRadioButtonField from '../../../shared/components/form/RadioButton';
import renderDropZoneField from '../../../shared/components/form/DropZoneMS';
import renderSelectField from '../../../shared/components/form/Select';
import FormBox from '../../../shared/components/custom/FormBox';
import t, { partial } from '../../../util/translation/translation';
import useConfig from '../../../util/useConfig';
import Api from '../../../util/api';
import offerValidations from '../validation';
import { OfferContext } from '../context';
import OfferTypes from '../types';
import TooltipWithIcon from '../../../shared/components/custom/TooltipWithIcon';
import AlertModal, {
  AlertModalType,
} from '../../../shared/components/custom/AlertModal';
import renderCheckBoxField from '../../../shared/components/form/CheckBox';
import TextEditor2 from '../../../shared/components/text-editor/TextEditor2';

const OfferForm = (props) => {
  const {
    handleSubmit,
    levels,
    groups,
    companies,
    tiers,
    initialize,
    scheduledPendingMessagesForEvent,
  } = props;
  const { TargetAudience, Tiers } = OfferTypes;
  const { fetchSingleOffer, fetchContactsFromCompany } = Api.offers;
  const { id } = useConfig();
  const s = partial('shared');
  const o = partial('OfferForm');
  const f = partial('OffersFormPage');
  const oc = partial('OfferCard');
  const [offerType, setOfferType] = useState('2');
  const [selectedCompany, setSelectedCompany] = useState('');
  const [contacts, setContacts] = useState([]);
  const [priceType, setPriceType] = useState('');
  const [promoCodeType, setPromoCodeType] = useState('none');
  const [isActivationLimited, setIsActivationLimited] = useState('unlimited');
  const [targetAudience, setTargetAudience] = useState(TargetAudience.partner);
  const [selectedLevels, setSelectedLevels] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [selectedTiers, setSelectedTiers] = useState([]);
  const params = useParams();
  const location = useLocation();
  const { categories, activeOffer, setActiveOffer } = useContext(OfferContext);
  const [validationErrors, setValidationErrors] = useState({});
  const [showValidationErrorModal, setShowValidationErrorModal] =
    useState(false);
  const [validationErrorMessage, setShowValidationErrorMessage] = useState('');

  const getFullName = (firstName, lastName) => `${firstName} ${lastName}`;

  const handleAppendAllCategory = (typeName, typeId, translationKey) => {
    if (typeId === null) {
      return { label: o(`${translationKey}`), value: null };
    } else if (typeName === 'All users') {
      return { label: o(`${'allUsers'}`), value: 'all' };
    } else if (typeId === 'all') {
      return { label: o(`${'allTiers'}`), value: 'all' };
    } else {
      return { label: typeName, value: typeId, id: typeId };
    }
  };

  const fromatCategory = (data) => {
    if (data.includes('&')) {
      return data
        .split('&')
        .map((word) => word.trim().toLowerCase())
        .join()
        .replace(',', '_');
    }
    return data.toLowerCase().replace(' ', '_');
  };

  useEffect(() => {
    if (params.offerID) {
      const clone = window.location.pathname.split('/')[3] === 'clone';
      fetchSingleOffer(params.offerID, id).then((offerResponse) => {
        const offer = offerResponse.data;
        setActiveOffer(offer);
        if (offer.companyId) {
          setOfferType('1');
        } else {
          setOfferType('2');
        }
        if (offer.price === 0) {
          setPriceType('3');
        } else if (offer.discountedPrice) {
          setPriceType('1');
        } else {
          setPriceType('2');
        }
        setTargetAudience(offer.targetAudience);
        if (offer && offer.companyId) {
          fetchContactsFromCompany(offer.companyId).then((contactResponse) => {
            setContacts(
              contactResponse.data.map((contact) => ({
                id: contact.id,
                label: getFullName(contact.firstName, contact.lastName),
                value: contact.id,
                name: getFullName(contact.firstName, contact.lastName),
              }))
            );
          });
        }
        setPromoCodeType(offer.promoType ?? 'none');
        setIsActivationLimited(
          offer?.activationLimit ? 'limited' : 'unlimited'
        );

        initialize({
          title: offer.title,
          subtitle: offer.subtitle,
          description: offer.description,
          category: {
            label: oc(fromatCategory(offer.category)),
            value: offer.category,
          },
          // eslint-disable-next-line no-nested-ternary
          price: offer.price === 0 ? '3' : offer.discountedPrice ? '1' : '2',
          promoType: offer.promoType ?? 'none',
          ordinaryPrice: offer.price,
          discountedPrice: offer.discountedPrice,
          discountedPercentage: offer.discountedPercentage,
          startDate: moment(offer.startDate).format('YYYY-MM-DDTHH:mm'),
          endDate: moment(offer.endDate).format('YYYY-MM-DDTHH:mm'),
          level:
            offer.levels?.length > 0
              ? offer.levels.map((level) =>
                  handleAppendAllCategory(
                    level.name,
                    level.levelId,
                    'allLevels'
                  )
                )
              : [],
          group:
            offer.groups?.length > 0
              ? offer.groups.map((group) =>
                  handleAppendAllCategory(
                    group.name,
                    group.groupId,
                    'allGroups'
                  )
                )
              : [],
          tier:
            offer.tiers?.length > 0
              ? offer.tiers.map((tier) =>
                  handleAppendAllCategory(tier.name, tier.tierId, 'allUsers')
                )
              : [],
          offerType: offer.companyId ? '1' : '2',
          imageUrl:
            offer?.imageUrl && !clone ? { preview: offer?.imageUrl } : null,
          isPinned: offer?.isPinned ?? false,
          promoCode: offer.promoType === 'code' ? offer.promoCode : null,
          promoImageUrl:
            offer.promoType === 'imageurl'
              ? { preview: offer.promoCode }
              : null,
          promoLink: offer.promoType === 'link' ? offer.websiteUrl : null,
          promoQrString:
            offer.promoType === 'qrstring' ? offer.promoCode : null,
          isActivationLimited: offer?.activationLimit ? 'limited' : 'unlimited',
          activationLimit: offer?.activationLimit || null,
          company: offer.companyId,
          targetAudience:
            offer.targetAudience === 'both' ? 'all' : offer.targetAudience,
          isVisible: offer.isVisible,
          // shouldNotify: offer.shouldNotify,
          contactPerson: offer.companyContactPersonId,
        });
      });
    } else {
      setActiveOffer(null);
    }
  }, [params]);

  useEffect(() => {
    props.setCurrentOffer(activeOffer);
  }, [activeOffer]);

  useEffect(() => {
    if (selectedCompany) {
      fetchContactsFromCompany(selectedCompany.id).then((response) => {
        setContacts(
          response.data.map((contact) => ({
            id: contact.id,
            label: getFullName(contact.firstName, contact.lastName),
            value: contact.id,
            name: getFullName(contact.firstName, contact.lastName),
          }))
        );
      });
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (offerType === '2') {
      props.change('offers_form', 'company', null);
      props.change('offers_form', 'contactPerson', null);
    }
  }, [offerType]);

  useEffect(() => {
    let currentSelectedLevels = [...selectedLevels];
    if (currentSelectedLevels.length > 1) {
      const index = currentSelectedLevels.findIndex(
        (level) => level.value === null
      );
      if (index !== -1 && index === 0) {
        currentSelectedLevels.splice(index, 1);
      }
      if (index !== -1 && index !== 0) {
        currentSelectedLevels = currentSelectedLevels.filter(
          (level) => level.value === null
        );
      }
      props.change('offers_form', 'level', currentSelectedLevels);
    }
  }, [selectedLevels]);

  useEffect(() => {
    let currentSelectedGroups = [...selectedGroups];
    if (currentSelectedGroups.length > 1) {
      const index = currentSelectedGroups.findIndex(
        (group) => group.value === null
      );
      if (index !== -1 && index === 0) {
        currentSelectedGroups.splice(index, 1);
      }
      if (index !== -1 && index !== 0) {
        currentSelectedGroups = currentSelectedGroups.filter(
          (group) => group.value === null
        );
      }
      props.change('offers_form', 'group', currentSelectedGroups);
    }
  }, [selectedGroups]);

  useEffect(() => {
    let currentSelectedTiers = [...selectedTiers];
    if (currentSelectedTiers.length > 1) {
      const allUsersIndex = currentSelectedTiers.findIndex(
        (tier) => tier.value === null
      );
      if (allUsersIndex !== -1 && allUsersIndex === 0) {
        currentSelectedTiers.splice(allUsersIndex, 1);
      }
      if (allUsersIndex !== -1 && allUsersIndex !== 0) {
        currentSelectedTiers = currentSelectedTiers.filter(
          (tier) => tier.value === null
        );
      }
      const allTiersIndex = currentSelectedTiers.findIndex(
        (tier) => tier.value === Tiers.allTiers
      );
      if (allTiersIndex !== -1 && allTiersIndex === 0) {
        currentSelectedTiers.splice(allTiersIndex, 1);
      }
      if (allTiersIndex !== -1 && allTiersIndex !== 0) {
        currentSelectedTiers = currentSelectedTiers.filter(
          (tier) => tier.value === Tiers.allTiers
        );
      }
      if (allUsersIndex !== -1 && allTiersIndex !== -1) {
        currentSelectedTiers.splice(allUsersIndex, 1);
        currentSelectedTiers = tiers.filter(
          (tiler) => tiler.value === Tiers.allTiers
        );
      }
      props.change('offers_form', 'tier', currentSelectedTiers);
    }
  }, [selectedTiers]);

  useEffect(() => {
    if (priceType === '3') {
      props.change('offers_form', 'ordinaryPrice', null);
      props.change('offers_form', 'discountedPrice', null);
      props.change('offers_form', 'discountedPercentage', null);
    } else if (priceType === '2') {
      props.change('offers_form', 'discountedPrice', null);
    } else {
      props.change('offers_form', 'discountedPercentage', null);
    }
  }, [priceType]);

  const renderPriceType = (
    <>
      <div className="flex">
        <Col>
          <div className="form__form-group">
            <span className="form-header">{o('ordinaryPrice')}</span>
            <Field
              name="ordinaryPrice"
              normalize={(e) => e.replace(/[^0-9]/g, '')}
              placeholder={o('ordinaryPrice')}
              component={renderField}
            />
          </div>
        </Col>
        {priceType === '1' && (
          <Col>
            <div className="form__form-group">
              <span className="form-header">{o('discountedPrice')}</span>
              <Field
                name="discountedPrice"
                normalize={(e) => e.replace(/[^0-9]/g, '')}
                placeholder={o('discountedPrice')}
                component={renderField}
              />
            </div>
          </Col>
        )}
        {priceType === '2' && (
          <Col>
            <div className="form__form-group">
              <span className="form-header">{o('discountedPercentage')}</span>
              <Field
                name="discountedPercentage"
                normalize={(e) => e.replace(/[^0-9]/g, '')}
                placeholder={o('discountedPercentage')}
                component={renderField}
              />
            </div>
          </Col>
        )}
      </div>
    </>
  );

  useMemo(() => {
    setValidationErrors(props.errors.offers_form?.syncErrors);
  }, [props.errors.offers_form?.syncErrors]);

  const renderCompanyDetails = (
    <>
      <Col>
        <div className="form__form-group">
          <span className="form-header">{o('company')}</span>
          <Field
            name="company"
            placeholder={o('selectCompany')}
            options={companies}
            component={renderSelectField}
            onChange={(e) => setSelectedCompany(e)}
          />
        </div>
      </Col>
      <Col>
        <div className="form__form-group">
          <span className="form-header">{o('responsibleContactPerson')}</span>
          <Field
            name="contactPerson"
            placeholder={o('choosePerson')}
            options={contacts}
            component={renderSelectField}
          />
        </div>
      </Col>
    </>
  );

  const handleValidationModal = () => {
    if (!validationErrors) return;
    const errors = Object.values(validationErrors);
    if (errors.length > 0) {
      setShowValidationErrorModal(true);
      setShowValidationErrorMessage(errors[0]);
    }
  };

  const RenderToolTip = () => {
    return (
      <TooltipWithIcon
        text={`${t('ScheduledMessagesPage.mainTooltipInfo1/2')} ${scheduledPendingMessagesForEvent} ${t('ScheduledMessagesPage.mainTooltipInfo2/2')}`}
        id="scheduleMsgTooltipOffer"
      />
    );
  };

  const getPromoComponent = (promoType) => {
    switch (promoType) {
      case 'code':
        return (
          <Field
            name="promoCode"
            placeholder={`${o('promoCode')}*`}
            component={renderField}
          />
        );

      case 'imageurl':
        return (
          <Field
            name="promoImageUrl"
            className="dropzone"
            maxSize={1}
            component={renderDropZoneField}
            noCrop
          />
        );

      case 'qrstring':
        return (
          <Field
            name="promoQrString"
            placeholder={`${o('qrCode')}*`}
            component={renderField}
          />
        );

      case 'link':
        return (
          <Field
            name="promoLink"
            placeholder={`${s('link')}*`}
            component={renderField}
          />
        );

      default:
        return null;
    }
  };

  return (
    <form onSubmit={handleSubmit} className="form">
      <AlertModal
        visible={showValidationErrorModal}
        modalType={AlertModalType.ERROR}
        handleModal={() => setShowValidationErrorModal(false)}
        message={validationErrorMessage}
      />
      <Container className="padding-0">
        <Row>
          <div className="col-12 col-lg-7 mb-3">
            <Col style={{ marginBottom: 20 }}>
              <FormBox title={s('details')}>
                <Col>
                  <div className="form__form-group">
                    <span className="form-header">{o('NameOnTheOffer')}*</span>
                    <Field
                      name="title"
                      placeholder={o('descriptiveNameOfOffer')}
                      component={renderField}
                    />
                  </div>
                </Col>
                <Col>
                  <div className="form__form-group">
                    <span className="form-header">{o('subtitle')}</span>
                    <Field
                      name="subtitle"
                      placeholder={o('subtitleOfOffer')}
                      component={renderField}
                    />
                  </div>
                </Col>
                <Col>
                  <div className="form__form-group">
                    <span className="form-header">
                      {o('offerDescription')}*
                    </span>
                    <Field
                      header={[2, 3, false]}
                      name="description"
                      placeholder={o('descriptionText')}
                      component={TextEditor2}
                    />
                  </div>
                </Col>
                <Col>
                  <div className="form__form-group">
                    <span className="form-header">{o('category')}*</span>
                    <Field
                      name="category"
                      placeholder={o('chooseCategoryForTheOffer')}
                      options={categories.filter(
                        (category) => category.key !== 'all_categories'
                      )}
                      component={renderSelectField}
                    />
                  </div>
                </Col>
              </FormBox>
            </Col>
            <Col style={{ marginBottom: 20 }}>
              <FormBox title={s('discounts')}>
                <Col>
                  <div className="flex">
                    <span className="form mr-1">
                      {o('priceinfo')}
                      {priceType !== '3' && (
                        <TooltipWithIcon
                          text={o('priceinfoTooltip')}
                          id="priceinfoTooltip"
                        />
                      )}
                    </span>
                  </div>
                  <div className="flex flex-column flex-xl-row">
                    <div className="form__form-group margin-0">
                      <div className="form__form-group-field">
                        <Field
                          name="price"
                          label={o('priceDiscount')}
                          component={renderRadioButtonField}
                          radioValue="1"
                          defaultChecked
                          onChange={(e) => setPriceType(e)}
                        />
                      </div>
                    </div>
                    <div className="form__form-group margin-0">
                      <div className="form__form-group-field">
                        <Field
                          name="price"
                          component={renderRadioButtonField}
                          label={o('percentageDiscount')}
                          radioValue="2"
                          onChange={(e) => setPriceType(e)}
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <Field
                          name="price"
                          component={renderRadioButtonField}
                          label={o('showNoPrice')}
                          radioValue="3"
                          onChange={(e) => setPriceType(e)}
                        />
                      </div>
                    </div>
                  </div>
                </Col>
                {priceType !== '3' && renderPriceType}
              </FormBox>
            </Col>
            <Col style={{ marginBottom: 20 }}>
              <FormBox title={s('date')}>
                <div className="flex flex-column flex-xl-row">
                  <Col>
                    <div className="form__form-group">
                      <span className="form-header">
                        {s('startDate')}{' '}
                        {scheduledPendingMessagesForEvent ? (
                          <RenderToolTip />
                        ) : (
                          <></>
                        )}
                      </span>
                      <Field
                        name="startDate"
                        placeholder={s('startDate')}
                        type="datetime-local"
                        component={renderField}
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="form__form-group">
                      <span className="form-header">
                        {s('endDate')}{' '}
                        {scheduledPendingMessagesForEvent ? (
                          <RenderToolTip />
                        ) : (
                          <></>
                        )}
                      </span>
                      <Field
                        name="endDate"
                        placeholder={s('endDate')}
                        type="datetime-local"
                        component={renderField}
                      />
                    </div>
                  </Col>
                </div>
              </FormBox>
            </Col>
            <Col style={{ marginBottom: 20 }}>
              <FormBox title={s('access')}>
                <Col>
                  <div className="flex">
                    <span className="form mr-1">{o('targetAudience')}</span>
                  </div>
                  <div className="flex">
                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <Field
                          name="targetAudience"
                          label={o('partner')}
                          component={renderRadioButtonField}
                          radioValue={TargetAudience.partner}
                          defaultChecked
                          onChange={(e) => setTargetAudience(e)}
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <Field
                          name="targetAudience"
                          component={renderRadioButtonField}
                          label={o('private')}
                          radioValue={TargetAudience.private}
                          onChange={(e) => setTargetAudience(e)}
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <Field
                          name="targetAudience"
                          component={renderRadioButtonField}
                          label={s('all')}
                          radioValue={TargetAudience.all}
                          onChange={(e) => setTargetAudience(e)}
                        />
                      </div>
                    </div>
                  </div>
                </Col>
                {targetAudience === TargetAudience.partner && (
                  <>
                    <Col>
                      <div
                        className="form__form-group"
                        style={{ marginTop: '-.5rem' }}
                      >
                        <span className="form-header">
                          {o('partnerlevelList')}
                        </span>
                        <Field
                          name="level"
                          placeholder={o('selectLevels')}
                          options={levels}
                          onChange={(e) => setSelectedLevels([...e])}
                          component={renderSelectField}
                          multiple
                        />
                      </div>
                    </Col>
                    <Col>
                      <div className="form__form-group">
                        <span className="form-header">
                          {o('partnergroupList')}
                        </span>
                        <Field
                          name="group"
                          placeholder={o('selectGroup')}
                          options={groups}
                          onChange={(e) => setSelectedGroups([...e])}
                          component={renderSelectField}
                          multiple
                        />
                      </div>
                    </Col>
                  </>
                )}
                {targetAudience === TargetAudience.private && (
                  <Col>
                    <div className="form__form-group">
                      <span className="form-header">{o('tierList')}</span>
                      <Field
                        name="tier"
                        placeholder={o('selectTier')}
                        options={tiers}
                        onChange={(e) => setSelectedTiers([...e])}
                        component={renderSelectField}
                        multiple
                      />
                    </div>
                  </Col>
                )}
                {targetAudience !== TargetAudience.all && (
                  <Col>
                    <div className="flex">
                      <span className="form">{o('visibility')}</span>
                    </div>
                    <div className="flex">
                      <div className="form__form-group">
                        <div className="form__form-group-field">
                          <Field
                            name="isVisible"
                            label={s('all')}
                            component={renderRadioButtonField}
                            radioValue
                          />
                        </div>
                      </div>
                      <div className="form__form-group">
                        <div className="form__form-group-field">
                          <Field
                            name="isVisible"
                            component={renderRadioButtonField}
                            label={o('targetedAudience')}
                            defaultChecked
                            radioValue={false}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                )}
              </FormBox>
            </Col>
            <Col style={{ marginBottom: 20 }}>
              <FormBox title={s('sender')}>
                <Col>
                  <div className="flex">
                    <span className="form">{o('offerOnBehalfOf')}</span>
                  </div>
                  <div className="flex">
                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <Field
                          name="offerType"
                          label={o('company')}
                          component={renderRadioButtonField}
                          radioValue="1"
                          defaultChecked
                          onChange={(e) => setOfferType(e)}
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <Field
                          name="offerType"
                          component={renderRadioButtonField}
                          label={s('club')}
                          radioValue="2"
                          onChange={(e) => setOfferType(e)}
                        />
                      </div>
                    </div>
                  </div>
                </Col>
                {offerType === '1' && renderCompanyDetails}
              </FormBox>
            </Col>
            {location.pathname === '/offers/create' && (
              <Col style={{ marginBottom: 20 }}>
                <FormBox title={s('notice')}>
                  <Col>
                    <div className="flex">
                      <span className="form">{o('notifyUsers')}</span>
                      <TooltipWithIcon
                        text={o('shouldNotifyTooltip')}
                        id="shouldNotifyTooltip"
                      />
                    </div>
                    <div className="flex">
                      <div className="form__form-group">
                        <div className="form__form-group-field">
                          <Field
                            name="shouldNotify"
                            label={s('yes')}
                            component={renderRadioButtonField}
                            radioValue
                          />
                        </div>
                      </div>
                      <div className="form__form-group">
                        <div className="form__form-group-field">
                          <Field
                            name="shouldNotify"
                            component={renderRadioButtonField}
                            label={s('no')}
                            defaultChecked
                            radioValue={false}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                </FormBox>
              </Col>
            )}
          </div>
          <div className="col-12 col-lg-5">
            <Col style={{ marginBottom: 20 }}>
              <FormBox title={s('uploadImage')}>
                <Col>
                  <Field
                    name="imageUrl"
                    className="dropzone"
                    maxSize={3}
                    component={renderDropZoneField}
                    crop="BANNER_CROP"
                  />
                  <span className="form-header">
                    <strong>{s('note')}:</strong> {s('recommendedImageSize')}{' '}
                    16:9 (1035 x 570 px)
                  </span>
                </Col>
              </FormBox>
            </Col>
            <Col className="mt-4">
              <div className="form__form-group">
                <Field
                  name="isPinned"
                  component={renderCheckBoxField}
                  label={f('pinOffer')}
                  className="colored-click"
                />
              </div>
              <div className="flex">
                <span className="form-header">{o('promoType')}</span>
              </div>
              <div className="flex flex-column">
                <div className="form__form-group margin-0">
                  <div className="form__form-group-field">
                    <Field
                      name="promoType"
                      label={o('none')}
                      component={renderRadioButtonField}
                      radioValue="none"
                      onChange={(e) => setPromoCodeType(e)}
                      defaultChecked
                    />
                  </div>
                </div>
                <div className="form__form-group margin-0">
                  <div className="form__form-group-field">
                    <Field
                      name="promoType"
                      label={o('promoCode')}
                      component={renderRadioButtonField}
                      radioValue="code"
                      onChange={(e) => setPromoCodeType(e)}
                      defaultChecked
                    />
                  </div>
                </div>
                <div className="form__form-group margin-0">
                  <div className="form__form-group-field">
                    <Field
                      name="promoType"
                      label={o('qrCode')}
                      component={renderRadioButtonField}
                      radioValue="qrstring"
                      onChange={(e) => setPromoCodeType(e)}
                      defaultChecked
                    />
                  </div>
                </div>
                <div className="form__form-group margin-0">
                  <div className="form__form-group-field">
                    <Field
                      name="promoType"
                      label={o('image')}
                      component={renderRadioButtonField}
                      radioValue="imageurl"
                      onChange={(e) => setPromoCodeType(e)}
                    />
                  </div>
                </div>
                <div className="form__form-group margin-0">
                  <div className="form__form-group-field">
                    <Field
                      name="promoType"
                      label={s('link')}
                      component={renderRadioButtonField}
                      radioValue="link"
                      onChange={(e) => setPromoCodeType(e)}
                      defaultChecked
                    />
                  </div>
                </div>
              </div>
              {getPromoComponent(promoCodeType)}
              {promoCodeType !== 'none' && promoCodeType !== 'link' ? (
                <>
                  <div className="flex marginTop-20">
                    <span className="form-header">{o('activationLimit')}</span>
                  </div>
                  <div className="flex flex-column flex-xl-row">
                    <div className="form__form-group margin-0">
                      <div className="form__form-group-field">
                        <Field
                          name="isActivationLimited"
                          label={o('unlimited')}
                          component={renderRadioButtonField}
                          radioValue="unlimited"
                          onChange={(e) => setIsActivationLimited(e)}
                          defaultChecked
                        />
                      </div>
                    </div>
                    <div className="form__form-group margin-0">
                      <div className="form__form-group-field">
                        <Field
                          name="isActivationLimited"
                          label={o('limited')}
                          component={renderRadioButtonField}
                          radioValue="limited"
                          onChange={(e) => setIsActivationLimited(e)}
                          defaultChecked
                        />
                      </div>
                    </div>
                  </div>
                  {isActivationLimited === 'limited' ? (
                    <Field
                      name="activationLimit"
                      placeholder={o('limit')}
                      component={renderField}
                      type="number"
                    />
                  ) : null}
                </>
              ) : null}
            </Col>
          </div>
        </Row>
        <Col>
          <Button onClick={handleValidationModal} type="submit" color="primary">
            {params.offerID ? s('update') : s('save')}
          </Button>
        </Col>
      </Container>
    </form>
  );
};

const mapStateToProps = (state) => ({
  errors: state.form,
});

export default reduxForm({
  form: 'offers_form',
  validate: offerValidations,
})(connect(mapStateToProps, { change })(OfferForm));
