import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import { partial } from '../../../util/translation/translation';
import { Chart, ArcElement, Legend, Tooltip } from 'chart.js';

Chart.register(ArcElement, Legend, Tooltip);
const s = partial('shared');

const CustomDoughnut = ({ graphData, title }) => {
  const legendOptions = {
    position: 'left',
    labels: {
      padding: 3,
      boxWidth: 12,
    },
  };
  const plugins = [
    {
      beforeDraw(chart) {
        const { ctx, width, height } = chart;
        ctx.restore();
        ctx.height = 200;
        const legendWidth = chart.legend.width;
        const pickedData = chart.config.data.datasets[0]?.data || [];
        const data = pickedData
          .map((attendance, index) => ({
            attendance,
            label: chart.config.data.labels[index],
          }))
          .filter(
            (attendance, index) => !chart.legend.legendItems[index].hidden
          );
        const total = data.reduce(
          (acc, attendance) => acc + attendance.attendance,
          0
        );

        ctx.fillStyle = '#646777';
        ctx.textBaseline = 'middle';
        ctx.textAlign = 'middle';

        if (total === undefined || total === null) {
          return;
        }
        if (total.length > 8 && total.length <= 10) {
          ctx.font = '27px roboto';
        } else if (total.length > 10) {
          ctx.font = '32px roboto';
        } else {
          ctx.font = '42px roboto';
        }
        if (chart.config.data.textSize) {
          ctx.font = chart.config.data.textSize;
        }
        const totalTextX = Math.round(
          (width + legendWidth - ctx.measureText(total).width) / 2
        );
        const centerY = (() => {
          const { display, text } = chart.config.options.title;
          if (display) {
            const { actualBoundingBoxAscent, actualBoundingBoxDescent } =
              ctx.measureText(text);
            const actualHeight =
              actualBoundingBoxAscent + actualBoundingBoxDescent;
            return (height + actualHeight) / 2;
          }
          return height / 2;
        })();
        ctx.fillText(total, totalTextX, centerY);
        ctx.font = '13px roboto';
        ctx.fillStyle = '#646777';
        const totalStringX = Math.round(
          (width + legendWidth - ctx.measureText(`${s('total')}`).width) / 2
        );
        ctx.fillText(`${s('total')}`, totalStringX, centerY + 25);
        ctx.save();
      },
    },
  ];

  return (
    <Doughnut
      width={500}
      height={250}
      data={graphData}
      plugins={plugins}
      legend={legendOptions}
      options={{
        maintainAspectRatio: true,
        responsive: false,
        cutoutPercentage: '70',
        title,
      }}
    />
  );
};

CustomDoughnut.defaultProps = {
  title: {},
};
CustomDoughnut.propTypes = {
  graphData: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.string),
    datasets: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        data: PropTypes.arrayOf(PropTypes.number),
        backgroundColor: PropTypes.arrayOf(PropTypes.string),
      })
    ),
  }).isRequired,
  title: PropTypes.shape({
    display: PropTypes.bool,
    text: PropTypes.string,
    fontColor: PropTypes.string,
  }),
};
export default CustomDoughnut;
