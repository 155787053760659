import React, { useEffect, useState, useMemo } from 'react';
import { Field, reduxForm, propTypes as reduxFormProps } from 'redux-form';
import { Button, Row, Col, Alert, ListGroup, Input, Table } from 'reactstrap';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import ArrowUpIcon from 'mdi-react/ArrowUpIcon';
import ArrowDownIcon from 'mdi-react/ArrowDownIcon';
import validateCategoryForm from './validateCategoryForm';
import renderField from '../../../shared/components/custom/Field';
import { partial } from '../../../util/translation/translation';
import Api from '../../../util/api';

const s = partial('shared');

const GroupForm = ({
  handleSubmit,
  settingState,
  modalType,
  initialize,
  type,
  updateSelectedCompanies,
  handleModalTypeChangeToEdit,
}) => {
  const [allCompanies, setAllCompanies] = useState([]);
  const [isEditGroup, setIsEditGroup] = useState(modalType === 'edit');
  const [isViewGroup, setIsViewGroup] = useState(modalType === 'view');
  const [sort, setSort] = useState({
    field: 'name',
    direction: false,
  });
  const sortComps = (companies, direction, field) => {
    const sortDir = direction ? -1 : 1;
    const companiesToSort = [...companies];
    switch (field) {
      case 'name':
        return companiesToSort.sort(
          (a, b) => sortDir * a.name.trim().localeCompare(b.name.trim())
        );
      case 'isSelected':
        return companiesToSort.sort(
          (a, b) => sortDir * (a.isSelected - b.isSelected)
        );
      default:
        return companiesToSort;
    }
  };
  const getCompanies = async () => {
    try {
      const resCompanies = await Api.companies.getCompaniesForClub();
      const resSelectedCompanies = await Api.companies.getCompaniesForGroup(
        settingState.value
      );
      const resSelectedCompaniesIds = resSelectedCompanies.map(
        (company) => company.id
      );
      updateSelectedCompanies(resSelectedCompanies);
      const sortedCompanies = sortComps(resCompanies, false, 'name');

      setAllCompanies(
        sortedCompanies.map((company) => ({
          ...company,
          isVisible: true,
          isSelected: resSelectedCompaniesIds.includes(company.id),
        }))
      );
    } catch (err) {
      toast.error(err || `${s('400')}`);
    }
  };

  const getCompaniesForGroup = async () => {
    try {
      const resSelectedCompanies = await Api.companies.getCompaniesForGroup(
        settingState.value
      );
      setAllCompanies(
        resSelectedCompanies.map((company) => ({
          ...company,
          isVisible: true,
          isSelected: true,
        }))
      );
    } catch (err) {
      toast.error(err || `${s('400')}`);
    }
  };

  const searchCompanies = (searchText) => {
    setAllCompanies(
      sortComps(
        allCompanies.map((company) => ({
          ...company,
          isVisible: company.name
            .toLowerCase()
            .includes(searchText.toLowerCase()),
        })),
        sort.direction,
        sort.field
      )
    );
  };

  const toggleCompanySelection = (companyToggled, checked) => {
    const updatedCompanies = allCompanies.map((company) => ({
      ...company,
      isSelected:
        company.id === companyToggled.id ? checked : company.isSelected,
    }));
    setAllCompanies(updatedCompanies);
    updateSelectedCompanies(
      updatedCompanies.filter((company) => company.isSelected)
    );
  };

  useEffect(() => {
    if (settingState && settingState.value) {
      initialize({
        name: settingState?.value,
      });
    }
  }, [settingState]);

  useEffect(() => {
    if (isEditGroup) {
      getCompanies();
    } else if (isViewGroup) {
      getCompaniesForGroup();
    }
  }, [isEditGroup, isViewGroup]);

  const [companiesToShow, selectedCompanies] = useMemo(() => {
    return [
      allCompanies.filter((company) => company.isVisible),
      allCompanies.filter((company) => company.isSelected),
    ];
  }, [allCompanies]);
  const renderSort = (cellOrder) => {
    if (sort.field !== cellOrder) {
      return null;
    }
    return sort.direction && sort.field === cellOrder ? (
      <ArrowUpIcon size={16} />
    ) : (
      <ArrowDownIcon size={16} />
    );
  };
  const handleSort = (field) => {
    const newDirection = sort.field === field ? !sort.direction : true;
    const newCompanies = sortComps(allCompanies, newDirection, field);
    setAllCompanies(newCompanies);
    setSort({
      field,
      direction: newDirection,
    });
  };
  return (
    <form className="form" onSubmit={handleSubmit}>
      <div className="form__form-group">
        <span className="form-header bold">{s(`${type}Name`)}</span>
        <Field
          name="name"
          component={renderField}
          placeholder={s(`${type}Name`)}
          disabled={isViewGroup}
        />
      </div>

      {(isEditGroup || isViewGroup) && (
        <div className="form__form-group">
          <span className="form-header bold">{s('companies')}</span>
          <div className="form__form-group-field">
            <Field
              component="input"
              type="text"
              placeholder="Search for companies"
              onChange={(e) => searchCompanies(e.target.value)}
              name="search"
            />
            <div className="form__form-group-icon">
              <MagnifyIcon />
            </div>
          </div>

          <div className="my-2 d-flex flex-row-reverse">
            <p>
              {companiesToShow.length} {s('companies')}
            </p>
          </div>

          {companiesToShow.length > 0 && (
            <ListGroup
              className={`companies-list-group ${isEditGroup ? 'green-border' : 'grey-border'}`}
              flush
            >
              <Table responsive="xl" bordered>
                <thead>
                  <tr>
                    <th
                      style={{ width: '25%' }}
                      className="sortable"
                      onClick={() => handleSort('name')}
                    >
                      {s('name')}
                      {renderSort('name')}
                    </th>
                    <th
                      className="sortable text-center"
                      onClick={() => handleSort('isSelected')}
                    >
                      {s('selected')}
                      {renderSort('isSelected')}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {companiesToShow.map((company) => {
                    return (
                      <tr key={company.id}>
                        <td>
                          <div>
                            {company?.images?.companyImage ? (
                              <img
                                className="company__profile__avatar mr-2"
                                src={company?.images?.companyImage}
                                alt="logo"
                              />
                            ) : (
                              <span className="company__profile__avatar_placeholder mr-2">
                                {company?.name[0]?.toUpperCase()}
                              </span>
                            )}
                            {company.name}
                          </div>
                        </td>
                        {isEditGroup && (
                          <td>
                            <Col className="d-flex flex-column align-items-center justify-content-center">
                              <Input
                                type="checkbox"
                                className="my-auto"
                                onChange={(e) =>
                                  toggleCompanySelection(
                                    company,
                                    e.target.checked
                                  )
                                }
                                style={{ height: '15px', width: '15px' }}
                                checked={company.isSelected}
                              />
                            </Col>
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </ListGroup>
          )}

          {isViewGroup && !selectedCompanies.length && (
            <Alert color="primary">{s('noCompaniesFound')}</Alert>
          )}
        </div>
      )}

      <Row>
        <Col>
          {!isViewGroup ? (
            <Button color="primary" type="submit">
              {settingState ? `${s('save')}` : `${s('create')}`}
            </Button>
          ) : (
            <Button
              color="secondary"
              onClick={(e) => {
                e.preventDefault();
                setIsEditGroup(true);
                setIsViewGroup(false);
                handleModalTypeChangeToEdit();
              }}
            >
              <p>{s('edit')}</p>
            </Button>
          )}
        </Col>
      </Row>
    </form>
  );
};

GroupForm.propTypes = {
  ...reduxFormProps,
  handleSubmit: PropTypes.func.isRequired,
  settingState: PropTypes.any,
  type: PropTypes.string,
};

GroupForm.defaultProps = {
  settingState: null,
  type: '',
};

const reduxF = reduxForm({
  form: 'group_form',
  validate: validateCategoryForm,
});

export default reduxF(GroupForm);
