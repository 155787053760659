import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Close from 'mdi-react/CloseCircleOutlineIcon';
import ExternalLinkWebsiteComponentForm from './ExternalLinkWebisteComponentForm';
import t from '../../util/translation/translation';

const ExternalLinkWebsiteComponent = ({
  showModal,
  onClose,
  selectedComponent,
  onSave,
}) => {
  if (selectedComponent?.id !== 'specific-external-link') {
    return null;
  }

  const handleSubmit = (values) => {
    const payload = {
      ...selectedComponent,
      value: values.link,
      label: values.label,
    };
    onSave(payload);
  };
  return (
    <Modal
      modalClassName="question-modal"
      isOpen={!!showModal}
      toggle={onClose}
    >
      <ModalHeader className="navbar-modal-header">
        <div className="modal-header-title">{t(`appLayout.externalLink`)}</div>
        <div className="float-right">
          <button className="marginBottom-0 builder-button" onClick={onClose}>
            <Close />
          </button>
        </div>
      </ModalHeader>
      <ModalBody>
        <ExternalLinkWebsiteComponentForm
          component={selectedComponent}
          onSubmit={handleSubmit}
          onClose={onClose}
        />
      </ModalBody>
    </Modal>
  );
};
export default ExternalLinkWebsiteComponent;
