import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import skeletonTableLoader from '../../../shared/components/custom/SkeletonLoaders';
import { partial } from '../../../util/translation/translation';

const UserChart = ({ loginData, userDataType, isLoading }) => {
  const a = partial('Analytics');
  return (
    <div style={{ height: '350px' }}>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center h-100">
          {skeletonTableLoader(1, 1, 750)}
        </div>
      ) : loginData.length === 0 ? (
        <div className="d-flex justify-content-center align-items-center h-100">
          <p className="">{a('noData')} </p>
        </div>
      ) : (
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={loginData}
            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
          >
            <CartesianGrid
              strokeDasharray="3 3"
              stroke="#e0e0e0"
              vertical={false}
            />
            <XAxis
              dataKey="date"
              tickLine={false}
              axisLine={false}
              tick={{ fill: '#666', fontSize: 12 }}
            />
            <YAxis
              tickLine={false}
              axisLine={false}
              tick={{ fill: '#666', fontSize: 12 }}
            />
            <Tooltip
              contentStyle={{
                background: 'white',
                border: '1px solid #e0e0e0',
                borderRadius: '4px',
                boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
              }}
            />
            <Legend />
            {(userDataType === 'all' || userDataType === 'both') && (
              <Line
                type="monotone"
                dataKey="allUsers"
                name="Sessions"
                stroke="#007bff"
                strokeWidth={2}
                dot={{ r: 0 }}
                activeDot={{
                  r: 6,
                  stroke: '#007bff',
                  strokeWidth: 1,
                  fill: 'white',
                }}
              />
            )}
            {(userDataType === 'unique' || userDataType === 'both') && (
              <Line
                type="monotone"
                dataKey="uniqueUsers"
                name="Active users"
                stroke="#000000"
                strokeWidth={2}
                dot={{ r: 0 }}
                activeDot={{
                  r: 6,
                  stroke: '#000000',
                  strokeWidth: 1,
                  fill: 'white',
                }}
              />
            )}
          </LineChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default UserChart;
