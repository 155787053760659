import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Row, Table } from 'reactstrap';
import { toast } from 'react-toastify';
import Api from '../../../util/api';
import { partial } from '../../../util/translation/translation';
import Loading from '../../../shared/components/custom/Loading';
import ShareModal from '../../../shared/components/custom/ShareModal';
import AddRoleForm from './AddRoleForm';
import useConfig from '../../../util/useConfig';

const UserRolesTab = () => {
  const config = useConfig();
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [currentRole, setCurrentRole] = useState(null);
  const u = partial('CreateUserPage');
  const p = partial('UserRolesTab');
  const r = partial('UserRoles');
  const s = partial('shared');

  const getRoles = async () => {
    setLoading(true);
    try {
      const data = await Api.users.getAvailableRoles();
      setRoles(data);
    } catch (e) {
      toast.error(s('somethingWentWrong'));
    }
    setLoading(false);
  };

  const handleShowUsers = async (role) => {
    setLoading(true);
    try {
      const data = await Api.users.getUsersByRoleName(role.name, config);
      setUsers(data);
    } catch (e) {
      toast.error(s('somethingWentWrong'));
      setLoading(false);
      return;
    }
    setLoading(false);
    setCurrentRole(role);
  };

  useEffect(() => {
    getRoles();
  }, []);

  const handleAssignRoles = async (user, assign) => {
    setLoading(true);
    try {
      await Api.users.updateUserRoles(
        user.teamUserId,
        currentRole.name,
        assign
      );
      toast.success(u('userWasAssignedRoles'));
    } catch (err) {
      toast.error(err || u('errorGivingUserPolicies'));
      setLoading(false);
    }
    try {
      const data = await Api.users.getUsersByRoleName(currentRole.name, config);
      setUsers(data);
    } catch (e) {
      toast.error(s('somethingWentWrong'));
    } finally {
      setLoading(false);
    }
  };

  const handleAddRoleSubmit = async ({ selectedUser }) => {
    if (
      users.some((user) => user.userCloudId === selectedUser.value.userCloudId)
    ) {
      toast.warning(p('userAlreadyHasRole'));
      return;
    }
    handleAssignRoles({ ...selectedUser.value }, true);
  };

  const listRoles = () =>
    roles.map((role) => (
      <tr key={role.id}>
        <td>{r(role.name)}</td>
        <td>{r(`${role.name}Description`)}</td>
        <td>
          <Button
            className="margin-0"
            color="primary"
            size="sm"
            onClick={() => handleShowUsers(role)}
          >
            {s('users')}
          </Button>
        </td>
      </tr>
    ));

  const renderUsersModal = () => {
    return (
      <div>
        <div className="marginBottom-5">
          <h5 className="bold-text">{!!currentRole && r(currentRole.name)}</h5>
        </div>
        <AddRoleForm onSubmit={handleAddRoleSubmit} />
        <Table responsive bordered>
          <thead>
            <tr>
              <th>{s('name')}</th>
              <th>{s('phonenumber')}</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={`user${user.id}`}>
                <td>{`${user.firstname} ${user.lastname}`}</td>
                <td>{user.username}</td>
                <td>
                  <Button
                    className="margin-0"
                    color="danger"
                    size="sm"
                    onClick={() => handleAssignRoles(user, false)}
                  >
                    {p('removeRole')}
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  };

  return (
    <Card>
      <Loading loading={loading} />
      <Row>
        <Col md="3">
          <h3 className="page-title">{s('roles')}</h3>
        </Col>
        <Col md="9">
          {/* <ButtonToolbar className="margin-top-0 float-right">
             <Link className="btn btn-primary btn-sm" to="/users/segment/create">{p('createSegment')}</Link>
          </ButtonToolbar> */}
        </Col>
      </Row>
      <Row>
        <Col>
          <div
            style={{
              display: 'flex',
              paddingBottom: 5,
              background: '#fff',
              borderRadius: 6,
              boxShadow: '0px 2px 8px 0px rgba(0,0,0,0.08)',
            }}
          >
            <Table responsive striped>
              <thead>
                <tr>
                  <th>{s('name')}</th>
                  <th>{s('description')}</th>
                  <th>{s('users')}</th>
                </tr>
              </thead>
              <tbody>{listRoles()}</tbody>
            </Table>
          </div>
        </Col>
      </Row>
      <ShareModal
        visible={!!currentRole}
        handleModal={() => setCurrentRole(null)}
      >
        {renderUsersModal()}
      </ShareModal>
    </Card>
  );
};

export default UserRolesTab;
