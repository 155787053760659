import React, { useEffect } from 'react';
import { Col, Button } from 'reactstrap';
import { Field, reduxForm, propTypes as reduxFormProps } from 'redux-form';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from '../validate';
import renderField from '../../../../../shared/components/custom/Field';
import { partial } from '../../../../../util/translation/translation';

const s = partial('shared');
const p = partial('product&CategoryShare');
const CategoryForm = (props) => {
  useEffect(() => {
    if (props.category) {
      props.initialize({
        name: props.category.name,
      });
    }
  }, [props.category]);
  const { handleSubmit } = props;
  return (
    <form className="form" onSubmit={handleSubmit}>
      <Col md={6} sm={12}>
        <div className="form__form-group flex-10">
          <span className="form-header">{s('name')}</span>
          <div className="marginTop-5" />
          <Field
            name="name"
            component={renderField}
            placeholder={p('theCategoriesName')}
          />
        </div>
      </Col>
      <Col className="marginTop-15" md={12}>
        <Button color="primary" type="submit">
          {props.type === 'create' ? `${s('create')}` : `${s('save')}`}
        </Button>
        <Link to="/cnc?tab=2">
          <Button type="button">{s('close')}</Button>
        </Link>
      </Col>
    </form>
  );
};

CategoryForm.propTypes = {
  ...reduxFormProps,
  handleSubmit: PropTypes.func.isRequired,
  category: PropTypes.shape({
    name: PropTypes.string,
  }),
  type: PropTypes.string,
};

CategoryForm.defaultProps = {
  type: 'create',
  category: null,
};

const reduxF = reduxForm({
  form: 'create-new-category-form',
  validate,
});

export default reduxF(CategoryForm);
