import React, { useEffect } from 'react';
import { Field, reduxForm, propTypes as reduxFormProps } from 'redux-form';
import PropTypes from 'prop-types';
import { Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import renderDropZoneField from '../../../shared/components/form/DropZone';
import renderField from '../../../shared/components/custom/Field';
import validate from './validateDetailsForm';
import { partial } from '../../../util/translation/translation';

const s = partial('shared');
const DetailsForm = ({ handleSubmit, kiosk, initialize }) => {
  useEffect(() => {
    if (kiosk) {
      initialize({
        name: kiosk.name,
        tax: kiosk.tax.toString(),
        image:
          kiosk.image === null
            ? []
            : [{ ...kiosk.image, preview: kiosk.image.url }],
      });
    }
  }, [kiosk]);

  return (
    <form className="form" onSubmit={handleSubmit}>
      <Col xs="6">
        <div className="form__form-group">
          <p className="form-header">{s('name')}</p>
          <Field name="name" component={renderField} placeholder={s('name')} />
        </div>
        <div className="form__form-group">
          <p className="form-header">{s('taxCaps')}</p>
          <Field
            name="tax"
            component={renderField}
            type="number"
            placeholder={s('taxCaps')}
          />
        </div>
      </Col>
      <Col xs="6">
        <div className="kiosk-upload-files-box">
          <div className="card__title marginTop-0">
            <h5 className="bold-text">{s('uploadImageCaps')}</h5>
            <h5 className="subhead">{s('imageOrVideo')}: 750px/750px</h5>
          </div>
          <Field name="image" component={renderDropZoneField} />
        </div>
      </Col>
      <Col xs="12">
        <Button color="primary">{s('save')}</Button>
        <Link to="/cnc">
          <Button color="secondary">{s('close')}</Button>
        </Link>
      </Col>
    </form>
  );
};

DetailsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  kiosk: PropTypes.shape({
    id: PropTypes.number,
    image: PropTypes.shape({
      url: PropTypes.string,
    }),
    name: PropTypes.string,
    tax: PropTypes.number,
    auction: PropTypes.number,
  }),
  ...reduxFormProps,
};

DetailsForm.defaultProps = {
  kiosk: null,
};

const reduxF = reduxForm({
  form: 'cnc_details',
  validate,
});

export default reduxF(DetailsForm);
