import React, { useState, useEffect } from 'react';
import {
  Container,
  Col,
  Row,
  Table,
  Button,
  TabContent,
  TabPane,
  ButtonToolbar,
  ButtonGroup,
  Badge,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { toast } from 'react-toastify';
import fileDownload from 'js-file-download';
import LinkPlusIcon from 'mdi-react/LinkPlusIcon';
import { Parser } from 'json2csv';
import DeleteModal from '../../shared/components/custom/DeleteModal';
import Loading from '../../shared/components/custom/Loading';
import useModal from '../../shared/hooks/useModal';

import Api from '../../util/api';
import t, { partial } from '../../util/translation/translation';
import { getBaseDateTime } from '../../util/date-and-time';
import NavTab from '../../shared/components/custom/NavTab';
import EventCategoryCard from './components/EventCategoryCard/EventCategoryCard';
import IconButton from '../../shared/components/custom/IconButton';
import LazyTabPane from '../../shared/components/custom/LazyTabPane';
import ParticipantStatsPage from './ParticipantStatsPage';

const EventsPage = () => {
  const [events, setEvents] = useState({ previous: [], upcoming: [] });
  const [handleModal, visible, deleteID] = useModal();
  const [tab, setTab] = useState('1');
  const [eventCategories, setEventCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  const p = partial('EventsPage');
  const s = partial('shared');

  const segregateEvents = (incomingEvents) => {
    const previous = incomingEvents.data
      .filter((event) => moment(event.startDate).isSameOrBefore(moment()))
      .sort((a, b) => new Date(b.startDate) - new Date(a.startDate));
    const upcoming = incomingEvents.data
      .filter((event) => moment(event.startDate).isAfter(moment()))
      .sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
    setEvents({ previous, upcoming });
  };

  const getEventCategories = async () => {
    setLoading(true);
    try {
      const { data: resCategories } = await Api.eventCategories.all();
      setEventCategories(resCategories);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(err);
    }
  };

  const getEvents = async () => {
    setLoading(true);
    try {
      const resEvents = await Api.events.all();
      segregateEvents(resEvents);
      getEventCategories();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(err);
    }
  };

  const exportEventCategoriesForAllCompanies = async () => {
    setLoading(true);
    try {
      const [resCompanyList, eventCategoriesForCompany] = await Promise.all([
        Api.companies.getCompaniesForClub(),
        Api.eventCategories.all(),
      ]);
      const categoryAndComapnyFlatArray = eventCategoriesForCompany.data.map(
        (category) => {
          return category.companyConfigs.map((config) => ({
            companyId: config.companyId,
            maxAttendes: config.maxAttendes,
            categoryTitle: category.title,
          }));
        }
      );

      const allCompanyName = resCompanyList.reduce((acc, company) => {
        acc[company.id] = { companyName: company.name };

        return acc;
      }, {});

      const finalArray = [];

      const companyKeys = Object.keys(allCompanyName);
      companyKeys.forEach((key) => {
        categoryAndComapnyFlatArray.map((a) => {
          const y = a.find((z) => z.companyId === key);
          finalArray.push({
            ...y,
            ...allCompanyName[key],
          });
          return finalArray;
        });
      });

      const sortedFinalArray = finalArray.sort((a, b) => {
        return a.companyName.trim() < b.companyName.trim() ? -1 : 1;
      });
      const fields = [
        { label: `${s('companyName')}`, value: 'companyName' },
        { label: `${s('category')}`, value: 'categoryTitle' },
        {
          label: `${t('EventsForm.maxNumberOfParticipant')}`,
          value: 'maxAttendes',
        },
      ];
      const json2csvParser = new Parser({ fields });
      const csv = json2csvParser.parse(sortedFinalArray);
      fileDownload(
        `\uFEFF${csv}`,
        `${t('EventCategories.companyCategoryConfigs')}.csv`
      );

      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(err || `${p('listExportingERROR')}'!'`);
    }
  };

  useEffect(() => {
    getEvents();
  }, []);

  const deleteEvent = async () => {
    setLoading(true);
    try {
      await Api.events.delete(deleteID);
      toast.success(p('eventHasBeenDeleted'));
      handleModal();
      getEvents();
    } catch (err) {
      toast.error(err);
      getEvents();
    }
  };

  const totalParticipantCount = (participants) =>
    participants.reduce((tot, curr) => {
      return tot + curr.friendAmount;
    }, 0);

  const StatusBadge = (event) => {
    const now = new Date().getTime();
    const eventDate = new Date(event.startDate).getTime();
    const eventRegistrationStart = new Date(event.registrationStart).getTime();
    const eventRegistrationEnd = new Date(event.registrationEnd).getTime();

    switch (true) {
      case now < eventRegistrationStart:
        return <Badge color="primary">{p('registrationNotStarted')}</Badge>;
      case now > eventRegistrationStart && now < eventRegistrationEnd:
        return <Badge color="success">{p('registrationActive')}</Badge>;
      /* case now > eventRegistrationEnd && event.waitlistEnabled:
        return (
          <Badge color="warning">
            {p('waitlist')}
          </Badge>
        ); */
      case now > eventRegistrationEnd && now < eventDate:
        return <Badge color="danger">{p('registrationClosed')}</Badge>;
      case now > eventDate:
        return <Badge color="secondary">{p('eventFinished')}</Badge>;
      default:
        return <div />;
    }
  };

  const renderEvents = (specificEvents) => {
    return specificEvents.map((event) => {
      return (
        <tr key={`event-${event.id}`}>
          <td>
            <Link to={`/events/edit/${event.id}`}>{event.title}</Link>
          </td>
          <td>
            <LinkPlusIcon
              style={{ cursor: 'pointer' }}
              onClick={() => {
                toast.success(s('linkCopied'));
                navigator.clipboard.writeText(event.dynamicLink);
              }}
            />
          </td>
          <td>{getBaseDateTime(event.startDate)}</td>
          <td>{s(event.targetAudience)}</td>
          <td>
            <strong>{s('opens')}</strong>:{' '}
            {event.registrationStart
              ? getBaseDateTime(event.registrationStart)
              : '-'}{' '}
            <br />
            <strong>{s('closes')}</strong>:{' '}
            {event.registrationEnd
              ? getBaseDateTime(event.registrationEnd)
              : '-'}
          </td>
          <td>
            {totalParticipantCount(event.participants) +
              event.participants.length}{' '}
            / {event?.maxAttendees}
            {event.waitlistEnabled && (
              <Badge className="m-1 p-1" pill color="warning">
                <Link
                  style={{ color: '#ED9E45' }}
                  to={`/events/edit/${event.id}?tab=5`}
                >{` + ${totalParticipantCount(event?.waitingParticipants) + event?.waitingParticipants?.length}`}</Link>
              </Badge>
            )}
          </td>
          <td>
            {StatusBadge(event)}
            {event.waitlistEnabled && (
              <Badge className="m-1" color="warning">
                {p('waitlist')}
              </Badge>
            )}
          </td>
          <td>
            <ButtonToolbar className="float-right">
              <ButtonGroup className="btn-group--icons edit-delete-btn-group">
                <Link to={`/events/edit/${event.id}`}>
                  <IconButton
                    onClick={() => {}}
                    type={IconButton.types.EDIT}
                    id={`edit-${event.id}`}
                  />
                </Link>
                <Link
                  to={{
                    pathname: `/events/clone/${event.id}`,
                    state: { event },
                  }}
                >
                  <IconButton
                    onClick={() => {}}
                    type={IconButton.types.CLONE}
                    id={`clone-${event.id}`}
                  />
                </Link>
                <IconButton
                  onClick={() => handleModal(event.id)}
                  type={IconButton.types.DELETE}
                  id={`delete-${event.id}`}
                />
              </ButtonGroup>
            </ButtonToolbar>
          </td>
        </tr>
      );
    });
  };

  const EventCard = ({ data, title, isPast }) => {
    return (
      <>
        <div className="card__title my-2">
          <h3 className="page-title">{title}</h3>
          {!isPast && (
            <Link to="/events/create">
              <Button size="sm" color="primary">
                {p('createEvent')}
              </Button>
            </Link>
          )}
        </div>
        <div
          style={{
            display: 'flex',
            marginBottom: 10,
            paddingBottom: 5,
            background: '#fff',
            borderRadius: 6,
            boxShadow: '0px 2px 8px 0px rgba(0,0,0,0.08)',
          }}
        >
          <Table responsive striped>
            <thead>
              <tr>
                <th>{s('title')}</th>
                <th>{s('link')}</th>
                <th>{p('eventStartDate')}</th>
                <th>{s('targetAudience')}</th>
                <th>{s('registationPeriod')}</th>
                <th>{p('signedUp')}</th>
                <th>{s('status')}</th>
                <th />
              </tr>
            </thead>
            <tbody>{renderEvents(data)}</tbody>
          </Table>
        </div>
      </>
    );
  };
  return (
    <Container>
      <NavTab
        activeTab={tab}
        tabs={[s('events'), p('eventCategories'), s('Statistics')]}
        setTab={(activeTab) => setTab(activeTab)}
      />
      <DeleteModal
        type="arrangement"
        visible={visible}
        handleModal={handleModal}
        modalAction={deleteEvent}
      />
      <Loading loading={loading} />
      <TabContent activeTab={tab}>
        <TabPane tabId="1">
          <Row>
            <Col>
              <EventCard data={events.upcoming} title={p('futureEvents')} />
            </Col>
          </Row>
          <Row>
            <Col>
              <EventCard
                data={events.previous}
                title={p('pastEvents')}
                isPast
              />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="2">
          <EventCategoryCard
            categories={eventCategories}
            fetchCategories={getEventCategories}
            setLoading={setLoading}
            exportEventCategoriesForAllCompanies={
              exportEventCategoriesForAllCompanies
            }
          />
        </TabPane>
        <LazyTabPane activeTab={tab} tabId="3">
          <ParticipantStatsPage />
        </LazyTabPane>
      </TabContent>
    </Container>
  );
};

export default EventsPage;
