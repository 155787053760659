import React, {
  createContext,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { toast } from 'react-toastify';
import Api from '../../util/api';
import { partial } from '../../util/translation/translation';

const OfferContext = createContext(null);

const OfferState = ({ children }) => {
  const s = partial('shared');
  const oc = partial('OfferCard');
  const { fetchCategories } = Api.offers;
  const [categories, setCategories] = useState([]);
  const [activeOffer, setActiveOffer] = useState({});

  const fromatCategory = (data) => {
    if (data.includes('&')) {
      return data
        .split('&')
        .map((word) => word.trim().toLowerCase())
        .join()
        .replace(',', '_');
    }
    return data.toLowerCase().replace(' ', '_');
  };
  const getCategories = useCallback(async () => {
    try {
      const results = await fetchCategories();
      const categoryList = [
        { label: 'All Categories', value: '' },
        ...results,
      ].map((category) => {
        const key = fromatCategory(category.label);
        return {
          ...category,
          label: oc(key),
          value: category.label,
          key,
          id: category.value,
        };
      });
      setCategories(categoryList);
    } catch (error) {
      toast.error(`${s('somethingWentWrong')}`);
    }
  }, []);

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  const memoizedValues = useMemo(
    () => ({ categories, activeOffer, setActiveOffer }),
    [categories, activeOffer, setActiveOffer]
  );

  return (
    <OfferContext.Provider value={memoizedValues}>
      {children}
    </OfferContext.Provider>
  );
};

export { OfferState, OfferContext };
