import React from 'react';
import {
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  UncontrolledPopover,
  PopoverBody,
  ButtonToolbar,
} from 'reactstrap';
import DotsHorizontalIcon from 'mdi-react/DotsHorizontalIcon';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { partial } from '../../../util/translation/translation';

const ContentAPIList = ({ apiAccess, handleModal }) => {
  const s = partial('shared');
  const renderNews = () => {
    return apiAccess.map((access) => {
      return (
        <tr key={`apiAccess-${access.id}`} className="center-list">
          <td>
            <Button
              color="link"
              className="no-style margin-0 vertical-pad"
              id={`apiKey${access.id}`}
              type="button"
            >
              {s('show')}
            </Button>
            <UncontrolledPopover
              trigger="legacy"
              placement="bottom"
              target={`apiKey${access.id}`}
            >
              <PopoverBody>
                <div className="margin-5">{access.apiKey}</div>
              </PopoverBody>
            </UncontrolledPopover>
          </td>
          <td>{access.entityName}</td>
          <td>{access.usage.requestAmount}</td>
          <td>
            <UncontrolledDropdown className="dashboard__table-more">
              <DropdownToggle>
                <DotsHorizontalIcon />
              </DropdownToggle>
              <DropdownMenu className="dropdown__menu">
                <DropdownItem
                  onClick={() => handleModal(true, access.id, 'api_access')}
                  className="danger"
                >
                  {s('delete')}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </td>
        </tr>
      );
    });
  };

  return (
    <div className="mt-4">
      <div className="flex space-between">
        <div>
          <h3 className="page-title mb-0">API</h3>
        </div>
        <div>
          <ButtonToolbar className="margin-top-0">
            <Link className="btn btn-primary btn-sm" to="/news_api/create">
              {s('create')} {s('key')}
            </Link>
          </ButtonToolbar>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          paddingBottom: 5,
          marginBottom: 10,
          background: '#fff',
          borderRadius: 6,
          boxShadow: '0px 2px 8px 0px rgba(0,0,0,0.08)',
        }}
      >
        <Table responsive striped>
          <thead>
            <tr>
              <th>
                <div className="flex alignItems-center">{s('key')}</div>
              </th>
              <th>
                <div className="flex alignItems-center">{s('companyName')}</div>
              </th>
              <th>
                <div className="flex alignItems-center">{s('usage')}</div>
              </th>
              <th />
            </tr>
          </thead>
          <tbody>{renderNews()}</tbody>
        </Table>
      </div>
    </div>
  );
};

export default ContentAPIList;
