import React, { useEffect, useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Col, Row, Container, Button } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import countries from 'i18n-iso-countries';
import { useRecoilValue } from 'recoil';
import LinkVariantIcon from 'mdi-react/LinkVariantIcon';
import renderRadioButtonField from '../../../shared/components/form/RadioButton';
import renderField from '../../../shared/components/custom/Field';
import renderDropZoneField from '../../../shared/components/form/DropZoneMS';
import FormBox from '../../../shared/components/custom/FormBox';
import renderSelectField from '../../../shared/components/form/Select';
import validateEvents from './validateEvents';
import t, { partial } from '../../../util/translation/translation';
import userLanguageState from '../../../recoil/userLanguage/selector';
import TooltipWithIcon from '../../../shared/components/custom/TooltipWithIcon';
import EventTypes from '../types';
import renderCheckBoxField from '../../../shared/components/form/CheckBox';
import TextEditor2 from '../../../shared/components/text-editor/TextEditor2';

countries.registerLocale(require('i18n-iso-countries/langs/nb.json'));
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

const EventsForm = (props) => {
  const {
    handleSubmit,
    initialize,
    event,
    change,
    eventCategories,
    eventLevels,
    eventGroups,
    eventTiers,
    setSelectedCountry,
    eventForm,
    numberOfPendingMessagesPerEvent,
  } = props;
  const p = partial('EventsForm');
  const s = partial('shared');
  const o = partial('OfferForm');
  const location = useLocation();
  const availableNoneLabel = { label: s('noneEvents'), value: null };
  const notAplicableLabel = { label: 'N/A', value: null };
  const allAllowedLabels = {
    eventLevels: { label: p('allLevels'), value: [] },
    eventGroups: { label: p('allGroups'), value: [] },
    eventTiers: { label: p('allTiers'), value: [] },
  };
  const mergedEventCategories = [availableNoneLabel, ...eventCategories];
  const mergedEventLevels = [
    notAplicableLabel,
    allAllowedLabels.eventLevels,
    ...eventLevels,
  ];
  const mergedEventGroups = [
    notAplicableLabel,
    allAllowedLabels.eventGroups,
    ...eventGroups,
  ];
  const mergedEventTiers = [
    notAplicableLabel,
    allAllowedLabels.eventTiers,
    ...eventTiers,
  ];
  const userLanguage = useRecoilValue(userLanguageState);
  const { TargetAudience } = EventTypes;
  const [targetAudience, setTargetAudience] = useState(TargetAudience.partner);
  const countryOptions = Object.entries(countries.getNames(userLanguage)).map(
    ([iso, country]) => ({
      name: country,
      iso,
      value: iso,
      label: country,
    })
  );
  const parseAccessFields = (selectedArray, selectedField) => {
    if (selectedArray === null) {
      return [notAplicableLabel];
    }
    if (selectedArray?.length === 0) {
      return [allAllowedLabels[selectedField]];
    }
    return props[selectedField].filter((group) =>
      selectedArray.includes(group.label)
    );
  };
  const parseAccessTiers = (selectedArray, selectedField) => {
    if (selectedArray === null) {
      return [notAplicableLabel];
    }
    if (selectedArray?.length === 0) {
      return [allAllowedLabels[selectedField]];
    }
    return props[selectedField].filter((group) =>
      selectedArray?.includes(group.value)
    );
  };
  useEffect(() => {
    if (event) {
      const initializedLevels = parseAccessFields(
        event?.accessLevels,
        'eventLevels'
      );
      const initializedGroups = parseAccessFields(
        event?.accessCategories,
        'eventGroups'
      );
      const initializedTiers = parseAccessTiers(
        event?.accessTiers,
        'eventTiers'
      );
      setTargetAudience(event.targetAudience);
      initialize({
        title: event.title,
        address: event.address,
        description: event.description,
        endDate: moment(event.endDate).format('YYYY-MM-DDTHH:mm'),
        startDate: moment(event.startDate).format('YYYY-MM-DDTHH:mm'),
        mapsUrl: event.mapsUrl,
        imageUrl: event?.imageUrl ? { preview: event?.imageUrl } : null,
        zip: event.zip,
        maxAttendees: event.maxAttendees,
        maxAccountAttendees: event.maxAccountAttendees,
        registrationStart: event.registrationStart
          ? moment(event.registrationStart).format('YYYY-MM-DDTHH:mm')
          : null,
        registrationEnd: event.registrationEnd
          ? moment(event.registrationEnd).format('YYYY-MM-DDTHH:mm')
          : null,
        urlText: event.urlText,
        urlDescription: event.urlDescription,
        websiteUrl: event.websiteUrl,
        eventCategories: event.category
          ? {
              label: event.category.title,
              value: event.category.id,
              maximum: event.category.maxAttendees,
            }
          : availableNoneLabel,
        eventLevels: initializedLevels,
        eventGroups: initializedGroups,
        eventTiers: initializedTiers,
        country: countryOptions.find((cntry) => cntry.name === event?.country),
        city: event.city,
        targetAudience: event.targetAudience,
        feedVisibility: event.feedVisibility ? 'ALL' : 'ONLYINVITED',
        // shouldNotify: event.shouldNotify === 'YES' : 'NO',
        dynamicLink: event.dynamicLink,
        waitlistEnabled: event.waitlistEnabled,
      });
    } else {
      initialize({
        eventCategories: mergedEventCategories[0],
        eventLevels: [{ label: 'N/A', value: null }],
        eventGroups: [{ label: 'N/A', value: null }],
        eventTiers: [{ label: 'N/A', value: null }],
        targetAudience: EventTypes.TargetAudience.partner,
        feedVisibility: true,
        waitlistEnabled: false,
      });
    }
  }, [
    event,
    event?.eventCategories,
    event?.eventLevels,
    event?.eventGroups,
    event?.eventTiers,
  ]);
  const adjustDropdowns = (fieldName) => {
    if (eventForm[fieldName] && eventForm[fieldName].length > 1) {
      const [old, ...newOnes] = eventForm[fieldName];
      const newestOne = eventForm[fieldName][eventForm[fieldName].length - 1];
      if (old.value === null || Array.isArray(old.value)) {
        change(fieldName, newOnes);
      }
      if (newestOne.value === null || Array.isArray(newestOne.value)) {
        change(fieldName, [newestOne]);
      }
    }
  };

  const RenderToolTip = () => {
    return (
      <TooltipWithIcon
        text={`${t('ScheduledMessagesPage.mainTooltipInfo1/2')} ${numberOfPendingMessagesPerEvent} ${t('ScheduledMessagesPage.mainTooltipInfo2/2')}`}
        id="scheduleMsgTooltipEvent"
      />
    );
  };

  useEffect(() => {
    adjustDropdowns('eventGroups');
    adjustDropdowns('eventLevels');
    adjustDropdowns('eventTiers');
  }, [
    eventForm.eventCategories,
    eventForm.eventLevels,
    eventForm.eventGroups,
    eventForm.eventTiers,
  ]);

  const handleChangeAudience = (e) => {
    setTargetAudience(e);
    change('eventLevels', 'null');
    change('eventGroups', 'null');
    change('eventTiers', 'null');
  };
  return (
    <form onSubmit={handleSubmit} className="form">
      <Container className="padding-0">
        <Row className="flex">
          <div className="flex-1">
            <Col style={{ marginBottom: 10 }}>
              <FormBox title={s('details')}>
                <Col md={12}>
                  <div className="form__form-group">
                    <span className="form-header">{s('title')}</span>
                    <Field
                      name="title"
                      placeholder={s('title')}
                      component={renderField}
                    />
                  </div>
                </Col>
                <Col md={12}>
                  <div className="form__form-group">
                    <span className="form-header">{p('description')}</span>
                    <Field
                      header={[2, 3, false]}
                      name="description"
                      placeholder={p('descriptionOfEvent')}
                      component={TextEditor2}
                    />
                  </div>
                </Col>
                {event ? (
                  <Col>
                    <div className="form__form-group">
                      <span className="form-header marginRight-8">
                        {s('universalLink')}
                      </span>
                      <div className="form__form-group-field">
                        <Field name="dynamicLink" component="input" disabled />
                        <button
                          type="button"
                          className="form__form-group-icon"
                          onClick={() =>
                            navigator.clipboard.writeText(event.dynamicLink)
                          }
                        >
                          <LinkVariantIcon />
                        </button>
                      </div>
                    </div>
                  </Col>
                ) : null}
              </FormBox>
            </Col>
            <Col style={{ marginBottom: 10 }}>
              <FormBox title={s('access')}>
                <Col>
                  <div className="flex">
                    <span className="form mr-1">{o('targetAudience')}</span>
                  </div>
                  <div className="flex">
                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <Field
                          name="targetAudience"
                          label={o('partner')}
                          component={renderRadioButtonField}
                          radioValue={TargetAudience.partner}
                          defaultChecked
                          onChange={handleChangeAudience}
                          disabled={!!event}
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <Field
                          name="targetAudience"
                          component={renderRadioButtonField}
                          label={o('private')}
                          radioValue={TargetAudience.private}
                          onChange={handleChangeAudience}
                          disabled={!!event}
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <Field
                          name="targetAudience"
                          component={renderRadioButtonField}
                          label={s('all')}
                          radioValue={TargetAudience.all}
                          onChange={handleChangeAudience}
                          disabled={!!event}
                        />
                      </div>
                    </div>
                  </div>
                </Col>
                {targetAudience === TargetAudience.partner && (
                  <Col md={12}>
                    <div className="form__form-group">
                      <span className="form-header">
                        {p('chooseCategoryEvent')}{' '}
                        {eventForm.eventCategories?.maximum
                          ? `(${t('EventCategories.maxParticipants')}:  ${eventForm.eventCategories?.maximum})`
                          : ''}
                      </span>
                      <Field
                        name="eventCategories"
                        placeholder={p('chooseCategoryEvent')}
                        options={mergedEventCategories}
                        component={renderSelectField}
                      />
                    </div>
                  </Col>
                )}
                {eventForm.eventCategories?.value === null && (
                  <>
                    <div className="flex">
                      <Col lg={6}>
                        <div className="form__form-group">
                          <span className="form-header">
                            {p('numberOfParticipant')}
                          </span>
                          <Field
                            name="maxAttendees"
                            component={renderField}
                            placeholder={p('numberOfParticipantOnEvent')}
                            type="number"
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="form__form-group">
                          <span className="form-header">
                            {targetAudience === TargetAudience.partner
                              ? p('maxNumberOfParticipantPerCompany')
                              : p('maxNumberOfParticipantPerUser')}
                          </span>
                          <Field
                            name="maxAccountAttendees"
                            component={renderField}
                            placeholder={`Max ${p('maxNumberOfParticipant')}`}
                            type="number"
                          />
                        </div>
                      </Col>
                    </div>
                  </>
                )}
                {(targetAudience === TargetAudience.partner ||
                  targetAudience === TargetAudience.both) &&
                  targetAudience !== TargetAudience.all && (
                    <>
                      <Col md={12}>
                        <div className="form__form-group">
                          <span className="form-header">
                            {p('chooseLevelForEvent')}
                          </span>
                          <Field
                            name="eventLevels"
                            placeholder={p('chooseLevelForEvent')}
                            options={mergedEventLevels}
                            component={renderSelectField}
                            multiple
                          />
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="form__form-group">
                          <span className="form-header">
                            {p('chooseUsergroupAsEvent')}
                          </span>
                          <Field
                            name="eventGroups"
                            placeholder={p('chooseUsergroupAsEvent')}
                            options={mergedEventGroups}
                            component={renderSelectField}
                            multiple
                          />
                        </div>
                      </Col>
                    </>
                  )}
                {(targetAudience === TargetAudience.private ||
                  targetAudience === TargetAudience.both) &&
                  targetAudience !== TargetAudience.all && (
                    <Col md={12}>
                      <div className="form__form-group">
                        <span className="form-header">
                          {p('chooseTierForEvent')}
                        </span>
                        <Field
                          name="eventTiers"
                          placeholder="Choose a tier"
                          options={mergedEventTiers}
                          component={renderSelectField}
                          multiple
                        />
                      </div>
                    </Col>
                  )}
                {targetAudience !== TargetAudience.all && (
                  <Col style={{ marginTop: 20 }}>
                    <div className="flex">
                      <span className="form">{p('visibility')}</span>
                      <TooltipWithIcon
                        text={p('visibilityInfoTooltip')}
                        id="visibilityInfoTooltip"
                      />
                    </div>
                    <div className="flex">
                      <div className="form__form-group">
                        <div className="form__form-group-field">
                          <Field
                            name="feedVisibility"
                            label={s('all')}
                            component={renderRadioButtonField}
                            radioValue="ALL"
                          />
                        </div>
                      </div>
                      <div className="form__form-group">
                        <div className="form__form-group-field">
                          <Field
                            name="feedVisibility"
                            component={renderRadioButtonField}
                            label={p('onlyInvited')}
                            defaultChecked
                            radioValue="ONLYINVITED"
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                )}
              </FormBox>
            </Col>
            <Col style={{ marginBottom: 10 }}>
              <FormBox title={s('date')}>
                <div className="flex">
                  <Col lg={6} md={6}>
                    <div className="form__form-group">
                      <span className="form-header">
                        {p('eventStartDate')}
                        {numberOfPendingMessagesPerEvent ? (
                          <RenderToolTip />
                        ) : (
                          <></>
                        )}
                      </span>
                      <Field
                        name="startDate"
                        placeholder={s('date')}
                        type="datetime-local"
                        component={renderField}
                      />
                    </div>
                  </Col>
                  <Col lg={6} md={6}>
                    <div className="form__form-group">
                      <span className="form-header">
                        {p('eventEndDate')}{' '}
                        {numberOfPendingMessagesPerEvent ? (
                          <RenderToolTip />
                        ) : (
                          <></>
                        )}
                      </span>
                      <Field
                        name="endDate"
                        placeholder={s('date')}
                        type="datetime-local"
                        component={renderField}
                      />
                    </div>
                  </Col>
                </div>
                <div className="flex">
                  <Col md={6}>
                    <div className="form__form-group">
                      <span className="form-header">
                        {p('registerOpen')}{' '}
                        {numberOfPendingMessagesPerEvent ? (
                          <RenderToolTip />
                        ) : (
                          <></>
                        )}
                      </span>
                      <Field
                        name="registrationStart"
                        placeholder={p('saleStart')}
                        type="datetime-local"
                        component={renderField}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form__form-group">
                      <span className="form-header">
                        {p('registerClose')}{' '}
                        {numberOfPendingMessagesPerEvent ? (
                          <RenderToolTip />
                        ) : (
                          <></>
                        )}
                      </span>
                      <Field
                        name="registrationEnd"
                        placeholder={s('date')}
                        type="datetime-local"
                        component={renderField}
                      />
                    </div>
                  </Col>
                </div>
              </FormBox>
            </Col>
            {location.pathname === '/events/create' && (
              <Col style={{ marginBottom: 20 }}>
                <FormBox title={s('notice')}>
                  <Col style={{ marginTop: 10 }}>
                    <div className="flex">
                      <span className="form">{o('notifyUsers')}</span>
                      <TooltipWithIcon
                        text={o('shouldNotifyTooltip')}
                        id="shouldNotifyTooltip"
                      />
                    </div>
                    <div className="flex">
                      <div className="form__form-group">
                        <div className="form__form-group-field">
                          <Field
                            name="shouldNotify"
                            label={s('yes')}
                            component={renderRadioButtonField}
                            radioValue="YES"
                          />
                        </div>
                      </div>
                      <div className="form__form-group">
                        <div className="form__form-group-field">
                          <Field
                            name="shouldNotify"
                            component={renderRadioButtonField}
                            label={s('no')}
                            defaultChecked
                            radioValue="NO"
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                </FormBox>
              </Col>
            )}
          </div>
          <div className="flex-1">
            <Col style={{ marginBottom: '20px' }}>
              <FormBox title={s('uploadImage')}>
                <Field
                  name="imageUrl"
                  className="dropzone"
                  maxSize={3}
                  component={renderDropZoneField}
                  crop="BANNER_CROP"
                />
                <small style={{ padding: 10 }}>
                  <strong>{s('note')}:</strong> {s('recommendedImageSize')} 1035
                  * 570px, 16:9 {s('ratio')}
                </small>
              </FormBox>
            </Col>
            <Col className="mb-2">
              <FormBox title={t('EventsPage.waitlist')}>
                <div className="flex">
                  <Col>
                    <Field
                      name="waitlistEnabled"
                      component={renderCheckBoxField}
                      label={t('EventsPage.enableWaitlist')}
                      className="colored-click"
                    />
                  </Col>
                </div>
              </FormBox>
            </Col>
            <Col>
              <FormBox title={s('optional')}>
                <div className="flex">
                  <Col md={6}>
                    <div className="form__form-group">
                      <span className="form-header">{s('link')}</span>
                      <Field
                        name="websiteUrl"
                        placeholder={s('link')}
                        component={renderField}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form__form-group">
                      <span className="form-header">{p('linkName')}</span>
                      <Field
                        name="urlText"
                        placeholder={s('name')}
                        component={renderField}
                      />
                    </div>
                  </Col>
                </div>
                <Col md={12}>
                  <div className="form__form-group">
                    <span className="form-header">{s('address')}</span>
                    <Field
                      name="address"
                      placeholder={s('address')}
                      component={renderField}
                    />
                  </div>
                </Col>
                <Col md={12}>
                  <div className="form__form-group">
                    <span className="form-header">{p('googleMapsLink')}</span>
                    <Field
                      name="mapsUrl"
                      placeholder={p('googleMapsLink')}
                      component={renderField}
                    />
                  </div>
                </Col>
                <div className="flex">
                  <Col md={4}>
                    <div className="form__form-group">
                      <span className="form-header">{p('zip')}</span>
                      <Field
                        name="zip"
                        placeholder={p('zip')}
                        component={renderField}
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="form__form-group">
                      <span className="form-header">{p('city')}</span>
                      <Field
                        name="city"
                        placeholder={p('city')}
                        component={renderField}
                      />
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="form__form-group">
                      <span className="form-header">{p('country')}</span>
                      <Field
                        name="country"
                        component={renderSelectField}
                        options={countryOptions}
                        placeholder={s('chooseCountry')}
                        onChange={(e) => setSelectedCountry(e.name)}
                      />
                    </div>
                  </Col>
                </div>
              </FormBox>
            </Col>
          </div>
        </Row>
        <Row>
          <Col>
            <Button color="primary">{event ? s('save') : s('create')}</Button>
            <Link to="/events">
              <Button color="secondary">{s('close')}</Button>
            </Link>
          </Col>
        </Row>
      </Container>
    </form>
  );
};

EventsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  event: PropTypes.shape({}),
};

EventsForm.defaultProps = {
  event: null,
};

const reduxF = reduxForm({
  form: 'events_form',
  validate: validateEvents,
});

export default reduxF(EventsForm);
