import React, { useEffect } from 'react';
import classnames from 'classnames';
import { Nav, NavLink, NavItem } from 'reactstrap';
import { PropTypes } from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';

const NavTab = (props) => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (props.autoSwitch === true) {
      const tab = new URLSearchParams(location.search).get('tab') || '1';
      if (props.activeTab === tab) {
        return;
      }
      if (
        props.tabs.length + 1 > tab &&
        (!props.tabs[tab - 1]?.disabled ?? false)
      ) {
        props.setTab(tab);
      }
    }
  }, [location.search]);
  const toggle = (n) => () => {
    if (props.activeTab !== n) {
      props.setTab(n.toString());
    }
    if (props.replaceHistory) {
      history.replace({ search: `?tab=${n}` });
    }
  };

  const classname = (n) =>
    classnames(
      { active: props.activeTab === n.toString() },
      {
        tabSmallText:
          props.activeTab !== n.toString() &&
          props.inline === false &&
          props.size !== 'sm',
      },
      {
        'fontWeight-700':
          props.activeTab === n.toString() && props.size === 'sm',
      }
    );

  let navItemClass =
    props.inline === false ? 'width-unset fontWeight-700 fontSize-24' : '';
  switch (props.size) {
    case 'sm':
      navItemClass = 'width-unset';
      break;
    default:
  }

  return (
    <div
      className={`tabs tabs--justify ${props.inline ? 'tabs--bordered-top' : 'tabs--bordered-bottom'}`}
      style={{ marginBottom: '30px', ...props.style }}
    >
      <div className="tabs__wrap">
        <Nav tabs>
          {props.tabs.map((tab, i) => (
            <NavItem key={`navTabs${tab?.tab ?? tab}`} className={navItemClass}>
              <NavLink
                disabled={tab?.disabled ?? false}
                className={classname(i + 1)}
                onClick={toggle(i + 1)}
              >
                {tab?.tab ?? tab}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
      </div>
    </div>
  );
};

NavTab.propTypes = {
  activeTab: PropTypes.string.isRequired,
  setTab: PropTypes.func.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        tab: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
        disabled: PropTypes.bool,
      }),
    ])
  ).isRequired,
  autoSwitch: PropTypes.bool,
  inline: PropTypes.bool,
  replaceHistory: PropTypes.bool,
};

NavTab.defaultProps = {
  autoSwitch: true,
  inline: false,
  replaceHistory: true,
};

export default NavTab;
