import { partial } from '../../../util/translation/translation';

const isnum = (val) => /^\d+$/.test(val);

const validate = (values) => {
  const p = partial('validateErrors');

  const errors = {};
  if (!values.firstName) {
    errors.firstName = p('firstnameCantBeEmpty');
  }
  if (!values.lastName) {
    errors.lastName = p('lastnameCantBeEmpty');
  }

  if (!values.countryCode) {
    errors.countryCode = p('countryCodeCantBeEmpty');
  }

  if (!values.username) {
    errors.username = p('usernameCantBeEmpty');
  }

  if (!isnum(values.username)) {
    errors.username = p('phoneFieldOnlyNumbers');
  }

  if (!values.password) {
    errors.password = p('passwordCantBeEmpty');
  }
  if (!values.email) {
    errors.email = p('emailFieldCantBeEmpty');
  }

  return errors;
};

export default validate;
