import React from 'react';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import CloseCircleIcon from 'mdi-react/CloseCircleIcon';
import CheckCircleIcon from 'mdi-react/CheckCircleIcon';
import PropTypes from 'prop-types';
import InfoCircleIcon from 'mdi-react/InfoCircleIcon';
import { partial } from '../../../util/translation/translation';

const s = partial('shared');

export const AlertModalType = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  CONFIRMATION: 'CONFIRMATION',
  DELETION: 'DELETION',
};

const AlertModal = (props) => {
  return (
    <Modal isOpen={props.visible} size="md" centered>
      <ModalHeader
        toggle={() => {
          props.handleModal(false);
        }}
      />
      <ModalBody>
        <div className="alert-modal-container">
          <div className="modal-icon">
            {props.modalType === AlertModalType.SUCCESS && (
              <CheckCircleIcon size="50" color="#13AF7B" />
            )}
            {props.modalType === AlertModalType.ERROR && (
              <CloseCircleIcon size="50" color="#F94848" />
            )}
            {props.modalType === AlertModalType.CONFIRMATION && (
              <InfoCircleIcon size="50" color="#FF9900" />
            )}
            {props.modalType === AlertModalType.DELETION && (
              <CloseCircleIcon size="50" color="#F94848" />
            )}
          </div>
          <div className="modal-text">
            <h3 className="bold">{props.message}</h3>
          </div>

          <div className="modal-text">
            {props.modalType === AlertModalType.SUCCESS && (
              <p className="text-center mt-2">{props.subText}</p>
            )}
            {props.modalType === AlertModalType.DELETION && (
              <p className="text-center mt-2">{props.subText}</p>
            )}
          </div>

          {props.modalType === AlertModalType.CONFIRMATION && (
            <div className="modal-buttons mt-4">
              <Button
                className="marginBottom-0"
                color="primary"
                onClick={() => {
                  props.modalAction();
                }}
                size="sm"
              >
                {s('yes')}
              </Button>{' '}
              <Button
                className="marginBottom-0"
                color="primary"
                outline
                onClick={() => {
                  props.handleModal(false);
                }}
                size="sm"
              >
                {s('no')}
              </Button>
            </div>
          )}

          {props.modalType === AlertModalType.SUCCESS && (
            <Button
              className="marginBottom-0 mt-4"
              color="primary"
              onClick={() => {
                props.handleModal(false);
              }}
              size="sm"
            >
              {s('gotIt')}
            </Button>
          )}

          {props.modalType === AlertModalType.DELETION && (
            <div className="modal-buttons mt-4">
              <Button
                className="marginBottom-0"
                color="danger"
                onClick={() => {
                  props.modalAction();
                }}
                size="sm"
              >
                {s('remove')}
              </Button>{' '}
              <Button
                className="marginBottom-0"
                color="secondary"
                outline
                onClick={() => {
                  props.handleModal(false);
                }}
                size="sm"
              >
                {s('dontRemove')}
              </Button>
            </div>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

AlertModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  handleModal: PropTypes.func.isRequired,
  modalAction: PropTypes.func,
  modalType: PropTypes.oneOf(Object.keys(AlertModalType)).isRequired,
  message: PropTypes.string.isRequired,
};

AlertModal.defaultProps = {
  modalAction: null,
};

export default AlertModal;
