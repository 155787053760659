import React, { Component } from 'react';
import { Container, Col, Card, CardBody } from 'reactstrap';
import { getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes, { any } from 'prop-types';
import { toast } from 'react-toastify';
import EditTicketForm from './components/EditTicketForm';
import Api from '../../../../../../util/api';
import Loading from '../../../../../../shared/components/custom/Loading';
import { partial } from '../../../../../../util/translation/translation';

const p = partial('EditMatchTicket');
const t = partial('TicketStates');
class EditMatchTicket extends Component {
  static propTypes = {
    editMatchTicketValues: PropTypes.objectOf(any),
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string,
      }),
    }).isRequired,
  };

  static defaultProps = {
    editMatchTicketValues: undefined,
  };

  state = {
    userOptions: [],
    ticketStates: [],
    matchTicket: {},
    matchPrices: [],
    loading: true,
    activeTab: '1',
  };

  componentDidMount() {
    this.initializeComponent();
  }

  onSubmit = () => {
    const payload = {
      ticket_state_id:
        this.props.editMatchTicketValues.ticketState.value &&
        Number(this.props.editMatchTicketValues.ticketState.value),
      price_group_id:
        this.props.editMatchTicketValues.priceGroup.value &&
        Number(this.props.editMatchTicketValues.priceGroup.value),
      user_id:
        this.props.editMatchTicketValues.user.value &&
        Number(this.props.editMatchTicketValues.user.value),
      vip:
        this.props.editMatchTicketValues.vip &&
        Number(this.props.editMatchTicketValues.vip),
      shareable:
        this.props.editMatchTicketValues.shareable &&
        !!Number(this.props.editMatchTicketValues.shareable),
    };
    this.setState({ loading: true }, () => {
      Api.tickets
        .editMatchTicket(this.state.matchTicket.id, payload)
        .then(() => {
          toast.success(p('ticketUpdated'));
          this.setState({ loading: false });
        })
        .catch((err) => {
          this.setState({ loading: false });
          toast.error(err || p('errorAssigningTicket'));
        });
    });
  };
  // eslint-disable-next-line react/sort-comp
  debounce = (cb) => {
    let id = '';
    return (value) => {
      clearTimeout(id);
      id = setTimeout(() => {
        cb(value);
      }, 400);
    };
  };
  getUser = this.debounce(async (text) => {
    const { users, club } = await Api.users.getTeamUsers(
      1,
      100,
      { phoneNumber: 'ASC' },
      text
    );
    const userOptions = users.map((user) => {
      const foundTeam = user.teams.find((item) => item.team.name === club);
      return {
        label: `${user.firstname} ${user.lastname} (${user.phoneNumber})`,
        value: foundTeam.monolithUserId?.toString(),
      };
    });
    this.setState({ userOptions });
  });

  handleTicketStates = (ticketStates) => {
    const acceptedStates = ['ACTIVE', 'INACTIVE', 'RESERVED/RELEASABLE'];
    const filterTicketStates =
      (_acceptedStates) =>
      ({ name }) =>
        _acceptedStates.includes(name);
    return ticketStates
      .filter(filterTicketStates(acceptedStates))
      .map((ticketState) => {
        return {
          label: t(ticketState.name.toLowerCase()),
          value: ticketState.id.toString(),
        };
      });
  };

  handleMatchPrices = (matchPrices) => {
    return matchPrices.map((price) => {
      return {
        label: price.title,
        value: price.id.toString(),
      };
    });
  };

  initializeComponent = () => {
    Promise.all([
      Api.tickets.getTicketStates(),
      Api.tickets.getPriceGroups(),
      Api.tickets.getMatchTicket(this.props.match.params.id),
    ])
      .then((res) => {
        const ticketStates = this.handleTicketStates(res[0].data);
        const matchPrices = this.handleMatchPrices(res[1].data);
        const matchTicket = res[2].data;
        this.setState({
          ticketStates,
          matchPrices,
          matchTicket,
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(err || p('errorFetchingInfo'));
      });
  };

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  render() {
    return (
      <Container>
        <Loading loading={this.state.loading} />
        <Col md={12}>
          <h3 className="page-title">
            {p('editMatchTicket')}{' '}
            {this.state.matchTicket.id !== undefined &&
              `( ${this.state.matchTicket.seat_match.seat.row.field.name} - ${this.state.matchTicket.seat_match.seat.row.number} - ${this.state.matchTicket.seat_match.seat.seat_id})`}
          </h3>
        </Col>
        <Col md={12}>
          <Card>
            <CardBody>
              <EditTicketForm
                userOptions={this.state.userOptions}
                getUser={this.getUser}
                ticketStates={this.state.ticketStates}
                matchPrices={this.state.matchPrices}
                onSubmit={this.onSubmit}
                matchTicket={this.state.matchTicket}
              />
            </CardBody>
          </Card>
        </Col>
      </Container>
    );
  }
}
export default connect((state) => ({
  editMatchTicketValues: getFormValues('edit_match_ticket')(state),
}))(EditMatchTicket);
