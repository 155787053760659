import React, { useEffect, useState } from 'react';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Table,
  Button,
  Col,
  Row,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import DotsHorizontalIcon from 'mdi-react/DotsHorizontalIcon';
import t, { partial } from '../../util/translation/translation';
import Api from '../../util/api';
import useModal from '../../shared/hooks/useModal';
import DeleteModal from '../../shared/components/custom/DeleteModal';
import Loading from '../../shared/components/custom/Loading';

const PrizesCard = () => {
  const p = partial('prizes');
  const s = partial('shared');
  const [availablePrizes, setAvailablePrizes] = useState([]);
  const [loading, setLoading] = useState([]);
  const [handleModal, visible, deleteID] = useModal();

  const fetchPrizes = async () => {
    setLoading(true);
    try {
      const prizeResults = await Api.mvpPrize.getAllPrizes();
      setAvailablePrizes(prizeResults);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(p('errorToFetchPrizes'));
    }
  };
  const deletePrize = async () => {
    setLoading(true);
    try {
      await Api.mvpPrize.deletePrize(deleteID);
      toast.success(p('prizeDeleted'));
    } catch (err) {
      toast.error(p('errorRemovingPrize'));
    } finally {
      handleModal();
      fetchPrizes();
    }
  };

  useEffect(() => {
    fetchPrizes();
  }, []);
  const renderPrizes = () => {
    return availablePrizes.map((prize, i) => (
      <tr
        style={{ background: i % 2 ? '#fff' : '#F9FAFB' }}
        key={`prizeItem-${prize.id}`}
      >
        <td>
          <img className="mr-2" src={prize?.imageUrl} alt="logo" />
        </td>
        <td>
          <Link to={`/prizes/edit/${prize.id}`}>{prize.title}</Link>
        </td>
        <td>
          {prize.description.length > 50
            ? `${prize.description.slice(0, 50)}...`
            : prize.description}
        </td>
        <td>
          <UncontrolledDropdown className="dashboard__table-more">
            <DropdownToggle>
              <DotsHorizontalIcon />
            </DropdownToggle>
            <DropdownMenu className="dropdown__menu">
              <Link to={`/prizes/edit/${prize.id}`}>
                <DropdownItem>{s('edit')}</DropdownItem>
              </Link>
              <DropdownItem
                onClick={() => handleModal(prize.id)}
                className="danger"
              >
                {s('delete')}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </td>
      </tr>
    ));
  };

  return (
    <>
      <Loading loading={loading} />
      <DeleteModal
        visible={visible}
        type={p('prize')}
        handleModal={handleModal}
        modalAction={deletePrize}
      />
      <Col>
        <Row>
          <h3 className="page-title mb-0">{t('prizes.prizes')}</h3>
          <Link
            to={{ pathname: '/prizes/create' }}
            style={{ marginLeft: 'auto' }}
          >
            <Button color="primary" size="sm">
              {p('createPrize')}
            </Button>
          </Link>
        </Row>
      </Col>
      <div
        style={{
          display: 'flex',
          paddingBottom: 5,
          marginTop: 5,
          background: '#fff',
          borderRadius: 6,
          boxShadow: 'rgba(0, 0, 0, 0.08) 0px 2px 8px 0px',
        }}
      >
        <Table responsive striped>
          <thead>
            <tr>
              <th className="bold" style={{ color: '#4D576A' }}>
                {s('image')}
              </th>
              <th className="bold" style={{ color: '#4D576A' }}>
                {s('title')}
              </th>
              <th className="bold" style={{ color: '#4D576A' }}>
                {s('description')}
              </th>
              <th />
            </tr>
          </thead>
          <tbody>{renderPrizes()}</tbody>
        </Table>
      </div>
    </>
  );
};

export default PrizesCard;
