import { useState } from 'react';
import Api from '../../../util/api.js';

export const useUserAvgLoginStats = () => {
  const [userAvgDataLoading, setUserAvgDataLoading] = useState(false);
  const [averageEngagementTimePerUser, setAverageEngagementTimePerUser] =
    useState({
      formatted_period_avg_engagement_per_user: '0m 00s',
      formatted_sum_of_daily_averages: '0m 00s',
    });

  const formatTime = (ms) => {
    if (typeof ms !== 'number') return '0m 00s';
    const totalSeconds = Math.floor(ms / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    if (minutes > 60) {
      const hours = Math.floor(minutes / 60);
      const remainingMins = minutes % 60;
      return `${hours}h ${remainingMins.toString().padStart(2, '0')}s`;
    }
    return `${minutes}m ${seconds.toString().padStart(2, '0')}s`;
  };

  const fetchUserAvgLoginStats = async (timeframeParam) => {
    try {
      setUserAvgDataLoading(true);
      const avgEngagementTimePerUser =
        await Api.analytics.getTotalUserAverageSessionTimeByDate(
          timeframeParam
        );
      const formatted_period_avg_engagement_per_user = formatTime(
        avgEngagementTimePerUser?.data[0]?.period_avg_engagement_per_user
      );
      const formatted_sum_of_daily_averages = formatTime(
        avgEngagementTimePerUser?.data[0]?.sum_of_daily_averages
      );
      setAverageEngagementTimePerUser({
        formatted_period_avg_engagement_per_user,
        formatted_sum_of_daily_averages,
      });
      setUserAvgDataLoading(false);
    } catch (e) {
      console.error('Error fetching average login stats:', e);
      setUserAvgDataLoading(false);
      setAverageEngagementTimePerUser({
        formatted_period_avg_engagement_per_user: '0m 00s',
        formatted_sum_of_daily_averages: '0m 00s',
      });
    }
  };

  const fetchCustomAvgDateStats = async (startDate, endDate) => {
    try {
      setUserAvgDataLoading(true);
      const formattedStartDate = startDate.toISOString().split('T')[0];
      const formattedEndDate = endDate.toISOString().split('T')[0];
      const avgEngagementTimePerUser =
        await Api.analytics.getTotalUserAverageSessionTimeByCustomDate(
          'custom',
          formattedStartDate,
          formattedEndDate
        );

      const formatted_period_avg_engagement_per_user = formatTime(
        avgEngagementTimePerUser?.data[0].period_avg_engagement_per_user
      );
      const formatted_sum_of_daily_averages = formatTime(
        avgEngagementTimePerUser?.data[0].formatted_sum_of_daily_averages
      );
      setAverageEngagementTimePerUser({
        formatted_period_avg_engagement_per_user,
        formatted_sum_of_daily_averages,
      });
      setUserAvgDataLoading(false);
    } catch (error) {
      console.error('fetchCustomAvgDateStats error:', error);
      setUserAvgDataLoading(false);
      setAverageEngagementTimePerUser({
        formatted_period_avg_engagement_per_user: '0m 00s',
        formatted_sum_of_daily_averages: '0m 00s',
      });
    }
  };

  return {
    userAvgDataLoading,
    averageEngagementTimePerUser,
    fetchUserAvgLoginStats,
    fetchCustomAvgDateStats,
    formatTime,
  };
};
