import React from 'react';
import { Col, Row } from 'reactstrap';
import Select from 'react-select';
import DragHandle from '../../events/components/QuestionnaireBuilder/DragHandle';
import DeleteIcon from '../../events/components/QuestionnaireBuilder/icons/DeleteIcon';
import UpArrowIcon from '../../events/components/QuestionnaireBuilder/icons/UpArrowIcon';
import DownArrowIcon from '../../events/components/QuestionnaireBuilder/icons/DownArrowIcon';
import QuestionType, {
  QuestionTypeEnum,
} from '../../events/components/QuestionnaireBuilder/QuestionType';
import t from '../../../util/translation/translation';
import { optionsAvailable } from './utils';

const mapQuestionType = (type) => {
  switch (type) {
    case 'TEXT_INPUT':
      return QuestionTypeEnum.TEXT_FIELD;
    case 'CHECKBOX':
      return QuestionTypeEnum.CHECKBOX;
    case 'DROPDOWN':
      return QuestionTypeEnum.DROPDOWN;
    case 'RADIO_BUTTONS':
      return QuestionTypeEnum.RADIO_GROUP;
    case 'SCORE_PREDICTOR':
      return QuestionTypeEnum.SCORE_PREDICTOR;
    case 'PLAYER_PICKER':
      return QuestionTypeEnum.PLAYER_PICKER;
    default:
      return null;
  }
};

export default function QuestionItem(props) {
  const {
    question,
    onDelete,
    onMoveUp,
    handleInput,
    onMoveDown,
    type,
    availableTeams,
  } = props;
  const shouldMoveUpDisabled = props.first;
  const shouldMoveDownDisabled = props.last;
  const questionType = mapQuestionType(type);
  const getPlaceholder = () => {
    switch (type) {
      case 'RADIO_BUTTONS':
      case 'CHECKBOX':
      case 'DROPDOWN':
      case 'SCORE_PREDICTOR': {
        return t('questionaire.insertAnswerOption');
      }
      default:
        return '';
    }
  };
  const renderComponent = () => {
    if (type === 'PLAYER_PICKER') {
      return (
        <Select
          value={question.value}
          onChange={handleInput}
          options={availableTeams}
          className="form__form-group-select"
          placeholder={t('settingsObjectsShared.selectTeam')}
          multi={false}
        />
      );
    }
    return (
      <input
        placeholder={getPlaceholder()}
        disabled={type === 'TEXT_INPUT'}
        className="question-description"
        value={question.value}
        onInput={(e) => handleInput(e.target.value)}
      />
    );
  };
  if (type === 'TEXT_INPUT') {
    return null;
  }
  return (
    <div key={question.id} className="picked-question-card">
      <div className="content-area">
        <Row noGutters>
          <Col className="question-content">
            <div className="float-left draggable-handle">
              <DragHandle />
            </div>
            {renderComponent()}
          </Col>
          {optionsAvailable(type) && (
            <Col className="col-3">
              <div className="card-actions">
                <button
                  className="action-button red-border"
                  onClick={() => onDelete(question)}
                >
                  <DeleteIcon />
                </button>
                <button
                  className="action-button move-up"
                  disabled={shouldMoveUpDisabled}
                  onClick={() => onMoveUp(question)}
                >
                  <UpArrowIcon />
                </button>
                <button
                  className="action-button move-down"
                  disabled={shouldMoveDownDisabled}
                  onClick={() => onMoveDown(question)}
                >
                  <DownArrowIcon />
                </button>
              </div>
            </Col>
          )}
          <Col className="col-3 d-flex justify-content-end">
            <QuestionType type={questionType} />
          </Col>
        </Row>
      </div>
    </div>
  );
}
