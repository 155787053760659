import React, { useState } from 'react';
import { Container } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MatchTickets from './screens/match_tickets/index';
import SeasonTickets from './screens/season_tickets/index';
import NavTab from '../../shared/components/custom/NavTab';
import '../../scss/containers/tickets.scss';
import Help from '../../shared/components/custom/Help';
import { partial } from '../../util/translation/translation';

const TicketsPage = ({ me }) => {
  const [activeTab, setActiveTab] = useState(
    new URLSearchParams(window.location.search).get('tab') || '1'
  );
  const p = partial('TicketsPage');

  const renderTabs = () => {
    if (me === null) {
      return [];
    }
    const hasSeasonTicketsRole = me.permissions.includes(
      'access-season-tickets'
    );
    const hasTicketsRole = me.permissions.includes('access-tickets');
    const tabs = [];
    if (hasSeasonTicketsRole) {
      tabs.push(p('seasonTickets'));
    }
    if (hasTicketsRole) {
      tabs.push(p('matchTickets'));
    }
    return tabs;
  };

  return (
    <Container>
      <NavTab activeTab={activeTab} setTab={setActiveTab} tabs={renderTabs()} />
      <Help />
      {activeTab === '1' && <SeasonTickets />}
      {activeTab === '2' && <MatchTickets />}
    </Container>
  );
};

TicketsPage.propTypes = {
  me: PropTypes.shape({
    permissions: PropTypes.arrayOf(PropTypes.string),
  }),
  features: PropTypes.shape({
    dagsvip: PropTypes.bool,
  }).isRequired,
};

TicketsPage.defaultProps = {
  me: null,
};

const mapStateToProps = (state) => ({
  me: state.admin.me,
  features: state.enabledFeatures,
});

export default connect(mapStateToProps)(TicketsPage);
