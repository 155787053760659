import React, { useEffect, useState } from 'react';

import { Button, Table } from 'reactstrap';
import { toast } from 'react-toastify';
import api from '../../util/api';

const SyncMatchControl = () => {
  const [, setSyncMatches] = useState({});
  const [, setTodaysMatches] = useState([]);
  const [controlMatches, setControlMatches] = useState({
    red: [],
    green: [],
    yellow: [],
  });

  const _controlMatches = async (sync, today) => {
    const matchIds = Object.keys(sync)
      .reduce((t, e) => [...t, ...sync[e]], [])
      .map((e) => +e);
    const green = matchIds.filter((mid) => today.find((m) => m.id === mid));
    const yellow = matchIds.filter((mid) => !today.find((m) => m.id === mid));
    const red = today.filter((m) => !matchIds.includes(m.id));
    try {
      const yellowMatches = (
        await Promise.all(yellow.map((y) => api.clubadmin.match.fetch(y)))
      ).map((m) => m.data);
      return {
        red,
        green: today.filter((m) => green.includes(m.id)),
        yellow: yellowMatches,
      };
    } catch (e) {
      return {
        red,
        green: today.filter((m) => green.includes(m.id)),
        yellow: [],
      };
    }
  };

  const fetch = async () => {
    try {
      const { data: _syncMatches } = await api.clubadmin.syncMatch.fetch();
      const { data: _todaysMatchs } = await api.clubadmin.match.todaysMatches();
      setTodaysMatches(_todaysMatchs);
      setSyncMatches(_syncMatches);
      setControlMatches(await _controlMatches(_syncMatches, _todaysMatchs));
    } catch (e) {
      toast.error('Error fetching matches');
    }
  };
  const renderMatches = (matches, warningLevel) =>
    matches.map((match) => (
      <tr key={match.id}>
        <td>{match.id}</td>
        <td>{match.matchStart}</td>
        <td>{`${match?.homeTeam?.name} - ${match?.awayTeam?.name}`}</td>
        <td>{match.league?.name || ''}</td>
        <td>{match.league?.id || ''}</td>
        <td>
          <div
            style={{ width: 40, height: 40, backgroundColor: warningLevel }}
          />
        </td>
      </tr>
    ));

  const syncDailies = async () => {
    try {
      await api.clubadmin.leagues.syncDailies();
    } catch (e) {
      toast.error('Error syncing dailies');
    } finally {
      setTimeout(async () => {
        await fetch();
      }, 5000);
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <>
      <div className="flex space-between" style={{ float: 'right' }}>
        <Button color="info" onClick={syncDailies}>
          Run Sync dailies
        </Button>
      </div>
      <Table responsive bordered>
        <thead>
          <tr>
            <th>Match Id</th>
            <th>Match start</th>
            <th>Match name</th>
            <th>League</th>
            <th>League Id</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {renderMatches(controlMatches.red, 'red')}
          {renderMatches(controlMatches.green, 'green')}
          {renderMatches(controlMatches.yellow, 'yellow')}
        </tbody>
      </Table>
    </>
  );
};

export default SyncMatchControl;
