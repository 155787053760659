import React, { useEffect, useState } from 'react';
import { Container, Col, Card, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import TermForm from './components/TermForm';
import Api from '../../util/api';
import Loading from '../../shared/components/custom/Loading';
import { partial } from '../../util/translation/translation';
import useConfig from '../../util/useConfig';

const TermFormPage = ({ match }) => {
  const config = useConfig();
  const termID = match?.params?.termID;
  const [loading, setLoading] = useState(false);
  const [term, setTerm] = useState(null);
  const [allTermTypes, setAllTermTypes] = useState([]);
  const [blockedTermTypes, setBlockedTermTypes] = useState([]);
  const history = useHistory();
  const p = partial('settingsTerms');

  const fetchInitialData = async () => {
    setLoading(true);
    try {
      if (termID) {
        const temrData = await Api.terms.getTerm(termID, config);
        setTerm(temrData);
      }
      const [blockedTypes, allTypes] = await Promise.all([
        Api.terms.getBlockedTermTypes(config),
        Api.terms.getTermTypes(),
      ]);
      setAllTermTypes(allTypes);
      setBlockedTermTypes(blockedTypes);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(p('errorFetchingTerm'));
    }
  };
  useEffect(() => {
    fetchInitialData();
  }, [termID]);

  const handleSubmit = async (values) => {
    const payload = {
      description: values.description,
      intro: values.intro,
      title: values.title,
      type: values.type.value,
    };
    try {
      setLoading(true);
      await Api.terms.createUpdateTerm(payload, config);
      if (termID) {
        toast.success(p('termWasUpdated'));
        history.push('/settings?tab=7');
        setLoading(false);
      } else {
        toast.success(p('termWasCreated'));
        history.push('/settings?tab=7');
        setLoading(false);
      }
    } catch (err) {
      toast.error(err);
      setLoading(false);
    }
  };

  return (
    <Container>
      <Loading loading={loading} />
      <Col>
        <h3 className="page-title">
          {match.params.matchID ? p('editTerm') : p('addTerm')}
        </h3>
      </Col>
      <Col>
        <Card>
          <CardBody>
            <TermForm
              term={term}
              onSubmit={handleSubmit}
              allTermTypes={allTermTypes}
              blockedTermTypes={blockedTermTypes}
            />
          </CardBody>
        </Card>
      </Col>
    </Container>
  );
};

TermFormPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func,
  }).isRequired,
};

export default TermFormPage;
