import React from 'react';

import PlayersIcon from './icons/PlayersIcon';
import CountIcon from './icons/CountIcon';
import RadioIcon from './icons/RadioIcon';
import CheckboxIcon from './icons/CheckboxIcon';
import TextFieldIcon from './icons/TextFieldIcon';
import Dropdown from './icons/DropdownIcon';
import { partial } from '../../../../util/translation/translation';

export const QuestionTypeEnum = {
  DROPDOWN: 'dropdown',
  TEXT_FIELD: 'text_field',
  CHECKBOX: 'checkbox',
  RADIO_GROUP: 'radio_button',
  SCORE_PREDICTOR: 'score_predictor',
  PLAYER_PICKER: 'player_picker',
};

const qT = partial('question');

const questionTypeMap = new Map([
  [
    QuestionTypeEnum.DROPDOWN,
    {
      label: qT('dropdown'),
      icon: Dropdown,
    },
  ],
  [
    QuestionTypeEnum.TEXT_FIELD,
    {
      label: qT('textInput'),
      icon: TextFieldIcon,
    },
  ],
  [
    QuestionTypeEnum.CHECKBOX,
    {
      label: qT('checkbox'),
      icon: CheckboxIcon,
    },
  ],
  [
    QuestionTypeEnum.RADIO_GROUP,
    {
      label: qT('radioGroup'),
      icon: RadioIcon,
    },
  ],
  [
    QuestionTypeEnum.SCORE_PREDICTOR,
    {
      label: qT('scorePredictor'),
      icon: CountIcon,
    },
  ],
  [
    QuestionTypeEnum.PLAYER_PICKER,
    {
      label: qT('playerPicker'),
      icon: PlayersIcon,
    },
  ],
]);

export function getQuestionType(type) {
  return questionTypeMap.get(type);
}

export default function QuestionType(props) {
  const qType = getQuestionType(props.type);

  if (!qType) return null;

  const IconComponent = qType.icon;

  return (
    <div className="question-type">
      <div className="type-icon">
        <IconComponent />
      </div>
      {qType.label}
    </div>
  );
}
