import React from 'react';
import { Col, Button } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import renderField from '../../../shared/components/custom/Field';
import validateSeasonPriceForm from './validateSeasonPriceForm';
import renderCheckBoxField from '../../../shared/components/form/CheckBox';
import renderSelectField from '../../../shared/components/form/Select';
import { partial } from '../../../util/translation/translation';

const SeasonPriceForm = ({ handleSubmit, priceGroups }) => {
  const p = partial('seasonObjectsShared');
  const s = partial('shared');

  return (
    <form className="form season-price-form width-100p" onSubmit={handleSubmit}>
      <Col md={12} style={{ margin: '0 0 10px' }}>
        <h4>{p('createSeasonPriceGroup')}</h4>
      </Col>
      <Col lg={4} md={12}>
        <div className="form__form-group">
          <span className="form-header">{s('title')}</span>
          <Field
            name="title"
            component={renderField}
            placeholder={s('title')}
          />
        </div>
      </Col>
      <Col lg={8} md={12}>
        <div className="form__form-group">
          <span className="form-header">{s('description')}</span>
          <Field
            name="description"
            component={renderField}
            placeholder={s('description')}
          />
        </div>
      </Col>
      <Col lg={4} md={12}>
        <div className="form__form-group">
          <span className="form-header">{s('price')}</span>
          <Field
            name="price"
            type="number"
            component={renderField}
            placeholder={s('price')}
          />
        </div>
      </Col>
      <Col lg={4} md={12}>
        <div className="form__form-group">
          <span className="form-header">{p('priceGroupAffiliation')}</span>
          <Field
            name="priceGroup"
            component={renderSelectField}
            options={priceGroups}
            placeholder={p('selectPriceGroup')}
          />
        </div>
      </Col>
      <Col md={12}>
        <div className="form__form-group">
          <span className="form-header" />
          <Field
            name="buyable"
            component={renderCheckBoxField}
            label={p('canBePurchased')}
            className="colored-click"
          />
        </div>
        <div className="form__form-group">
          <span className="form-header" />
          <Field
            name="releasable"
            component={renderCheckBoxField}
            label={p('hasPickupDeadline')}
            className="colored-click"
          />
        </div>
      </Col>
      <Col md={12}>
        <Button color="primary">{p('createPriceGroup')}</Button>
      </Col>
    </form>
  );
};

SeasonPriceForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  priceGroups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
};

SeasonPriceForm.defaultProps = {};

const reduxF = reduxForm({
  form: 'season_price_form',
  validateSeasonPriceForm,
});

export default reduxF(SeasonPriceForm);
