import React, { useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  Col,
  Table,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from 'reactstrap';
import DotsHorizontalIcon from 'mdi-react/DotsHorizontalIcon';
import { Link } from 'react-router-dom';
import Api from '../../../util/api';
import Loading from '../../../shared/components/custom/Loading';
import { partial } from '../../../util/translation/translation';
import useConfig from '../../../util/useConfig';

const TermsList = () => {
  const config = useConfig();
  const p = partial('settingsTerms');
  const s = partial('shared');
  const [terms, setTerms] = useState([]);
  const [loading, setLoading] = useState(false);

  const getAllTerms = async () => {
    setLoading(true);
    try {
      const data = await Api.terms.getAllTerms(config);
      setTerms(data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllTerms();
  }, []);
  const renderTerms = () =>
    terms.map((term) => {
      const { type, title, intro, description } = term;
      const MAX_LENGTH = 30;
      const limitedText =
        description.length > MAX_LENGTH
          ? `${description.substring(0, MAX_LENGTH)}...`
          : description;
      return (
        <tr className="center-list" key={`term-${type}`}>
          <td>
            <Link to={`/settings/terms/edit/${type}`}>{title}</Link>
          </td>
          <td>{intro}</td>
          <td>{limitedText}</td>
          <td className="vertical-alignMiddle">
            {p(`termName-${type.toUpperCase()}`)}
          </td>
          <td className="vertical-alignMiddle">
            <UncontrolledDropdown className="dashboard__table-more">
              <DropdownToggle>
                <DotsHorizontalIcon />
              </DropdownToggle>
              <DropdownMenu className="dropdown__menu">
                <Link to={`/settings/terms/edit/${type}`}>
                  <DropdownItem>{s('edit')}</DropdownItem>
                </Link>
              </DropdownMenu>
            </UncontrolledDropdown>
          </td>
        </tr>
      );
    });

  return (
    <Col>
      <Loading loading={loading} />
      <Card>
        <CardBody>
          <div className="card__title marginTop-0">
            <p className="page-title">{s('terms')}</p>
            <Link
              className="btn btn-primary btn-sm"
              to="/settings/terms/create"
            >
              {p('createTerm')}
            </Link>
          </div>
          <Table responsive bordered>
            <thead>
              <tr>
                <th>{p('title')}</th>
                <th>{p('name')}</th>
                <th>{p('intro')}</th>
                <th>{p('text')}</th>
                <th>{p('termType')}</th>
                <th />
              </tr>
            </thead>
            <tbody>{renderTerms()}</tbody>
          </Table>
        </CardBody>
      </Card>
    </Col>
  );
};
TermsList.propTypes = {};

export default TermsList;
